import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import colors from '@packages/core/styles/colors';
import { WorksheetsFetchObject, WorksheetsFetchResponse, WorksheetGroupStatus, Worksheets } from '@packages/models/api';
import { BadgeVariants, Button, Icon, TextBadge, Typography } from '@packages/ui/shared';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import { worksheetsService } from '@web/services/singletons';
import { createUseStyles } from 'react-jss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './table.css';
import { WorkSheetStatusEnum } from '@packages/models/api';
import { WorksheetAction } from '../types';
import useWorksheetFormTranslation from '../hooks/use-worksheet-form-translation';
import useWorksheetPermission from '../hooks/use-worksheet-permission';

export const statusVariants: Record<string, BadgeVariants> = {
    [WorksheetGroupStatus.Pending]: 'red',
    [WorksheetGroupStatus.Archived]: 'default',
    [WorksheetGroupStatus.Published]: 'green',
    [WorksheetGroupStatus.Pilot]: 'yellow',
};

interface WorksheetRowModel extends WorksheetsFetchObject {
    rowId: string;
    worksheetGroupName: string;
}
interface NavigateUrl {
    worksheetId: string;
    status: WorksheetGroupStatus;
    allowEdit: boolean;
    maxVersion: number;
    isPreview?: boolean;
}

const StatusCell = ({ status, hide }: { status: WorksheetGroupStatus[]; hide?: boolean }) => {
    const statusLength = status.length;
    return (
        <TableCell className={`${hide ? 'd-none' : 'd-flex '}`} width={300}>
            {status.map((status: string, index: number) => {
                if (index <= 2) {
                    return (
                        <TextBadge variant={statusVariants[status]} style={{ marginLeft: 5 }} key={index}>
                            {status}
                        </TextBadge>
                    );
                }
                if (index === 3) {
                    return (
                        <TextBadge variant={'default'} style={{ marginLeft: 5 }} key={index}>
                            +{statusLength - 3}
                        </TextBadge>
                    );
                }

                return <Fragment key={index}></Fragment>;
            })}
        </TableCell>
    );
};

const DateCell = ({ date }: { date: Date | string }) => {
    return (
        <TableCell>
            {new Date(date).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            })}
        </TableCell>
    );
};

const ActionCell = ({
    rowId,
    status,
    totalCount,
    worksheetId,
    disabled,
    active,
    handleShowSubRows,
    allowEdit,
    maxVersion,
    navigateUrl,
    editPermission,
}: {
    rowId: string;
    status: WorksheetGroupStatus;
    totalCount: number;
    worksheetId: string;
    disabled?: boolean;
    active?: boolean;
    allowEdit: boolean;
    maxVersion: number;
    navigateUrl: ({ worksheetId, status, allowEdit, maxVersion }: NavigateUrl) => string;
    editPermission: boolean;
    handleShowSubRows: (id: string) => void;
}) => {
    const { dataTranslate } = useWorksheetFormTranslation();
    const navigate = useNavigate();
    return (
        <TableCell>
            <Button variant="link" disabled={disabled}>
                {totalCount > 1 ? (
                    <Icon
                        name={`chevron-${active ? 'up' : 'down'}`}
                        color="blueOne"
                        onPress={() => handleShowSubRows(rowId)}
                    />
                ) : (
                    <Button
                        variant="link"
                        disabled={status === WorksheetGroupStatus.Archived || !editPermission}
                        onPress={() => {
                            navigate(
                                navigateUrl({
                                    worksheetId,
                                    status,
                                    allowEdit,
                                    maxVersion,
                                })
                            );
                        }}
                    >
                        <Icon name={allowEdit ? 'pencil' : 'library-add'} color="blueOne" />
                    </Button>
                )}
            </Button>
        </TableCell>
    );
};

const TableBody = ({
    rowData,
    handleShowSubRows,
    show,
    showRowsId,
}: {
    rowData: WorksheetRowModel;
    handleShowSubRows: (id: string) => void;
    show: boolean;
    showRowsId?: string;
}) => {
    const classes = useStyleWrapper(showRowsId);
    const { isReadonlyPermission, isEditOnlyPermission } = useWorksheetPermission();

    const checkShow = rowData.rowId === showRowsId;
    const navigateUrl = useCallback(({ worksheetId, status, allowEdit, maxVersion, isPreview }: NavigateUrl) => {
        const base = isPreview
            ? `/administration/worksheet/${worksheetId}`
            : `/administration/worksheet/${worksheetId}/edit`;
        const actionAndMaxVersion = allowEdit
            ? `action=${WorksheetAction.Edit}&status=${status}`
            : `action=${WorksheetAction.AddVersion}&maxVersion=${maxVersion}&status=${
                  isPreview ? status : WorkSheetStatusEnum.Pending
              }`;
        return `${base}?${actionAndMaxVersion}`;
    }, []);
    const displayName = useMemo(
        () =>
            rowData.totalCount > 1
                ? `${rowData.worksheetGroupName} ${`(${rowData.totalCount})`}`
                : `${rowData.worksheets[0].displayName}`,
        [rowData.totalCount, rowData.worksheetGroupName, rowData.worksheets]
    );
    return (
        <>
            {
                <>
                    <TableRow key={rowData.rowId} className={checkShow ? classes.parentRow : ''}>
                        <TableCell>
                            {rowData.totalCount === 1 ? (
                                <Link
                                    to={navigateUrl({
                                        worksheetId: rowData.worksheets[0].worksheetId,
                                        status: rowData.statusList[0],
                                        allowEdit: rowData.worksheets[0].allowEdit,
                                        maxVersion: rowData.maxVersion,
                                        isPreview: true,
                                    })}
                                >
                                    {displayName}
                                </Link>
                            ) : (
                                <Link to={``} onClick={() => handleShowSubRows(rowData.rowId)}>
                                    {displayName}
                                </Link>
                            )}
                        </TableCell>

                        <TableCell>{rowData.caseType}</TableCell>
                        <StatusCell status={rowData.statusList} hide={checkShow} key={rowData.rowId} />
                        <DateCell date={rowData.lastUpdatedTimestampForDisplay} />
                        <ActionCell
                            rowId={rowData.rowId}
                            status={rowData.statusList[0]}
                            totalCount={rowData.totalCount}
                            worksheetId={rowData.worksheets[0].worksheetId}
                            handleShowSubRows={handleShowSubRows}
                            active={checkShow}
                            allowEdit={rowData.worksheets[0].allowEdit}
                            maxVersion={rowData.maxVersion}
                            navigateUrl={navigateUrl}
                            editPermission={isEditOnlyPermission}
                        />
                    </TableRow>
                    {rowData.totalCount > 1 &&
                        rowData.worksheets.map((w: Worksheets, index: number) => {
                            return (
                                <TableRow
                                    key={w.worksheetId}
                                    className={`${classes.subRows} ${
                                        checkShow
                                            ? index === rowData.worksheets.length - 1
                                                ? classes.bottomBorder
                                                : ''
                                            : 'd-none'
                                    }`}
                                >
                                    <TableCell>
                                        <Link
                                            to={navigateUrl({
                                                worksheetId: w.worksheetId,
                                                status: w.status,
                                                allowEdit: w.allowEdit,
                                                maxVersion: rowData.maxVersion,
                                                isPreview: true,
                                            })}
                                        >{`${w.displayNameWithVersion}`}</Link>
                                    </TableCell>
                                    <TableCell>{rowData.caseType}</TableCell>
                                    <StatusCell status={[w.status]} key={w.worksheetId} />
                                    <DateCell date={w.lastUpdatedTimestamp} />
                                    <ActionCell
                                        rowId={rowData.rowId}
                                        status={w.status}
                                        totalCount={1}
                                        worksheetId={w.worksheetId}
                                        handleShowSubRows={handleShowSubRows}
                                        active={checkShow}
                                        allowEdit={w.allowEdit}
                                        maxVersion={rowData.maxVersion}
                                        navigateUrl={navigateUrl}
                                        editPermission={isEditOnlyPermission}
                                    />
                                </TableRow>
                            );
                        })}
                </>
            }
        </>
    );
};

export const WorkSheetsTable: FC<{ tab: WorkSheetStatusEnum }> = ({ tab }) => {
    const { dataTranslate } = useWorksheetFormTranslation();
    const { search } = useLocation();

    const [worksheets, setWorksheets] = useState<WorksheetRowModel[]>([]);
    const [showRowsId, setShowRowsId] = useState<string>();
    const [show, setShow] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    useEffect(() => {
        handleShowSubRows('');
    }, [page, size]);

    useEffect(() => {
        setPage(0);
    }, [tab, search]);

    const fetchWorksheets = useCallback(async () => {
        setIsLoading(true);

        const paramsObj: {
            page: string;
            size: string;
            status?: string;
        } = {
            page: '' + page,
            size: '' + size,
        };

        if (tab !== WorkSheetStatusEnum.All) {
            paramsObj['status'] = tab;
        }

        // Extract existing search parameters from the current URL(like filters and search query).
        const searchParams = new URLSearchParams(search);

        // Merge existing search parameters with additional parameters like page, size, and status.
        Object.entries(paramsObj).forEach(([key, value]) => {
            searchParams.set(key, value);
        });

        await worksheetsService
            .fetchWorksheets({ ignoreCache: true, queryString: searchParams.toString() })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    console.error(response.data);
                    return;
                }
                setIsLoading(false);
                const data: WorksheetsFetchResponse = response.data;
                const worksheets = data.summaryMap;
                const worksheetsData: WorksheetRowModel[] = Object.keys(worksheets).map((w: string, index) => ({
                    ...worksheets[w],
                    worksheetGroupName: w,
                    rowId: index.toString(),
                }));
                setWorksheets(worksheetsData);
                setTotal(data.totalCount);
            });
        setShowRowsId('');
        setIsLoading(false);
    }, [page, size, tab, search]);

    useEffect(() => {
        fetchWorksheets();
    }, [page, size, tab, search]);

    const renderTableHeader = useCallback(() => {
        return (
            <TableRow>
                <TableHeader>
                    <Typography variant="h5">{dataTranslate.adminFormList.headers.name}</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">{dataTranslate.adminFormList.headers.caseType}</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">{dataTranslate.adminFormList.headers.status}</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">{dataTranslate.adminFormList.headers.lastUpdate}</Typography>
                </TableHeader>
                <TableHeader width={209}></TableHeader>
            </TableRow>
        );
    }, [dataTranslate]);

    const handleShowSubRows = useCallback(
        (id: string) => {
            setShow(!show);
            if (id === showRowsId) setShowRowsId('');
            else setShowRowsId(id);
        },
        [show, showRowsId]
    );

    const handlePageSizeChange = useCallback((value: number) => {
        setSize(value);
        setPage(0);
    }, []);

    return (
        <>
            <TableRenderer<WorksheetRowModel>
                isLoading={isLoading}
                tableRowsData={worksheets}
                tableRowsDataSetter={setWorksheets}
                noResultsIconName="administration"
                tableHeaderRowRenderer={() => {
                    return renderTableHeader();
                }}
                tableBodyRowRenderer={(rowData) => {
                    return (
                        <TableBody
                            rowData={rowData}
                            show={show}
                            showRowsId={showRowsId}
                            handleShowSubRows={handleShowSubRows}
                            key={rowData.rowId}
                        />
                    );
                }}
            />
            <TablePagination
                {...{ page, size, total }}
                disabled={isLoading}
                onClick={setPage}
                onSizeChange={handlePageSizeChange}
            />
        </>
    );
};

const useStyleWrapper = createUseStyles((show: string) => {
    const borderStyle = show ? `2px solid ${colors.blueOne}` : 0;
    return {
        subRows: {
            display: show ? '' : 'none',
            backgroundColor: colors.grayTwo,
            borderLeft: borderStyle,
            borderRight: borderStyle,
            transition: '3s display',
        },
        parentRow: {
            borderTop: borderStyle,
            borderLeft: borderStyle,
            borderRight: borderStyle,
        },
        bottomBorder: {
            borderBottom: `2px solid ${colors.blueOne}`,
        },
        displayHide: {
            display: show ? '' : 'none',
            transition: '3s display',
        },
    };
});
