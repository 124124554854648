import React, { FC, useEffect } from 'react';
import { bmisService } from '@web/services/singletons';
import { getDisplayDate, getDisplayValue } from '@web/utils';
import { setVin, useBmisDispatch, useBmisState } from '@packages/contexts/bmis';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@web/config/constants';
import { DataCard } from '@web/components/data-card';
import { BmisBatteryBadge } from './bmis-battery-badge';
import { useParams } from 'react-router-dom';

export const BmisSummaryCard: FC = () => {
    const bmisDispatch = useBmisDispatch();
    const { bmisData, loading } = useBmisState();
    const { vin: vinString } = useParams<{ vin: string }>();

    const { t } = useTranslation();
    const { finalBatteryTestDecision, rowGuid, serviceDateandTime, testType, toolType, voltage } = bmisData?.[0] || {};

    const data = [
        { label: t('Service Date'), value: getDisplayDate(serviceDateandTime, DATE_FORMAT.MMM_D_YEAR) },
        { label: t('Test Type'), value: getDisplayValue(testType) },
        { label: t('Voltage'), value: `${getDisplayValue(voltage)}v` },
        { label: t('Tool Type'), value: getDisplayValue(toolType) },
    ];

    useEffect(() => {
        setVin(bmisDispatch, bmisService, { vin: vinString, size: 1 });
    }, [bmisDispatch, vinString]);

    return (
        <DataCard
            data={data}
            footerButtonText="View Full Report"
            footerButtonLink={`./bmis/${rowGuid}`}
            headerText="Latest BMIS Test"
            headerBadge={<BmisBatteryBadge data-testid="bmis-status-badge" label={finalBatteryTestDecision} />}
            loading={loading}
            isEmpty={!loading && !bmisData?.length}
            emptyTitle="There is no BMIS Test for this Car"
            emptyDescription="A test will be available once the vehicle undergoes service."
        />
    );
};
