export interface PermissionsState {
    permissions: any;
    loading: boolean;
    error: string | null;
}

export const initialState: PermissionsState = {
    permissions: [],
    loading: false,
    error: null,
};

export type PermissionsAction =
    | { type: 'SET_PERMISSIONS'; payload: { permissions: any } }
    | { type: 'SET_LOADING'; payload: { loading: boolean } }
    | { type: 'SET_ERROR'; payload: { error: string | null } }
    | { type: 'CLEAR_PERMISSIONS' };

export const permissionsReducer = (state: PermissionsState, action: PermissionsAction): PermissionsState => {
    switch (action.type) {
        case 'SET_PERMISSIONS':
            return { ...state, permissions: action.payload.permissions, loading: false, error: null };
        case 'SET_LOADING':
            return { ...state, loading: action.payload.loading };
        case 'SET_ERROR':
            return { ...state, loading: false, error: action.payload.error };
        case 'CLEAR_PERMISSIONS':
            return { ...state, permissions: {}, loading: false, error: null };
        default:
            throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
};
