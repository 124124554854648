import React, { FC, useState, useEffect } from 'react';
import { Form, FormControl, InputGroup, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Icon, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { vinSessionService } from '@web/services/singletons';
import { useNavigate } from 'react-router-dom';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { VIN } from '../types';
import VinDetails from './vin-details';
import { useAuthState } from '@packages/contexts/auth/auth.hooks';
import { getVin } from '../utils/vinSessionUtils';

type VinSessionModalProps = {
    show: boolean;
    onHide: () => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createUseStyles({
    modalClass: {
        maxWidth: '528px',
        maxHeight: '504px',
    },
    text: {
        fontSize: '15px',
        lineHeight: '24px',
        color: '#071424',
    },
    vinText: {
        fontSize: '13px',
        lineHeight: '16px',
        width: '91%',
        marginRight: '8px',
    },
    vinCount: {
        minWidth: '35px',
        textAlign: 'right',
    },
    inputBox: {
        position: 'relative',
        fontSize: '15px',
        fontWeight: '400',
        lineHeight: '24px',
    },
    spinnerContainer: {
        position: 'absolute',
        right: 16,
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvent: 'none',
    },
    searchIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 16,
        top: '50%',
        transform: 'translateY(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvent: 'none',
    },
    vinNumber: {
        width: 160,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        fontSize: 15,
        fontWeight: 400,
        color: '#007BFF',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
});

const VinSessionModal: FC<VinSessionModalProps> = (props) => {
    const { account } = useAuthState();
    const { t } = useTranslation();
    const classes = useStyles();
    const { isLoading, setIsLoading, onHide } = props;
    const navigate = useNavigate();
    const { selectedVin } = useVinSessionState();
    const [vin, setVin] = useState<string>('');
    const [recentlyViewed, setRecentlyViewed] = useState<VIN[]>([]);
    // future implementation
    // const [activeVinSessions, setActiveVinSessions] = useState<VIN[]>([]);
    const [searchVin, setSearchVin] = useState<VIN>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        getRecentlyViewed();
        // getActiveVinSessions();
    }, []);

    async function getRecentlyViewed() {
        setIsLoading(true);
        try {
            const response = await vinSessionService.getRecentlyViewedVinData({
                ignoreCache: true,
                accountId: account?.accountId,
            });

            if (response.success) {
                const recentlyViewedData = response.data.map((record) => record.vehicleDetails);
                setRecentlyViewed(recentlyViewedData);
            }
        } catch (error) {
            window.alert(`Recently Viewed VIN Error: ${error.message}`);
            setIsLoading(false);
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }

    // future implementation
    // async function getActiveVinSessions() {
    //     setIsLoading(true);
    //     try {
    //         const response = await vinSessionService.getActiveVinSessions({
    //             ignoreCache: true,
    //             accountId: account?.accountId,
    //         });

    //         if (response.success) {
    //             console.log(response);
    //             // setActiveVinSessions(response.data);
    //         }
    //     } catch (error) {
    //         window.alert(`Actively viewed VIN Error: ${error.message}`);
    //         setIsLoading(false);
    //     }
    //     setTimeout(() => {
    //         setIsLoading(false);
    //     }, 500);
    // }

    async function fetchVin(vin: string) {
        if (vin.length < 8) {
            setErrorMessage('Please Enter atleast 8 digits to get results');
            return;
        }

        const data = await getVin(vin, setIsLoading);
        if (JSON.stringify(data) == '{}' || (data && !data.vinRecord)) {
            setErrorMessage('No Records Found');
            setIsLoading(false);
            return;
        }
        setSearchVin(data);
        setIsLoading(false);
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetchVin(vin);
    };

    const handleVinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 17) {
            return;
        }
        setErrorMessage('');
        setSearchVin(undefined);
        const upperCaseVin = e.target.value.toUpperCase();
        if (upperCaseVin.length == 8 || upperCaseVin.length == 17) {
            fetchVin(upperCaseVin);
        }
        setVin(upperCaseVin);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>);
        }
    };

    const handleClick = (vin: VIN) => {
        navigate('/vin/' + `${vin.vinRecord.vin}`);
        onHide();
    };

    return (
        <Modal
            show={props.show}
            centered={true}
            onHide={() => {
                props.onHide();
            }}
            dialogClassName={classes.modalClass}
        >
            <Modal.Header placeholder="" closeButton>
                <Modal.Title style={{ maxWidth: '446px', maxHeight: '32px' }}>{t('VIN Session')}</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ padding: '24px' }}>
                <Form style={{ marginBottom: '16px' }}>
                    <Form.Group style={{ minHeight: '88px', minWidth: '480px' }}>
                        <Form.Label style={{ width: '100%' }}>
                            <div className={classes.text}>
                                <Typography>Search by VIN</Typography>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={classes.vinText}>
                                    <Typography variant="caption" style={{ color: '#4C525D' }}>
                                        Enter at least the last 8 digits to get results.
                                    </Typography>
                                </div>
                                <div className={classes.vinCount}>
                                    <Typography variant="caption">{vin.length}/17</Typography>
                                </div>
                            </div>
                        </Form.Label>
                        <InputGroup>
                            <FormControl
                                className={classes.inputBox}
                                type="text"
                                onChange={handleVinChange}
                                value={vin}
                                aria-label="VIN"
                                disabled={isLoading}
                                onKeyDown={handleKeyDown}
                                style={errorMessage ? { border: '#DE2622 1px solid' } : {}}
                            />
                            {isLoading ? (
                                <div className={classes.spinnerContainer}>
                                    <Spinner animation="border" style={{ color: '#436CA1' }} />
                                </div>
                            ) : (
                                <div className={classes.searchIcon}>
                                    <Icon
                                        name="magnifying-glass"
                                        color="grayFive"
                                        style={{ color: 'rgb(76, 82, 93)' }}
                                        onPress={() => fetchVin(vin)}
                                    />
                                </div>
                            )}
                        </InputGroup>
                        <Typography variant="vinError" style={{ color: '#DE2622' }}>
                            {errorMessage}
                        </Typography>
                    </Form.Group>
                </Form>

                {searchVin && !errorMessage && (
                    <VinDetails
                        car={searchVin}
                        handleClick={handleClick}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        index={1}
                        onHide={onHide}
                    />
                )}

                {selectedVin && (
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ padding: '12 0 12 0' }}>
                            <Typography style={{ color: '#999DA3' }}>Current VIN Session</Typography>
                        </div>
                        <VinDetails
                            style={{ backgroundColor: '#DEE6EF', borderRadius: '4px', marginTop: 5 }}
                            car={selectedVin}
                            handleClick={handleClick}
                            index={1}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            onHide={onHide}
                        />
                    </div>
                )}

                {recentlyViewed.length > 0 && (
                    <div>
                        <div style={{ padding: '12 0 12 0' }}>
                            <Typography style={{ color: '#999DA3' }}>Recenty viewed</Typography>
                        </div>
                        {recentlyViewed.map((car, index: any) => (
                            <VinDetails
                                car={car}
                                handleClick={handleClick}
                                index={index}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                onHide={onHide}
                            />
                        ))}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default VinSessionModal;
