import React, { FC, useCallback, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { TablePagination } from '@web/components/table';
import { useLocation, useNavigate } from 'react-router-dom';
import useQuery from '@web/core/hooks/use-query';
import colors from '@packages/core/styles/colors';
import { Icon } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';

type DocsPaginationProps = {};

const useStyles = createUseStyles({
    paginationContainer: {
        height: 64,
        bottom: 0,
        borderTop: `1px solid ${colors.grayThree}`,
        paddingLeft: 20,
        marginBottom: 10,
        position: 'relative',
    },
    icon: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 36,
        width: 36,
        backgroundColor: colors.blueOne,
        right: '48px',
        bottom: '40px',
        borderRadius: 4,
    },
});

const DocsPagination: FC<DocsPaginationProps> = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const {} = props;
    const queryParams = useQuery();
    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 20) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 20) : 20;
    const { account } = useAuthState();

    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    return (
        <div className={classes.paginationContainer}>
            <TablePagination
                page={parsedPage}
                size={parsedSize}
                total={totalEntries}
                onClick={handlePaginationClick}
                onSizeChange={handlePaginationSizeChange}
                displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
            />
            {account && (
                <div className={classes.icon}>
                    <Icon name="question-mark" color="white" size={24} />
                </div>
            )}
        </div>
    );
};

export default DocsPagination;
