import { Dispatch, Reducer } from 'react';
import { action, on, props, reducer, union } from 'ts-action';
import { VinSessionActionTypes } from './vin-session.actionTypes';
import { VIN, VinSessionState } from '../types';

export const vinSessionActions = {
    setSelectedVin: action(VinSessionActionTypes.setSelectedVin, props<{ selectedVin: VIN | undefined }>()),
    setIsVinLoading: action(VinSessionActionTypes.setIsVinLoading, props<{ isVinLoading: boolean }>()),
    setSelectedFilters: action(VinSessionActionTypes.setSelectedFilters, props<{ selectedFilters: string[] }>()),
    setTitle: action(VinSessionActionTypes.setTitle, props<{ title: string }>()),
    setVinBannerOpen: action(VinSessionActionTypes.setVinBannerOpen, props<{ vinBannerOpen: boolean }>()),
};

const actionsUnion = union(...Object.values(vinSessionActions));
type VinSessionActions = typeof actionsUnion.actions;

export type VinSessionActionDispatcher = Dispatch<VinSessionActions>;

export const initialState: VinSessionState = {
    selectedVin: undefined,
    isVinLoading: false,
    selectedFilters: [],
    title: 'Subaru TechShare',
    vinBannerOpen: false,
};

export const vinSessionReducer: Reducer<VinSessionState, VinSessionActions> = reducer(
    initialState,
    on(vinSessionActions.setSelectedVin, (state, { selectedVin }) => {
        return {
            ...state,
            selectedVin: selectedVin,
        };
    }),
    on(vinSessionActions.setIsVinLoading, (state, { isVinLoading }) => {
        return {
            ...state,
            isVinLoading: isVinLoading,
        };
    }),
    on(vinSessionActions.setSelectedFilters, (state, { selectedFilters }) => {
        return {
            ...state,
            selectedFilters: selectedFilters,
        };
    }),
    on(vinSessionActions.setTitle, (state, { title }) => {
        return {
            ...state,
            title: title,
        };
    }),
    on(vinSessionActions.setVinBannerOpen, (state, { vinBannerOpen }) => {
        return {
            ...state,
            vinBannerOpen: vinBannerOpen,
        };
    })
);
