import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from '@packages/ui/shared';
import { Synonym } from '@packages/models/api/synonyms-search-term';
import { useSynonymsDispatch, deleteSynonym } from '@packages/contexts/synonyms';
import { synonymsService } from '@web/services/singletons';
interface AddSynonymModalProps {
    show: boolean;
    onHide: () => void;
    selectedSynonym?: Synonym;
}

export const DeleteSynonymModal: FC<AddSynonymModalProps> = (props) => {
    const { t } = useTranslation();
    const { show, onHide, selectedSynonym } = props;
    const synonymDispatch = useSynonymsDispatch();

    function handleDeleteSynonym() {
        if (selectedSynonym?.searchTermId) {
            deleteSynonym(synonymDispatch, synonymsService, selectedSynonym.searchTermId);
            onHide();
        }
    }

    return (
        <Modal show={show} centered={true} onHide={onHide} size="lg">
            <Modal.Header placeholder={'Delete Confirmation Synonym'}>
                <Modal.Title>{t('Delete Confirmation Synonym')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography variant="caption" color="black">
                    Do you want to eliminate the search term “{selectedSynonym?.searchTerm}” and their synonyms?
                </Typography>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="ghost-blue" style={{ marginRight: 8 }} onPress={onHide}>
                    {t('Cancel')}
                </Button>
                <Button variant="danger" isLoading={false} disabled={false} onPress={handleDeleteSynonym}>
                    {t('Delete')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
