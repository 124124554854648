import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import { StyleProp, TextStyle } from 'react-native';

export interface QuickReferenceCardProps {
    Icon: JSX.Element;
    title: string;
    image: string;
    version: string;
}

const useStyles = createUseStyles({
    cardContainer: {
        border: `1px solid ${colors.grayThree}`,
        borderRadius: '4px',
        minWidth: 265,
        minHeight: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
        '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        },
    },
    title: {
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'start',
        fontSize: '1rem',
        fontWeight: 'bold',
        width: '100%',
        maxWidth: '100%',
        backgroundColor: colors.grayOne,
        padding: '16px 12px',
    },
    text: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 15,
        fontWeight: 400,
    },
    imageContainer: {
        borderBottom: `1px solid ${colors.grayThree}`,
        padding: 16,
    },
    image: {
        height: '100%',
        width: '100%',
        objectFit: 'contain',
        marginBottom: 8,
    },
    version: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        height: '100%',
        width: '100%',
        padding: 12,
    },
});

export const QuickReferenceCard: FC<QuickReferenceCardProps> = (props) => {
    const classes = useStyles();
    const { Icon, title, image, version } = props;

    const typographyStyle = {
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '16px',
    } as unknown as StyleProp<TextStyle>;

    return (
        <div className={classes.cardContainer}>
            <div className={classes.title}>
                {Icon}
                <span className={classes.text}>{title}</span>
            </div>
            <div className={classes.imageContainer}>
                <img className={classes.image} src={image} alt={`Image for ${title}`} />
            </div>
            <div className={classes.version}>
                <Typography style={typographyStyle}>Version: {version}</Typography>
            </div>
        </div>
    );
};
