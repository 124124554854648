import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import React, { useCallback, useEffect, useRef, useState, FC, useMemo } from 'react';
import { qmrsService, analyticsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { useLocation, useNavigate } from 'react-router-dom';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { useTranslation } from 'react-i18next';
import useQuery from '@web/core/hooks/use-query';
import { Button, Icon, Typography } from '@packages/ui/shared';
import CreateQmrModal from '@web/components/create-qmr-modal';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import QmrQuickFiltersPanel from '@web/qmr/components/qmr-quick-filters-panel';
import { Modal } from 'react-bootstrap';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    cardContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    card: {
        width: '365px',
        borderLeft: `3px solid ${colors.grayThree}`,
        backgroundColor: colors.grayOne,
    },
    cardBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '3px',
        marginLeft: '2px',
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
        alignSelf: 'flex-start',
    },
});
interface QmrSearchBarProps {
    path: string;
    account: any;
    includeFilters: boolean;
    showNQQmrs?: boolean;
}

const QmrSearch: FC<QmrSearchBarProps> = ({ path, account, includeFilters, showNQQmrs }) => {
    const classes = useStyles();
    const { abortSignalRef } = useAbortController();
    const [recentSearches, setRecentSearches] = useState<string[]>([]);
    const [showCreateQmrModal, setShowCreateQmrModal] = useState(false);
    const [showCreateQMRPreSelectionModal, setShowCreateQMRPreSelectionModal] = useState(false);
    const [isNQRReports, setIsNQRReports] = useState(false);
    const searchInputRef = useRef(null);
    const { t } = useTranslation();
    const { pathname, search } = useLocation();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = useQuery();
    const quickSearchQuery = queryParams.get('query') || '';
    const queryMatch = search?.match(/query=([^&]*)/);
    let lastSearchQuery = queryMatch !== null ? queryMatch[1] : '';
    const hasQuickFilters = useMemo(() => {
        return (
            queryParams.has('retailer') ||
            queryParams.has('submissionDate') ||
            queryParams.has('carline') ||
            queryParams.has('yearFrom') ||
            queryParams.has('yearTo') ||
            queryParams.has('isImported') ||
            queryParams.has('hasTurboIndicator') ||
            queryParams.has('hasAttachments')
        );
    }, [queryParams]);

    useEffect(() => {
        const params = new URLSearchParams(search);
        if (!params.has('query')) {
            (searchInputRef.current as any).clear();
        }
    }, [search]);

    const fetchRecentSearches = useCallback(() => {
        qmrsService.fetchRecentQuickSearches({ ignoreCache: true, signal: abortSignalRef.current }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRecentSearches(response.data.recentSearches);
        });
    }, [abortSignalRef]);

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('qmrQuickFilters');
    }, [toggleOverlay]);

    useEffect(() => {
        if (location.state?.startNew) {
            // setShowCreateQMRPreSelectionModal(true);

            setIsNQRReports(false);
            setShowCreateQmrModal(true);
        }
    }, [location.state?.startNew]);

    useEffect(() => {
        fetchRecentSearches();
    }, [fetchRecentSearches]);

    const searchByText = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_BY_TEXT);
            params.set('page', '0');
            if (text) {
                params.set('query', text);
                if (!recentSearches.includes(text)) {
                    const lastFiveRecentSearchs = [text, ...recentSearches].slice(0, 5);
                    setRecentSearches(lastFiveRecentSearchs);
                }
            } else {
                params.delete('query');
            }

            navigate(`${path}?${params.toString()}`);
        },
        [navigate, path, recentSearches, search]
    );

    function handleCreateQmrButtonClick() {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_CREATE_A_QMR);
        // setShowCreateQMRPreSelectionModal(true);

        setIsNQRReports(false);
        setShowCreateQmrModal(true);
    }

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (quickSearchQuery) {
            newQueryParams.set('query', quickSearchQuery);
        }

        navigate(`${path}?${newQueryParams.toString()}`);
    }, [navigate, path, quickSearchQuery]);

    const renderCreateQMRCard = () => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2 ml-4`}>
                <Icon size={24} name="car-subaru" />
                <div className={`ml-2 ${classes.cardBody}`}>
                    <Typography variant="h6" color="blueOne">
                        Create a QMR if you need to:
                    </Typography>
                    <div className="ml-2 mt-2 mb-4">
                        <Typography color="black">
                            <ul>
                                <li>To report a customer/repair concern related to a Subaru Vehicle. </li> <br />
                                <li>To support a Technical Service Bulletin as directed by Subaru of America (SOA).</li>
                                <br />
                                <li>To support a recall claim as directed by Subaru of America (SOA).</li>
                                <br />
                                <li>To request release of a part on hold.</li>
                                <br />
                            </ul>
                        </Typography>
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            setShowCreateQMRPreSelectionModal(false);
                            setIsNQRReports(false);
                            setShowCreateQmrModal(true);
                        }}
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            Create QMR
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };

    const renderCreateNQRCard = () => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2 ml-4`}>
                <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M1 19C0.716667 19 0.479167 18.9042 0.2875 18.7125C0.0958333 18.5208 0 18.2833 0 18V10L2.1 4C2.2 3.7 2.37917 3.45833 2.6375 3.275C2.89583 3.09167 3.18333 3 3.5 3H8.3C8.2 3.31667 8.125 3.64167 8.075 3.975C8.025 4.30833 8 4.65 8 5H3.85L2.8 8H8.675C9.04167 8.8 9.55417 9.49583 10.2125 10.0875C10.8708 10.6792 11.6 11.1417 12.4 11.475C12.2667 11.625 12.1667 11.7875 12.1 11.9625C12.0333 12.1375 12 12.3167 12 12.5C12 12.9167 12.1458 13.2708 12.4375 13.5625C12.7292 13.8542 13.0833 14 13.5 14C13.9167 14 14.2708 13.8542 14.5625 13.5625C14.8542 13.2708 15 12.9167 15 12.5C15 12.4167 14.9958 12.3333 14.9875 12.25C14.9792 12.1667 14.9583 12.0833 14.925 12C15.4583 12 15.9833 11.9458 16.5 11.8375C17.0167 11.7292 17.5167 11.5583 18 11.325V18C18 18.2833 17.9042 18.5208 17.7125 18.7125C17.5208 18.9042 17.2833 19 17 19H16C15.7167 19 15.4792 18.9042 15.2875 18.7125C15.0958 18.5208 15 18.2833 15 18V17H3V18C3 18.2833 2.90417 18.5208 2.7125 18.7125C2.52083 18.9042 2.28333 19 2 19H1ZM4.5 14C4.91667 14 5.27083 13.8542 5.5625 13.5625C5.85417 13.2708 6 12.9167 6 12.5C6 12.0833 5.85417 11.7292 5.5625 11.4375C5.27083 11.1458 4.91667 11 4.5 11C4.08333 11 3.72917 11.1458 3.4375 11.4375C3.14583 11.7292 3 12.0833 3 12.5C3 12.9167 3.14583 13.2708 3.4375 13.5625C3.72917 13.8542 4.08333 14 4.5 14ZM15 10C13.6167 10 12.4375 9.5125 11.4625 8.5375C10.4875 7.5625 10 6.38333 10 5C10 3.63333 10.4833 2.45833 11.45 1.475C12.4167 0.491667 13.6 0 15 0C16.3833 0 17.5625 0.4875 18.5375 1.4625C19.5125 2.4375 20 3.61667 20 5C20 6.38333 19.5125 7.5625 18.5375 8.5375C17.5625 9.5125 16.3833 10 15 10ZM15 8C15.1333 8 15.25 7.95 15.35 7.85C15.45 7.75 15.5 7.63333 15.5 7.5C15.5 7.36667 15.45 7.25 15.35 7.15C15.25 7.05 15.1333 7 15 7C14.8667 7 14.75 7.05 14.65 7.15C14.55 7.25 14.5 7.36667 14.5 7.5C14.5 7.63333 14.55 7.75 14.65 7.85C14.75 7.95 14.8667 8 15 8ZM14.5 6H15.5V2H14.5V6Z"
                        fill="#071424"
                    />
                </svg>
                <div className={`ml-2 ${classes.cardBody}`}>
                    <Typography variant="h6" color="blueOne">
                        Create an NQR if you need to:
                    </Typography>
                    <div className="ml-2 mt-2 mb-4">
                        <Typography color="black">
                            <ul>
                                <li>To request warranty pre-authorization prior to repair.</li>
                            </ul>
                            These reports won't require a QA review.
                        </Typography>
                    </div>
                    <button
                        type="button"
                        onClick={() => {
                            setShowCreateQMRPreSelectionModal(false);
                            setIsNQRReports(true);
                            setShowCreateQmrModal(true);
                        }}
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            Create NQR
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };

    const createQMRPreSelectionModal = () => {
        return (
            <Modal
                show={showCreateQMRPreSelectionModal}
                centered={true}
                onHide={() => setShowCreateQMRPreSelectionModal(false)}
                size="lg"
            >
                <Modal.Header placeholder={''}>
                    <Modal.Title>Start New Report</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className={`${classes.cardContainer}`}>
                        {renderCreateQMRCard()}
                        {renderCreateNQRCard()}
                    </div>
                    <div className="mt-5">
                        <Typography>
                            *Once you select a report type (QMR or NQR), you'll be taken to its corresponding creation
                            flow and sections.
                        </Typography>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="mr-2">
                        <Button variant="ghost-blue" onPress={() => setShowCreateQMRPreSelectionModal(false)}>
                            {t('buttons:cancel')}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className="mt-4 d-flex" style={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {toggledOverlays.qmrQuickFilters && (
                <QmrQuickFiltersPanel
                    hasActiveFilters={hasQuickFilters}
                    onClear={handleClearQuickFilters}
                    onClose={toggleQuickFilterPanel}
                    path={path}
                    showNQQmrs={showNQQmrs}
                />
            )}
            {createQMRPreSelectionModal()}
            {(account?.systemCapabilities.createDraftQmr ||
                account?.systemCapabilities.createDraftQmrForAnyRetailer) && (
                <CreateQmrModal
                    show={showCreateQmrModal}
                    onHide={(identifier) => {
                        setShowCreateQmrModal(false);

                        if (identifier) {
                            navigate('/qmrs/' + identifier);
                        }
                    }}
                    isNQRReports={isNQRReports}
                />
            )}
            <div className="d-flex" style={{ width: '60%', gap: '5px' }}>
                <div style={{ width: '80%' }}>
                    <RecentSearchTypeahead
                        ref={searchInputRef}
                        onChange={searchByText}
                        placeholder={`Look for a ${
                            showNQQmrs ? 'NQR' : 'QMR'
                        } entering ID, Carline, DTC Code and more...`}
                        recentSearches={recentSearches}
                        quickSearchQuery={quickSearchQuery}
                        defaultInputValue={lastSearchQuery}
                    />
                </div>
                {includeFilters && (
                    <div>
                        <Button
                            active={hasQuickFilters}
                            variant="ghost-blue"
                            iconLeft={<Icon color="blueOne" name="filter-list" />}
                            onPress={toggleQuickFilterPanel}
                        >
                            {hasQuickFilters
                                ? t('views:qmrs.actions.filterToggle.active', 'Filters applied')
                                : t('views:qmrs.actions.filterToggle.inactive', 'Filters')}
                        </Button>
                        {hasQuickFilters && (
                            <div className="ml-1">
                                <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                    <Icon color="blueOne" name="x-close" />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div>
                {(account?.systemCapabilities.createDraftQmr ||
                    account?.systemCapabilities.createDraftQmrForAnyRetailer) && (
                    <div className="ml-auto d-flex">
                        <Button
                            iconRight={
                                <Icon
                                    size={16}
                                    raised
                                    name="plus"
                                    raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                />
                            }
                            onPress={handleCreateQmrButtonClick}
                            style={{ marginRight: 10 }}
                        >
                            {t('buttons:startQmr')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default QmrSearch;
