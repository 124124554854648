import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { Switch, ActivityIndicator } from 'react-native';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { EditorApi, UIEvent, PhotoEditorSDKUI, CanvasAction, Tool, ExportFormat } from 'photoeditorsdk';
import Viewer from 'react-viewer';
import { uniq, split } from 'lodash';

import colors from '@packages/core/styles/colors';

import { Asset, AssetType, AssetProcessingState } from '@packages/models/api';
import {
    useQmrState,
    deleteQmrAssets,
    useQmrDispatch,
    updateQmrRestrictedAssetIds,
    getQmrAssetDetails,
    updateQmrAsset,
    QmrAssetDetails,
} from '@packages/contexts/qmrs';
import { assetUploadsService, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { getMediaUrl } from '@packages/core/utils';
import { Icon, Typography } from '@packages/ui/shared';
import LoaderButton from '@web/components/loader-button';
import ConfirmDeleteAssetModal from './confirm-delete-asset-modal';

const useCanvasStyles = createUseStyles({
    canvasOuter: {
        zIndex: 110,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'flex',
        '& canvas': {
            display: 'block',
            border: `1px solid ${colors.black}`,
        },
    },
    canvasDisplay: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
    },
    canvasWrapper: {
        position: 'relative',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    canvasControlsOuter: {
        width: 400,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    canvasControlsHeader: {
        display: 'flex',
        flexDirection: 'column',
        padding: '34px 24px',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    nameWrapper: {
        flex: 1,
        minWidth: 0,
    },
    canvasControls: {
        padding: 24,
    },
    canvasControlsFormField: {
        paddingBottom: 24,
    },
    canvasControlsActions: {
        marginTop: 'auto',
        padding: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTop: `1px solid ${colors.grayThree}`,
    },
    viewerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        flex: 1,
        '& .react-viewer': {
            '& .react-viewer-canvas .react-viewer-image': {
                marginTop: '20px',
            },
        },
        '& .react-viewer-footer': {
            top: 0,
            height: 64,
            '& .react-viewer-btn': {
                backgroundColor: 'transparent',
                position: 'absolute',
                height: 24,
                width: 24,
            },
            '& .react-viewer-btn[data-key=zoomIn], .react-viewer-btn[data-key=zoomOut]': {
                top: 30,
            },
            '& .react-viewer-btn[data-key=zoomOut]': {
                left: '45%',
            },
            '& .react-viewer-btn[data-key=zoomIn]': {
                right: '45%',
            },
            '& .react-viewer-btn[data-key=download]': {
                top: 28,
                right: 42,
            },
        },
    },
    viewerOverrideContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        marginTop: -40,
        maxHeight: '77%',
        maxWidth: '77%',
    },
    viewerPrevBtn: {
        zIndex: 1200,
        position: 'absolute',
        top: '45%',
        left: 25,
    },
    viewerNextBtn: {
        zIndex: 1200,
        position: 'absolute',
        top: '45%',
        right: 25,
    },
    viewerScale: {
        zIndex: 1100,
        position: 'absolute',
        top: 24,
        paddingTop: 5,
    },
    viewerClose: {
        zIndex: 1200,
        position: 'absolute',
        top: 24,
        left: 30,
    },
    fileIconContainer: {
        zIndex: 1100,
        position: 'absolute',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    },
});

interface AssetEditorModalProps {
    viewOnly: boolean;
    assetIndex: number;
    toggleEdit: () => void;
    onClose: () => void;
    toggle: () => void;
}

export const AssetEditorModal = React.memo(
    ({ viewOnly, assetIndex, toggleEdit, onClose, toggle }: AssetEditorModalProps) => {
        const { t } = useTranslation();
        const assetEditorRootRef = useRef(document.getElementById('asset-editor-root'));
        const assetEditorElementRef = useRef(document.createElement('div'));
        const editorRef = useRef<EditorApi | null>(null);
        const originalBlobRef = useRef<Blob | null>(null);
        const viewerContainerRef = useRef(null);
        const { qmr } = useQmrState();
        const [editorReady, setEditorReady] = useState(false);
        const [viewerData, setViewerData] = useState<any[]>([]);
        const [activeViewerIndex, setActiveViewerIndex] = useState(assetIndex);
        const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(false);
        const confirmDeleteAssetCallback = useRef<() => Promise<any>>();
        const [zoomScaleDisplay, setZoomScaleDisplay] = useState('100%');
        const qmrAssetsDetails = useMemo(() => getQmrAssetDetails(qmr), [qmr]);
        const [selectedAssetDetails, setSelectedAssetDetails] = useState<QmrAssetDetails>(
            qmrAssetsDetails[activeViewerIndex] || {}
        );
        useEffect(() => {
            setSelectedAssetDetails(qmrAssetsDetails[activeViewerIndex]);
        }, [activeViewerIndex]);

        const [isSaving, setIsSaving] = useState(false);

        const refreshViewerData = useCallback(() => {
            setViewerData(
                qmrAssetsDetails.map((a) => {
                    const url = getMediaUrl(a.media);

                    return {
                        type: a.assetTypeId,
                        assetProcessingStateId: a.assetProcessingStateId,
                        src: a.assetTypeId !== AssetType.Image ? '' : url,
                        alt: a.name,
                        iconName: a.media.FILE && a.media.FILE.iconName,
                        downloadUrl: url,
                    };
                })
            );
        }, [qmrAssetsDetails]);

        useEffect(() => {
            refreshViewerData();
        }, [refreshViewerData]);

        const [assetNameInputValue, setAssetNameInputValue] = useState(selectedAssetDetails?.name ?? '');
        const [assetNoteInputValue, setAssetNoteInputValue] = useState(selectedAssetDetails?.note ?? '');
        const [inValidMediaName, setInValidMediaName] = useState(false);
        const [validationMessage, setValidationMessage] = useState('');
        const { abortSignalRef } = useAbortController();
        const qmrDispatch = useQmrDispatch();

        const classes = useCanvasStyles();

        const showEditorSDK = !viewOnly && selectedAssetDetails.assetTypeId === AssetType.Image;

        const handleCanvasClose = useCallback(() => {
            editorRef.current?.dispose();
            onClose();
        }, [onClose]);

        // Mount the modal as sibling to app
        // worth mentioning, this component/modal renders through a PORTAL
        useEffect(() => {
            const rootRef = assetEditorRootRef.current;
            const elRef = assetEditorElementRef.current;
            elRef.classList.toggle(classes.canvasOuter, true);
            rootRef?.appendChild(assetEditorElementRef.current);
            const imageUrl = getMediaUrl(selectedAssetDetails.media);
            if (showEditorSDK && imageUrl) {
                PhotoEditorSDKUI.init({
                    container: '#editor',
                    image: imageUrl,
                    license: JSON.stringify(require('@web/pesdk_html5_license.json')),
                    mainCanvasActions: [CanvasAction.UNDO, CanvasAction.REDO],
                    assetBaseUrl: '/photoeditorsdk/assets/',
                    defaultTool: Tool.TRANSFORM,
                    tools: [Tool.TRANSFORM, Tool.ADJUSTMENT, Tool.BRUSH],
                    [Tool.TRANSFORM]: {
                        categories: [
                            {
                                identifier: 'imgly_transforms_common',
                                items: [
                                    { identifier: 'imgly_transform_common_custom' },
                                    { identifier: 'imgly_transform_common_square' },
                                    { identifier: 'imgly_transform_common_4' },
                                    { identifier: 'imgly_transform_common_16' },
                                    { identifier: 'imgly_transform_common_3' },
                                    { identifier: 'imgly_transform_common_9' },
                                ],
                            },
                        ],
                    },
                    order: 'reverse',
                    export: {
                        image: {
                            exportType: ExportFormat.BLOB,
                            quality: 1,
                            enableDownload: false,
                        },
                    },
                })
                    .then((editor) => {
                        editorRef.current = editor;
                        setEditorReady(true);

                        editor
                            .export()
                            .then((data) => {
                                if (data instanceof Blob) {
                                    originalBlobRef.current = data;
                                }
                            })
                            .catch((e) => {
                                console.log('Export Error', e);
                            });
                    })
                    .catch((e) => {
                        console.log('PhotoEditorSDK Init failed', e);
                    });
            }

            return () => {
                editorRef.current?.dispose();
                rootRef?.removeChild(elRef);
            };
        }, [selectedAssetDetails.media, classes.canvasOuter, showEditorSDK]);

        // Setup mutation observer to watch Scale css changes
        // and update UI Zoom Text
        useEffect(() => {
            if (!viewOnly) {
                return;
            }

            const obs = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    //@ts-ignore
                    const xform: string = mutation.target.style.transform;

                    const matches = xform.match(/scaleX\(([\d\.]+)\)/);

                    if (!matches || !matches[1]) {
                        setZoomScaleDisplay('100%');
                    } else {
                        const scale = parseFloat(matches[1]) * 100;
                        setZoomScaleDisplay(Math.round(scale) + '%');
                    }
                });
            });

            obs.observe(document.getElementById('viewer')!, {
                subtree: true,
                attributes: true,
            });

            return () => {
                obs.disconnect();
            };
        }, [viewOnly]);

        useEffect(() => {
            if (!editorReady) {
                return;
            }

            editorRef.current?.on(UIEvent.CLOSE, handleCanvasClose);

            return () => {
                editorRef.current?.off(UIEvent.CLOSE, handleCanvasClose);
            };
        }, [editorReady, handleCanvasClose]);

        function handleDeleteAsset() {
            setShowDeleteAssetModal(true);
            confirmDeleteAssetCallback.current = () => {
                if (!qmr) {
                    return Promise.resolve();
                }

                return deleteQmrAssets({
                    qmrId: qmr.qmrId,
                    assetIds: [selectedAssetDetails.assetId],
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                })
                    .then((updatedQmr) => {
                        if (!updatedQmr) {
                            return;
                        }

                        handleCanvasClose();
                    })
                    .catch((error) => {
                        alert(error.message);
                    });
            };
        }

        function uploadEditedAsset() {
            setIsSaving(true);

            // const nameChanged = assetNameInputValue && assetNameInputValue !== assetDetails.name;
            // const noteChanged = assetNoteInputValue && assetNoteInputValue !== assetDetails.note;

            // TODO: exporting "original" from editor flags it as "hasChanges"
            // Most likey need to refactor on backend before making changes here
            // so that we can upload "edits" without re-uploading original

            // if (!editorRef.current?.hasChanges() && (nameChanged || noteChanged)) {
            //     updateQmrAsset({
            //         assetId: asset.assetId,
            //         assetPatch: {
            //             name: assetNameInputValue,
            //             note: assetNoteInputValue,
            //         },
            //         qmrsService,
            //         qmrDispatch,
            //         signal: abortSignalRef.current,
            //     })
            //         .then(() => {
            //             editorRef.current?.close();
            //         })
            //         .catch((error) => {
            //             console.log(error);
            //             alert('TODO: Unable to patch asset');
            //         });
            // }

            if (selectedAssetDetails.assetTypeId !== AssetType.Image) {
                updateQmrAsset({
                    assetId: selectedAssetDetails.assetId,
                    assetPatch: {
                        name: assetNameInputValue,
                        note: assetNoteInputValue,
                    },
                    qmrsService,
                    qmrDispatch,
                    signal: abortSignalRef.current,
                })
                    .then((updatedAsset) => {
                        if (!updatedAsset) {
                            return;
                        }

                        setIsSaving(false);
                        handleCanvasClose();
                    })
                    .catch((error) => {
                        setIsSaving(false);
                        window.alert(error.message);
                    });

                return;
            }

            if (!editorRef.current) {
                setIsSaving(false);
                handleCanvasClose();
                return;
            }

            editorRef.current.export().then((strokeBlob) => {
                assetUploadsService
                    .queueUploadTasks([
                        {
                            assetTypeId: AssetType.Image,
                            assetDisposition: {
                                assetDispositionId: 'QMR' as 'QMR',
                                qmrId: qmr!.qmrId,
                            },
                            assetUri: originalBlobRef.current as any,
                            name: assetNameInputValue,
                            note: assetNoteInputValue,
                            overlayUri: strokeBlob as any,
                            contentType: originalBlobRef.current?.type,
                            replacesAssetId: selectedAssetDetails.assetId,
                            eventHandlers: {
                                onCompleted: (uploadId: string, asset: Asset) => {
                                    setTimeout(() => {
                                        setIsSaving(false);
                                        handleCanvasClose();
                                    }, 2500);
                                },
                                onError: (uploadId: string, asset: Asset, error: any) => {
                                    setIsSaving(false);
                                    if (typeof error === 'string') {
                                        alert(error);
                                    } else if (error.message) {
                                        alert(error.message);
                                    }
                                },
                            },
                        },
                    ])
                    .then(() => {
                        toggle();
                    })
                    .catch((error) => {
                        setIsSaving(false);
                        window.alert(error.message);
                    });
            });
        }

        let editName = '';
        if (viewOnly) {
            editName = selectedAssetDetails.name;
        } else {
            editName =
                selectedAssetDetails.assetTypeId === AssetType.Image
                    ? t('modals:assetEditor.imageTitle')
                    : t('modals:assetEditor.videoTitle');
        }

        const activeViewerAsset = viewerData[activeViewerIndex];
        const isProcessing =
            activeViewerAsset && activeViewerAsset.assetProcessingStateId === AssetProcessingState.Processing;

        return ReactDOM.createPortal(
            <>
                {qmr?.capabilities.deleteAssetFromQmr && (
                    <ConfirmDeleteAssetModal
                        show={showDeleteAssetModal}
                        onConfirm={() => {
                            if (confirmDeleteAssetCallback.current) {
                                confirmDeleteAssetCallback.current().finally(() => {
                                    setShowDeleteAssetModal(false);
                                });
                            }
                        }}
                        onHide={() => {
                            setShowDeleteAssetModal(false);
                        }}
                    />
                )}

                <div className={classes.canvasDisplay}>
                    {!showEditorSDK && (
                        <>
                            <div id="viewer" ref={viewerContainerRef} className={classes.viewerWrapper}>
                                {viewerData.length <= 1 ? null : (
                                    <>
                                        <div className={classes.viewerPrevBtn}>
                                            <Icon
                                                name="chevron-left"
                                                size={40}
                                                color="white"
                                                onPress={() => {
                                                    if (activeViewerIndex !== 0) {
                                                        setActiveViewerIndex(activeViewerIndex - 1);
                                                    } else {
                                                        setActiveViewerIndex(viewerData.length - 1);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className={classes.viewerNextBtn}>
                                            <Icon
                                                name="chevron-right"
                                                size={40}
                                                color="white"
                                                onPress={() => {
                                                    if (activeViewerIndex < viewerData.length - 1) {
                                                        setActiveViewerIndex(activeViewerIndex + 1);
                                                    } else {
                                                        setActiveViewerIndex(0);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                                {activeViewerAsset && activeViewerAsset.type === AssetType.Image && (
                                    <div className={classes.viewerScale}>
                                        <Typography color="white">{zoomScaleDisplay}</Typography>
                                    </div>
                                )}

                                <div className={classes.viewerClose}>
                                    <Icon name="x-close" color="white" size={32} onPress={onClose} />
                                </div>
                            </div>

                            {activeViewerAsset && (
                                <>
                                    {isProcessing ? (
                                        <div className={classes.viewerOverrideContainer}>
                                            <ActivityIndicator size="large" color={colors.blueOne} />

                                            <div className="mt-4">
                                                <Typography color="white">
                                                    {t('modals:assetEditor.assetProcessing', 'upload processing...')}
                                                </Typography>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <Viewer
                                                visible
                                                images={viewerData}
                                                activeIndex={activeViewerIndex}
                                                container={viewerContainerRef.current as any}
                                                noNavbar
                                                noClose
                                                downloadable={qmr?.capabilities.downloadAttachmentsOnQmr}
                                                disableMouseZoom
                                                disableKeyboardSupport={!viewOnly}
                                                zoomSpeed={0.1}
                                                attribute={false}
                                                customToolbar={() => {
                                                    let actions = [
                                                        {
                                                            key: 'download',
                                                            actionType: 11,
                                                            render: <Icon name="download" color="white" />,
                                                        },
                                                    ];

                                                    if (activeViewerAsset.type === AssetType.Image) {
                                                        actions = [
                                                            {
                                                                key: 'zoomIn',
                                                                actionType: 1,
                                                                render: <Icon name="plus" color="white" />,
                                                            },
                                                            {
                                                                key: 'zoomOut',
                                                                actionType: 2,
                                                                render: <Icon name="minus" color="white" />,
                                                            },
                                                            ...actions,
                                                        ];
                                                    }

                                                    return actions;
                                                }}
                                            />

                                            {activeViewerAsset.type === AssetType.Video && (
                                                <video
                                                    key={activeViewerAsset.downloadUrl}
                                                    className={classes.viewerOverrideContainer}
                                                    controls
                                                >
                                                    <source src={activeViewerAsset.downloadUrl} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            )}

                                            {activeViewerAsset.type === AssetType.File && (
                                                <div className={classes.fileIconContainer}>
                                                    <Icon name={activeViewerAsset.iconName} size={280} color="white" />

                                                    <Typography variant="lead" color="white">
                                                        {t(
                                                            'modals:assetEditor.noPreview',
                                                            'Preview not available.\r\nDownload to view the file.'
                                                        )}
                                                    </Typography>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {showEditorSDK && <div id="editor" className={classes.canvasWrapper}></div>}
                </div>

                <div className={classes.canvasControlsOuter}>
                    <div className={classes.canvasControlsHeader}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className={classes.nameWrapper}>
                                <Typography variant="h4">{editName}</Typography>
                            </div>

                            {qmr?.capabilities.editMediaOnQmr && (
                                <Button
                                    variant="link"
                                    onClick={() => {
                                        if (!viewOnly) {
                                            setAssetNameInputValue(selectedAssetDetails?.name);
                                            setAssetNoteInputValue(selectedAssetDetails?.note);
                                        }

                                        toggleEdit();
                                        // SD-766 workaround - otherwise Viewer component was not properly finding its parent containerRef
                                        setTimeout(() => {
                                            refreshViewerData();
                                        }, 200);
                                    }}
                                >
                                    {viewOnly
                                        ? t('modals:assetEditor.actions.edit')
                                        : t('modals:assetEditor.actions.cancel')}
                                </Button>
                            )}
                        </div>

                        {viewOnly && (
                            <div style={{ paddingBottom: 100 }}>
                                <Typography color="textDarkSecondary">
                                    {selectedAssetDetails.note || t('modals:assetEditor.noDescription')}
                                </Typography>
                            </div>
                        )}
                    </div>

                    <Form className={classes.canvasControls}>
                        {!viewOnly && (
                            <>
                                {qmr?.capabilities.viewMediaOnQmr && (
                                    <Form.Group className={classes.canvasControlsFormField} controlId="filename">
                                        <Form.Label>
                                            <strong>{t('qmr:inputs.asset.filename.label', 'Media Name')}</strong>
                                            <span style={{ color: 'red' }}> *</span>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder={t('qmr:inputs.asset.filename.placeholder', 'Enter name')}
                                            value={assetNameInputValue}
                                            isInvalid={inValidMediaName}
                                            onChange={(e) => {
                                                const newVal = e.target.value;
                                                const originalFileExtn =
                                                    selectedAssetDetails?.name &&
                                                    selectedAssetDetails?.name.substring(
                                                        selectedAssetDetails?.name.lastIndexOf('.'),
                                                        selectedAssetDetails?.name.length
                                                    );
                                                const newFileName = newVal.substring(0, newVal.lastIndexOf('.'));
                                                const newFileExtn = newVal.substring(
                                                    newVal.lastIndexOf('.'),
                                                    newVal.length
                                                );

                                                if (newFileName === '') {
                                                    setInValidMediaName(true);
                                                    setValidationMessage('Media name cannot be empty.');
                                                } else if (originalFileExtn !== newFileExtn || newVal === newFileExtn) {
                                                    setInValidMediaName(true);
                                                    setValidationMessage(
                                                        'Invalid Media name. File extension must match.'
                                                    );
                                                } else {
                                                    setInValidMediaName(false);
                                                }
                                                setAssetNameInputValue(newVal);
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {validationMessage}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                )}
                                <Form.Group className={classes.canvasControlsFormField} controlId="note">
                                    <Form.Label>
                                        <strong>{t('qmr:inputs.asset.note.label', 'Description')}</strong>
                                        <span style={{ fontSize: 'smaller', color: '#59616c' }}> (Optional)</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        value={assetNoteInputValue}
                                        onChange={(e) => setAssetNoteInputValue(e.target.value)}
                                        placeholder={t('qmr:inputs.asset.note.placeholder')}
                                    />
                                </Form.Group>
                            </>
                        )}

                        {qmr?.capabilities.markRestrictedMediaOnQmr && (
                            <div className="d-flex justify-content-between align-items-center">
                                <Typography variant="label">{t('modals:assetEditor.setRestricted')}</Typography>

                                <Switch
                                    trackColor={{
                                        false: colors.grayFive,
                                        true: colors.blueThree,
                                    }}
                                    thumbColor={selectedAssetDetails?.isRestricted ? colors.blueOne : colors.grayThree}
                                    value={selectedAssetDetails?.isRestricted}
                                    onValueChange={(newVal) => {
                                        setSelectedAssetDetails({
                                            ...selectedAssetDetails,
                                            isRestricted: newVal,
                                        });

                                        if (!qmr) {
                                            return;
                                        }

                                        let restrictedAssetIds = qmr.restrictedAssetIds || [];

                                        restrictedAssetIds = newVal
                                            ? uniq([...restrictedAssetIds, selectedAssetDetails.assetId])
                                            : (restrictedAssetIds = restrictedAssetIds.filter(
                                                  (id) => id !== selectedAssetDetails.assetId
                                              ));

                                        updateQmrRestrictedAssetIds({
                                            qmrId: qmr.qmrId,
                                            restrictedAssetIds,
                                            qmrDispatch,
                                            qmrsService,
                                        }).catch((error) => {
                                            alert(error.message);
                                            setSelectedAssetDetails({
                                                ...selectedAssetDetails,
                                                isRestricted: newVal,
                                            });
                                        });
                                    }}
                                />
                            </div>
                        )}
                    </Form>

                    <div className={classes.canvasControlsActions}>
                        {qmr?.capabilities.deleteAssetFromQmr && (
                            <Button variant="danger" onClick={handleDeleteAsset}>
                                {selectedAssetDetails.assetTypeId === AssetType.File
                                    ? t('modals:assetEditor.actions.deleteFile', 'Delete File')
                                    : selectedAssetDetails.assetTypeId === AssetType.Video
                                      ? t('modals:assetEditor.actions.deleteVideo')
                                      : t('modals:assetEditor.actions.deleteImage')}
                            </Button>
                        )}

                        {!viewOnly && qmr?.capabilities.editMediaOnQmr && (
                            <LoaderButton
                                isLoading={isSaving}
                                variant="primary"
                                disabled={inValidMediaName}
                                onClick={uploadEditedAsset}
                            >
                                {t('modals:assetEditor.actions.save')}
                            </LoaderButton>
                        )}
                    </div>
                </div>
            </>,
            assetEditorElementRef.current
        );
    }
);

export default AssetEditorModal;
