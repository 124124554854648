import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Switch } from 'react-native';
import { createUseStyles } from 'react-jss';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Select, { SelectOption } from '@web/components/select';

import colors from '@packages/core/styles/colors';
import { Typography, Icon, Button } from '@packages/ui/shared';
import useQuery from '@web/core/hooks/use-query';
import { useNavigate } from 'react-router-dom';
import { CarlineQuickFilterOption, QuickFilterOption } from '@packages/models/api';
import { analyticsService, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAuthState } from '@packages/contexts/auth';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '16px 24px',
    },
    filterContent: {
        margin: '0 24px 16px',
    },
    hr: {
        margin: '0 24px',
    },
});

type Props = {
    hasActiveFilters: boolean;
    onClose: () => void;
    onClear: () => void;
    path: string;
    showNQQmrs?: boolean;
};

const QmrQuickFiltersPanel = (props: Props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const currentQueryParams = useQuery();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();
    const { account } = useAuthState();

    const [selectedRetailerOptions, setSelectedRetailerOptions] = useState(currentQueryParams.getAll('retailer'));
    const [selectedSubmissionDate, setSelectedSubmissionDate] = useState(
        currentQueryParams.get('submissionDate') || ''
    );
    const [selectedCarlines, setSelectedCarlines] = useState<CarlineQuickFilterOption[]>([]);
    const [selectedYearFrom, setSelectedYearFrom] = useState(currentQueryParams.get('yearFrom') || '');
    const [selectedYearTo, setSelectedYearTo] = useState(currentQueryParams.get('yearTo') || '');
    const [selectedIsImported, setSelectedIsImported] = useState(currentQueryParams.get('isImported' || ''));
    const [selectedOtherFlags, setSelectedOtherFlags] = useState({
        hasTurboIndicator: !!currentQueryParams.get('hasTurboIndicator'),
        hasAttachments: !!currentQueryParams.get('hasAttachments'),
    });

    const [retailerOptions, setRetailerOptions] = useState<QuickFilterOption[]>([]);
    const [carlineInputFocused, setCarlineInputFocused] = useState(false);
    const [carlineOptions, setCarlineOptions] = useState<CarlineQuickFilterOption[]>([]);
    const [submissionDateOptions, setSubmissionDateOptions] = useState<SelectOption[]>([]);
    const [modelYearOptions, setModelYearOptions] = useState<SelectOption[]>([]);

    const handleChangeRetailerFilter = useCallback(
        (optionId: string) => {
            if (selectedRetailerOptions.includes(optionId)) {
                setSelectedRetailerOptions(selectedRetailerOptions.filter((o) => o !== optionId));
                return;
            }

            setSelectedRetailerOptions([...selectedRetailerOptions, optionId]);
        },
        [selectedRetailerOptions]
    );

    useEffect(() => {
        qmrsService.fetchQmrQuickFilters({ signal: abortSignalRef.current }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRetailerOptions(response.data.retailers);

            const carlinesFromParams = currentQueryParams.getAll('carline');
            setCarlineOptions(response.data.carlines);
            setSelectedCarlines(response.data.carlines.filter((c) => carlinesFromParams.includes(c.carlineId)));
            setModelYearOptions([
                {
                    title: '—',
                    value: '',
                },
                ...response.data.yearRange.map((option) => ({
                    title: option.description,
                    value: option.id,
                })),
            ]);
            setSubmissionDateOptions([
                {
                    title: t('text:common.select', 'Select'),
                    value: '',
                },
                ...response.data.submissionDate.map((option) => ({
                    title: option.description,
                    value: option.id,
                })),
            ]);
        });
    }, [abortSignalRef, currentQueryParams, t]);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    const divider = <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>{t('qmr:quickFilters.title', 'Filters')}</Typography>

                <div className={classes.closeButton} onClick={props.onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('qmr:quickFilters.retailers.label', 'Retailers')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    {retailerOptions.map((option, index) => {
                        return (
                            <Form.Check
                                className="mb-3"
                                label={option.description}
                                type="checkbox"
                                key={option.id}
                                id={option.id}
                                checked={selectedRetailerOptions.includes(option.id)}
                                onChange={() => handleChangeRetailerFilter(option.id)}
                            />
                        );
                    })}
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('qmr:quickFilters.submissionDate', 'Submission Date')}
                    </Typography>
                </div>

                <div className={classes.filterContent}>
                    <Select
                        value={selectedSubmissionDate}
                        options={submissionDateOptions}
                        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                            setSelectedSubmissionDate(event.target.value);
                        }}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('qmr:quickFilters.carline.label', 'Carline')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Typeahead
                        onFocus={() => setCarlineInputFocused(true)}
                        onBlur={() => setCarlineInputFocused(false)}
                        open={carlineInputFocused}
                        id="carline-quick-filter"
                        onChange={setSelectedCarlines}
                        options={carlineOptions}
                        multiple
                        labelKey="name"
                        placeholder={t('text:common.select', 'Select')}
                        selected={selectedCarlines}
                    />
                </div>

                {divider}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('qmr:quickFilters.modelYear.label', 'Model Year')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <div className="d-flex">
                        <div className="w-100 mr-2">
                            <Typography variant="label">{t('qmr:quickFilters.modelYear.from', 'From')}</Typography>
                            <Select
                                value={selectedYearFrom}
                                options={modelYearOptions}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedYearFrom(event.target.value);

                                    if (event.target.value > selectedYearTo) {
                                        setSelectedYearTo('');
                                    }
                                }}
                            />
                        </div>
                        <div className="ml-2 w-100">
                            <Typography variant="label">{t('qmr:quickFilters.modelYear.to', 'To')}</Typography>
                            <Select
                                value={selectedYearTo}
                                options={modelYearOptions}
                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                    setSelectedYearTo(event.target.value);

                                    if (event.target.value < selectedYearFrom) {
                                        setSelectedYearFrom('');
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>

                {divider}

                {account?.systemCapabilities.canSearchImportedFromSubarunet && (
                    <>
                        <div className={classes.filterHeader}>
                            <Typography variant="caption2">{t('qmr:quickFilters.source.label', 'Source')}</Typography>
                        </div>
                        <div className={classes.filterContent}>
                            <Form.Check
                                id="imported"
                                className="mb-3"
                                label={t('qmr:quickFilters.source.imported', 'Imported from Subarunet')}
                                type="checkbox"
                                checked={selectedIsImported === 'imported'}
                                onChange={() => {
                                    if (selectedIsImported === 'imported') {
                                        setSelectedIsImported('');
                                        return;
                                    }
                                    setSelectedIsImported('imported');
                                }}
                            />
                        </div>

                        {divider}
                    </>
                )}

                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('qmr:quickFilters.others.label', 'Others')}</Typography>
                </div>
                <div className={classes.filterContent}>
                    <div className="d-flex justify-content-between mb-4">
                        <Typography variant="label">
                            {t('qmr:quickFilters.others.turboIndicator', 'Turbo Indicator')}
                        </Typography>

                        <Switch
                            trackColor={{
                                false: colors.grayFive,
                                true: colors.blueThree,
                            }}
                            value={selectedOtherFlags.hasTurboIndicator}
                            //@ts-ignore
                            activeThumbColor={colors.blueOne}
                            onValueChange={(newVal) => {
                                setSelectedOtherFlags({
                                    ...selectedOtherFlags,
                                    hasTurboIndicator: newVal,
                                });
                            }}
                        />
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                        <Typography variant="label">
                            {t('qmr:quickFilters.others.hasAttachments', 'Has attachments')}
                        </Typography>

                        <Switch
                            trackColor={{
                                false: colors.grayFive,
                                true: colors.blueThree,
                            }}
                            value={selectedOtherFlags.hasAttachments}
                            //@ts-ignore
                            activeThumbColor={colors.blueOne}
                            onValueChange={(newVal) => {
                                setSelectedOtherFlags({
                                    ...selectedOtherFlags,
                                    hasAttachments: newVal,
                                });
                            }}
                        />
                    </div>
                </div>

                {divider}

                <div className={classes.filterContent}>
                    <div className="mt-4 d-flex justify-content-between">
                        <Button
                            onPress={() => {
                                const newQueryParams = new URLSearchParams();
                                const currentQmrStatusId = currentQueryParams.get('qmrStatusId');
                                const currentSize = currentQueryParams.get('size');
                                const currentQuery = currentQueryParams.get('query');

                                if (currentQmrStatusId) {
                                    newQueryParams.set('qmrStatusId', currentQmrStatusId);
                                }

                                if (currentSize) {
                                    newQueryParams.set('size', currentSize);
                                }

                                if (currentQuery) {
                                    newQueryParams.set('query', currentQuery);
                                }

                                for (const retailer of selectedRetailerOptions) {
                                    newQueryParams.append('retailer', retailer);
                                }

                                if (selectedSubmissionDate) {
                                    newQueryParams.append('submissionDate', selectedSubmissionDate);
                                }

                                for (const carline of selectedCarlines) {
                                    newQueryParams.append('carline', carline.carlineId);
                                }

                                if (selectedYearFrom) {
                                    newQueryParams.append('yearFrom', selectedYearFrom);
                                }

                                if (selectedYearTo) {
                                    newQueryParams.set('yearTo', selectedYearTo);
                                }

                                if (selectedIsImported) {
                                    newQueryParams.set('isImported', selectedIsImported);
                                }

                                for (const [flag, isSelected] of Object.entries(selectedOtherFlags)) {
                                    if (isSelected) {
                                        newQueryParams.set(flag, 'true');
                                    }
                                }
                                analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_BY_QUICKSEARCH);
                                navigate(`${props.path}?${newQueryParams.toString()}`);
                                props.onClose();
                            }}
                        >
                            {t('qmr:quickFilters.actions.apply', 'Apply')}
                        </Button>

                        {props.hasActiveFilters && (
                            <Button
                                variant="ghost-blue"
                                onPress={() => {
                                    props.onClear();
                                    props.onClose();
                                }}
                            >
                                {t('qmr:quickFilters.actions.clear', 'Clear All')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default QmrQuickFiltersPanel;
