import { ApiTarget, HttpClient } from '@packages/core/http';
import { FavoriteDocument, FetchAccountParams } from '../types';

export class DocsService {
    constructor(private http: HttpClient) {}

    getAccountFavorites({
        fetchAccountParams,
        ignoreCache,
        signal,
    }: {
        fetchAccountParams: FetchAccountParams;
        ignoreCache?: boolean;
        signal?: AbortSignal;
    }) {
        const url = '/docs/account-favorites' + this.http.queryString(fetchAccountParams);

        return this.http.orchestrateRequest<FavoriteDocument[]>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
            apiTarget: ApiTarget.VinService,
        });
    }
}
