import React, { createContext, FC, PropsWithChildren, useReducer } from 'react';
import { PatchAccountDto } from '@packages/models/api';
import { AuthService } from './auth.service';
import { MobileStorageKeys, StorageService } from '@packages/core/storage';

import { authActions, AuthActionDispatcher, authReducer, AuthState, AuthStatus, initialState } from './auth.state';
import { QmrsService, RetailerSearchParams } from '../qmrs';
import { AuthTokenService, StoredToken } from './auth-token.service';

export const AuthStateContext = createContext<AuthState | undefined>(undefined);
export const AuthDispatchContext = createContext<AuthActionDispatcher | undefined>(undefined);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialState);

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    );
};

interface AuthDataAccess {
    authService: AuthService;
    authDispatch: AuthActionDispatcher;
    qmrsService?: QmrsService;
    authTokenService: AuthTokenService;
    storageService?: StorageService;
    signal?: AbortSignal;
    ignoreCache?: boolean;
}

export async function fetchAuthUserProfile({
    accountId,
    currentAuthStatus,
    authService,
    authDispatch,
    signal,
}: {
    accountId: string;
    currentAuthStatus: AuthStatus;
} & Omit<AuthDataAccess, 'qmrsService' | 'authTokenService'>) {
    if (currentAuthStatus !== AuthStatus.Authenticated) {
        const status = AuthStatus.FetchingProfile;
        authDispatch(authActions.setAuthStatus({ status }));
    }
    const response = await authService.getAccount(accountId, signal);

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    const account = response.data.account;
    authDispatch(authActions.setAccount({ account }));

    return response;
}

export async function patchAuthUserProfile({
    authDispatch,
    authService,
    accountId,
    accountPatch,
    signal,
}: {
    accountId: string;
    accountPatch: PatchAccountDto;
} & Omit<AuthDataAccess, 'qmrsService' | 'authTokenService'>) {
    const response = await authService.patchAccount(accountId, accountPatch, signal);

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    authDispatch(authActions.setAccount({ account: response.data.account }));

    return response;
}

export async function fetchAuthUserRetailers({
    searchParams,
    qmrsService,
    authDispatch,
    signal,
    ignoreCache,
}: { searchParams: RetailerSearchParams } & Omit<AuthDataAccess, 'authService' | 'authTokenService'>) {
    if (!qmrsService) {
        return Promise.reject('Invalid arugments. qmrsService is required to fetch the authUser retailers');
    }

    const response = await qmrsService.fetchRetailers({
        searchParams,
        signal,
        ignoreCache,
    });

    if (!response.success && response.aborted) {
        return;
    } else if (!response.success) {
        return Promise.reject(response.data);
    }

    authDispatch(authActions.setRetailers({ retailers: response.data.retailers }));

    return response;
}

export async function setToken({
    token,
    authService,
    authTokenService,
    storageService,
    authDispatch,
    signal,
}: { token: string } & AuthDataAccess) {
    let status = AuthStatus.Saving;
    authDispatch(authActions.setAuthStatus({ status }));
    let storedToken: StoredToken | null;
    try {
        storedToken = await authTokenService.storeToken(token);
        await storageService!.setItem(MobileStorageKeys.AuthTokenTimestamp, Date.now().toString());
    } catch (e) {
        alert(e);
        console.error('Unable to store token', e);
        throw e;
    }

    if (!storedToken || !storedToken.decodedToken) {
        return;
    }

    await fetchAuthUserProfile({
        accountId: storedToken.decodedToken.sub,
        currentAuthStatus: status,
        authService,
        authDispatch,
        signal,
    });
}

export async function checkAuth({ authService, authTokenService, storageService, authDispatch }: AuthDataAccess) {
    let status = AuthStatus.Checking;
    authDispatch(authActions.setAuthStatus({ status }));

    try {
        const storedTokenTimestampString = await storageService!.getItem(MobileStorageKeys.AuthTokenTimestamp);
        const currentTimestamp = Date.now();
        const timeLimit = 28800000; // 8 hours in milliseconds

        const storedTokenTimestamp = storedTokenTimestampString ? parseInt(storedTokenTimestampString) : null;
        if (!storedTokenTimestamp || currentTimestamp - storedTokenTimestamp > timeLimit) {
            return null;
        }

        const storedToken = await authTokenService.getStoredToken();
        if (!storedToken || !storedToken.decodedToken) {
            return null;
        }

        await fetchAuthUserProfile({
            accountId: storedToken.decodedToken.sub,
            currentAuthStatus: status,
            authService,
            authDispatch,
        });

        // Token is valid and within the time limit
        return storedToken.token;
    } catch (e) {
        console.error('Error in checkAuth:', e);
        throw e;
    }
}
