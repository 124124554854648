import React from 'react';
import { Icon, IconProps, StsIconName, Typography } from '@packages/ui/shared';
import { StyleProp, TextStyle, StyleSheet } from 'react-native';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
    },
    imageWrapper: {
        width: 160,
        height: 160,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        backgroundColor: colors.grayOne,
        marginBottom: 16,
        overflow: 'hidden',
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
});

export interface EmptyProps {
    image?: React.ReactNode | string;
    imageStyle?: React.CSSProperties;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    icon?: StsIconName;
    iconProps?: Partial<IconProps>;
    className?: string;
    descriptionStyle?: StyleProp<TextStyle>;
    containerStyle?: React.CSSProperties;
    iconFlag?: boolean;
}

const DefaultIconProps: Partial<IconProps> = {
    color: 'grayFive',
    size: 80,
    style: {},
};

export const Empty: React.FC<EmptyProps> = React.memo(
    ({
        image,
        imageStyle,
        description = 'No Result',
        children,
        icon = 'battery',
        iconProps = DefaultIconProps,
        className = '',
        descriptionStyle,
        containerStyle,
        iconFlag,
    }) => {
        const classes = useStyles();

        const renderImage = () => {
            if (image) {
                if (typeof image === 'string') {
                    return (
                        <img
                            src={image}
                            alt="Empty state"
                            className={classes.image}
                            style={imageStyle}
                            loading="lazy"
                        />
                    );
                }
                return <div style={imageStyle}>{image}</div>;
            }

            return (
                <Icon testID="icon" name={icon} aria-label="Empty state icon" {...DefaultIconProps} {...iconProps} />
            );
        };

        const typographyStyle = StyleSheet.flatten([
            {
                fontSize: '21px',
                textAlign: 'center',
                marginTop: '8px',
                width: '320px',
                paddingRight: '30px',
                paddingLeft: '30px',
                gap: 4,
                fontWeight: '600',
                whiteSpace: 'pre-wrap',
                lineHeight: '32px',
            },
            descriptionStyle,
        ]);

        return (
            <div className={`${classes.container} ${className}`} style={containerStyle}>
                {!iconFlag && <div className={classes.imageWrapper}>{renderImage()}</div>}
                {description && (
                    <Typography color="textDarkSecondary" variant="caption" style={typographyStyle as TextStyle}>
                        {description}
                    </Typography>
                )}
                {children}
            </div>
        );
    }
);
