import { vinSessionService } from '@web/services/singletons';
import { useVinSessionDispatch } from '../context/vin-sessions.hooks';
import { vinSessionActions } from '../context/vin-sessions.state';
import { VIN } from '../types';

export const filterList = [
    'QMR Report',
    'techline case',
    'Accessory Installation Guide',
    'Owner manual',
    'On-Board Diagnostics',
    'Maintainance Advisory Bulletin',
    'TechTIPS NewsLetter',
    'Campaign Bulletin',
    'Service Manual',
    'Service Manual Correction',
    'Video',
    'Warranty Bulletin',
    'Technical Reference Booklet',
    'Technical Service Bulletin',
    'Troubleshooting Guide',
    'Warranty Booklet',
    'Service Diagnostics',
];

export const publicationTypes = [
    { icon: 'car', publicationType: 'QMR Reports' },
    { icon: 'phone', publicationType: 'Techline Cases' },
    { icon: 'battery', publicationType: 'BMIS Test' },
    { icon: 'treeTroubleshooting', publicationType: 'Troubleshooting Guide' },
    { icon: 'techTips', publicationType: 'TechTIPS NewsLetter' },
    { icon: 'menuBook', publicationType: 'Service Manual' },
    { icon: 'guides', publicationType: 'Accessory Installation Guide' },
    { icon: 'speedVelocimeter', publicationType: 'On-Board Diag II Information' },
    { icon: 'accountManager', publicationType: 'Owner Manual' },
    { icon: 'menuBookCorrection', publicationType: 'Service Manual Correction' },
    { icon: 'oilCan', publicationType: 'Maintenance Advisory Bulletin' },
    { icon: 'pushPin', publicationType: 'Product/Campaign Bulletin' },
    { icon: 'pushPin', publicationType: 'Technical Service Bulletin' },
    { icon: 'speedVelocimeter', publicationType: 'Service Diagnostics' },
    { icon: 'booklet', publicationType: 'Technical Reference Booklet' },
    { icon: 'video', publicationType: 'Video' },
    { icon: 'carCheck', publicationType: 'Warranty Booklet' },
    { icon: 'carCheck', publicationType: 'Warranty Bulletin' },
];

export const dateRange = ['Today', 'Last 7 days', 'Last 30 days', 'This year', 'Last year', 'Custom date range'];

export function handleVinSessionError(message: string, error?: Error) {
    console.error('[VinSession Error]:', message, error);
}

export function handleDocsError(message: string, error?: Error) {
    console.error('[DocsSession Error]:', message, error);
}

export function handleSelectedFilters(filter: string, selectedFilters: any, vinSessionActions: any, dispatch: any) {
    if (selectedFilters?.includes(filter)) {
        const updatedFilters = selectedFilters.filter((f: string) => f !== filter);
        dispatch(
            vinSessionActions.setSelectedFilters({
                selectedFilters: updatedFilters,
            })
        );
        return;
    }
    dispatch(
        vinSessionActions.setSelectedFilters({
            selectedFilters: [...selectedFilters, filter],
        })
    );
}

export async function openVinSession(
    vin: string,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    selectedVin: VIN | undefined
) {
    if (selectedVin && selectedVin.vinRecord.vin == vin) {
        return;
    }
    setIsLoading(true);
    try {
        await vinSessionService.openVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
    } catch (error) {
        handleVinSessionError('Open VIN Session Error', error);
    }
    setIsLoading(false);
}

export async function closeVinSession(
    vin: any,
    accountId: any,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    dispatch: ReturnType<typeof useVinSessionDispatch>
) {
    setIsLoading(true);
    try {
        await vinSessionService.closeVinSession({
            body: {
                vin: vin,
                createdAccountId: accountId,
            },
            ignoreCache: true,
        });
        dispatch(vinSessionActions.setVinBannerOpen({ vinBannerOpen: false }));
    } catch (error) {
        handleVinSessionError('Close VIN Session Error', error);
    }
    setIsLoading(false);
}

export async function getVin(vin: string, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) {
    try {
        const response = await vinSessionService.getVinData({
            fetchVinParams: {
                vin: vin,
            },
            ignoreCache: true,
        });
        if (response.success) {
            return response.data;
        }
    } catch (error) {
        window.alert(`Vin Fetching Error: ${error.message}`);
    }
}
