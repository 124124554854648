import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography } from '@packages/ui/shared';
import { VIN } from '../types';
import NoImageSubaruCar from '../assets/4c_Vertical.jpg';
import { openVinSession } from '../utils/vinSessionUtils';
import { useAuthState } from '@packages/contexts/auth/auth.hooks';
import { OpenInNew } from '../assets/Icons';
import { SkeletonLoader } from '@web/components/skeleton-loader';
import { useVinSessionState } from '../context/vin-sessions.hooks';

type VinDetailsProps = {
    car: VIN;
    handleClick: (car: VIN) => void;
    index: any;
    style?: any;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    onHide: () => void;
};

const useStyles = createUseStyles({
    vinDetailsContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px',
        width: '480px',
        gap: '8px',
    },
    vinDetails: {
        display: 'flex',
        gap: '8px',
        width: '416px',
        height: '24px',
    },
    imgContainer: {
        width: '55px',
        height: '24px',
        position: 'relative',
    },
    image: {
        width: '60px',
        height: '30px',
        objectFit: 'cover',
        position: 'absolute',
        right: 2,
        bottom: 0,
    },
    openInNew: {
        height: 24,
        width: 24,
        cursor: 'pointer',
    },
    vinNumber: {
        width: 160,
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        fontSize: 15,
        fontWeight: 400,
        color: '#007BFF',
        display: 'flex',
        alignItems: 'center',
        textTransform: 'uppercase',
    },
    dataCardTextOuter: {
        color: '#071424',
        fontSize: 13,
        marginTop: 8,
        width: '100%',
        gap: 10,
    },
});

const VinDetails: FC<VinDetailsProps> = (props) => {
    const { car, index, handleClick, style, setIsLoading, onHide, isLoading } = props;
    const { vin, model, modelYear } = car.vinRecord;
    const classes = useStyles();
    const { account } = useAuthState();
    const itemClasses = `d-flex align-items-center ${classes.dataCardTextOuter}`;
    const [loading, setLoading] = useState<boolean>(true);
    const { selectedVin } = useVinSessionState();

    const openSessionClick = (vin: string) => {
        window.open(`/vin/${vin}`, '_blank', 'noopener,noreferrer');
        openVinSession(vin, account?.accountId, setIsLoading, selectedVin);
        onHide();
    };

    useEffect(() => {
        const img = new Image();
        img.src = car.vehicleImageUrl;

        img.onload = () => {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };

        img.onerror = () => {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };
    }, [car.vehicleImageUrl]);

    return (
        <div key={index} className={`${classes.vinDetailsContainer} ${style}`}>
            {isLoading || loading ? (
                <div className={itemClasses}>
                    <SkeletonLoader type="text" className="w-full" />
                    <SkeletonLoader type="text" className="w-full" />
                    <SkeletonLoader type="text" className="w-full" />
                    <SkeletonLoader type="text" className="w-full" />
                </div>
            ) : (
                <>
                    <div className={classes.vinDetails}>
                        <div className={classes.imgContainer}>
                            <img
                                src={car.vehicleImageUrl}
                                className={classes.image}
                                loading="lazy"
                                onError={(e) => {
                                    const target = e.target as HTMLImageElement;
                                    target.onerror = null;
                                    target.src = `${NoImageSubaruCar}`;
                                }}
                            />
                        </div>
                        <div onClick={() => handleClick(car)} className={classes.vinNumber}>
                            {vin}
                        </div>
                        <Typography variant="vin">
                            {modelYear} {model}
                        </Typography>
                    </div>
                    <div className={classes.openInNew} onClick={() => openSessionClick(vin)}>
                        <OpenInNew />
                    </div>
                </>
            )}
        </div>
    );
};

export default VinDetails;
