import React, { FC, useEffect, useRef, useState } from 'react';
import VinDetails from './vin-details';
import { Menu, TypeaheadMenuProps } from 'react-bootstrap-typeahead';
import { SkeletonLoader } from '@web/components/skeleton-loader';
import { filterList } from '../utils/vinSessionUtils';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Icon, RobotoFontFamily, Typography } from '@packages/ui/shared';
import VinFilterButton from './vin-filter-button';
import { VIN } from '../types';
import { useMatch } from 'react-router-dom';

type RenderMenuProps = {
    isLoading: boolean;
    onHide: () => void;
    handleClick: (car: VIN) => void;
    menuProps: TypeaheadMenuProps<never>;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    searchVinData: any;
};

const useStyles = createUseStyles({
    recentSearchMenu: {
        display: 'flex !important',
        flexDirection: 'column !important',
        maxHeight: '400px !important',
        padding: '0px !important',
    },
    scrollBar: {
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '6px',
            backgroundColor: '#ffffff',
        },
        '&::-webkit-scrollbar-thumb': {
            borderRadius: '8px',
            backgroundColor: '#E3E3E3',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: colors.blueOne,
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    menuFooter: {
        textAlign: 'right',
        fontSize: 14,
        color: '#071424',
        borderTop: '1px solid #ddd',
        marginTop: 2,
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#ffffff',
        display: 'flex',
        justifyContent: 'end',
        gap: 12,
        cursor: 'pointer',
        padding: 8,
    },
    menu: {
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
    },
    filterList: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 535,
        minHeight: 204,
        gap: 16,
    },
    dataCardTextOuter: {
        color: '#071424',
        fontSize: 13,
        marginTop: 8,
        width: '100%',
        gap: 10,
    },
    vinHeader: {
        marginBottom: 10,
        backgroundColor: '#DEE6EF',
        borderRadius: 4,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 16px 16px 16px',
    },
    vinDetailsStyling: {
        backgroundColor: '#fff',
        borderRadius: '4px',
        padding: '12px 16px 12px 16px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    savedSearchContainer: {
        marginTop: 5,
        display: 'flex',
        gap: 6,
        flexDirection: 'column',
    },
    recentSearchItemType: {
        fontSize: 15,
        fontWeight: '400',
        color: '#1F518F',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    recentSearchItemContent: {
        fontSize: 15,
        fontWeight: '400',
        color: '#071424',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export const RenderMenu: FC<RenderMenuProps> = (props) => {
    const classes = useStyles();
    const vinSessionRouteMatch = useMatch('/VIN/*');
    const itemClasses = `d-flex align-items-center ${classes.dataCardTextOuter}`;
    const { isLoading, searchVinData, setIsLoading, onHide, handleClick, menuProps } = props;

    // mock-data
    const recentSearches = [
        {
            icon: 'clock',
            data: '587969',
            type: null,
        },
        {
            icon: 'phone',
            type: 'Techline case',
            data: 'Glanzman',
        },
        {
            icon: 'car-subaru',
            type: 'QMR',
            data: 'TS-15',
        },
    ];

    const recentSavedSearches = [
        {
            icon: 'magnifying-glass',
            type: 'Outback Battery Manuals',
            content: 'Car model: Outback, Document type: manuals - Sep 26, 2023',
        },
        {
            icon: 'magnifying-glass',
            type: 'Outback Battery Manuals',
            content: 'Car model: Outback, Document type: manuals - Sep 26, 2023',
        },
        {
            icon: 'magnifying-glass',
            type: 'Outback Battery Manuals',
            content: 'Car model: Outback, Document type: manuals - Sep 26, 2023',
        },
    ];

    return (
        <Menu {...menuProps} className={`${classes.recentSearchMenu} ${classes.scrollBar}`}>
            <div style={{ padding: '8px 16px 16px 8px' }}>
                {!searchVinData && !isLoading && (
                    <div className={classes.filters}>
                        <Typography variant="caption" style={{ color: '#999DA3' }}>
                            I'm searching for...
                        </Typography>
                        <div className={classes.menu}>
                            <div className={classes.filterList}>
                                {filterList.map((filter, index) => (
                                    <VinFilterButton
                                        filter={filter}
                                        index={index}
                                        iconName="x-close"
                                        style={{ color: colors.blueOneHover }}
                                    />
                                ))}
                            </div>
                            <div style={{ borderTop: '1px solid #E3E3E3', padding: 4 }}>
                                <Typography variant="caption" color="grayFive">
                                    Recent Searches
                                </Typography>
                                {recentSearches.map((searchItem, index) => (
                                    <div style={{ marginTop: 5, display: 'flex', gap: 6 }}>
                                        <Icon name={searchItem.icon} size={20} color="textDarkSecondary" />
                                        <div>
                                            {searchItem.type && (
                                                <Typography
                                                    style={{ fontSize: 15, fontWeight: '700', color: '#1F518F' }}
                                                >{`@${searchItem.type} `}</Typography>
                                            )}
                                            <Typography style={{ fontSize: 15, fontWeight: '400', color: '#071424' }}>
                                                {searchItem.data}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={{ borderTop: '1px solid #E3E3E3', padding: 4 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="caption" color="grayFive">
                                        Recent Saved Searches
                                    </Typography>
                                    <Typography style={{ fontSize: 15, fontWeight: '400', color: '#007BFF' }}>
                                        See all
                                    </Typography>
                                </div>
                                <div className={classes.savedSearchContainer}>
                                    {recentSavedSearches.map((recentSearchItem, index) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 4,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Icon name={recentSearchItem.icon} color="textDarkSecondary" />
                                            <div
                                                className={classes.recentSearchItemType}
                                            >{`${recentSearchItem.type} `}</div>
                                            <div className={classes.recentSearchItemContent}>
                                                {recentSearchItem.content}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isLoading && (
                    <div className={itemClasses}>
                        <SkeletonLoader type="text" className="w-full" />
                        <SkeletonLoader type="text" className="w-full" />
                        <SkeletonLoader type="text" className="w-full" />
                        <SkeletonLoader type="text" className="w-full" />
                    </div>
                )}
                {searchVinData && searchVinData?.vinRecord && (
                    <div className={classes.vinHeader}>
                        <div style={{ padding: 10 }}>
                            <Typography
                                style={{
                                    color: '#071424',
                                    fontFamily: RobotoFontFamily.Regular,
                                    fontWeight: '400',
                                    fontSize: 13,
                                    lineHeight: 20,
                                }}
                            >
                                {vinSessionRouteMatch
                                    ? 'Want to open a different session? You can do it in a new tab.'
                                    : "Searching by VIN? Open a VIN Session for a streamlined overview of the car's info."}
                            </Typography>
                        </div>
                        <VinDetails
                            style={classes.vinDetailsStyling}
                            car={searchVinData}
                            handleClick={handleClick}
                            index={1}
                            onHide={onHide}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                )}
            </div>
            <div className={classes.menuFooter}>
                <span style={{ fontSize: 15 }}>To see all results - Enter</span>
                <Icon name="keyboard-return" size={24} color="black" />
            </div>
        </Menu>
    );
};
