import { Synonym, SynonymPagination } from '@packages/models/api/synonyms-search-term';

export interface SynonymsState {
    synonyms: Synonym[];
    pagination: SynonymPagination;
    loading: boolean;
    error: string | null;
}

export const initialSynonymsState: SynonymsState = {
    synonyms: [],
    pagination: { size: 10, page: 1, total: 0 },
    loading: false,
    error: null,
};

export type SynonymsAction =
    | { type: 'SET_SYNONYMS'; payload: { synonyms: Synonym[]; pagination: SynonymPagination } }
    | { type: 'ADD_SYNONYM'; payload: { synonym: Synonym } }
    | { type: 'UPDATE_SYNONYM'; payload: { synonym: Synonym } }
    | { type: 'DELETE_SYNONYM'; payload: { id: string } }
    | { type: 'SET_LOADING'; payload: { loading: boolean } }
    | { type: 'SET_ERROR'; payload: { error: string | null } }
    | { type: 'CLEAR_SYNONYMS' };

export const synonymsReducer = (state: SynonymsState, action: SynonymsAction): SynonymsState => {
    switch (action.type) {
        case 'SET_SYNONYMS':
            return {
                ...state,
                synonyms: action.payload.synonyms,
                pagination: action.payload.pagination,
                loading: false,
                error: null,
            };
        case 'ADD_SYNONYM':
            return { ...state, synonyms: [...state.synonyms, action.payload.synonym], loading: false };
        case 'UPDATE_SYNONYM':
            return {
                ...state,
                synonyms: state.synonyms.map((syn) =>
                    syn.searchTermId === action.payload.synonym.searchTermId ? action.payload.synonym : syn
                ),
            };
        case 'DELETE_SYNONYM':
            return { ...state, synonyms: state.synonyms.filter((syn) => syn.searchTermId !== action.payload.id) };
        case 'SET_LOADING':
            return { ...state, loading: action.payload.loading };
        case 'SET_ERROR':
            return { ...state, loading: false, error: action.payload.error };
        case 'CLEAR_SYNONYMS':
            return { ...state, synonyms: [], pagination: { size: 10, page: 1, total: 0 }, loading: false, error: null };
        default:
            throw new Error(`Unhandled action type: ${(action as any).type}`);
    }
};
