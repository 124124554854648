import { useContext } from 'react';
import { SynonymsStateContext, SynonymsDispatchContext } from './synonyms.context';

export const useSynonymsState = () => {
    const state = useContext(SynonymsStateContext);

    if (state === undefined) {
        throw new Error('useSynonymsState must be used within a SynonymsProvider');
    }

    return state;
};

export const useSynonymsDispatch = () => {
    const dispatch = useContext(SynonymsDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useSynonymsDispatch must be used within a SynonymsProvider');
    }

    return dispatch;
};
