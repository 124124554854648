import { Account, Retailer } from '@packages/models/api';
import { Button, Typography } from '@packages/ui/shared';
import { Chip } from '@web/administration/components';
import AsyncPage from '@web/components/async-page';
import { DetailHeader, DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { qmrsService } from '@web/services/singletons';
import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const RetailerDetail: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { retailerId } = useParams<{ retailerId: string }>() as { retailerId: string };
    const [retailer, setRetailer] = useState<Retailer>();
    const [approvers, setApprovers] = useState<Account[]>([]);
    const retailerText = `Region-Zone-District: ${retailer?.regionCode}-${retailer?.zoneCode}-${retailer?.districtCode}`;

    const fetchData = useCallback(async () => {
        const retailerResultPromise = qmrsService.fetchRetailerbyId({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setRetailer(response.data.retailer);
        });
        const approverResultPromise = qmrsService.fetchRetailerFailCodeApprovers({ retailerId }).then((response) => {
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setApprovers(response.data.accounts);
        });
        return Promise.all([retailerResultPromise, approverResultPromise]);
    }, [retailerId]);

    function handleEditButtonPress() {
        if (!retailer) {
            return;
        }

        navigate(`/administration/retailers/${retailer.retailerId}/edit`);
    }

    const breadcrumbs = [
        {
            to: '/administration',
            title: t('administration:breadcrumbs.adminHub', 'Administration Hub'),
        },
        {
            to: '/administration/retailers',
            title: t('retailers:breadcrumbs.manageRetailers', 'Manage Retailers'),
        },
        {
            to: `/administration/retailers/${retailerId}`,
            title: t('retailers:breadcrumbs.retailer', 'Retailer'),
        },
    ];

    const rightSideItems = [
        <Typography color="textDarkSecondary">{retailerText}</Typography>,
        <Button onPress={handleEditButtonPress}>Edit Retailer</Button>,
    ];

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailHeader
                    rightSideItems={rightSideItems}
                    breadcrumbs={breadcrumbs}
                    hasBack={true}
                    subtitle={`${retailer?.name} (#${retailer?.code})`}
                ></DetailHeader>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Fail Code Settings</Typography>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <div className="mb-2">
                                    <Typography variant="label">Fail Code Approval Setting</Typography>
                                </div>
                                <Typography>{retailer?.failCodeApproverSettingDescription}</Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="mb-2">
                                    <Typography variant="label">Fail Code Approvers</Typography>
                                </div>
                                <ul className="list-unstyled">
                                    {approvers.length > 0 ? (
                                        approvers.map((approver) => {
                                            return (
                                                <li key={approver.accountId} className="mb-1">
                                                    <Chip title={approver.name} />
                                                </li>
                                            );
                                        })
                                    ) : (
                                        <Typography>No Approvers</Typography>
                                    )}
                                </ul>
                            </Col>
                        </Row>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
