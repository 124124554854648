import React, { FC } from 'react';
import { AppRoute } from '@web/core/navigation/app.navigator';
import { MainPage } from './main-page';
import { Navigate, Route, Routes } from 'react-router-dom';
import { StisPublications } from '@web/vin-sessions/components/stis-publications/stis-publications';

interface MainContentProps {}

export const routes: AppRoute[] = [
    {
        path: '/',
        main: MainPage,
    },
    {
        path: '/stis',
        main: StisPublications,
    },
];

export const MainContent: FC<MainContentProps> = (props) => {
    return (
        <Routes>
            {routes.map((route, index) => {
                if (route.redirectTo) {
                    return <Route key={index} path={route.path} element={<Navigate to={route.redirectTo} />} />;
                }

                if (route.main) {
                    return <Route key={index} path={route.path} element={<route.main />} />;
                }

                return null;
            })}
        </Routes>
    );
};
