import colors from '@packages/core/styles/colors';
import { Typography } from '@packages/ui/shared';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { MenuProps } from '@web/views/overview';
import { PublicationType } from '@web/vin-sessions/components/stis-publications/publication-type-icons';

const useStyles = createUseStyles({
    tableContainer: {
        marginBottom: 24,
        width: '35%',
    },
    menuTable: {
        minHeight: 280,
        width: '32%',
        color: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        width: '100%',
        padding: 15,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.grayOne,
    },
    link: {
        fontSize: 15,
        fontWeight: '400',
        color: '#007BFF',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    content: {
        borderTop: '1px solid #ddd',
    },
    noData: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    noDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 5,
        textAlign: 'center',
    },
});

interface MenuTableProps {
    tableData: MenuProps[];
    header: string;
    noDataText: string;
    noDataSubText: string;
}

export const MenuTable: FC<MenuTableProps> = (props) => {
    const { tableData, header, noDataText, noDataSubText } = props;
    const classes = useStyles();

    return (
        <div className={`my-4 border rounded ${classes.menuTable}`}>
            <div className={classes.headerContainer}>
                <Typography variant="label" numberOfLines={1}>
                    {header}
                </Typography>
                <span className={classes.link}>See all</span>
            </div>

            <div className={tableData.length == 0 ? classes.noData : ''}>
                {tableData.length > 0 &&
                    tableData.map((item, index) => (
                        <div key={index} className={classes.content}>
                            <div style={{ display: 'flex', padding: 15, gap: 24 }}>
                                <div style={{ maxWidth: '20%' }}>
                                    <PublicationType publicationType={item.icon} size={24} />
                                </div>
                                <div className={classes.link}>{item.name}</div>
                            </div>
                        </div>
                    ))}

                {tableData.length == 0 && (
                    <div className={classes.noDataContainer}>
                        <Typography style={{ fontSize: 15, fontWeight: 'bold', color: colors.graySix }}>
                            {noDataText}
                        </Typography>
                        <Typography style={{ fontSize: 12, fontWeight: '200', color: colors.graySix }}>
                            {noDataSubText}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
};
