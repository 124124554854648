import {
    AccountManagerIcon,
    BatteryIcon,
    BookletIcon,
    CarCheckIcon,
    CarIcon,
    GuidesIcon,
    MenuBookCorrectionIcon,
    MenuBookIcon,
    OilCanIcon,
    PhoneIcon,
    PushPinIcon,
    SpeedVelocimeterIcon,
    TechTipsIcon,
    TreeTroubleshootingIcon,
    VideoIcon,
} from '@web/vin-sessions/assets/Icons';
import React, { FC } from 'react';

export type PublicationTypeName =
    | string
    | 'QMR Reports'
    | 'Techline Cases'
    | 'BMIS Test'
    | 'Troubleshooting Guide'
    | 'TechTIPS NewsLetter'
    | 'Service Manual'
    | 'Accessory Installation Guide'
    | 'Owner Manual'
    | 'Service Manual Correction'
    | 'Service Diagnostics'
    | 'Maintenance Advisory Bulletin'
    | 'Product/Campaign Bulletin'
    | 'Technical Reference Booklet'
    | 'Video'
    | 'Warranty Booklet'
    | 'Warranty Bulletin'
    | 'On-Board Diag II Information'
    | 'Technical Service Bulletin';

interface PublicationTypeProps {
    publicationType: PublicationTypeName;
    size: number;
}

const iconMapping: Record<PublicationTypeName, (props: { size: number }) => JSX.Element> = {
    'QMR Reports': CarIcon,
    'Techline Cases': PhoneIcon,
    'BMIS Test': BatteryIcon,
    'Troubleshooting Guide': TreeTroubleshootingIcon,
    'TechTIPS NewsLetter': TechTipsIcon,
    'Service Manual': MenuBookIcon,
    'Accessory Installation Guide': GuidesIcon,
    'Owner Manual': SpeedVelocimeterIcon,
    'On-Board Diag II Information': AccountManagerIcon,
    'Service Diagnostics': AccountManagerIcon,
    'Service Manual Correction': MenuBookCorrectionIcon,
    'Maintenance Advisory Bulletin': OilCanIcon,
    'Product/Campaign Bulletin': PushPinIcon,
    'Technical Service Bulletin': PushPinIcon,
    'Technical Reference Booklet': BookletIcon,
    'Warranty Booklet': CarCheckIcon,
    'Warranty Bulletin': CarCheckIcon,
    Video: VideoIcon,
};

export const PublicationType: FC<PublicationTypeProps> = ({ publicationType, size }) => {
    const IconComponent = iconMapping[publicationType];

    if (!IconComponent) {
        return <div>Icon not found</div>;
    }

    return <IconComponent size={size} />;
};
