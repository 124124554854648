import { HttpClient } from '@packages/core/http';

export interface PermissionsServiceOptions {
    accountId: string;
    abortSignal: AbortSignal;
}

export class PermissionsService {
    constructor(private http: HttpClient) {}

    /**
     * Fetch permissions for a specific account
     * @param options - The options containing accountId and abortSignal
     * @returns An array of permission strings
     */
    async fetchPermissions(options: PermissionsServiceOptions): Promise<string[]> {
        const { accountId, abortSignal } = options;

        // Request configuration
        const config = {
            method: 'GET' as const,
            signal: abortSignal,
            ignoreCache: true,
        };

        // Perform the request
        const response = await this.http.orchestrateRequest<any>({
            ...config,
            url: `/accounts/${accountId}/permissions-debug`,
        });

        if (!response.success) {
            throw new Error('Failed to fetch permissions');
        }

        // Combine global and retailer-specific permissions
        let allPermissions: string[] = [...(response.data?.globalPermissions || [])];

        response.data?.retailerPermissions?.forEach((retailer: any) => {
            if (retailer.permissions?.length) {
                allPermissions = [...allPermissions, ...retailer.permissions];
            }
        });

        return allPermissions;
    }
}
