import { Button, Typography } from '@packages/ui/shared';
import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';
import { StyleSheet } from 'react-native';

interface CardProps {
    title: string;
    content: string;
    Icon: JSX.Element;
    buttonText: string;
    borderFlag?: boolean;
    onClick?: () => void;
}

const useMainContentStyles = createUseStyles({
    border: {
        borderBottom: '1px solid #CCCCCC',
    },
    buttonContainer: {
        maxWidth: '60%',
        display: 'flex',
        width: 170,
        height: 32,
    },
});

const styles = StyleSheet.create({
    content: {
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 20,
        color: '#4C525D',
    },
});

const Card: FC<CardProps> = (props) => {
    const { title, content, Icon, buttonText, borderFlag, onClick } = props;
    const classes = useMainContentStyles();

    return (
        <div className={borderFlag ? classes.border : ''}>
            <h5>{title}</h5>
            <Typography style={styles.content}>{content}</Typography>
            <div className={`mb-4 ${classes.buttonContainer}`}>
                <Button variant="info" style={{ backgroundColor: 'none' }} iconRight={Icon} onPress={onClick}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default Card;
