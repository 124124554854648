import React, { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import AsyncPage from '@web/components/async-page';
import {
    DetailView,
    DetailViewAside,
    DetailViewBody,
    DetailViewContent,
    DetailHeader,
} from '@web/components/detail-view';
import { Button, TextBadge, Typography } from '@packages/ui/shared';

import { FeedbackForm } from '@packages/models/api';
import { feedbackAdminService } from '@web/services/singletons';
import { FeedbackFormElement } from '@web/feedback/components';
import { useTranslation } from 'react-i18next';

export const FeedbackDetail: FC = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { feedbackFormId } = useParams<{ feedbackFormId: string }>();
    const [feedbackForm, setFeedbackForm] = useState<FeedbackForm | undefined>();

    const fetchData = useCallback(async () => {
        if (feedbackFormId) {
            const response = await feedbackAdminService.fetchFeedbackFormById({ feedbackFormId });

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            setFeedbackForm(response.data.form);
        }
    }, [feedbackFormId]);

    function handleEditButtonPress() {
        if (!feedbackForm) {
            return;
        }
        navigate(`/administration/feedback/${feedbackForm.feedbackFormId}/edit`);
    }

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <DetailHeader
                    breadcrumbs={[
                        {
                            to: '/administration',
                            title: t('feedback:breadcrumbs.adminHub', 'Administration Hub'),
                        },
                        { to: '/administration/feedback', title: 'Manage Feedback' },
                        { to: `/administration/feedback/${feedbackFormId}`, title: 'Feedback' },
                    ]}
                    hasBack={true}
                    subtitle={feedbackForm?.formTitle}
                    rightSideItems={[
                        <Button style={{ marginLeft: 16 }} onPress={handleEditButtonPress}>
                            Edit Feedback
                        </Button>,
                    ]}
                />
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4">Feedback Details</Typography>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {feedbackForm &&
                                    feedbackForm.formElements.map((el) => {
                                        return <FeedbackFormElement key={el.formElementId} item={el} />;
                                    })}
                            </Col>
                        </Row>
                    </DetailViewBody>
                    <DetailViewAside>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    Feedback Settings
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                                        Status
                                    </Typography>
                                </div>
                                <div className="d-inline-flex">
                                    {feedbackForm?.active ? (
                                        <TextBadge variant="green">Active</TextBadge>
                                    ) : (
                                        <TextBadge variant="red">Inactive</TextBadge>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </DetailViewAside>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};
