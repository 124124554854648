import React, { FC, useRef, useState } from 'react';
import { AdminHubHeader } from '../components/admin-hub/admin-hub-header';
import { useTranslation } from 'react-i18next';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@web/components/table';
import { createUseStyles } from 'react-jss';
import { StyleSheet } from 'react-native';
import { AddSynonymModal } from '../components/synonyms/add-synonym-modal';
import { DeleteSynonymModal } from '../components/synonyms/delete-synonym-modal';
import { Pagination } from '@web/components/pagination';
import colors from '@packages/core/styles/colors';
import { EditSynonymModal } from '../components/synonyms/edit-synonym-modal';
import { useSynonymsState } from '@packages/contexts/synonyms';
import { find } from 'lodash';
import { Synonym } from '@packages/models/api/synonyms-search-term';
import { LoadingCircle } from '@packages/ui/shared';

const HEADER_TITLES = ['Search Term', 'Synonyms', 'Actions'];

interface SynonymsTableRowModel {
    rowId: string;
    searchTerm: string;
    synonyms: string[];
}
interface SynonymTableRowProps {
    rowData: SynonymsTableRowModel;
    handleDelete: (id?: string) => void;
    handleEdit: (id?: string) => void;
}

const useStyles = createUseStyles({
    container: {
        minHeight: '82vh',
    },
    tableCell: {
        padding: '16px',
    },
    actionIcons: {
        display: 'flex',
        justifyContent: 'center',
        gap: '12px',
        padding: '10px',
    },
    pageFooter: {
        borderTop: '1px solid #ddd',
        height: '60px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    synonymsTable: {
        marginTop: '20px',
    },
});

const styles = StyleSheet.create({
    tableHeader: {
        fontWeight: '500',
    },
    addSynonymsButton: {
        display: 'flex',
        alignItems: 'center',
    },
    emptyStateDescriptionTitle: {
        fontWeight: '600',
        fontSize: 21,
        lineHeight: 32,
        display: 'flex',
        justifyContent: 'center',
    },
    emptyStateDescriptionBody: {
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 24,
        display: 'flex',
        justifyContent: 'center',
    },
    emptyStateIcon: {
        backgroundColor: colors.grayFive,
        borderRadius: 50,
    },
    addSynonymPlusIcon: {
        backgroundColor: colors.white,
        borderRadius: 50,
    },
});

const EmptyStateDescription: FC = () => {
    return (
        <>
            <Typography color="textDarkSecondary" style={styles.emptyStateDescriptionTitle}>
                No synonym added yet
            </Typography>
            <Typography color="textDarkSecondary" style={styles.emptyStateDescriptionBody}>
                Add synonyms linked to search terms to improve search engine performance
            </Typography>
        </>
    );
};

const SynonymsTableHeader: FC = () => {
    return (
        <TableRow>
            {HEADER_TITLES.map((header) => (
                <TableHeader key={header}>
                    <Typography style={styles.tableHeader}>{header}</Typography>
                </TableHeader>
            ))}
        </TableRow>
    );
};

const SynonymsTableRow: FC<SynonymTableRowProps> = ({ rowData, handleEdit, handleDelete }) => {
    const { rowId, searchTerm, synonyms } = rowData;

    const classes = useStyles();

    return (
        <TableRow key={rowId}>
            <TableCell className={classes.tableCell}>
                <Typography>{searchTerm}</Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>
                <Typography>{synonyms?.join(', ')}</Typography>
            </TableCell>
            <TableCell className={classes.actionIcons}>
                <Button variant="ghost-blue" onPress={() => handleEdit(rowId)}>
                    <Icon name="pencil" color="blueOne" size={18} />
                </Button>
                <Button variant="ghost-blue" onPress={() => handleDelete(rowId)}>
                    <Icon name="trash" color="blueOne" size={18} />
                </Button>
            </TableCell>
        </TableRow>
    );
};

export const Synonyms: FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { synonyms, pagination, loading: isSynonymsLoading } = useSynonymsState();
    const searchInputRef = useRef(null);
    const [selectedSynonym, setSelectedSynonym] = useState<Synonym>();

    const [modals, setModals] = useState({
        add: false,
        edit: false,
        delete: false,
    });

    const toggleModal = (type: 'add' | 'edit' | 'delete', synonymId?: string) => {
        setModals((prev) => ({ ...prev, [type]: !prev[type] }));
        if (synonymId) {
            const synonym = find(synonyms, { searchTermId: synonymId });
            setSelectedSynonym(synonym);
        }
    };

    if (isSynonymsLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <LoadingCircle size={64} />
            </div>
        );
    }

    return (
        <>
            <div className={`d-flex flex-column ${classes.container}`}>
                <AdminHubHeader
                    breadcrumbs={[
                        {
                            title: t('administration:breadcrumbs.adminHub', 'Administration Hub'),
                            to: '/administration',
                        },
                        { title: t('synonyms:breadcrumbs.synonyms', 'Synonyms') },
                    ]}
                />
                <div className="container-fluid mt-4">
                    <div className="row mb-3">
                        <div className="col-12 col-md-6">
                            <RecentSearchTypeahead
                                ref={searchInputRef}
                                onChange={() => {}}
                                placeholder={t('views:toughbookAttachments.search.placeholder', 'Search for a synonym')}
                                defaultInputValue=""
                                recentSearches={[]}
                                quickSearchQuery=""
                                searchIconColor="grayFive"
                            />
                        </div>
                        <div className="col-12 col-md-6 text-md-end d-flex justify-content-end">
                            <Button
                                style={styles.addSynonymsButton}
                                variant="primary"
                                title={t('Add new synonym')}
                                iconRight={
                                    <Icon name="plus" color="blueOne" size={19.5} style={styles.addSynonymPlusIcon} />
                                }
                                onPress={() => toggleModal('add')}
                            />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <TableRenderer<SynonymsTableRowModel>
                                isLoading={isSynonymsLoading}
                                tableRowsData={synonyms.map(
                                    (synonym) =>
                                        ({
                                            rowId: synonym.searchTermId,
                                            searchTerm: synonym.searchTerm,
                                            synonyms: synonym.synonyms,
                                        }) as SynonymsTableRowModel
                                )}
                                tableRowsDataSetter={() => {}}
                                tableHeaderRowRenderer={() => <SynonymsTableHeader />}
                                tableBodyRowRenderer={(rowData) => (
                                    <SynonymsTableRow
                                        handleEdit={(id) => toggleModal('edit', id)}
                                        handleDelete={(id) => toggleModal('delete', id)}
                                        rowData={rowData}
                                    />
                                )}
                                emptyStateProps={{
                                    icon: 'plus',
                                    description: <EmptyStateDescription />,
                                    iconProps: { size: 65, color: 'white', style: styles.emptyStateIcon },
                                }}
                            />
                        </div>
                    </div>
                </div>

                <AddSynonymModal show={modals.add} onHide={() => toggleModal('add')} />
                <EditSynonymModal
                    show={modals.edit}
                    onHide={() => toggleModal('edit')}
                    selectedSynonym={selectedSynonym}
                />
                <DeleteSynonymModal
                    show={modals.delete}
                    onHide={() => toggleModal('delete')}
                    selectedSynonym={selectedSynonym}
                />
            </div>
            <footer className={`row d-flex justify-content-center align-items-center ${classes.pageFooter}`}>
                <div className="col-12">
                    <Pagination
                        currentPage={1}
                        pages={Math.round(pagination.total / pagination.size)}
                        itemsPerPage={10}
                        itemsPerPageOptions={[10, 20, 50]}
                        onItemsPerPageChange={() => {}}
                    />
                </div>
            </footer>
        </>
    );
};
