import React, { FC, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { useNavigate } from 'react-router-dom';
import { SystemSoftwareDownloadQuickReference } from '../assets/Icons';
import thumbnail from '../assets/Thumbnail.png';
import { QuickReferenceCard } from './reference-card';
import { Icon } from '@packages/ui/shared';

const useStyles = createUseStyles({
    quickReferencesContainer: {
        border: `1px solid ${colors.grayThree}`,
        width: '100%',
        overflow: 'hidden',
        borderRadius: 4,
    },
    tabsContainer: {
        display: 'flex',
        gap: 16,
        padding: '8px 16px 0px 16px',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    tab: {
        padding: '8px 0px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: '600',
        color: colors.grayFive,
        '&:hover': {
            color: colors.blueOneHover,
        },
        '&.active': {
            color: colors.blueOne,
            borderBottom: `2px solid ${colors.blueOne}`,
        },
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    referenceCardContainer: {
        padding: 24,
        display: 'flex',
        gap: 16,
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    scrollButton: {
        position: 'absolute',
        top: '50%',
        bottom: '8px',
        transform: 'translateY(-50%)',
        width: 40,
        height: 40,
        backgroundColor: colors.blueOne,
        border: 'none',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 2,
        '&:hover': {
            backgroundColor: '#0056b3',
        },
    },
    scrollButtonLeft: {
        left: '16px',
    },
    scrollButtonRight: {
        right: '8px',
    },
    gradientOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: 120,
        zIndex: 1,
        pointerEvent: 'none',
    },
    gradientLeft: {
        left: 0,
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0))',
    },
    gradientRight: {
        right: 0,
        background: 'linear-gradient(to left, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0))',
    },
});

export const QuickReferences: FC = () => {
    const [showLeftButton, setShowLeftButton] = useState(false);
    const [showRightButton, setShowRightButton] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const [activeCategory, setActiveCategory] = useState('file-updates');

    const checkScrollButtons = () => {
        if (scrollRef.current) {
            const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
            setShowLeftButton(scrollLeft > 0);
            setShowRightButton(scrollLeft + clientWidth < scrollWidth - 5);
        }
    };

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            const scrollAmount = 300;
            if (direction == 'left') {
                scrollRef.current.scrollLeft -= scrollAmount;
            } else {
                scrollRef.current.scrollLeft += scrollAmount;
            }
            setTimeout(checkScrollButtons, 300);
        }
    };

    const categories = [
        { id: 'file-updates', label: 'File Updates "Software Download"' },
        { id: 'Service-diagnostics', label: 'Service Diagnostics' },
        { id: 'tabs', label: 'Technical Service Bulletins (TSBs)' },
        { id: 'technical-support', label: 'Technical Support Guide' },
        { id: 'training-material', label: 'Training Material' },
    ];

    const referenceData = [
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'Denso Audio and Navigation Head Units',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'MID-21-Legacy-Outback-Denso-CP1 AUDIO UPDATE-November 2023',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'Map Update Gen 4',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'STARLINK Multimedia Software & Navigation Maps',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'Denso Audio and Navigation Head Units',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'Denso Audio and Navigation Head Units',
            image: thumbnail,
            version: 'L2570BE',
        },
        {
            icon: <SystemSoftwareDownloadQuickReference />,
            title: 'Denso Audio and Navigation Head Units',
            image: thumbnail,
            version: 'L2570BE',
        },
    ];

    useEffect(() => {
        const currentRef = scrollRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', checkScrollButtons);
            checkScrollButtons();

            return () => {
                currentRef.removeEventListener('scroll', checkScrollButtons);
            };
        }
    }, []);

    return (
        <div data-testid="quick-references" className="mb-2">
            <h3 className="mb-4">{`Quick References`}</h3>

            <div className={classes.quickReferencesContainer}>
                <div className={classes.tabsContainer}>
                    {categories.map((category) => (
                        <div
                            onClick={() => {
                                setActiveCategory(category.id);
                                navigate('');
                            }}
                            className={`${classes.tab} ${activeCategory == category.id ? 'active' : ''}`}
                        >
                            {category.label}
                        </div>
                    ))}
                </div>
                <div style={{ position: 'relative' }}>
                    <div className={classes.referenceCardContainer} ref={scrollRef}>
                        {showLeftButton && <div className={`${classes.gradientOverlay} ${classes.gradientLeft}`}></div>}
                        {showLeftButton && (
                            <button
                                className={`${classes.scrollButton} ${classes.scrollButtonLeft}`}
                                onClick={() => scroll('left')}
                                aria-label="left"
                            >
                                <Icon name="chevron-left" color="white" />
                            </button>
                        )}
                        {showRightButton && (
                            <div className={`${classes.gradientOverlay} ${classes.gradientRight}`}></div>
                        )}
                        {showRightButton && (
                            <button
                                className={`${classes.scrollButton} ${classes.scrollButtonRight}`}
                                onClick={() => scroll('right')}
                                aria-label="right"
                            >
                                <Icon name="chevron-right" color="white" />
                            </button>
                        )}
                        {referenceData.map((item, idx) => (
                            <QuickReferenceCard
                                key={idx}
                                Icon={item.icon}
                                title={item.title}
                                image={item.image}
                                version={item.version}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
