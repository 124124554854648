import React, { FC } from 'react';
import { createUseStyles } from 'react-jss';

import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import Select from '../select';

const useTablePaginationStyles = createUseStyles({
    pagination: {
        flexShrink: 0,
        display: 'flex',
        padding: '16px 0',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    buttonGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    active: {
        color: `${colors.black} !important`,
    },
});

interface TablePaginationProps {
    page: number;
    size: number;
    total: number;
    displayCount?: string;
    prevDisable?: boolean;
    nextDisable?: boolean;
    disabled?: boolean;
    pageNumberDisable?: boolean;
    onClick(pageIndex: number): void;
    onSizeChange(pageSize: number): void;
}

export const TablePagination: FC<TablePaginationProps> = React.memo(
    ({ displayCount, page, size, total, prevDisable, nextDisable, disabled, onClick, onSizeChange }) => {
        const classes = useTablePaginationStyles();
        const buttonIndices: number[] = Array.from(Array(Math.ceil(total / size)).keys());
        const lastPageIndex = buttonIndices.length - 1 >= 0 ? buttonIndices.length - 1 : 0;

        function getPageButtonsToShow() {
            let pageArray = [];

            if (page > 0 && page < lastPageIndex) {
                pageArray = [page];

                if (buttonIndices[page + 1] !== undefined) {
                    pageArray.push(page + 1);
                }
                if (buttonIndices[page - 1] !== undefined) {
                    pageArray.unshift(page - 1);
                }

                return pageArray;
            }

            if (page === 0) {
                pageArray = [page];

                if (buttonIndices[page + 1] !== undefined) {
                    pageArray.push(page + 1);
                }
                if (buttonIndices[page + 2] !== undefined) {
                    pageArray.push(page + 2);
                }

                return pageArray;
            }

            if (page === lastPageIndex) {
                pageArray = [page];

                if (buttonIndices[page - 1] !== undefined) {
                    pageArray.unshift(page - 1);
                }
                if (buttonIndices[page - 2] !== undefined) {
                    pageArray.unshift(page - 2);
                }

                return pageArray;
            }

            return [];
        }

        function handlePreviousButtonClick() {
            const previousPage = page - 1;

            if (previousPage < 0) {
                return;
            }

            onClick(previousPage);
        }

        function handlePaginationButtonClick(buttonIndex: number) {
            if (buttonIndex === page) {
                return;
            }

            onClick(buttonIndex);
        }

        function handleNextButtonClick() {
            const nextPage = page + 1;

            if (nextPage > lastPageIndex) {
                return;
            }

            onClick(nextPage);
        }

        function handlePageSizeSelectChange(event: React.ChangeEvent<HTMLSelectElement>) {
            onSizeChange(parseInt(event.currentTarget.value, 10));
        }

        return (
            <div className={classes.pagination}>
                <div>
                    <Typography variant="label" color="blueOne">
                        {displayCount}
                    </Typography>
                </div>
                <div className={classes.buttonGroup}>
                    <Button
                        variant="ghost-blue"
                        onPress={handlePreviousButtonClick}
                        disabled={page === 0 || prevDisable || disabled}
                        iconLeft={<Icon name="chevron-left" color="blueOne" />}
                    >
                        Prev
                    </Button>
                    {getPageButtonsToShow().map((buttonIndex) => {
                        return (
                            <Button
                                key={buttonIndex}
                                variant={buttonIndex === page ? 'primary' : 'ghost-blue'}
                                onPress={() => {
                                    handlePaginationButtonClick(buttonIndex);
                                }}
                                disabled={disabled}
                            >
                                <Typography variant="label" color={buttonIndex === page ? 'white' : 'blueOne'}>
                                    {buttonIndex + 1}
                                </Typography>
                            </Button>
                        );
                    })}
                    <Button
                        variant="ghost-blue"
                        onPress={handleNextButtonClick}
                        disabled={page === lastPageIndex || nextDisable || disabled}
                        iconRight={<Icon name="chevron-right" color="blueOne" />}
                    >
                        Next
                    </Button>
                    <Select
                        value={String(size)}
                        options={[
                            {
                                value: '10',
                                title: '10 per page',
                            },
                            {
                                value: '25',
                                title: '25 per page',
                            },
                            {
                                value: '50',
                                title: '50 per page',
                            },
                        ]}
                        onChange={handlePageSizeSelectChange}
                        disabled={disabled}
                    />
                </div>
                <div />
            </div>
        );
    }
);

TablePagination.displayName = 'TablePagination';
