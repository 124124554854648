import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { ReactComponent as TechShareLogo } from '@packages/ui/assets/images/techshare-logo.svg';
import { Button, Typography, Icon } from '@packages/ui/shared';
import { StyleSheet } from 'react-native';
import { Link } from 'react-router-dom';
import Card from './card';
import { useNavigate } from 'react-router-dom';
import { OpenInNew } from '@web/vin-sessions/assets/Icons';
import { useAuthState } from '@packages/contexts/auth';
import { handleLogin } from '../utils';

interface MainPageProps {}

const useMainContentStyles = createUseStyles({
    mainContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: `1px solid ${colors.grayThree}`,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: '100%',
    },
    row: {
        flex: 1,
    },
    leftColContainer: {
        width: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    rightColContainer: {
        width: '40%',
        backgroundColor: colors.grayTwo,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardContainer: {
        display: 'flex',
        gap: 16,
        flexDirection: 'column',
        width: '85%',
    },
    logo: {
        height: 40,
        width: 270,
        marginTop: 6,
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 14,
    },
});

const styles = StyleSheet.create({
    welcomeText: {
        fontSize: 50,
        lineHeight: 50,
        color: colors.blueZero,
        fontWeight: '300',
    },
    noteOne: {
        lineHeight: 36,
        fontSize: 24,
        fontWeight: '400',
    },
    noteTwo: {
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 20,
        color: '#4C525D',
    },
    noteThree: {
        fontWeight: '400',
        fontSize: 15,
        lineHeight: 20,
        color: '#4C525D',
    },
});

export const MainPage: FC<MainPageProps> = (props) => {
    const classes = useMainContentStyles();
    const navigate = useNavigate();
    const { showUnauthorizedPrompt } = useAuthState();

    return (
        <Container fluid className={classes.mainContentContainer}>
            <Row className={`align-items-stretch ${classes.row}`}>
                <Col md={7} className={`p-10 ${classes.leftColContainer}`}>
                    <Container fluid style={{ maxWidth: '90%' }}>
                        <div className="d-flex">
                            <Typography style={styles.welcomeText}>{'Welcome to'}</Typography>
                            <TechShareLogo className={classes.logo} />
                        </div>
                        <div className="mt-4" style={{ maxWidth: '95%' }}>
                            <Typography style={styles.noteOne}>
                                {'The all-in-one digital platform for Subaru technical support & information.'}
                            </Typography>
                        </div>
                        <div className="mt-8" style={{ maxWidth: '80%' }}>
                            <div className={classes.textContainer}>
                                <Typography style={styles.noteTwo}>
                                    {
                                        'Third-Party Users: With a subscription, gain access to the Subaru Technical Information System (STIS)'
                                    }
                                </Typography>
                                <Typography style={styles.noteThree}>
                                    {
                                        'Employees and Retailer Partners: Full access to all Techshare functionalities, including Techline, QMR, and STIS.'
                                    }
                                </Typography>
                            </div>
                            <div className="mt-10" style={{ maxWidth: '75%' }}>
                                <Button
                                    title={'Sign in'}
                                    onPress={() => handleLogin(showUnauthorizedPrompt, navigate)}
                                />
                                <div className="mt-1">
                                    <Typography>{'Not a member yet?'}</Typography>
                                    <Link to={'#'} className="ml-1">
                                        {'Create account'}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Col>

                <Col md={5} className={classes.rightColContainer}>
                    <div className={classes.cardContainer}>
                        <Card
                            title="STIS Public Documents"
                            content="Explore available public documents in STIS. Some documents require a subscription to access."
                            Icon={<Icon size={16} name="chevron-right" color="blueOne" />}
                            buttonText="Search for documents"
                            borderFlag={true}
                            onClick={() => navigate('/stis')}
                        />

                        <Card
                            title="Emergency Response Guides"
                            content="Find essential procedures for first responders to safely manage emergencies. Only for Subaru electric and hybrid cars (EVs)."
                            Icon={<Icon size={16} name="chevron-right" color="blueOne" />}
                            buttonText="View guides by model"
                            borderFlag={true}
                        />

                        <Card
                            title="Immobilizer Key"
                            content="Subaru Vehicle Security Professional (VSP) for key and immobilizer codes."
                            Icon={<OpenInNew style={{ marginBottom: 0 }} fill={colors.blueOne} />}
                            buttonText="Go to VSP"
                            borderFlag={false}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
