import React, { forwardRef, useRef, useCallback } from 'react';
import { Menu, MenuItem, Typeahead, TypeaheadState } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Icon, IconProps, Typography, useHoverState } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    searchIcon: {
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 2,
        backgroundColor: colors.white,
    },
    recentSearchMenu: {
        display: 'flex !important',
        flexDirection: 'column !important',
        padding: '0 !important',
    },
    recentSearchesList: {
        overflow: 'auto',
    },
    recentSearchHeader: {
        padding: '8px 16px',
    },
    recentSearchFooter: {
        padding: '16px 24px',
        borderTop: `1px solid ${colors.grayThree}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&:hover *': {
            cursor: 'pointer',
        },
    },
});

const RecentSearchItem = ({ result, index }: { result: string; index: number }) => {
    const { isHovered, hoverEventHandlers } = useHoverState();
    return (
        <MenuItem {...hoverEventHandlers} option={result} position={index}>
            <div className="d-flex align-items-center">
                <span className="mr-2">
                    <Icon name="history" color={isHovered ? 'white' : 'textDarkPrimary'} />
                </span>
                <Typography color={isHovered ? 'white' : 'textDarkPrimary'}>{result}</Typography>
            </div>
        </MenuItem>
    );
};

const useTypeaheadHandle = (ref: React.Ref<any>) => {
    const inputRef = useRef(null);

    React.useImperativeHandle(ref, () => inputRef.current, []);

    const hideAndBlurMenu = useCallback(() => {
        if (inputRef.current) {
            (inputRef.current as any).hideMenu();
            (inputRef.current as any).inputNode.blur();
        }
    }, []);

    return { inputRef, hideAndBlurMenu };
};

interface RecentSearchTypeaheadProps {
    defaultInputValue?: string;
    onChange(value: string): void;
    placeholder?: string;
    recentSearches: string[];
    quickSearchQuery: string;
    searchIconColor?: IconProps['color'];
}

const RecentSearchTypeahead = forwardRef<React.LegacyRef<Typeahead<string>>, RecentSearchTypeaheadProps>(
    ({ defaultInputValue, onChange, placeholder, recentSearches, quickSearchQuery, searchIconColor }, ref) => {
        const { t } = useTranslation();
        const classes = useStyles();
        const { inputRef, hideAndBlurMenu } = useTypeaheadHandle(ref);

        const handleSelection = useCallback(
            (value: string) => {
                onChange(value.trim());
                hideAndBlurMenu();
            },
            [onChange, hideAndBlurMenu]
        );

        const handleKeyDown = useCallback(
            (e: any) => {
                if (e.key === 'Enter') {
                    handleSelection((inputRef.current as any).inputNode.value);
                }
            },
            [handleSelection]
        );

        return (
            <Typeahead
                ref={inputRef}
                id="qmr-quick-search"
                defaultInputValue={defaultInputValue}
                onChange={(selected) => selected[0] && handleSelection(selected[0])}
                filterBy={() => true}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
                renderMenu={(results, menuProps, ...args) => {
                    const typeaheadState = args[0] as TypeaheadState<string>;
                    return (
                        <Menu {...menuProps} className={classes.recentSearchMenu}>
                            {results.length > 0 && (
                                <div className={classes.recentSearchHeader}>
                                    <Typography variant="caption">
                                        {t('views:qmrs.recentSearches.label', 'Recent Searches')}
                                    </Typography>
                                </div>
                            )}
                            <div className={classes.recentSearchesList}>
                                {results.map((result, index) => (
                                    <RecentSearchItem key={index} result={result} index={index} />
                                ))}
                            </div>
                            <div
                                className={classes.recentSearchFooter}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleSelection((inputRef.current as any).inputNode.value);
                                }}
                            >
                                <span className="mr-2">
                                    <Typography color={typeaheadState.text ? 'blueOne' : 'textDarkSecondary'}>
                                        {t('views:qmrs.recentSearches.hintEnter', 'Enter')}
                                    </Typography>
                                </span>
                                <Icon
                                    name="keyboard-return"
                                    color={typeaheadState.text ? 'blueOne' : 'textDarkSecondary'}
                                />
                            </div>
                        </Menu>
                    );
                }}
                options={recentSearches}
            >
                <div className={classes.searchIcon}>
                    <Icon
                        name={quickSearchQuery ? 'x-close' : 'magnifying-glass'}
                        onPress={() => {
                            if (quickSearchQuery) {
                                handleSelection('');
                                (inputRef.current as any).clear();
                                return;
                            } else {
                                (inputRef.current as any).inputNode.focus();
                            }
                        }}
                        color={searchIconColor}
                    />
                </div>
            </Typeahead>
        );
    }
);

export default RecentSearchTypeahead;
