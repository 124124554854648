import React, { FC, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Button, Typography } from '@packages/ui/shared';
import { TagInput } from '@web/components/tag-input';
import { StyleSheet } from 'react-native';
import { useSynonymsDispatch, createSynonym } from '@packages/contexts/synonyms';
import { synonymsService } from '@web/services/singletons';

interface AddSynonymModalProps {
    show: boolean;
    onHide: () => void;
}

const useStyles = createUseStyles({
    header: {
        padding: '16px 24px!important',
    },
    title: {
        fontSize: '21px!important',
        fontWeight: '600!important',
    },
    body: {
        padding: '16px 24px!important',
    },
    bodyDescription: {
        fontSize: '15px!important',
        fontWeight: '400!important',
    },
    footer: {
        padding: '24px!important',
    },

    formLabel: {
        fontSize: '15px',
    },
});

const styles = StyleSheet.create({
    bodyDescription: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
});

export const AddSynonymModal: FC<AddSynonymModalProps> = ({ show, onHide }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [synonyms, setSynonyms] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>();
    const synonymDispatch = useSynonymsDispatch();

    const closeModal = () => {
        onHide();
        setSynonyms([]);
        setSearchTerm(undefined);
    };

    function handleAddSynonym(synonym: string) {
        if (synonym.trim() && !synonyms.includes(synonym.trim())) {
            const newTags = [...synonyms, synonym.trim()];
            setSynonyms(newTags);
        }
    }
    function handleRemoveSynonym(index: number) {
        const newTags = synonyms.filter((_, i) => i !== index);
        setSynonyms(newTags);
    }
    function handleAddSynonyms() {
        if (searchTerm) {
            createSynonym(synonymDispatch, synonymsService, { searchTerm, synonyms });
            closeModal();
        }
    }

    return (
        <Modal show={show} centered onHide={closeModal} size="lg">
            <Modal.Header placeholder={'Add Synonyms'} className={classes.header}>
                <Modal.Title className={classes.title}>{t('Add Synonyms')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.header}>
                <Typography variant="caption" color="black" style={styles.bodyDescription}>
                    {t(
                        "Enter the search term and add all related words as synonyms to enhance the search engine's effectiveness."
                    )}
                </Typography>
                <Form>
                    <Form.Group controlId="search-term" className="mb-3 mt-2">
                        <Form.Label className={classes.formLabel}>{t('Search Term')}</Form.Label>
                        <Form.Control
                            onChange={(event) => setSearchTerm(event.target.value)}
                            name="searchTerm"
                            type="text"
                            placeholder={t('Enter search term')}
                            maxLength={50}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="synonyms" className="mb-3">
                        <Form.Label className={classes.formLabel}>{t('Synonyms')}</Form.Label>
                        <TagInput
                            placeholder="Enter Synonyms"
                            tags={synonyms}
                            handleAdd={handleAddSynonym}
                            handleRemove={handleRemoveSynonym}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={classes.footer}>
                <Button variant="ghost-blue" style={{ marginRight: 8 }} onPress={closeModal}>
                    {t('Cancel')}
                </Button>
                <Button variant="primary" onPress={handleAddSynonyms} disabled={!synonyms?.length || !searchTerm}>
                    {t('Add')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
