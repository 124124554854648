import React from 'react';

export const OpenInNew = ({ style = {}, fill = '#999DA3' }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ marginBottom: 12, ...style }}
        >
            <g id="Open in New">
                <path
                    id="Vector"
                    d="M5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H12V5H5V19H19V12H21V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM9.7 15.7L8.3 14.3L17.6 5H14V3H21V10H19V6.4L9.7 15.7Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
};

export const SystemSoftwareDownloadQuickReference = () => {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="SystemUpdateSoftwareDownloadQuickReference">
                    <path
                        id="Vector"
                        d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H9V6H4V18H20V6H15V4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM12 15.4L7 10.4L8.4 9L11 11.6V4H13V11.6L15.6 9L17 10.4L12 15.4Z"
                        fill="#4C525D"
                    />
                </g>
            </svg>
        </div>
    );
};

export const VinSessionCarIcon = () => {
    return (
        <div>
            <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Left Icon">
                    <path
                        id="Vector"
                        d="M12 6.05625L9.64583 3.60625L10.8125 2.35937L12 3.60625L14.9583 0.5L16.125 1.725L12 6.05625ZM5.33333 21.5C5.09722 21.5 4.89931 21.4161 4.73958 21.2484C4.57986 21.0807 4.5 20.8729 4.5 20.625V13.625L6.25 8.375C6.33333 8.1125 6.48264 7.90104 6.69792 7.74062C6.91319 7.58021 7.15278 7.5 7.41667 7.5H16.5833C16.8472 7.5 17.0868 7.58021 17.3021 7.74062C17.5174 7.90104 17.6667 8.1125 17.75 8.375L19.5 13.625V20.625C19.5 20.8729 19.4201 21.0807 19.2604 21.2484C19.1007 21.4161 18.9028 21.5 18.6667 21.5H17.8333C17.5972 21.5 17.3993 21.4161 17.2396 21.2484C17.0799 21.0807 17 20.8729 17 20.625V19.75H7V20.625C7 20.8729 6.92014 21.0807 6.76042 21.2484C6.60069 21.4161 6.40278 21.5 6.16667 21.5H5.33333ZM6.83333 11.875H17.1667L16.2917 9.25H7.70833L6.83333 11.875ZM8.25 17.125C8.59722 17.125 8.89236 16.9974 9.13542 16.7422C9.37847 16.487 9.5 16.1771 9.5 15.8125C9.5 15.4479 9.37847 15.138 9.13542 14.8828C8.89236 14.6276 8.59722 14.5 8.25 14.5C7.90278 14.5 7.60764 14.6276 7.36458 14.8828C7.12153 15.138 7 15.4479 7 15.8125C7 16.1771 7.12153 16.487 7.36458 16.7422C7.60764 16.9974 7.90278 17.125 8.25 17.125ZM15.75 17.125C16.0972 17.125 16.3924 16.9974 16.6354 16.7422C16.8785 16.487 17 16.1771 17 15.8125C17 15.4479 16.8785 15.138 16.6354 14.8828C16.3924 14.6276 16.0972 14.5 15.75 14.5C15.4028 14.5 15.1076 14.6276 14.8646 14.8828C14.6215 15.138 14.5 15.4479 14.5 15.8125C14.5 16.1771 14.6215 16.487 14.8646 16.7422C15.1076 16.9974 15.4028 17.125 15.75 17.125Z"
                        fill="white"
                    />
                </g>
            </svg>
        </div>
    );
};

export const TextIcon = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.5 28V15H13V12H27V15H21.5V28H18.5Z" fill="#1F518F" />
        </svg>
    );
};

export const Save = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M29 15V29H11V11H25L29 15ZM20 26C20.8333 26 21.5417 25.7083 22.125 25.125C22.7083 24.5417 23 23.8333 23 23C23 22.1667 22.7083 21.4583 22.125 20.875C21.5417 20.2917 20.8333 20 20 20C19.1667 20 18.4583 20.2917 17.875 20.875C17.2917 21.4583 17 22.1667 17 23C17 23.8333 17.2917 24.5417 17.875 25.125C18.4583 25.7083 19.1667 26 20 26ZM14 18H23V14H14V18Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Share = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26 30C25.1667 30 24.4583 29.7083 23.875 29.125C23.2917 28.5417 23 27.8333 23 27C23 26.8833 23.0083 26.7623 23.025 26.637C23.0417 26.5123 23.0667 26.4 23.1 26.3L16.05 22.2C15.7667 22.45 15.45 22.6457 15.1 22.787C14.75 22.929 14.3833 23 14 23C13.1667 23 12.4583 22.7083 11.875 22.125C11.2917 21.5417 11 20.8333 11 20C11 19.1667 11.2917 18.4583 11.875 17.875C12.4583 17.2917 13.1667 17 14 17C14.3833 17 14.75 17.0707 15.1 17.212C15.45 17.354 15.7667 17.55 16.05 17.8L23.1 13.7C23.0667 13.6 23.0417 13.4877 23.025 13.363C23.0083 13.2377 23 13.1167 23 13C23 12.1667 23.2917 11.4583 23.875 10.875C24.4583 10.2917 25.1667 10 26 10C26.8333 10 27.5417 10.2917 28.125 10.875C28.7083 11.4583 29 12.1667 29 13C29 13.8333 28.7083 14.5417 28.125 15.125C27.5417 15.7083 26.8333 16 26 16C25.6167 16 25.25 15.929 24.9 15.787C24.55 15.6457 24.2333 15.45 23.95 15.2L16.9 19.3C16.9333 19.4 16.9583 19.5123 16.975 19.637C16.9917 19.7623 17 19.8833 17 20C17 20.1167 16.9917 20.2373 16.975 20.362C16.9583 20.4873 16.9333 20.6 16.9 20.7L23.95 24.8C24.2333 24.55 24.55 24.354 24.9 24.212C25.25 24.0707 25.6167 24 26 24C26.8333 24 27.5417 24.2917 28.125 24.875C28.7083 25.4583 29 26.1667 29 27C29 27.8333 28.7083 28.5417 28.125 29.125C27.5417 29.7083 26.8333 30 26 30Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Recent = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.8 24.2L19 20.4V15H21V19.6L24.2 22.8L22.8 24.2ZM20 29C17.7 29 15.696 28.2373 13.988 26.712C12.2793 25.1873 11.3 23.2833 11.05 21H13.1C13.3333 22.7333 14.104 24.1667 15.412 25.3C16.7207 26.4333 18.25 27 20 27C21.95 27 23.604 26.3207 24.962 24.962C26.3207 23.604 27 21.95 27 20C27 18.05 26.3207 16.3957 24.962 15.037C23.604 13.679 21.95 13 20 13C18.85 13 17.775 13.2667 16.775 13.8C15.775 14.3333 14.9333 15.0667 14.25 16H17V18H11V12H13V14.35C13.85 13.2833 14.8877 12.4583 16.113 11.875C17.3377 11.2917 18.6333 11 20 11C21.25 11 22.421 11.2373 23.513 11.712C24.6043 12.1873 25.5543 12.829 26.363 13.637C27.171 14.4457 27.8127 15.3957 28.288 16.487C28.7627 17.579 29 18.75 29 20C29 21.25 28.7627 22.4207 28.288 23.512C27.8127 24.604 27.171 25.554 26.363 26.362C25.5543 27.1707 24.6043 27.8127 23.513 28.288C22.421 28.7627 21.25 29 20 29Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Favourite = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.825 29L15.45 21.975L10 17.25L17.2 16.625L20 10L22.8 16.625L30 17.25L24.55 21.975L26.175 29L20 25.275L13.825 29Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const FavouriteOpen = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.85 24.825L20 22.925L23.15 24.85L22.325 21.25L25.1 18.85L21.45 18.525L20 15.125L18.55 18.5L14.9 18.825L17.675 21.25L16.85 24.825ZM13.825 29L15.45 21.975L10 17.25L17.2 16.625L20 10L22.8 16.625L30 17.25L24.55 21.975L26.175 29L20 25.275L13.825 29Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Download = ({ color = '#1F518F' }: { color?: string }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 28C13.45 28 12.9793 27.8043 12.588 27.413C12.196 27.021 12 26.55 12 26V23H14V26H26V23H28V26C28 26.55 27.8043 27.021 27.413 27.413C27.021 27.8043 26.55 28 26 28H14ZM20 24L15 19L16.4 17.55L19 20.15V12H21V20.15L23.6 17.55L25 19L20 24Z"
                fill={color}
            />
        </svg>
    );
};

export const Eye = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 24C21.25 24 22.3127 23.5627 23.188 22.688C24.0627 21.8127 24.5 20.75 24.5 19.5C24.5 18.25 24.0627 17.1873 23.188 16.312C22.3127 15.4373 21.25 15 20 15C18.75 15 17.6873 15.4373 16.812 16.312C15.9373 17.1873 15.5 18.25 15.5 19.5C15.5 20.75 15.9373 21.8127 16.812 22.688C17.6873 23.5627 18.75 24 20 24ZM20 22.2C19.25 22.2 18.6127 21.9373 18.088 21.412C17.5627 20.8873 17.3 20.25 17.3 19.5C17.3 18.75 17.5627 18.1123 18.088 17.587C18.6127 17.0623 19.25 16.8 20 16.8C20.75 16.8 21.3877 17.0623 21.913 17.587C22.4377 18.1123 22.7 18.75 22.7 19.5C22.7 20.25 22.4377 20.8873 21.913 21.412C21.3877 21.9373 20.75 22.2 20 22.2ZM20 27C17.5667 27 15.35 26.3207 13.35 24.962C11.35 23.604 9.9 21.7833 9 19.5C9.9 17.2167 11.35 15.3957 13.35 14.037C15.35 12.679 17.5667 12 20 12C22.4333 12 24.65 12.679 26.65 14.037C28.65 15.3957 30.1 17.2167 31 19.5C30.1 21.7833 28.65 23.604 26.65 24.962C24.65 26.3207 22.4333 27 20 27Z"
                fill="#1F518F"
            />
        </svg>
    );
};

export const Layers = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 21C3.45 21 2.97917 20.8042 2.5875 20.4125C2.19583 20.0208 2 19.55 2 19V12H4V19H13V21H4ZM8 17C7.45 17 6.97917 16.8042 6.5875 16.4125C6.19583 16.0208 6 15.55 6 15V8H8V15H17V17H8ZM12 13C11.45 13 10.9792 12.8042 10.5875 12.4125C10.1958 12.0208 10 11.55 10 11V5C10 4.45 10.1958 3.97917 10.5875 3.5875C10.9792 3.19583 11.45 3 12 3H20C20.55 3 21.0208 3.19583 21.4125 3.5875C21.8042 3.97917 22 4.45 22 5V11C22 11.55 21.8042 12.0208 21.4125 12.4125C21.0208 12.8042 20.55 13 20 13H12ZM12 11H20V7H12V11Z"
                fill="#999DA3"
            />
        </svg>
    );
};

export const NoFoundError = () => {
    return (
        <svg width="60" height="60" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.3334 73.3333C18.7223 73.3333 14.7917 71.7083 11.5417 68.4583C8.29175 65.2083 6.66675 61.2778 6.66675 56.6667C6.66675 52.0556 8.29175 48.125 11.5417 44.875C14.7917 41.625 18.7223 40 23.3334 40C27.9445 40 31.8751 41.625 35.1251 44.875C38.3751 48.125 40.0001 52.0556 40.0001 56.6667C40.0001 61.2778 38.3751 65.2083 35.1251 68.4583C31.8751 71.7083 27.9445 73.3333 23.3334 73.3333ZM68.6668 70L47.3334 48.6667C46.6668 47.9444 45.9584 47.2083 45.2084 46.4583C44.4584 45.7083 43.7223 45 43.0001 44.3333C45.1112 43 46.8056 41.2222 48.0834 39C49.3612 36.7778 50.0001 34.3333 50.0001 31.6667C50.0001 27.5 48.5418 23.9583 45.6251 21.0417C42.7084 18.125 39.1668 16.6667 35.0001 16.6667C30.8334 16.6667 27.2918 18.125 24.3751 21.0417C21.4584 23.9583 20.0001 27.5 20.0001 31.6667C20.0001 32 20.014 32.3194 20.0417 32.625C20.0695 32.9306 20.1112 33.25 20.1667 33.5833C19.1667 33.6944 18.0695 33.9167 16.8751 34.25C15.6806 34.5833 14.6112 34.9722 13.6667 35.4167C13.5556 34.8056 13.4723 34.1944 13.4167 33.5833C13.3612 32.9722 13.3334 32.3333 13.3334 31.6667C13.3334 25.6111 15.4306 20.4861 19.6251 16.2917C23.8195 12.0972 28.9445 10 35.0001 10C41.0556 10 46.1806 12.0972 50.3751 16.2917C54.5695 20.4861 56.6668 25.6111 56.6668 31.6667C56.6668 34.0556 56.2918 36.3194 55.5418 38.4583C54.7918 40.5972 53.7501 42.5556 52.4168 44.3333L73.3334 65.3333L68.6668 70ZM17.4167 64.9167L23.3334 59L29.1668 64.9167L31.5834 62.5833L25.6668 56.6667L31.5834 50.75L29.2501 48.4167L23.3334 54.3333L17.4167 48.4167L15.0834 50.75L21.0001 56.6667L15.0834 62.5833L17.4167 64.9167Z"
                fill="#999DA3"
            />
        </svg>
    );
};

export const Document = ({ color }: { color: string }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 18H16V16H8V18ZM8 14H16V12H8V14ZM6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V4C4 3.45 4.19583 2.97917 4.5875 2.5875C4.97917 2.19583 5.45 2 6 2H14L20 8V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM13 9H18L13 4V9Z"
                fill={color}
            />
        </svg>
    );
};

export const CarIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 19V20C6 20.2833 5.90433 20.5207 5.713 20.712C5.521 20.904 5.28333 21 5 21H4C3.71667 21 3.47933 20.904 3.288 20.712C3.096 20.5207 3 20.2833 3 20V12L5.1 6C5.2 5.7 5.37933 5.45833 5.638 5.275C5.896 5.09167 6.18333 5 6.5 5H17.5C17.8167 5 18.1043 5.09167 18.363 5.275C18.621 5.45833 18.8 5.7 18.9 6L21 12V20C21 20.2833 20.904 20.5207 20.712 20.712C20.5207 20.904 20.2833 21 20 21H19C18.7167 21 18.4793 20.904 18.288 20.712C18.096 20.5207 18 20.2833 18 20V19H6ZM5.8 10H18.2L17.15 7H6.85L5.8 10ZM7.5 16C7.91667 16 8.27067 15.854 8.562 15.562C8.854 15.2707 9 14.9167 9 14.5C9 14.0833 8.854 13.7293 8.562 13.438C8.27067 13.146 7.91667 13 7.5 13C7.08333 13 6.72933 13.146 6.438 13.438C6.146 13.7293 6 14.0833 6 14.5C6 14.9167 6.146 15.2707 6.438 15.562C6.72933 15.854 7.08333 16 7.5 16ZM16.5 16C16.9167 16 17.2707 15.854 17.562 15.562C17.854 15.2707 18 14.9167 18 14.5C18 14.0833 17.854 13.7293 17.562 13.438C17.2707 13.146 16.9167 13 16.5 13C16.0833 13 15.7293 13.146 15.438 13.438C15.146 13.7293 15 14.0833 15 14.5C15 14.9167 15.146 15.2707 15.438 15.562C15.7293 15.854 16.0833 16 16.5 16Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const PhoneIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.95 21C17.8 21 15.7043 20.5207 13.663 19.562C11.621 18.604 9.81267 17.3373 8.238 15.762C6.66267 14.1873 5.396 12.379 4.438 10.337C3.47933 8.29567 3 6.2 3 4.05C3 3.75 3.1 3.5 3.3 3.3C3.5 3.1 3.75 3 4.05 3H8.1C8.33333 3 8.54167 3.075 8.725 3.225C8.90833 3.375 9.01667 3.56667 9.05 3.8L9.7 7.3C9.73333 7.53333 9.72933 7.74567 9.688 7.937C9.646 8.129 9.55 8.3 9.4 8.45L7 10.9C7.7 12.1 8.575 13.225 9.625 14.275C10.675 15.325 11.8333 16.2333 13.1 17L15.45 14.65C15.6 14.5 15.796 14.3873 16.038 14.312C16.2793 14.2373 16.5167 14.2167 16.75 14.25L20.2 14.95C20.4333 15 20.625 15.1123 20.775 15.287C20.925 15.4623 21 15.6667 21 15.9V19.95C21 20.25 20.9 20.5 20.7 20.7C20.5 20.9 20.25 21 19.95 21Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const BatteryIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.72727 4.8H3.81818V5.7H9.27273V4.8H8.36364V3.9C8.36364 3.40294 7.95662 3 7.45455 3H5.63636C5.13429 3 4.72727 3.40294 4.72727 3.9V4.8Z"
                fill="#4C525D"
            />
            <path
                d="M2.90909 6.6C2.40701 6.6 2 7.00294 2 7.5V8.4C2 8.89706 2.40701 9.3 2.90909 9.3H21.0909C21.593 9.3 22 8.89706 22 8.4V7.5C22 7.00294 21.593 6.6 21.0909 6.6H2.90909Z"
                fill="#4C525D"
            />
            <path
                d="M14.7273 4.8H15.6364V3.9C15.6364 3.40294 16.0434 3 16.5455 3H18.3636C18.8657 3 19.2727 3.40294 19.2727 3.9V4.8H20.1818V5.7H14.7273V4.8Z"
                fill="#4C525D"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.90909 10.2H21.0909V21H2.90909V10.2ZM9.86314 16.5438H10.9699L10.6843 18.4932C10.6724 18.5932 10.6917 18.6858 10.7423 18.7711C10.7929 18.8563 10.8628 18.9195 10.952 18.9607C11.0413 19.0019 11.1335 19.0107 11.2287 18.9872C11.3239 18.9636 11.4043 18.9137 11.4697 18.8372L14.4062 15.3618C14.5074 15.2442 14.5267 15.1178 14.4642 14.9826C14.4017 14.8473 14.2931 14.7797 14.1384 14.7797H12.755L13.0406 12.4951C13.0525 12.3951 13.0347 12.3011 12.9871 12.2128C12.9395 12.1246 12.8651 12.0629 12.7639 12.0276C12.6628 11.9923 12.5646 11.9909 12.4694 12.0232C12.3742 12.0555 12.2968 12.1129 12.2373 12.1952L9.5686 15.9881C9.4853 16.1057 9.47786 16.2278 9.54629 16.3542C9.61472 16.4806 9.72034 16.5438 9.86314 16.5438Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const TreeTroubleshootingIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 21V18H11V8H9V11H2V3H9V6H15V3H22V11H15V8H13V16H15V13H22V21H15Z" fill="#4C525D" />
        </svg>
    );
};

export const TechTipsIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 13V11H22V13H18ZM19.2 20L16 17.6L17.2 16L20.4 18.4L19.2 20ZM17.2 8L16 6.4L19.2 4L20.4 5.6L17.2 8ZM5 19V15H4C3.45 15 2.97917 14.8042 2.5875 14.4125C2.19583 14.0208 2 13.55 2 13V11C2 10.45 2.19583 9.97917 2.5875 9.5875C2.97917 9.19583 3.45 9 4 9H8L13 6V18L8 15H7V19H5ZM14 15.35V8.65C14.45 9.05 14.8125 9.5375 15.0875 10.1125C15.3625 10.6875 15.5 11.3167 15.5 12C15.5 12.6833 15.3625 13.3125 15.0875 13.8875C14.8125 14.4625 14.45 14.95 14 15.35Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const MenuBookIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.49995 7.50019C6.07456 7.49788 5.64973 7.53133 5.22995 7.60019C4.80995 7.67019 4.39995 7.75019 3.99995 7.85019V9.45019C4.3931 9.30334 4.79785 9.18961 5.20995 9.11019C5.63607 9.03714 6.06761 9.00034 6.49995 9.00019C7.11465 8.99793 7.72685 9.07865 8.31995 9.24019C8.90266 9.39731 9.46611 9.61867 9.99995 9.90019V8.20019C9.45497 7.96958 8.88902 7.79209 8.30995 7.67019C7.71336 7.55634 7.10731 7.49941 6.49995 7.50019Z"
                fill="#4C525D"
            />
            <path
                d="M18 11.0002C18.9319 11.0014 19.8542 11.1886 20.7128 11.5509C21.5713 11.9133 22.3489 12.4434 23 13.1102V6.11019C22.9975 5.92471 22.9494 5.74269 22.86 5.58019C22.7671 5.4156 22.6232 5.28572 22.45 5.21019C21.6663 4.82698 20.8453 4.52539 20 4.31019C19.1825 4.10466 18.3428 4.00054 17.5 4.00019C15.564 3.98856 13.6619 4.50732 12 5.50019C11.1676 5.0067 10.273 4.62667 9.33995 4.37019C8.41379 4.12191 7.45881 3.99749 6.49995 4.00019C5.65705 4.00054 4.8174 4.10466 3.99995 4.31019C3.16864 4.52476 2.36119 4.82295 1.58995 5.20019C1.41673 5.27572 1.27281 5.4056 1.17995 5.57019C1.07645 5.72897 1.01455 5.91122 0.99995 6.10019V18.1002C0.996462 18.2771 1.03998 18.4518 1.12607 18.6064C1.21215 18.761 1.33772 18.89 1.48995 18.9802C1.64197 19.068 1.81441 19.1142 1.98995 19.1142C2.16549 19.1142 2.33793 19.068 2.48995 18.9802C3.10457 18.6492 3.75561 18.3907 4.42995 18.2102C5.10691 18.0447 5.80356 17.9741 6.49995 18.0002C7.4911 17.9957 8.47446 18.1754 9.39995 18.5302C10.0112 18.7684 10.5974 19.0665 11.15 19.4202C11.0491 18.9536 10.9988 18.4775 11 18.0002C11 16.1437 11.7374 14.3632 13.0502 13.0504C14.363 11.7377 16.1434 11.0002 18 11.0002ZM8.78995 16.2602C7.47666 15.9621 6.11764 15.9248 4.78995 16.1502C4.18186 16.2504 3.58314 16.4009 2.99995 16.6002V6.70019C3.55128 6.46811 4.12401 6.2906 4.70995 6.17019C5.30013 6.05868 5.89933 6.00177 6.49995 6.00019C7.28296 6.00018 8.0627 6.10101 8.81995 6.30019C9.58491 6.49743 10.3186 6.80035 11 7.20019V17.0502C10.2928 16.7106 9.55212 16.4458 8.78995 16.2602Z"
                fill="#4C525D"
            />
            <path
                d="M6.49995 10.2502C6.07456 10.2479 5.64973 10.2813 5.22995 10.3502C4.80995 10.4202 4.39995 10.5002 3.99995 10.6002V12.2002C4.3931 12.0533 4.79785 11.9396 5.20995 11.8602C5.63607 11.7871 6.06761 11.7503 6.49995 11.7502C7.11511 11.7512 7.72731 11.8353 8.31995 12.0002C8.90266 12.1573 9.46611 12.3787 9.99995 12.6602V11.0002C9.45497 10.7696 8.88902 10.5921 8.30995 10.4702C7.71497 10.3395 7.1089 10.2659 6.49995 10.2502Z"
                fill="#4C525D"
            />
            <path
                d="M6.49995 13.0002C6.07456 12.9979 5.64973 13.0313 5.22995 13.1002C4.80995 13.1702 4.39995 13.2502 3.99995 13.3502V15.0002C4.3931 14.8533 4.79785 14.7396 5.20995 14.6602C5.63463 14.5704 6.06619 14.5168 6.49995 14.5002C7.11348 14.4963 7.72502 14.5702 8.31995 14.7202C8.90775 14.871 9.47275 15.0997 9.99995 15.4002V13.7002C9.45497 13.4696 8.88902 13.2921 8.30995 13.1702C7.71336 13.0563 7.10731 12.9994 6.49995 13.0002Z"
                fill="#4C525D"
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.6199 17.3602C21.6527 17.572 21.6694 17.7859 21.6699 18.0002C21.6817 18.2068 21.6783 18.414 21.6599 18.6202L22.7899 19.6202L21.7899 21.2802L20.3699 20.8402C20.2011 20.9622 20.024 21.0725 19.8399 21.1702L19.2899 21.4302L18.9999 22.8902H16.9999L16.7099 21.4702L16.1599 21.2102C15.9759 21.1125 15.7988 21.0022 15.6299 20.8802L14.2099 21.3202L13.2099 19.6602L14.3399 18.6602C14.2733 18.2361 14.2733 17.8043 14.3399 17.3802L13.2099 16.3802L14.2099 14.7202L15.6299 15.1602C15.7988 15.0381 15.9759 14.9279 16.1599 14.8302L16.7099 14.5702L16.9999 13.1102H18.9999L19.2499 14.5502L19.7999 14.8102C19.984 14.9079 20.1611 15.0181 20.3299 15.1402L21.7499 14.7002L22.7499 16.3602L21.6199 17.3602ZM18 20.0002C19.1045 20.0002 20 19.1048 20 18.0002C20 16.8956 19.1045 16.0002 18 16.0002C16.8954 16.0002 16 16.8956 16 18.0002C16 19.1048 16.8954 20.0002 18 20.0002Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const GuidesIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 17H11V15H6V17ZM16 17H18V15H16V17ZM6 13H11V11H6V13ZM16 13H18V7H16V13ZM6 9H11V7H6V9ZM2 21V3H22V21H2Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const AccountManagerIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.45 15.5C10.8667 15.9167 11.3917 16.1125 12.025 16.0875C12.6583 16.0625 13.1167 15.8333 13.4 15.4L19 7L10.6 12.6C10.1667 12.9 9.92917 13.3542 9.8875 13.9625C9.84583 14.5708 10.0333 15.0833 10.45 15.5ZM5.1 20C4.73333 20 4.39583 19.9208 4.0875 19.7625C3.77917 19.6042 3.53333 19.3667 3.35 19.05C2.91667 18.2667 2.58333 17.4542 2.35 16.6125C2.11667 15.7708 2 14.9 2 14C2 12.6167 2.2625 11.3167 2.7875 10.1C3.3125 8.88333 4.025 7.825 4.925 6.925C5.825 6.025 6.88333 5.3125 8.1 4.7875C9.31667 4.2625 10.6167 4 12 4C13.3667 4 14.65 4.25833 15.85 4.775C17.05 5.29167 18.1 5.99583 19 6.8875C19.9 7.77917 20.6167 8.82083 21.15 10.0125C21.6833 11.2042 21.9583 12.4833 21.975 13.85C21.9917 14.7667 21.8875 15.6625 21.6625 16.5375C21.4375 17.4125 21.0917 18.25 20.625 19.05C20.4417 19.3667 20.1958 19.6042 19.8875 19.7625C19.5792 19.9208 19.2417 20 18.875 20H5.1Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const SpeedVelocimeterIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
                fill="#4C525D"
            />
            <path
                d="M10.67 13.02C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.47 18.87 10 17.49 10 16C10 14.93 10.25 13.93 10.67 13.02Z"
                fill="#4C525D"
            />
            <path
                d="M20.75 16C20.75 15.78 20.72 15.58 20.69 15.37L21.83 14.36L20.83 12.63L19.38 13.12C19.06 12.85 18.7 12.64 18.3 12.49L18 11H16L15.7 12.49C15.3 12.64 14.94 12.85 14.62 13.12L13.17 12.63L12.17 14.36L13.31 15.37C13.28 15.58 13.25 15.78 13.25 16C13.25 16.22 13.28 16.42 13.31 16.63L12.17 17.64L13.17 19.37L14.62 18.88C14.94 19.15 15.3 19.36 15.7 19.51L16 21H18L18.3 19.51C18.7 19.36 19.06 19.15 19.38 18.88L20.83 19.37L21.83 17.64L20.69 16.63C20.72 16.42 20.75 16.22 20.75 16ZM17 18C15.9 18 15 17.1 15 16C15 14.9 15.9 14 17 14C18.1 14 19 14.9 19 16C19 17.1 18.1 18 17 18Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const MenuBookCorrectionIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.49995 7.50019C6.07456 7.49788 5.64973 7.53133 5.22995 7.60019C4.80995 7.67019 4.39995 7.75019 3.99995 7.85019V9.45019C4.3931 9.30334 4.79785 9.18961 5.20995 9.11019C5.63607 9.03714 6.06761 9.00034 6.49995 9.00019C7.11465 8.99793 7.72685 9.07865 8.31995 9.24019C8.90266 9.39731 9.46611 9.61867 9.99995 9.90019V8.20019C9.45497 7.96958 8.88902 7.7921 8.30995 7.67019C7.71336 7.55634 7.10731 7.49941 6.49995 7.50019Z"
                fill="#4C525D"
            />
            <path
                d="M18 11.0002C18.9319 11.0014 19.8542 11.1886 20.7128 11.5509C21.5713 11.9133 22.3489 12.4434 23 13.1102V6.11019C22.9975 5.92471 22.9494 5.74269 22.86 5.58019C22.7671 5.4156 22.6232 5.28572 22.45 5.21019C21.6663 4.82698 20.8453 4.52539 20 4.31019C19.1825 4.10466 18.3428 4.00054 17.5 4.00019C15.564 3.98856 13.6619 4.50732 12 5.50019C11.1676 5.0067 10.273 4.62667 9.33995 4.37019C8.41379 4.12191 7.45881 3.99749 6.49995 4.00019C5.65705 4.00054 4.8174 4.10466 3.99995 4.31019C3.16864 4.52476 2.36119 4.82295 1.58995 5.20019C1.41673 5.27572 1.27281 5.4056 1.17995 5.57019C1.07645 5.72897 1.01455 5.91122 0.99995 6.10019V18.1002C0.996462 18.2771 1.03998 18.4518 1.12607 18.6064C1.21215 18.761 1.33772 18.89 1.48995 18.9802C1.64197 19.068 1.81441 19.1142 1.98995 19.1142C2.16549 19.1142 2.33793 19.068 2.48995 18.9802C3.10457 18.6492 3.75561 18.3907 4.42995 18.2102C5.10691 18.0447 5.80356 17.9741 6.49995 18.0002C7.4911 17.9957 8.47446 18.1754 9.39995 18.5302C10.0112 18.7684 10.5974 19.0665 11.15 19.4202C11.0491 18.9536 10.9988 18.4775 11 18.0002C11 16.1437 11.7374 14.3632 13.0502 13.0504C14.363 11.7377 16.1434 11.0002 18 11.0002ZM8.78995 16.2602C7.47666 15.9621 6.11764 15.9248 4.78995 16.1502C4.18186 16.2504 3.58314 16.4009 2.99995 16.6002V6.70019C3.55128 6.46811 4.12401 6.2906 4.70995 6.17019C5.30013 6.05868 5.89933 6.00177 6.49995 6.00019C7.28296 6.00018 8.0627 6.10101 8.81995 6.30019C9.58491 6.49743 10.3186 6.80035 11 7.20019V17.0502C10.2928 16.7106 9.55212 16.4458 8.78995 16.2602Z"
                fill="#4C525D"
            />
            <path
                d="M6.49995 10.2502C6.07456 10.2479 5.64973 10.2813 5.22995 10.3502C4.80995 10.4202 4.39995 10.5002 3.99995 10.6002V12.2002C4.3931 12.0533 4.79785 11.9396 5.20995 11.8602C5.63607 11.7871 6.06761 11.7503 6.49995 11.7502C7.11511 11.7512 7.72731 11.8353 8.31995 12.0002C8.90266 12.1573 9.46611 12.3787 9.99995 12.6602V11.0002C9.45497 10.7696 8.88902 10.5921 8.30995 10.4702C7.71497 10.3395 7.1089 10.2659 6.49995 10.2502Z"
                fill="#4C525D"
            />
            <path
                d="M6.49995 13.0002C6.07456 12.9979 5.64973 13.0313 5.22995 13.1002C4.80995 13.1702 4.39995 13.2502 3.99995 13.3502V15.0002C4.3931 14.8533 4.79785 14.7396 5.20995 14.6602C5.63463 14.5704 6.06619 14.5168 6.49995 14.5002C7.11348 14.4963 7.72502 14.5702 8.31995 14.7202C8.90775 14.871 9.47275 15.0997 9.99995 15.4002V13.7002C9.45497 13.4696 8.88902 13.2921 8.30995 13.1702C7.71336 13.0563 7.10731 12.9994 6.49995 13.0002Z"
                fill="#4C525D"
            />
            <path
                d="M18 23.0002C16.6739 23.0002 15.4021 22.4734 14.4644 21.5357C13.5267 20.598 13 19.3263 13 18.0002C13 16.6741 13.5267 15.4023 14.4644 14.4647C15.4021 13.527 16.6739 13.0002 18 13.0002C18.6566 13.0002 19.3067 13.1295 19.9134 13.3808C20.52 13.6321 21.0712 14.0004 21.5355 14.4647C21.9998 14.929 22.3681 15.4801 22.6193 16.0868C22.8706 16.6934 23 17.3436 23 18.0002C23 18.6568 22.8706 19.307 22.6193 19.9136C22.3681 20.5202 21.9998 21.0714 21.5355 21.5357C21.0712 22 20.52 22.3683 19.9134 22.6196C19.3067 22.8709 18.6566 23.0002 18 23.0002ZM17.28 20.2502L20.75 16.8002L19.7 15.7502L17.28 18.1202L16.28 17.1202L15.2299 18.1902L17.28 20.2502Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const OilCanIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.0892 10.6618C21.4017 10.6283 21.7151 10.7154 21.9643 10.9051C22.0233 10.9051 22.0429 10.9051 22.0921 10.9051C22.3183 10.6229 22.5543 10.3408 22.7903 10.0684C22.8493 10.0003 22.7903 9.96139 22.7903 9.91275L21.9839 9.3096C21.4136 8.88156 18.0507 8.8913 17.2936 8.8913H10.4105C10.3416 8.8913 10.3122 8.89129 10.3122 8.79401C10.3122 8.69673 10.3122 7.1402 10.3122 7.05264C10.3122 6.96509 10.3121 6.93591 10.4301 6.93591H11.8363C11.9536 6.93591 12.0662 6.88979 12.1492 6.80769C12.2322 6.72559 12.2788 6.61426 12.2788 6.49816C12.2788 6.35223 12.2788 5.58368 12.2788 5.43775C12.2762 5.32076 12.2274 5.20943 12.1428 5.1276C12.0583 5.04577 11.9447 4.99997 11.8264 5H6.78207C6.66379 4.99997 6.55021 5.04577 6.46566 5.1276C6.38111 5.20943 6.33236 5.32076 6.32979 5.43775V6.41058C6.32527 6.47645 6.33521 6.5425 6.35896 6.6042C6.3827 6.6659 6.41969 6.7218 6.46734 6.76802C6.515 6.81424 6.57221 6.8497 6.63503 6.87196C6.69785 6.89423 6.7648 6.90276 6.83128 6.89699H8.21771C8.27671 6.89699 8.30624 6.89702 8.30624 6.98457C8.30624 7.07212 8.30624 8.63836 8.30624 8.73564C8.30624 8.83292 8.30624 8.85238 8.18824 8.85238H2.96691C2.70741 8.85217 2.45041 8.90278 2.21077 9.00129C1.97113 9.0998 1.75353 9.24426 1.57058 9.42634C1.38588 9.60433 1.24003 9.81786 1.14194 10.0538C1.04386 10.2897 0.995656 10.543 1.00031 10.798V14.6893C0.995656 14.9444 1.04386 15.1977 1.14194 15.4336C1.24003 15.6695 1.38588 15.883 1.57058 16.061C1.75353 16.2431 1.97113 16.3875 2.21077 16.4861C2.45041 16.5846 2.70741 16.6352 2.96691 16.635H4.93352V18.5125C4.92767 18.577 4.936 18.642 4.958 18.703C4.98001 18.7639 5.01512 18.8195 5.06093 18.8657C5.10674 18.912 5.16215 18.9478 5.22333 18.9708C5.28451 18.9938 5.35003 19.0034 5.41532 18.9989H13.6652C13.8225 18.9989 14.0585 18.9308 14.0585 18.7849V16.7031C14.0585 16.7031 19.8994 11.2552 20.1649 11.0315C20.429 10.8202 20.7508 10.6916 21.0892 10.6618ZM2.95707 14.7185V10.8272H4.92367V14.7185H2.95707Z"
                fill="#4C525D"
            />
            <path
                d="M23.6949 14.5045C23.4942 14.1263 23.2675 13.7623 23.0164 13.4149L22.5543 12.802C22.3871 12.5977 22.2199 12.3935 22.0331 12.1989C21.8561 12.3935 21.689 12.5977 21.5218 12.802L21.0498 13.4149C20.8019 13.7625 20.5785 14.1265 20.3811 14.5045C20.1881 14.8404 20.0801 15.2176 20.0665 15.6038C20.0665 16.1198 20.2737 16.6147 20.6425 16.9796C21.0113 17.3444 21.5115 17.5494 22.0331 17.5494C22.2926 17.5496 22.5496 17.499 22.7892 17.4005C23.0289 17.302 23.2465 17.1575 23.4294 16.9755C23.6141 16.7975 23.76 16.5839 23.8581 16.348C23.9561 16.1121 24.0043 15.8588 23.9997 15.6038C23.9898 15.2183 23.8852 14.841 23.6949 14.5045Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const PushPinIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4 2V22H20V8L14 2H4ZM14.9993 13.4654L13.9861 12.4523L13.9782 8.88243L14.4927 8.89036L14.4848 7.86927L9.40312 7.86926L9.3952 8.89037L9.90179 8.89037L9.9018 12.4523L8.88866 13.4654L8.88864 14.4944L11.4295 14.4865L11.4295 17.5419L11.944 18.0563L12.4506 17.5498L12.4505 14.4944L14.9993 14.4944L14.9993 13.4654Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const BookletIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 22V2H20V22H4ZM11 11L13.5 9.5L16 11V4H11V11Z" fill="#4C525D" />
        </svg>
    );
};

export const VideoIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H16C16.55 4 17.021 4.196 17.413 4.588C17.8043 4.97933 18 5.45 18 6V10.5L22 6.5V17.5L18 13.5V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H4Z"
                fill="#4C525D"
            />
        </svg>
    );
};

export const CarCheckIcon = ({ size }: { size: number }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.07 11.51C12.9572 11.3459 12.8537 11.1757 12.76 11H4.76L5.76 8H11.5C11.4162 7.53461 11.3727 7.06286 11.37 6.59V6H5.37C5.08336 6.02308 4.80992 6.1303 4.58398 6.3082C4.35804 6.48611 4.18968 6.72677 4.1 7L2 13V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H4C4.26522 22 4.51957 21.8946 4.70711 21.7071C4.89464 21.5196 5 21.2652 5 21V20H17V21C17 21.2652 17.1054 21.5196 17.2929 21.7071C17.4804 21.8946 17.7348 22 18 22H19C19.2652 22 19.5196 21.8946 19.7071 21.7071C19.8946 21.5196 20 21.2652 20 21V12.84C19.1864 13.5613 18.213 14.0788 17.16 14.35C15.4997 13.9256 14.0477 12.9174 13.07 11.51ZM7.56 16.51C7.27875 16.7909 6.8975 16.9487 6.5 16.9487C6.1025 16.9487 5.72125 16.7909 5.44 16.51C5.24681 16.2982 5.11838 16.0354 5.0699 15.7529C5.02142 15.4703 5.05493 15.1798 5.16647 14.9157C5.278 14.6516 5.46289 14.4249 5.69923 14.2627C5.93556 14.1004 6.21346 14.0092 6.5 14C6.79648 14.0003 7.08623 14.0884 7.33266 14.2532C7.5791 14.4181 7.77115 14.6522 7.88457 14.9262C7.998 15.2001 8.0277 15.5015 7.96993 15.7923C7.91216 16.0831 7.76951 16.3502 7.56 16.56V16.51ZM16.56 16.51C16.3127 16.7565 15.9872 16.9091 15.6395 16.9414C15.2918 16.9736 14.9438 16.8836 14.6554 16.6867C14.3669 16.4899 14.1562 16.1986 14.0596 15.8631C13.9629 15.5276 13.9863 15.1688 14.1257 14.8487C14.2652 14.5286 14.512 14.2671 14.8235 14.1094C15.1351 13.9518 15.4919 13.9077 15.8324 13.9849C16.173 14.062 16.4759 14.2556 16.689 14.5322C16.9022 14.8088 17.0122 15.151 17 15.5C17.0032 15.6974 16.9659 15.8934 16.8902 16.0757C16.8145 16.2581 16.7021 16.4229 16.56 16.56V16.51Z"
                fill="#4C525D"
            />
            <path
                d="M12.86 3.57V6.83C12.8499 8.16216 13.2747 9.46124 14.07 10.53C14.8049 11.5871 15.8939 12.3462 17.14 12.67C18.3861 12.3462 19.4751 11.5871 20.21 10.53C21.0053 9.46124 21.4301 8.16216 21.42 6.83V3.57L17.14 2L12.86 3.57ZM19.46 6.19L16.46 9.19L14.55 7.33L15.31 6.57L16.44 7.69L18.7 5.43L19.46 6.19Z"
                fill="#4C525D"
            />
        </svg>
    );
};
