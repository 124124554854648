import React, { FC, useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { Button, Typography } from '@packages/ui/shared';
import { TagInput } from '@web/components/tag-input';
import { StyleSheet } from 'react-native';
import { Synonym } from '@packages/models/api/synonyms-search-term';
import { useSynonymsDispatch, updateSynonym } from '@packages/contexts/synonyms';
import { synonymsService } from '@web/services/singletons';

interface EditSynonymModalProps {
    show: boolean;
    onHide: () => void;
    selectedSynonym?: Synonym;
}

const useStyles = createUseStyles({
    header: {
        padding: '16px 24px!important',
    },
    title: {
        fontSize: '21px!important',
        fontWeight: '600!important',
    },
    body: {
        padding: '16px 24px!important',
    },
    bodyDescription: {
        fontSize: '15px!important',
        fontWeight: '400!important',
    },
    footer: {
        padding: '24px!important',
    },
    formLabel: {
        fontSize: '15px',
    },
});

const styles = StyleSheet.create({
    bodyDescription: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
    },
});

export const EditSynonymModal: FC<EditSynonymModalProps> = ({ show, onHide, selectedSynonym }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [synonyms, setSynonyms] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isChangeForm, setIsChangeForm] = useState(false);
    const synonymDispatch = useSynonymsDispatch();

    const closeModal = () => {
        onHide();
        setSynonyms([]);
        setSearchTerm('');
        setIsChangeForm(false);
    };

    const handleEditSynonym = (synonym: string) => {
        if (synonym.trim() && !synonyms.includes(synonym.trim())) {
            setSynonyms((prev) => {
                const newSynonyms = [...prev, synonym.trim()];
                setIsChangeForm(true);
                return newSynonyms;
            });
        }
    };

    const handleRemoveSynonym = (index: number) => {
        setSynonyms((prev) => {
            const newSynonyms = prev.filter((_, i) => i !== index);
            setIsChangeForm(true);
            return newSynonyms;
        });
    };

    const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        setIsChangeForm(
            value !== selectedSynonym?.searchTerm || !areArraysEqual(synonyms, selectedSynonym?.synonyms || [])
        );
    };

    const areArraysEqual = (arr1: string[], arr2: string[]): boolean => {
        return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort());
    };

    function handleUpdateSynonym() {
        if (selectedSynonym?.searchTermId) {
            updateSynonym(synonymDispatch, synonymsService, {
                id: selectedSynonym.searchTermId,
                searchTerm,
                synonyms,
            });
        }

        closeModal();
    }

    useEffect(() => {
        if (selectedSynonym) {
            setSynonyms(selectedSynonym.synonyms || []);
            setSearchTerm(selectedSynonym.searchTerm || '');
        }
        setIsChangeForm(false);
    }, [selectedSynonym]);

    return (
        <Modal show={show} centered onHide={closeModal} size="lg">
            <Modal.Header className={classes.header} placeholder={t('Edit Synonyms')}>
                <Modal.Title className={classes.title}>{t('Edit Synonyms')}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.body}>
                <Typography variant="caption" color="black" style={styles.bodyDescription}>
                    {t(
                        "Enter the search term and add all related words as synonyms to enhance the search engine's effectiveness."
                    )}
                </Typography>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Group controlId="search-term" className="mb-3 mt-2">
                        <Form.Label className={classes.formLabel}>{t('Search Term')}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t('Enter search term')}
                            maxLength={50}
                            required
                            value={searchTerm}
                            onChange={handleSearchTermChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="synonyms" className="mb-3">
                        <Form.Label className={classes.formLabel}>{t('Synonyms')}</Form.Label>
                        <TagInput
                            placeholder="Enter Synonyms"
                            tags={synonyms}
                            handleAdd={handleEditSynonym}
                            handleRemove={handleRemoveSynonym}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className={classes.footer}>
                <Button variant="ghost-blue" style={{ marginRight: 8 }} onPress={closeModal}>
                    {t('Cancel')}
                </Button>
                <Button variant="primary" onPress={handleUpdateSynonym} disabled={!isChangeForm}>
                    {t('Save changes')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
