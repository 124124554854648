import React, { FC, useEffect } from 'react';
import { Button, Icon, StsIconName, Typography } from '@packages/ui/shared';
import { Toast } from 'react-bootstrap';
import colors from '@packages/core/styles/colors';
import { createUseStyles } from 'react-jss';

interface Props {
    show: boolean;
    onClose: () => void;
    body: JSX.Element | string;
    autoHideTime?: number;
    iconName?: StsIconName;
    styles?: React.CSSProperties | undefined;
    textColor?: keyof typeof colors;
}

export const SuccessToaster: FC<Props> = ({
    show,
    onClose,
    body,
    autoHideTime,
    iconName,
    styles,
    textColor = 'black',
}) => {
    useEffect(() => {
        autoHide();
    }, [show]);

    const autoHide = () => {
        setTimeout(() => {
            onClose();
        }, autoHideTime || 3000);
    };

    return (
        <Toast
            show={show}
            style={{ position: 'fixed', top: 10, left: '35%', zIndex: 9999, ...styles }}
            onClose={onClose}
            data-testid="id-toast"
        >
            <Toast.Body>
                <div className="d-flex justify-content-between align-items-center pl-2">
                    <Typography variant="caption" color={textColor}>
                        {body}
                    </Typography>
                    <Button style={{ backgroundColor: 'none' }} variant="ghost-gray" onPress={onClose}>
                        <Icon color={textColor} name={iconName || 'x-close'} />
                    </Button>
                </div>
            </Toast.Body>
        </Toast>
    );
};
