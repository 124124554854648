import React, { createContext, FC, useReducer, useEffect } from 'react';
import { synonymsReducer, SynonymsState, SynonymsAction, initialSynonymsState } from './synonyms.state';
import { SynonymsService } from './synonyms.service';
import { SynonymCreatePayload } from '@packages/models/api/synonyms-search-term';

type SynonymsActionDispatcher = React.Dispatch<SynonymsAction>;

interface SynonymsProviderProps {
    children: React.ReactNode;
    synonymsService: SynonymsService;
    query?: string;
}

export const SynonymsStateContext = createContext<SynonymsState | undefined>(undefined);
export const SynonymsDispatchContext = createContext<SynonymsActionDispatcher | undefined>(undefined);

export const SynonymsProvider: FC<SynonymsProviderProps> = ({ children, synonymsService, query }) => {
    const [state, dispatch] = useReducer(synonymsReducer, initialSynonymsState);

    useEffect(() => {
        const abortController = new AbortController();

        const fetchSynonyms = async () => {
            dispatch({ type: 'SET_LOADING', payload: { loading: true } });
            try {
                const { data, pagination } = await synonymsService.fetchSynonyms({
                    text: query,
                    page: state.pagination.page,
                    size: state.pagination.size,
                    orderBy: 'updatedAt',
                    order: 'desc',
                    abortSignal: abortController.signal,
                });
                dispatch({ type: 'SET_SYNONYMS', payload: { synonyms: data, pagination } });
            } catch (error: any) {
                dispatch({ type: 'SET_ERROR', payload: { error: error.message } });
            } finally {
                dispatch({ type: 'SET_LOADING', payload: { loading: false } });
            }
        };

        fetchSynonyms();

        return () => {
            abortController.abort();
        };
    }, [synonymsService, query, state.pagination.page, state.pagination.size]);

    return (
        <SynonymsStateContext.Provider value={state}>
            <SynonymsDispatchContext.Provider value={dispatch}>{children}</SynonymsDispatchContext.Provider>
        </SynonymsStateContext.Provider>
    );
};

// Add utility functions for create, update, and delete
export const createSynonym = async (
    dispatch: SynonymsActionDispatcher,
    synonymsService: SynonymsService,
    payload: SynonymCreatePayload
) => {
    dispatch({ type: 'SET_LOADING', payload: { loading: true } });
    try {
        const synonym = await synonymsService.createSynonym(payload);
        dispatch({ type: 'ADD_SYNONYM', payload: { synonym } });
    } catch (error: any) {
        dispatch({ type: 'SET_ERROR', payload: { error: error.message } });
    } finally {
        dispatch({ type: 'SET_LOADING', payload: { loading: false } });
    }
};

export const updateSynonym = async (
    dispatch: SynonymsActionDispatcher,
    synonymsService: SynonymsService,
    payload: Partial<SynonymCreatePayload> & { id: string }
) => {
    dispatch({ type: 'SET_LOADING', payload: { loading: true } });
    try {
        const synonym = await synonymsService.updateSynonym(payload.id, payload);
        dispatch({ type: 'UPDATE_SYNONYM', payload: { synonym } });
    } catch (error: any) {
        dispatch({ type: 'SET_ERROR', payload: { error: error.message } });
    } finally {
        dispatch({ type: 'SET_LOADING', payload: { loading: false } });
    }
};

export const deleteSynonym = async (
    dispatch: SynonymsActionDispatcher,
    synonymsService: SynonymsService,
    id: string
) => {
    dispatch({ type: 'SET_LOADING', payload: { loading: true } });
    try {
        await synonymsService.deleteSynonym(id);
        dispatch({ type: 'DELETE_SYNONYM', payload: { id } });
    } catch (error: any) {
        dispatch({ type: 'SET_ERROR', payload: { error: error.message } });
    } finally {
        dispatch({ type: 'SET_LOADING', payload: { loading: false } });
    }
};
