import { useContext, useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { isEqual, every } from 'lodash';
import { useQuery, useMutation } from 'react-query';

import {
    QmrEwdState,
    AssetProcessingState,
    QmrStatusId,
    DsqmReviewStatusId,
    QaQmrAction,
    DsqmQmrAction,
    Qmr,
} from '@packages/models/api';
import { AlertHook } from '@packages/ui/shared';
import { QmrsService } from './qmrs.service';
import { fetchQmr, QmrDispatchContext, QmrStateContext } from './qmrs.context';
import {
    getQmrEwdState,
    getQmrRetailerSection,
    getQmrVehicleDetailsSection,
    getQmrMediaSection,
    getQmrConcernSection,
    getQmrCauseSection,
    getQmrCorrectionSection,
    getQmrDtcCodesSection,
    getQmrFailCodeSection,
    getQmrObjectiveSection,
} from './qmrs.utils';
import { useQmrAssetUploads, AssetUploadsService } from '../asset-uploads';
import { qmrActions } from './qmrs.state';
import { useTranslation } from 'react-i18next';
import { AdvancedSearchContext } from './advanced-search.context';
import { useInvestigationModal } from '../investigations';
import { useEscalationModal } from '../escalations';
import React from 'react';

export const useQmrState = () => {
    const state = useContext(QmrStateContext);

    if (state === undefined) {
        throw new Error('useQmrState must be used within a QmrProvider');
    }

    return state;
};

export const useQmrDispatch = () => {
    const dispatch = useContext(QmrDispatchContext);

    if (dispatch === undefined) {
        throw new Error('useQmrDispatch must be used within a QmrProvider');
    }

    return dispatch;
};

export const useQmrEwdState = () => {
    const { qmr } = useQmrState();

    const [ewdState, setEwdState] = useState<QmrEwdState>(getQmrEwdState(qmr!));
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const hasSigns = ewdState ? ewdState.ewdSignsExist : false;

    useEffect(() => {
        if (!qmr || !ewdState) {
            return;
        }

        setHasUnsavedChanges(!isEqual(getQmrEwdState(qmr), ewdState));
    }, [ewdState, qmr]);

    useEffect(() => {
        if (hasSigns || !hasUnsavedChanges) {
            return;
        }

        setEwdState({
            ewdSignsExist: false,
            ewdAccident: false,
            ewdDeath: false,
            ewdDeathCount: 0,
            ewdFire: false,
            ewdInjury: false,
            ewdInjuryCount: 0,
            ewdPropertyDamage: false,
            ewdRollover: false,
        });
    }, [hasSigns, hasUnsavedChanges]);

    return {
        ewdState,
        setEwdState,
        hasUnsavedChanges,
    };
};

export const useQmrReportSections = (submitAttempted: boolean) => {
    const { qmr, draftMedia } = useQmrState();
    const { inProgressUploads, failedUploads } = useQmrAssetUploads(qmr?.qmrId);

    const retailerSection = useMemo(() => getQmrRetailerSection({ qmr }), [qmr]);
    const vehicleDetailsSection = useMemo(
        () => getQmrVehicleDetailsSection({ qmr, submitAttempted }),
        [qmr, submitAttempted]
    );
    const mediaSection = useMemo(() => {
        if (!qmr) {
            return;
        }
        return getQmrMediaSection({
            qmr,
            submitAttempted,
            numPending: draftMedia.length,
            numUploading: inProgressUploads.length,
            numUploadErrors: failedUploads.length,
        });
    }, [draftMedia.length, failedUploads.length, inProgressUploads.length, qmr, submitAttempted]);
    const concernSection = useMemo(() => getQmrConcernSection({ qmr, submitAttempted }), [qmr, submitAttempted]);
    const causeSection = useMemo(() => getQmrCauseSection({ qmr, submitAttempted }), [qmr, submitAttempted]);
    const correctionSection = useMemo(() => getQmrCorrectionSection({ qmr, submitAttempted }), [qmr, submitAttempted]);
    const dtcCodesSection = useMemo(() => getQmrDtcCodesSection({ qmr, submitAttempted }), [qmr, submitAttempted]);
    const failCodesSection = useMemo(() => getQmrFailCodeSection({ qmr, submitAttempted }), [qmr, submitAttempted]);
    const objectiveSection = useMemo(() => getQmrObjectiveSection({ qmr }), [qmr]);
    return {
        retailerSection,
        vehicleDetailsSection,
        mediaSection,
        concernSection,
        causeSection,
        correctionSection,
        dtcCodesSection,
        failCodesSection,
        objectiveSection,
    };
};

interface UseQmrPolling {
    qmrsService: QmrsService;
    intervalSec?: number;
    signal?: AbortSignal;
    pollImmediately?: boolean;
}

/**
 * @desc Hook to poll for QMR in current context
 */
export const useQmrPolling = ({ intervalSec = 120, qmrsService, signal, pollImmediately = false }: UseQmrPolling) => {
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();

    const qmrId = qmr?.qmrId;

    const poll = useCallback(async () => {
        if (!qmrId) {
            return;
        }

        try {
            await fetchQmr({
                qmrId,
                qmrDispatch,
                qmrsService,
                ignoreCache: true,
                signal,
            });
        } catch (error) {
            console.error('Error: Failed to fetch qmr');
        }
    }, [qmrId, qmrDispatch, qmrsService, signal]);

    useEffect(() => {
        if (pollImmediately) {
            poll();
        }

        const intervalId = setInterval(poll, intervalSec * 1000);

        return () => clearInterval(intervalId);
    }, [intervalSec, poll, pollImmediately, signal]);
};

export const useQmrAssetPolling = (assetUploadsService: AssetUploadsService) => {
    const { qmr } = useQmrState();
    const qmrDispatch = useQmrDispatch();
    const pollingIntervalsRef = useRef(new Map());

    useEffect(() => {
        if (!qmr?.assets) {
            return;
        }

        const intervals = pollingIntervalsRef.current;

        for (const asset of qmr?.assets) {
            if (intervals.has(asset.assetId) && asset.assetProcessingStateId !== AssetProcessingState.Processing) {
                // if we are polling for this asset, but it's no longer processing
                // then clear its polling interval timer
                clearInterval(intervals.get(asset.assetId));
                intervals.delete(asset.assetId);
                return;
            }

            if (asset.assetProcessingStateId === AssetProcessingState.Processing && !intervals.has(asset.assetId)) {
                const id = setInterval(() => {
                    assetUploadsService
                        .getAsset({
                            assetId: asset.assetId,
                            ignoreCache: true,
                        })
                        .then((response) => {
                            if (response.success) {
                                if (response.data && response.data.asset) {
                                    qmrDispatch(qmrActions.updateAsset({ asset: response.data.asset }));

                                    if (
                                        response.data.asset.assetProcessingStateId !== AssetProcessingState.Processing
                                    ) {
                                        clearInterval(id);
                                    }
                                }
                            }
                        });
                }, 5000);

                intervals.set(asset.assetId, id);
            }
        }
    }, [assetUploadsService, qmr?.assets, qmrDispatch]);

    useEffect(() => {
        const intervals = pollingIntervalsRef.current;

        return () => {
            intervals.forEach((id) => {
                clearInterval(id);
            });
        };
    }, []);
};

export const useQmrReviewState = () => {
    const { qmr } = useQmrState();
    const { t } = useTranslation();
    const [updatedStatusId, setUpdatedStatusId] = useState<'dsqmReviewStatusId' | 'qmrStatusId'>();
    const [updatedStatusValue, setUpdatedStatusValue] = useState<QmrStatusId | DsqmReviewStatusId>();
    const [primaryActionDescription, setPrimaryActionDescription] = useState('');
    const [qaActions, setQaActions] = useState<QaQmrAction[]>([]);
    const [dsqmActions, setDsqmActions] = useState<DsqmQmrAction[]>([]);

    const { finishReviewText, qmrActionsText } = useMemo(
        () => ({
            finishReviewText: t('text:finishReview', 'Finish Review'),
            qmrActionsText: t('text:qmrActions', 'Change status'),
        }),
        [t]
    );

    const showPrimaryActionButton = useMemo(() => {
        return (
            !!updatedStatusId &&
            (qaActions.length === 0 && dsqmActions.length === 0
                ? updatedStatusValue !== QmrStatusId.MoreInfoRequested &&
                  updatedStatusValue !== DsqmReviewStatusId.MoreInfoRequested
                : true)
        );
    }, [dsqmActions.length, qaActions.length, updatedStatusId, updatedStatusValue]);

    const primaryActionButtonVariant = useMemo(() => {
        return primaryActionDescription === finishReviewText
            ? 'success'
            : primaryActionDescription === qmrActionsText
              ? 'primary-dropdown'
              : 'primary';
    }, [finishReviewText, primaryActionDescription, qmrActionsText]);

    const hasManyPrimaryActions = useMemo(() => {
        return qaActions.length > 0 || dsqmActions.length > 0;
    }, [dsqmActions.length, qaActions.length]);

    const availableActions = useMemo(() => {
        if (dsqmActions.length > 0) {
            return dsqmActions.map((action) => {
                return {
                    label: action.actionDescription,
                    isSelected: action.dsqmReviewStatusId === updatedStatusValue,
                    onSelect: () => setUpdatedStatusValue(action.dsqmReviewStatusId),
                    statusId: action.dsqmReviewStatusId,
                };
            });
        }

        return qaActions.map((action) => {
            return {
                label: action.actionDescription,
                isSelected: action.qmrStatusId === updatedStatusValue,
                onSelect: () => setUpdatedStatusValue(action.qmrStatusId),
                statusId: action.qmrStatusId,
            };
        });
    }, [dsqmActions, qaActions, updatedStatusValue]);

    const showOpenInfoRequestButton = useMemo(() => {
        if (dsqmActions.length > 0) {
            return dsqmActions.findIndex((a) => a.dsqmReviewStatusId === DsqmReviewStatusId.MoreInfoRequested) > -1;
        }

        return qaActions.findIndex((a) => a.qmrStatusId === QmrStatusId.MoreInfoRequested) > -1;
    }, [dsqmActions, qaActions]);

    const shouldShowAsDropdown = useCallback(() => {
        if (!qmr) {
            return false;
        }
        if (qmr.capabilities.transitionableDsqmReviewStatuses.length === 1) {
            return (
                qmr.capabilities.transitionableDsqmReviewStatuses[0].dsqmReviewStatusId ===
                DsqmReviewStatusId.NoActionNeeded
            );
        } else if (qmr.capabilities.transitionableQmrStatuses.length === 1) {
            return (
                qmr.capabilities.transitionableQmrStatuses[0].qmrStatusId === QmrStatusId.NoActionNeeded ||
                qmr.capabilities.transitionableQmrStatuses[0].qmrStatusId === QmrStatusId.FurtherStudy
            );
        }
        return false;
    }, [qmr]);

    useEffect(() => {
        if (!qmr) {
            return;
        }

        if (qmr.capabilities.transitionableDsqmReviewStatuses.length > 0) {
            setUpdatedStatusId('dsqmReviewStatusId');
            if (qmr.capabilities.transitionableDsqmReviewStatuses.length === 1 && !shouldShowAsDropdown()) {
                const action = qmr.capabilities.transitionableDsqmReviewStatuses[0];
                setUpdatedStatusValue(action.dsqmReviewStatusId);
                setPrimaryActionDescription(action.actionDescription);
            } else {
                setDsqmActions(qmr.capabilities.transitionableDsqmReviewStatuses);
                setPrimaryActionDescription(
                    qmr.dsqmReviewStatus.dsqmReviewStatusId === DsqmReviewStatusId.UnderReview
                        ? finishReviewText
                        : qmrActionsText
                );
            }
        } else if (qmr.capabilities.transitionableQmrStatuses.length > 0) {
            setUpdatedStatusId('qmrStatusId');
            if (qmr.capabilities.transitionableQmrStatuses.length === 1 && !shouldShowAsDropdown()) {
                const action = qmr.capabilities.transitionableQmrStatuses[0];
                setUpdatedStatusValue(action.qmrStatusId);
                setPrimaryActionDescription(action.actionDescription);
            } else {
                setQaActions(qmr.capabilities.transitionableQmrStatuses);
                setPrimaryActionDescription(
                    qmr.qmrStatus.qmrStatusId === QmrStatusId.UnderReview ? finishReviewText : qmrActionsText
                );
            }
        } else {
            setUpdatedStatusId(undefined);
            setUpdatedStatusValue(undefined);
            setPrimaryActionDescription('');
            setDsqmActions([]);
            setQaActions([]);
        }
    }, [finishReviewText, qmr, qmrActionsText, shouldShowAsDropdown]);

    return {
        updatedStatusId,
        updatedStatusValue,
        availableActions,
        primaryActionDescription,
        finishReviewText,
        qmrActionsText,
        showPrimaryActionButton,
        primaryActionButtonVariant,
        hasManyPrimaryActions,
        showOpenInfoRequestButton,
    };
};

export const useAdvancedSearchContext = () => {
    const advancedSearchContext = useContext(AdvancedSearchContext);
    return advancedSearchContext;
};

/**
 * @desc Hook to get a single info request
 */
export const useQmrInfoRequest = ({ id, qmrsService }: { id: string; qmrsService: QmrsService }) => {
    const queryFn = async () => {
        const res = await qmrsService.getInfoRequest({ id });

        if (!res.success) {
            throw res.data;
        }

        return res.data.data;
    };

    const query = useQuery(`qmr-info-req-${id}`, queryFn);

    return {
        query,
    };
};

/**
 * @desc Hook to get all info requests for a QMR
 */
export const useQmrInfoRequests = ({ qmrsService }: { qmrsService: QmrsService }) => {
    const { qmr } = useQmrState();

    const qmrId = qmr?.qmrId;

    const queryFn = async () => {
        if (!qmrId) {
            throw new Error('No QMR id');
        }

        const res = await qmrsService.getInfoRequests({ qmrId });

        if (!res.success) {
            throw res.data;
        }

        return res.data;
    };

    const query = useQuery(`qmr-info-reqs-${qmrId}`, queryFn, { enabled: !!qmrId });

    const openRequest = query.data?.openRequest;
    const closedRequests = query.data?.closedRequests || [];

    const canViewInfoRequest = qmr?.capabilities.viewMoreInfoRequest || false;
    const hasOpenInfoRequest = !!openRequest && canViewInfoRequest;

    return {
        query,
        openRequest,
        closedRequests,
        hasOpenInfoRequest,
    };
};

/**
 * @desc Hook that provides functions for manipulating QMR Info Requests
 */
export const useQmrInfoRequestActions = ({
    qmrsService,
    onSuccess,
}: {
    qmrsService: QmrsService;
    onSuccess(): void;
}) => {
    const { qmr } = useQmrState();

    const qmrId = qmr?.qmrId;

    const openInfoRequest = useCallback(
        async ({ request }: { request: string }) => {
            if (!qmrId) {
                throw new Error('No QMR id');
            }

            const res = await qmrsService.openInfoRequest({ qmrId, request });

            if (!res.success && !res.aborted) {
                throw res.data;
            }
        },
        [qmrId, qmrsService]
    );

    const openInfoRequestMutation = useMutation(openInfoRequest, { onSuccess });

    const closeInfoRequest = useCallback(
        async ({ requestId }: { requestId: string }) => {
            const res = await qmrsService.closeInfoRequest({ requestId });

            if (!res.success && !res.aborted) {
                throw res.data;
            }
        },
        [qmrsService]
    );

    const closeInfoRequestMutation = useMutation(closeInfoRequest, { onSuccess });

    const addCommentOnInfoRequest = useCallback(
        async ({ requestId, comment }: { requestId: string; comment: string }) => {
            if (!qmrId) {
                throw new Error('No QMR id');
            }

            const res = await qmrsService.addCommentOnInfoRequest({ comment, qmrId, requestId });

            if (!res.success && !res.aborted) {
                throw res.data;
            }
        },
        [qmrId, qmrsService]
    );

    const addCommentOnInfoRequestMutation = useMutation(addCommentOnInfoRequest, { onSuccess });

    const canViewInfoRequest = qmr?.capabilities.viewMoreInfoRequest || false;
    const canOpenCloseInfoRequest = qmr?.capabilities.openCloseMoreInfoRequest || false;

    return {
        openInfoRequestMutation,
        closeInfoRequestMutation,
        addCommentOnInfoRequestMutation,
        canViewInfoRequest,
        canOpenCloseInfoRequest,
    };
};

export const useQmrCsvExport = ({ qmrsService }: { qmrsService: QmrsService }) => {
    const initiateCsvExport = useCallback(
        async ({
            qmrs,
            alert,
            searchId,
            isNQRSearch,
        }: {
            qmrs: Qmr[] | null;
            alert: AlertHook;
            searchId?: string | null;
            isNQRSearch?: boolean;
        }) => {
            try {
                const response = await qmrsService.initiateCsvExport({ qmrs }, searchId, isNQRSearch);

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                alert.showAlert({
                    type: 'success',
                    content:
                        'Request to download Reports as CSV is in progress. It may take a few minutes. You will receive a notification when it is ready to view.',
                });
            } catch (error) {
                alert.showAlert({
                    type: 'error',
                    content: 'Request to download Reports as CSV has failed. Please try again.',
                });
            }
        },
        [qmrsService]
    );

    const initiateCsvExportMutation = useMutation(initiateCsvExport);

    const isLoading = initiateCsvExportMutation.isLoading;

    return {
        initiateCsvExportMutation,
        isLoading,
    };
};

export const useQmrTableActions = ({
    qmrs,
    alert,
    qmrsService,
}: {
    qmrs: Qmr[] | null;
    alert: AlertHook;
    qmrsService: QmrsService;
}) => {
    const qmrCsvExport = useQmrCsvExport({ qmrsService });
    const { showModal } = useInvestigationModal();
    const { showModal: showEscalationModal } = useEscalationModal();

    const actionHandlerMap: { [k: string]: () => any } = useMemo(
        () => ({
            DOWNLOAD: () => {
                qmrCsvExport.initiateCsvExportMutation.mutateAsync({ qmrs, alert });
            },
            ADD_TO_INVESTIGATION: () => {
                showModal({
                    allowInvestigationReassignment: true,
                    selectedQmrs: qmrs ? qmrs : [],
                    onSuccess: (investigation) => {
                        alert.showAlert({
                            type: 'success',
                            content: React.createElement('div', { style: { fontWeight: 500 } }, [
                                React.createElement(
                                    'span',
                                    {},
                                    `${qmrs?.length} QMR ${
                                        qmrs?.length === 1 ? 'was' : 'were'
                                    } added to the investigation "${investigation.title}". `
                                ),
                                React.createElement(
                                    'a',
                                    { href: `/investigations/${investigation.investigationId}` },
                                    'Go to investigation'
                                ),
                            ]),
                        });
                    },
                });
            },
            ESCALATE: () => {
                if (every(qmrs, { escalated: true })) {
                    return window.alert('The QMR is already Escalated');
                }
                showEscalationModal({
                    selectedQmrs: qmrs ? qmrs : [],
                });
            },
        }),
        [qmrCsvExport.initiateCsvExportMutation, qmrs, alert, showModal, showEscalationModal]
    );

    const handleActionPress = useCallback(
        (actionId: string) => {
            const handler = actionHandlerMap[actionId];

            if (!handler) {
                return;
            }

            handler();
        },
        [actionHandlerMap]
    );

    return {
        handleActionPress,
    };
};
