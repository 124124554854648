import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Button, Icon, TextBadge, TextInputHelper, Typography } from '@packages/ui/shared';
import { useContentOverlayState, useQuery } from '@web/core/hooks';
import {
    SORT_DIRECTION,
    TableCell,
    TableHeader,
    TablePagination,
    TableRenderer,
    TableRow,
} from '@web/components/table';
import { escalationsService } from '@web/services/singletons';
import { EscalationModel } from '@packages/models/api';
import { getBadgeVariant, getBadgeVariantTRSharedReply } from '@web/utils';
import EscalationsQuickFiltersPanel from '../components/escalations-quick-filters-panel';
import { useAbortController } from '@packages/core/http';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';

interface EscalationTableModel extends EscalationModel {
    rowId: string;
    checked: boolean;
}

const useStyles = createUseStyles({
    flexGap: {
        gap: 20,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
});

export const Escalations = () => {
    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { pathname, search } = useLocation();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const { abortSignalRef: searchAbortSignal, signalAbort } = useAbortController();
    const searchInputRef = useRef(null);

    const queryParams = useQuery();
    const escalationTrSharedQueryId =
        queryParams.getAll('trReplySharedStatus').length === 1 ? queryParams.get('trReplySharedStatus') : undefined;
    const textSearchQuery = queryParams.get('query') || '';
    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = queryParams.get('sortOrder');
    const parsedSortDirection = queryParams.get('sortDir');

    const [newTextSearch, setNewTextSearch] = useState(textSearchQuery);
    const [isLoading, setIsLoading] = useState(false);
    const [escalations, setEscalations] = useState<EscalationTableModel[]>([]);
    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const fetchEscalations = useCallback(() => {
        signalAbort();
        setIsLoading(true);
        const params = new URLSearchParams(search);

        params.set('page', '' + parsedPage);
        params.set('size', '' + parsedSize);

        if (!params.has('query')) {
            (searchInputRef.current as any).clear();
        }

        escalationsService
            .fetchEscalations({
                queryString: params.toString(),
                ignoreCache: true,
                signal: searchAbortSignal.current,
            })
            .then((response) => {
                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                if (!params.has('query')) {
                    setNewTextSearch('');
                }
                setEscalations(
                    response.data.escalations.map((escalation, index) => {
                        const _escalation = {
                            rowId: `escalation-rowid-${index}`,
                            checked: false,
                            ...escalation,
                        };
                        return _escalation;
                    })
                );
                setIsLoading(false);
                setPageStartCount(response.data.displayStartCountDescription);
                setPageEndCount(response.data.displayEndCountDescription);
                setPageTotalCount(response.data.totalCountDescription);
                setTotalEntires(response.data.totalCount);
            })
            .catch((e) => {
                setIsLoading(false);
                alert(e.message);
            });
    }, [parsedPage, parsedSize, search, searchAbortSignal, signalAbort]);

    useEffect(() => {
        fetchEscalations();
    }, [fetchEscalations]);

    const hasQuickFilters = useMemo(() => {
        return (
            queryParams.has('model') ||
            queryParams.has('modelYearFrom') ||
            queryParams.has('modelYearTo') ||
            queryParams.has('trReplyStatus') ||
            queryParams.has('trReplySharedStatus') ||
            queryParams.has('finalResult') ||
            queryParams.has('sbrStatus')
        );
    }, [queryParams]);

    const searchByText = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            params.delete('page');
            params.delete('size');

            if (text) {
                params.set('query', text);
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('escalationQuickFilters');
    }, [toggleOverlay]);

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (sizeFromQuery) {
            newQueryParams.set('size', sizeFromQuery);
        }

        if (textSearchQuery) {
            newQueryParams.set('query', textSearchQuery);
        }

        navigate(`/escalations?${newQueryParams.toString()}`);
    }, [navigate, sizeFromQuery, textSearchQuery]);

    const handleSortChange = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);
            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);
            params.set('page', String(index));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);
            params.set('page', String(0));
            params.set('size', String(size));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    return (
        <>
            {toggledOverlays.escalationQuickFilters && (
                <EscalationsQuickFiltersPanel
                    hasActiveFilters={hasQuickFilters}
                    onClear={handleClearQuickFilters}
                    onClose={toggleQuickFilterPanel}
                />
            )}
            <div className="pt-7 pl-7 pr-7 d-flex h-100">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-4">
                        <Typography variant="h2">{t('escalations:browser.title', 'Escalations')}</Typography>
                    </div>

                    <div className={`d-flex flex-row ${classes.flexGap} mb-4`}>
                        <div onClick={() => navigate('/escalations')} className={`${classes.cursorPointer} d-flex`}>
                            <Typography
                                style={escalationTrSharedQueryId === undefined ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                All
                            </Typography>
                        </div>
                        <div
                            onClick={() => navigate('/escalations?trReplySharedStatus=NO_REPLY')}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={escalationTrSharedQueryId === 'NO_REPLY' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                No TR Reply
                            </Typography>
                        </div>
                        <div
                            onClick={() => {
                                navigate('/escalations?trReplySharedStatus=PENDING');
                            }}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={escalationTrSharedQueryId === 'PENDING' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Pending
                            </Typography>
                        </div>
                        <div
                            onClick={() => navigate('/escalations?trReplySharedStatus=SHARED')}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={escalationTrSharedQueryId === 'SHARED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Shared TR Replies
                            </Typography>
                        </div>
                        <div
                            onClick={() => navigate('/escalations?trReplySharedStatus=CLOSED')}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={escalationTrSharedQueryId === 'CLOSED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Closed TR Replies
                            </Typography>
                        </div>
                    </div>

                    <div className="mb-8 d-flex">
                        <div className="w-50">
                            <TextInputHelper
                                ref={searchInputRef}
                                rightIcon={
                                    <Icon
                                        name={textSearchQuery ? 'x-close' : 'magnifying-glass'}
                                        onPress={
                                            textSearchQuery
                                                ? () => {
                                                      searchByText('');
                                                  }
                                                : undefined
                                        }
                                    />
                                }
                                value={newTextSearch}
                                onChangeText={setNewTextSearch}
                                placeholder="Look for an escalation"
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key === 'Enter') {
                                        console.log('search!!', newTextSearch);
                                        searchByText(newTextSearch);
                                    }
                                }}
                            />
                        </div>

                        <div className="ml-4 d-flex">
                            <Button
                                active={hasQuickFilters}
                                variant="ghost-blue"
                                iconLeft={<Icon color="blueOne" name="filter-list" />}
                                onPress={toggleQuickFilterPanel}
                            >
                                {hasQuickFilters
                                    ? t('views:escalations.actions.filterToggle.active', 'Filters applied')
                                    : t('views:escalations.actions.filterToggle.inactive', 'Filters')}
                            </Button>
                            {hasQuickFilters && (
                                <div className="ml-1">
                                    <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                        <Icon color="blueOne" name="x-close" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <TableRenderer
                        isLoading={isLoading}
                        tableRowsData={escalations}
                        tableRowsDataSetter={setEscalations}
                        noResultsTitle={t('views:escalations.noResults', 'No escalations found')}
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'DATE'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('DATE', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            Escalation Date
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'TR_ID'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('TR_ID', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            TR ID
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'QMR_ID'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('QMR_ID', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            QMR ID
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'TR_REPLY_STATUS'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('TR_REPLY_STATUS', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            TR Reply Status
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'SQIP_CONTROL_ITEM'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('SQIP_CONTROL_ITEM', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            SQIP Control Item
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'SQIP_CONTROL_GROUP'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('SQIP_CONTROL_GROUP', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            SQIP
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'VIN'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('VIN', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            VIN
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'TR_REPLY_SHARE'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('TR_REPLY_SHARE', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            TR Reply Share
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'INVESTIGATION_NAME'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('INVESTIGATION_NAME', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            Investigation
                                        </Typography>
                                    </TableHeader>
                                    <TableHeader
                                        sortable
                                        sortDirection={
                                            parsedSortOrder === 'TR_REPLY_DATE'
                                                ? (parsedSortDirection as SORT_DIRECTION)
                                                : undefined
                                        }
                                        onSort={(_event, sortDirection) => {
                                            handleSortChange('TR_REPLY_DATE', sortDirection);
                                        }}
                                    >
                                        <Typography variant="label" numberOfLines={1}>
                                            TR Reply Date
                                        </Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId}>
                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                {rowData.createdTimestampDescription}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                <a
                                                    href={`/escalations/${rowData.trReply.trReplyId}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {rowData.trReply.trIdentifer}
                                                </a>
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                <a
                                                    href={`/qmrs/${rowData.qmrIdentifier}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {rowData.qmrIdentifier}
                                                </a>
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <TextBadge variant={getBadgeVariant(rowData.trReplyStatus.trReplyStatusId)}>
                                                {/* Transferring QMRs and attachments */}
                                                {rowData.trReplyStatus.description}
                                            </TextBadge>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>{rowData.trReply.sqipControlItem}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>
                                                {rowData.trReply.sqip.toUpperCase()}
                                            </Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography numberOfLines={1}>{rowData.trReply.vin17}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <TextBadge variant={getBadgeVariantTRSharedReply(rowData.trReplyShare)}>
                                                {rowData.trReplyShare}
                                            </TextBadge>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography>{rowData.investigation}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell>
                                        <div className="d-flex align-items-center">
                                            <Typography>{rowData.trReply.modifiedDateDescription}</Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                    <TablePagination
                        page={parsedPage}
                        size={parsedSize}
                        total={totalEntries}
                        disabled={isLoading}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                        displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                    />
                </div>
            </div>
        </>
    );
};
