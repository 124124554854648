import { HttpClient } from '@packages/core/http';
import { BmisResponseDto } from '@packages/models/api/bmis';
import { Bmis } from '@packages/models/api';

export interface FetchBmisOptions {
    vin?: string;
    ignoreCache?: boolean;
    page?: number;
    size?: number;
    orderBy?: keyof Bmis;
    orderByDirection?: 'asc' | 'desc';
}

export class BmisService {
    constructor(private http: HttpClient) {}

    async fetchBmis(options: FetchBmisOptions): Promise<Bmis[]> {
        const {
            vin,
            ignoreCache = false,
            size = 5,
            page = 0,
            orderByDirection = 'desc',
            orderBy = 'ServiceDateandTime',
        } = options;

        const query = `filter=${vin}&orderBy=${orderBy}&orderByDirection=${orderByDirection}&page=${page}&size=${size}`;

        const response = await this.http.orchestrateRequest<BmisResponseDto>({
            method: 'GET',
            url: `/bmis/retrieveVinDetails?${query}`,
            ignoreCache,
        });

        if (!response.success && response.aborted) {
            return [];
        } else if (!response.success) {
            return Promise.reject(response.data);
        }

        if (!response?.data?.tests?.length) {
            return [];
        }

        return response.data.tests;
    }
}
