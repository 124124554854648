import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AlertBar, Button, Icon, LoadingCircle, TextBadge, Typography } from '@packages/ui/shared';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { caseStatusMap as statusMap, getBadgeVariantTechline } from '@web/utils';
import { CreateCaseModal, TechlineQuickFilters } from '../components';
import { createUseStyles } from 'react-jss';
import { isEqual, isAfter, isBefore, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { Retailer } from './all-cases';
import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@web/components/table';
import { techlineService, httpClient, analyticsService, qmrsService } from '@web/services/singletons';
import { useAbortController } from '@packages/core/http';
import { useAuthState } from '@packages/contexts/auth';
import { useContentOverlayState, useQuery } from '@web/core/hooks';
import { useNavigate } from 'react-router-dom';
import { useTechlineState, fetchTechline, useTechlineDispatch } from '@packages/contexts/techline';
import { SearchRetailer } from '../components/dashboard/search-retailer';
import colors from '@packages/core/styles/colors';
import RecentSearchTypeahead from '@web/components/recent-search-typeahead';

const ALLOW_VIEW_DRAFT_PERMISSION = 'TECHLINE015';

const useStyles = createUseStyles({
    tableHead: {
        fontWeight: 600,
    },
    createCaseCard: {
        height: '272px',
        width: '365px',
    },
    quickTipCard: {
        height: '136px',
        width: '365px',
    },
    card: {
        height: '168px',
        width: '365px',
        borderLeft: `3px solid ${colors.grayThree}`,
        backgroundColor: colors.grayOne,
    },
    cardBody: {
        flex: 0.7,
    },
    cardBorderRadius: {
        borderRadius: 5,
    },
    primaryOutlineBtn: {
        color: colors.blueOne,
        borderColor: colors.blueOne,
        padding: 10,
        borderRadius: 4,
        '&:hover, &:active': {
            backgroundColor: 'transparent',
            borderColor: colors.blueOne,
        },
    },
    loaderCircleOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    caseNumberLink: {
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    clearIcon: {
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 2,
        backgroundColor: colors.white,
    },
});

const emptyFilters = {
    vin: '',
    selectedStatus: [],
    selectedCasetypes: [],
    selectedSubmissionDate: [],
};
export const Dashboard = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const queryParams = useQuery();
    const showCloseCaseSuccessPopupForCaseNumber = queryParams.get('showCloseCaseSuccessFor');
    const showSubmitCaseSuccessForCaseNumber = queryParams.get('showSubmitCaseSuccessFor');
    const retailerNumber = queryParams.get('retailerNumber');

    const { account } = useAuthState();
    const { abortSignalRef, signalAbort } = useAbortController();
    const { techline } = useTechlineState();
    const techlineDispatch = useTechlineDispatch();
    const [showCreateCaseModal, setShowCreateCaseModal] = useState(false);
    const [selectedRetailer, setSelectedRetailer] = useState<Retailer[]>([]);
    const [allRetailers, setAllRetailers] = useState<Retailer[] | any[]>([]);
    const [loadingCaseList, setLoadingCaseList] = useState(false);
    const [allCases, setAllCases] = useState<any>([]);
    const [showCloseCaseSuccessPopup, setShowCloseCaseSuccessPopup] = useState(false);
    const [permissions, setPermissions] = useState<any>({});

    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(0);
    const [totalCases, setTotalCases] = useState(0);

    const [filters, setFilters] = useState<any>(emptyFilters);
    const [currentStatusFilter, setCurrentStatusFilter] = useState('ALL');
    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const [showSubmitCaseSuccessPopup, setShowSubmitCaseSuccessPopup] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [isNonRetailer, setIsNonRetailer] = useState(false);
    const searchInputRef = useRef(null);
    const [showSubmitCaseSuccessPopupFlag, setShowSubmitCaseSuccessPopupFlag] = useState<boolean>(false);
    const [showCloseCaseSuccessPopupFlag, setShowCloseCaseSuccessPopupFlag] = useState<boolean>(false);

    useEffect(() => {
        if (showSubmitCaseSuccessForCaseNumber) {
            setShowSubmitCaseSuccessPopup(true);
            setShowSubmitCaseSuccessPopupFlag(true);
            setTimeout(() => {
                navigate('/techline/dashboard', { replace: true });
                setShowSubmitCaseSuccessPopup(false);
                setShowSubmitCaseSuccessPopupFlag(false);
            }, 3000);
        }
    }, [navigate, queryParams, showSubmitCaseSuccessForCaseNumber]);

    useEffect(() => {
        fetchTechline({
            techlineId: undefined,
            techlineDispatch,
            techlineService,
        });
    }, [techlineDispatch]);

    useEffect(() => {
        if (showCloseCaseSuccessPopupForCaseNumber) {
            setShowCloseCaseSuccessPopup(true);
            setShowCloseCaseSuccessPopupFlag(true);
            setTimeout(() => {
                navigate('/techline/dashboard', { replace: true });
                setShowCloseCaseSuccessPopup(false);
                setShowCloseCaseSuccessPopupFlag(false);
            }, 3000);
        }
    }, [navigate, queryParams, showCloseCaseSuccessPopupForCaseNumber]);

    useEffect(() => {
        const fetchRetailers = async () => {
            try {
                const retailersRes = await techlineService.retrieveRetailer(false, abortSignalRef.current);
                if (retailersRes?.data?.results?.length) {
                    const retailers = retailersRes.data.results;

                    setAllRetailers(retailers);

                    if (retailerNumber) {
                        const selectedRetailer = retailers.find(
                            (d: { retailerNumber: string }) => d.retailerNumber === retailerNumber
                        );

                        if (selectedRetailer) {
                            setSelectedRetailer([selectedRetailer]);
                            navigate('/techline/dashboard', { replace: true });
                            return;
                        }
                    }

                    setSelectedRetailer([retailers[0]]);
                } else {
                    setIsNonRetailer(true);
                    fetchSearchHistory();
                }
            } catch (error) {
                console.error('Failed to fetch retailers:', error);
                alert('An error occurred while retrieving retailer data. Please refresh and try again.');
            }
        };

        fetchRetailers();

        return () => {
            if (abortSignalRef.current) {
                signalAbort();
            }
        };
    }, []);

    const fetchSearchHistory = async () => {
        try {
            const response = await techlineService.recentSearch(false, abortSignalRef.current);
            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }
            if (response.data.retailerSearch.length) {
                setAllRetailers(
                    response.data.retailerSearch.map((d: { name: any; code: any }) => ({
                        retailerName: d.name,
                        retailerNumber: d.code,
                    }))
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchPermissions = useCallback(() => {
        if (!account || !account.accountId) {
            return;
        }
        const config = {
            method: 'GET' as const,
            signal: abortSignalRef.current,
            ignoreCache: true,
        };
        httpClient
            .orchestrateRequest<any>({
                ...config,
                url: `/accounts/${account.accountId}/permissions-debug`,
            })
            .then((permissionsResponse) => {
                if (permissionsResponse.success) {
                    let allPermissions = [...(permissionsResponse.data?.globalPermissions || [])];
                    if (permissionsResponse.data?.retailerPermissions?.length) {
                        permissionsResponse.data.retailerPermissions.forEach((item: any) => {
                            if (item.permissions?.length) {
                                allPermissions = [...allPermissions, ...item.permissions];
                            }
                        });
                    }
                    setPermissions(allPermissions);
                }
            })
            .catch((e) => {
                alert('Error: ' + e + '. Please refresh & try again.');
                console.log('error::', e);
            });
    }, [abortSignalRef, account]);

    useEffect(() => {
        fetchPermissions();
    }, [fetchPermissions]);

    const clearSelection = async () => {
        setAllCases([]);
        signalAbort();
        setSelectedRetailer([]);
        await fetchSearchHistory();
    };

    const fetchAllRetailers = async (query: string) => {
        try {
            if (!query.replace(/\s/g, '').length) {
                await clearSelection();
                return;
            }

            if (isNonRetailer) {
                const response = await qmrsService.fetchRetailers({
                    searchParams: { query, canCreateQmr: true },
                    signal: abortSignalRef.current,
                    ignoreCache: true,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }
                if (response.data.retailers.length) {
                    setAllRetailers(
                        response.data.retailers.map((d) => ({
                            retailerName: d.name,
                            retailerNumber: d.code,
                        }))
                    );
                }
            }
        } catch (e) {
            alert('Error: ' + e + '. Please refresh & try again.');
            console.log(e);
        }
    };

    const handleSetRetailer = (selected: Retailer[]) => {
        if (selected.length) {
            setAllCases([]);
        }
        setSelectedRetailer(selected);
    };

    const searchCases = useCallback(async () => {
        if (loadingCaseList) return;
        try {
            if (selectedRetailer?.[0]?.retailerNumber) {
                setLoadingCaseList(true);
                const allCasesRes = await techlineService.searchCases(
                    selectedRetailer?.[0]?.retailerNumber,
                    searchQuery
                );
                if (allCasesRes?.data?.results?.length) {
                    setAllCases(allCasesRes.data.results);
                    setTotalCases(allCasesRes.data.results.length);
                }
                setLoadingCaseList(false);
            }
        } catch (e) {
            console.log(e);
            setLoadingCaseList(false);
        }
    }, [loadingCaseList, searchQuery, selectedRetailer]);

    useEffect(() => {
        setPageIndex(0);
        setFilters(emptyFilters);
        searchCases();
    }, [searchQuery, selectedRetailer?.[0]?.retailerNumber]);

    const applyStatusFilter = useCallback(
        (allCases = []) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_TECHLINE_CASES);
            if (currentStatusFilter === 'ALL') return allCases;
            if (allCases.length) {
                return allCases.filter((caseItem: { techlineCaseStatus: string }) =>
                    statusMap[currentStatusFilter].includes(caseItem.techlineCaseStatus)
                );
            }
            return [];
        },
        [currentStatusFilter]
    );

    const applyOtherSideFilters = useCallback(
        (allCases = []) => {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_SEARCHES_TECHLINE_CASES);
            if (allCases.length) {
                return allCases.filter(
                    (caseItem: { createdOn: any; vin: string; techlineCaseStatus: string; caseType: string }) => {
                        if (filters.vin?.length && caseItem.vin !== filters.vin) return false;
                        if (
                            filters.selectedStatus?.length &&
                            !filters.selectedStatus
                                .map((status: any) => status.value)
                                .filter((status: string | number) =>
                                    statusMap[status].includes(caseItem.techlineCaseStatus)
                                )?.length
                        )
                            return false;
                        if (
                            filters.selectedCasetypes?.length &&
                            !filters.selectedCasetypes
                                .map((caseType: any) => caseType.caseTypeName)
                                .filter((caseType: string) => caseType === caseItem.caseType)?.length
                        )
                            return false;
                        if (filters.selectedSubmissionDate?.[0]?.label) {
                            const dateFilterType = filters.selectedSubmissionDate?.[0]?.label;
                            if (caseItem.createdOn) {
                                if (dateFilterType === 'Today') {
                                    const todaysDate = new Date();
                                    if (!isEqual(todaysDate, new Date(caseItem.createdOn))) {
                                        return false;
                                    }
                                }
                                if (dateFilterType === 'Yesterday') {
                                    const todaysDate = new Date();
                                    todaysDate.setDate(todaysDate.getDate() - 1);
                                    if (!isEqual(todaysDate, new Date(caseItem.createdOn))) {
                                        return false;
                                    }
                                }
                                if (dateFilterType === 'This week') {
                                    const todaysDate = new Date();
                                    const startDate = startOfWeek(todaysDate);
                                    const endDate = endOfWeek(todaysDate);
                                    if (
                                        !(
                                            isEqual(startDate, new Date(caseItem.createdOn)) ||
                                            isEqual(endDate, new Date(caseItem.createdOn)) ||
                                            (isAfter(new Date(caseItem.createdOn), startDate) &&
                                                isBefore(new Date(caseItem.createdOn), endDate))
                                        )
                                    ) {
                                        return false;
                                    }
                                }
                                if (dateFilterType === 'This month') {
                                    const todaysDate = new Date();
                                    const startDate = startOfMonth(todaysDate);
                                    const endDate = endOfMonth(todaysDate);
                                    if (
                                        !(
                                            isEqual(startDate, new Date(caseItem.createdOn)) ||
                                            isEqual(endDate, new Date(caseItem.createdOn)) ||
                                            (isAfter(new Date(caseItem.createdOn), startDate) &&
                                                isBefore(new Date(caseItem.createdOn), endDate))
                                        )
                                    ) {
                                        return false;
                                    }
                                }
                            }
                        }

                        return true;
                    }
                );
            }
            return [];
        },
        [filters]
    );

    const showDraftCases = useMemo(() => {
        if (permissions.length) {
            return !!permissions.find(
                (permission: { permissionId: string }) => permission.permissionId === ALLOW_VIEW_DRAFT_PERMISSION
            );
        }
        return false;
    }, [permissions]);

    const displayCases = useMemo(() => {
        if (!allCases.length) return [];
        let finalCases: any = [...allCases];
        if (!showDraftCases) {
            finalCases = finalCases.filter(
                (caseItem: { techlineCaseStatus: string }) => !statusMap['DRAFT'].includes(caseItem.techlineCaseStatus)
            );
        }
        const statusFilteredCases = applyStatusFilter(finalCases);
        const otherFilteredCases = applyOtherSideFilters(statusFilteredCases);
        setTotalCases(otherFilteredCases.length);
        return [...otherFilteredCases].slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
    }, [allCases, applyOtherSideFilters, applyStatusFilter, pageIndex, pageSize, showDraftCases]);

    const toggleTechlineFilters = (filters?: any) => {
        toggleOverlay('techlineQuickFilters');
    };

    const handlePaginationClick = useCallback((index: number) => {
        setPageIndex(index);
    }, []);
    const handlePaginationSizeChange = useCallback((size: number) => {
        setPageSize(size);
    }, []);
    const openPage = (actionUrl: string) => {
        if (!actionUrl) return;
        window.open(actionUrl, '_blank');
    };

    const navigateToCaseDetails = (item: any) => {
        if (item.caseNumber) {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_OPENS_EXISTING_CASE);
            window.open(`/techline/${item.caseNumber}`, '_blank');
        }
    };

    const filtersCount = useMemo(() => {
        let count = 0;
        if (filters.vin && filters.vin.length) {
            count += 1;
        }
        if (filters.selectedStatus && filters.selectedStatus.length) {
            count += 1;
        }
        if (filters.selectedCasetypes && filters.selectedCasetypes.length) {
            count += 1;
        }
        if (filters.selectedSubmissionDate && filters.selectedSubmissionDate.length) {
            count += 1;
        }
        return count;
    }, [filters]);

    const renderTable = () => {
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader>
                            <Typography variant="h5">Case No</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">VIN number</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Status</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Case type</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Created by</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">RO</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Last updated</Typography>
                        </TableHeader>
                        <TableHeader>
                            <Typography variant="h5">Created Date</Typography>
                        </TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayCases.map((item: any) => {
                        if (!item) return null;
                        return (
                            <TableRow key={item.caseNumber}>
                                <TableCell width={150}>
                                    <Typography>
                                        <div
                                            className={`${classes.caseNumberLink}`}
                                            onClick={() => navigateToCaseDetails(item)}
                                        >
                                            {item.caseNumber}
                                        </div>
                                    </Typography>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.vin}</Typography>
                                </TableCell>
                                <TableCell width={150} className="d-flex">
                                    <TextBadge variant={getBadgeVariantTechline(item.techlineCaseStatus)}>
                                        {item.techlineCaseStatus}
                                    </TextBadge>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.caseType}</Typography>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.fullName}</Typography>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.repairOrderNumber}</Typography>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.modifiedOnDescription}</Typography>
                                </TableCell>
                                <TableCell width={150}>
                                    <Typography>{item.createdOnDescription}</Typography>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {loadingCaseList && (
                        <div className="mt-20 mb-10">
                            <TableRow>
                                <TableCell>
                                    <div className={classes.loaderCircleOuter}>
                                        <LoadingCircle size={64} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </div>
                    )}
                    {!displayCases.length && !loadingCaseList && (
                        <TableRow>
                            <div className="ml-5 mt-10 mb-10">
                                <Typography>No Matching Records Found!</Typography>
                            </div>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    };
    const renderCreateCaseCard = () => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2 ml-4`}>
                <Icon size={24} name="escalation" />
                <div className="ml-2">
                    <Typography variant="h6" color="blueOne">
                        New case
                    </Typography>
                    <div className="mt-2 mb-4">
                        <Typography color="black">
                            Before creating a case please take a look at our tips and articles.
                        </Typography>
                    </div>
                    <div className="d-flex">
                        <Button
                            onPress={() => {
                                setShowCreateCaseModal(true);
                                analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_CREATE_A_TECHLINE_CASE);
                            }}
                            iconRight={
                                <Icon
                                    size={16}
                                    raised
                                    name="plus"
                                    raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                />
                            }
                        >
                            Create a new case
                        </Button>
                    </div>
                </div>
            </div>
        );
    };
    const renderQuickTipCard = (actionUrl: string) => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2 ml-4`}>
                <Icon size={24} name="info" />
                <div className="ml-2">
                    <Typography variant="h6" color="blueOne">
                        Before you submit a case
                    </Typography>
                    <div className="mt-2 mb-4">
                        <Typography color="black">
                            You can search through all TechTIPS by using our TechTIPS locator tool.
                        </Typography>
                    </div>
                    <button
                        type="button"
                        onClick={() => openPage(actionUrl)}
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            View TIPS locator
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };
    const renderTechlineVideo = (actionUrl: string) => {
        return (
            <div className={`${classes.card} d-flex flex-row p-2`}>
                <Icon size={24} name="videocam" />
                <div className="ml-2">
                    <Typography variant="h6" color="blueOne">
                        Techline How To’s
                    </Typography>
                    <div className="mt-2 mb-4">
                        <Typography color="black">
                            Watch quick how to’s and find the TechShare Special Edition Tech TIPS.
                        </Typography>
                    </div>
                    <button
                        type="button"
                        onClick={() => openPage(actionUrl)}
                        className={`${classes.primaryOutlineBtn} btn btn-outline-primary`}
                    >
                        <Typography variant="h6" color="blueOne">
                            View
                        </Typography>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div>
            {showCloseCaseSuccessPopup && showCloseCaseSuccessPopupForCaseNumber && showCloseCaseSuccessPopupFlag && (
                <div className="mt-2">
                    <AlertBar
                        success
                        show={true}
                        onClose={() => {
                            // setRestrictedStatusChanged(false);
                        }}
                    >
                        <Typography>
                            Case “{showCloseCaseSuccessPopupForCaseNumber}” has been successfully closed
                        </Typography>
                    </AlertBar>
                </div>
            )}
            {showSubmitCaseSuccessPopup && showSubmitCaseSuccessForCaseNumber && showSubmitCaseSuccessPopupFlag && (
                <div className="mt-2">
                    <AlertBar
                        success
                        show={true}
                        onClose={() => {
                            // setRestrictedStatusChanged(false);
                        }}
                    >
                        <Typography>
                            Case “{showSubmitCaseSuccessForCaseNumber}” has been successfully submitted
                        </Typography>
                    </AlertBar>
                </div>
            )}
            <div className="pt-7 pl-7 pr-7 d-flex h-100 w-100">
                <div className="d-flex flex-column w-100">
                    <div className="d-flex flex-row justify-content-between">
                        <Typography color="black" variant="h2">
                            Techline last updates
                        </Typography>
                        <SearchRetailer
                            allRetailers={allRetailers}
                            clearSelection={clearSelection}
                            fetchRetailers={fetchAllRetailers}
                            placeholder="Search Retailer"
                            recentSearch={fetchSearchHistory}
                            selected={selectedRetailer}
                            setSelected={handleSetRetailer}
                        />
                    </div>
                    <div className="d-flex flex-row mr-10 mt-5">
                        {renderTechlineVideo(techline?.linkThree)}
                        {renderQuickTipCard(techline?.linkTwo)}
                        {account?.techlineCapabilities?.canCreateDLTechlineCase && renderCreateCaseCard()}
                    </div>
                    <div className="w-50 mb-2 mt-10">
                        <RecentSearchTypeahead
                            ref={searchInputRef}
                            onChange={(query) => {
                                setSearchQuery(query);
                            }}
                            placeholder={'Look for a Techline Case'}
                            recentSearches={[]}
                            quickSearchQuery={''}
                            defaultInputValue={''}
                        />
                    </div>
                    <div className="d-flex flex-row mt-5">
                        <div
                            onClick={() => {
                                setPageIndex(0);
                                setCurrentStatusFilter('ALL');
                            }}
                            className={`${classes.cursorPointer} d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'ALL' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                All
                            </Typography>
                        </div>
                        {showDraftCases && (
                            <div
                                onClick={() => {
                                    setPageIndex(0);
                                    setCurrentStatusFilter('DRAFT');
                                }}
                                className={`${classes.cursorPointer} ml-5 d-flex`}
                            >
                                <Typography
                                    style={currentStatusFilter === 'DRAFT' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    Draft
                                </Typography>
                            </div>
                        )}
                        <div
                            onClick={() => {
                                setPageIndex(0);
                                setCurrentStatusFilter('OPEN');
                            }}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'OPEN' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Open
                            </Typography>
                        </div>
                        <div
                            onClick={() => {
                                setPageIndex(0);
                                setCurrentStatusFilter('UPDATE_REQUESTED');
                            }}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'UPDATE_REQUESTED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Update Requested
                            </Typography>
                        </div>
                        <div
                            onClick={() => {
                                setPageIndex(0);
                                setCurrentStatusFilter('PENDING_TECHLINE');
                            }}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'PENDING_TECHLINE' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Pending Techline
                            </Typography>
                        </div>
                        <div
                            onClick={() => {
                                setPageIndex(0);
                                setCurrentStatusFilter('CLOSED');
                            }}
                            className={`${classes.cursorPointer} ml-5 d-flex`}
                        >
                            <Typography
                                style={currentStatusFilter === 'CLOSED' ? selectedTabHeadStyles : {}}
                                color="grayFive"
                                variant="h6"
                            >
                                Closed
                            </Typography>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-center mt-5">
                        <div style={{ flex: 5 }} className="d-flex flex-row align-items-center">
                            <div className="ml-0">
                                {filtersCount ? (
                                    <div className="d-flex flex-row">
                                        <Button
                                            active={false}
                                            variant="info"
                                            iconLeft={<Icon color="blueOne" name="filter-list" />}
                                            onPress={toggleTechlineFilters}
                                        >
                                            <Typography variant="h5" color="blueOne">
                                                Applied filters ({filtersCount})
                                            </Typography>
                                        </Button>
                                        <div className="ml-5">
                                            <Button variant="ghost-blue" onPress={() => setFilters(emptyFilters)}>
                                                Clear all
                                            </Button>
                                        </div>
                                    </div>
                                ) : (
                                    <Button
                                        active={false}
                                        variant="ghost-blue"
                                        iconLeft={<Icon color="blueOne" name="filter-list" />}
                                        onPress={toggleTechlineFilters}
                                    >
                                        Filters
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column mt-5">
                        {renderTable()}
                        {!loadingCaseList && (
                            <TablePagination
                                page={pageIndex}
                                size={pageSize}
                                total={totalCases}
                                onClick={handlePaginationClick}
                                onSizeChange={handlePaginationSizeChange}
                            />
                        )}
                        <div className="mt-5 mb-5 d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-row align-items-center">
                                <Typography variant="h6" color="blueOne">
                                    Still need help with your diagnostics?
                                </Typography>
                                <div className="ml-2 mr-2">
                                    <Icon size={22} name="phone" />
                                </div>
                                <Typography variant="h6" color="black">
                                    1-866-782-2782
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                {showCreateCaseModal && (
                    <CreateCaseModal
                        show={showCreateCaseModal}
                        onHide={(identifier) => {
                            setShowCreateCaseModal(false);
                            if (identifier) {
                                navigate('/techline/' + identifier);
                            }
                        }}
                    />
                )}
                {toggledOverlays.techlineQuickFilters && (
                    <TechlineQuickFilters
                        filters={filters as any}
                        applyFilters={(newFilter) => {
                            setPageIndex(0);
                            setFilters(newFilter);
                        }}
                        clearFilters={() => setFilters(emptyFilters)}
                        onClose={toggleTechlineFilters}
                        selectedRetailer={selectedRetailer[0]}
                    />
                )}
            </div>
        </div>
    );
};
