export default {
    headerHeight: 64,
    qmrHeaderHeight: 72,
    bmisHeaderHeight: 64,
};

// date-fns format
export const DATE_FORMAT = {
    MM_DD_YEAR: 'MM/dd/yyyy',
    MMM_D_YEAR: 'MMM d, yyyy',
    MON_DAY_YEAR: 'PP',
    MON_DAY_YEAR_AT_H_MM: "MMM do, yyyy 'at' h:mm a",
};

export const TIME_FORMAT = {
    HH_MM_SS: 'hh:mm:ss',
};

export const isPrimaryObjectiveEnabled = false;
