import React, { FC, useEffect, useRef, useState } from 'react';
import { Menu, Typeahead } from 'react-bootstrap-typeahead';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Icon, Typography } from '@packages/ui/shared';
import { RobotoFontFamily } from '@packages/ui/shared';
import { configService } from '@web/services/singletons';
import { useAuthState } from '@packages/contexts/auth';
import VinFilterButton from './vin-filter-button';
import { useMatch, useNavigate } from 'react-router-dom';
import { VIN } from '../types';
import { useVinSessionDispatch, useVinSessionState } from '../context/vin-sessions.hooks';
import { vinSessionActions } from '../context/vin-sessions.state';
import { getVin } from '../utils/vinSessionUtils';
import { RenderMenu } from './render-menu';
import { closeVinSession } from '../utils/vinSessionUtils';

type VinSessionSearchBarProps = {
    onHide: () => void;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createUseStyles({
    typeAheadContainer: {
        width: 570,
        borderRadius: 4,
        backgroundColor: colors.white,
    },
    typeAhead: {
        '& input::placeholder': {
            color: `#4C525D !important`,
            fontWeight: 400,
            fontSize: 15,
            lineHeight: 24,
        },
        '& input': {
            fontWeight: 400,
            color: `#4C525D !important`,
            padding: '8px 12px 8px 40px !important',
        },
    },
    hideSearch: {
        position: 'absolute',
        right: 12,
        top: 9,
        zIndex: 2,
        fontFamily: RobotoFontFamily.Regular,
        backgroundColor: colors.white,
        fontWeight: 400,
        fontSize: 15,
        color: `#999DA3 !important`,
        lineHeight: '24px',
        cursor: 'pointer',
    },
    hide: {
        position: 'absolute',
        right: 12,
        top: 10,
        backgroundColor: colors.white,
        height: 20,
        width: 20,
        zIndex: 2,
    },
    searchBarFilterList: {
        display: 'flex',
        position: 'absolute',
        top: 9,
        left: 40,
        gap: 4,
        whiteSpace: 'nowrap',
    },
    filter: {
        display: 'flex',
        position: 'absolute',
        top: 4,
        left: 40,
        width: '57px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px 4px 2px 4px',
        borderRadius: '4px',
        border: '1px solid #DEE6EF',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 16,
        marginTop: 2,
        marginLeft: -5,
    },
    vinButton: {
        '& input': {
            padding: '8px 12px 8px 104px !important',
        },
    },
    searchFilter: ({ vinDivWidth }: any) => ({
        height: 24,
        top: 8,
        left: `${vinDivWidth + 42}px`,
    }),
    dynamicPadding: ({ divWidth, vinDivWidth }: any) => ({
        '& input': {
            padding: `8px 12px 8px ${divWidth + vinDivWidth + 44}px !important`,
        },
    }),
});

const VinSessionSearchBar: FC<VinSessionSearchBarProps> = (props) => {
    const { onHide, isLoading, setIsLoading } = props;
    const navigate = useNavigate();
    const { selectedFilters } = useVinSessionState();
    const dispatch = useVinSessionDispatch();
    const searchInputRef = useRef(null);
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const vinContainerRef = useRef<HTMLDivElement>(null);
    const { account } = useAuthState();
    const { selectedVin } = useVinSessionState();
    const canViewDebugger =
        configService.debugEnvName === 'production'
            ? account?.baseRoleIds.includes('LAG_SUPER_ADMIN')
            : configService.buildDebugEnabled;
    const [divWidth, setDivWidth] = useState<Number>(0);
    const [vinDivWidth, setvinDivWidth] = useState<number>(0);
    const classes = useStyles({ divWidth, vinDivWidth });
    const [hasInput, setHasInput] = useState<boolean>(false);
    const vinSessionRouteMatch = useMatch('/VIN/*');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [searchVinData, setSearchedVinData] = useState<any>(undefined);
    const [searchFilter, setSearchFilter] = useState<boolean>(true);

    useEffect(() => {
        const updateWidth = () => {
            if (scrollContainerRef.current) {
                setDivWidth(scrollContainerRef.current.offsetWidth);
            }
            if (vinContainerRef.current) {
                setvinDivWidth(vinContainerRef.current.offsetWidth);
            }
        };
        updateWidth();
        window.addEventListener('resize', updateWidth);
        return () => {
            window.removeEventListener('resize', updateWidth);
        };
    }, [selectedFilters, vinSessionRouteMatch]);

    useEffect(() => {
        if (selectedFilters.length == 0) {
            setDivWidth(0);
        }
    }, [selectedFilters]);

    useEffect(() => {
        if (
            searchQuery.length > 0 &&
            (searchQuery.length == 8 || searchQuery.length == 17) &&
            /^(?=.*[A-Za-z])([A-Za-z\d]{2,11}\d{6})$/.test(searchQuery)
        )
            fetchVIN();
    }, [searchQuery]);

    const handleChange = (selected: string) => {
        if (searchInputRef.current) {
            setHasInput(true);
            const searchedQuery = (searchInputRef.current as any).getInput().value;
            setIsLoading(false);
            if (searchedQuery.length == 8 || searchedQuery.length == 17) {
                setSearchQuery(searchedQuery);
            } else if (!/^(?=.*[A-Za-z])([A-Za-z\d]{2,11}\d{6})$/.test(searchQuery)) {
                navigate(`/docs?query=${searchedQuery}`);
                (searchInputRef.current as any).blur();
            }
        }
    };

    const clearInput = () => {
        if (searchInputRef.current) {
            (searchInputRef.current as any).clear();
            setHasInput(false);
        }
        dispatch(
            vinSessionActions.setSelectedFilters({
                selectedFilters: [],
            })
        );
        navigate('/');
        (searchInputRef.current as any).blur();
        (searchInputRef.current as any).clear();
    };

    // API call
    async function fetchVIN() {
        setIsLoading(true);
        const searchVin = await getVin(searchQuery, setIsLoading);
        if (searchVin) {
            setSearchedVinData(searchVin);
        }
        setIsLoading(false);
    }

    const handleClick = (vin: VIN) => {
        onHide();
        navigate('/VIN/' + `${vin.vinRecord.vin}`);
        setSearchedVinData(undefined);
        setHasInput(false);
        setSearchQuery('');
        (searchInputRef.current as any).blur();
        (searchInputRef.current as any).clear();
    };

    return (
        <div className={classes.typeAheadContainer} style={{ left: canViewDebugger ? 200 : 270 }}>
            <Typeahead
                ref={searchInputRef}
                onChange={(selected) => {
                    if (!selected[0]) return;
                    handleChange(selected[0]);
                }}
                onInputChange={() => {
                    if (searchInputRef.current) {
                        if ((searchInputRef.current as any).getInput().value == 0) {
                            setSearchedVinData(undefined);
                            setHasInput(false);
                            setSearchQuery('');
                        } else {
                            setSearchedVinData(undefined);
                            setHasInput(false);
                            setSearchQuery((searchInputRef.current as any).getInput().value);
                        }
                    }
                }}
                filterBy={() => true}
                onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                        handleChange((searchInputRef.current as any).inputNode.value);
                    }
                }}
                options={[]}
                placeholder="Enter Keyword for search or a VIN to Open a VIN Session"
                className={`${classes.typeAhead} ${vinSessionRouteMatch && classes.dynamicPadding}`}
                renderMenu={(results, menuProps, ...args) => {
                    return (
                        <RenderMenu
                            isLoading={isLoading}
                            onHide={onHide}
                            handleClick={handleClick}
                            menuProps={menuProps}
                            setIsLoading={setIsLoading}
                            searchVinData={searchVinData}
                        />
                    );
                }}
            >
                <Icon
                    name="magnifying-glass"
                    style={{ position: 'absolute', top: 9, left: 12, color: '#999DA3' }}
                    onPress={() => {
                        (searchInputRef.current as any).inputNode.focus();
                    }}
                />

                {vinSessionRouteMatch && (
                    <div
                        className={classes.filter}
                        style={{
                            backgroundColor: colors.blueFour,
                            top: 7,
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            closeVinSession(selectedVin?.vinRecord.vin, account?.accountId, setIsLoading, dispatch);
                            dispatch(vinSessionActions.setSelectedVin({ selectedVin: undefined }));
                            navigate('/');
                        }}
                        ref={vinContainerRef}
                    >
                        <div className={classes.icon}>{<Icon name="x-close" color="blueOne" status={true} />}</div>
                        <Typography variant="caption5" style={{ color: colors.blueOne, marginTop: 2 }}>
                            VIN
                        </Typography>
                    </div>
                )}

                {selectedFilters.length > 0 && (
                    <div
                        className={`${classes.searchBarFilterList} ${searchFilter ? classes.searchFilter : ''}`}
                        ref={scrollContainerRef}
                    >
                        <VinFilterButton
                            filter={selectedFilters[0]}
                            textColor={colors.blueOne}
                            iconName="x-close"
                            searchFilter={searchFilter}
                            style={
                                searchFilter
                                    ? {
                                          color: colors.blueOne,
                                          fontFamily: RobotoFontFamily.Bold,
                                          fontWeight: 700,
                                          fontSize: 11,
                                          lineHeight: 16,
                                          textAlign: 'center',
                                      }
                                    : { color: colors.blueOne }
                            }
                        />
                        {selectedFilters.length > 1 && (
                            <VinFilterButton
                                filter={`+ ${selectedFilters.length - 1}`}
                                searchFilter={searchFilter}
                                style={
                                    searchFilter
                                        ? {
                                              color: colors.blueOne,
                                              fontFamily: RobotoFontFamily.Bold,
                                              fontWeight: 700,
                                              fontSize: 11,
                                              lineHeight: 16,
                                              textAlign: 'center',
                                          }
                                        : { color: colors.blueOne }
                                }
                            />
                        )}
                    </div>
                )}

                {hasInput && searchQuery.length > 0 ? (
                    <div className={classes.hideSearch} onClick={clearInput}>
                        Clear search
                    </div>
                ) : (
                    <div className={classes.hide} />
                )}
            </Typeahead>
        </div>
    );
};

export default VinSessionSearchBar;
