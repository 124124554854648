import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Button, Icon, TextInputHelper, Typography } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';
import { useTranslation } from 'react-i18next';
import { useContentOverlayState, useQuery } from '@web/core/hooks';
import { EscalationModel } from '@packages/models/api';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import StisPublicationsQuickFiltersPanel from './stis-filter-panel';
import StisTable from './stis-table';
import DocsPagination from '../docs/docs-pagination';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { CustomToggle } from '../docs/docs-filter-panel';
import { handleVinSessionError, publicationTypes } from '@web/vin-sessions/utils/vinSessionUtils';
import { PublicationType } from './publication-type-icons';
import { StisDocument, VIN } from '@web/vin-sessions/types';
import { vinSessionService } from '@web/services/singletons';

export type PublicationType = {
    icon: string;
    publicationType: string;
};

const useStyles = createUseStyles({
    flexGap: {
        gap: 20,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    dropdown: {
        marginLeft: 10,
        '& .dropdown-menu': {
            width: 280,
            maxHeight: 300,
            padding: '8px 0 !important',
            borderRadius: `3px !important`,
            border: `1px solid ${colors.grayThree} !important`,
            boxShadow: `0px 16px 12px -12px rgba(0, 0, 0, 0.16) !important`,
            overflowY: 'auto !important',
        },
        '& .dropdown-menu::-webkit-scrollbar': {
            width: 10,
        },
        '& .dropdown-menu::-webkit-scrollbar-track': {
            backgroundColor: '#ffffff',
        },
        '& .dropdown-menu::-webkit-scrollbar-thumb': {
            backgroundColor: colors.blueFour,
            borderRadius: 10,
            border: '2px solid #ffffff',
        },
    },
    dropdownItem: {
        padding: '5px 10px',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.blueFour,
        },
        '& label': {
            display: 'flex',
            alignItems: 'center',
        },
    },
    publicationItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        marginLeft: 5,
        padding: '8px',
    },
    filterContainer: {
        display: 'flex',
        gap: '10px',
        flexWrap: 'wrap',
    },
    publicationsHover: {
        '&:hover': {
            backgroundColor: colors.blueFour,
        },
    },
    banner: {
        padding: 16,
        display: 'flex',
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.blueOne,
    },
});

export const StisPublications = () => {
    const selectedTabHeadStyles = {
        borderBottomColor: colors.blueOne,
        color: colors.blueOne,
        borderBottomWidth: 2,
        paddingBottom: 5,
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { account } = useAuthState();
    const { selectedVin } = useVinSessionState();
    const { pathname, search } = useLocation();
    const searchInputRef = useRef(null);
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const queryParams = useQuery();
    const stisPublicationFilter = queryParams.getAll('filter').length === 1 ? queryParams.get('filter') : undefined;
    const textSearchQuery = queryParams.get('query') || '';
    const sizeFromQuery = queryParams.get('size');
    const [newTextSearch, setNewTextSearch] = useState(textSearchQuery);
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = queryParams.get('sortOrder');
    const parsedSortDirection = queryParams.get('sortDir');

    const [selectedPublications, setSelectedPublications] = useState<PublicationType[]>([]);
    const [publications, setPublications] = useState<StisDocument[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownTextOpen, setIsDropdownTextOpen] = useState(false);
    const [isAlertBarOpen, setisAlertBarOpen] = useState<boolean>(true);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (dropdownRef.current && dropdownRef.current.classList.contains('show')) {
            setIsDropdownOpen(true);
        }
    }, [dropdownRef]);

    const searchByText = useCallback(
        (text: string) => {
            const params = new URLSearchParams(search);
            params.delete('page');
            params.delete('size');

            if (text) {
                params.set('query', text);
            } else {
                params.delete('query');
            }

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const hasQuickFilters = useMemo(() => {
        return (
            queryParams.has('model') ||
            queryParams.has('modelYearFrom') ||
            queryParams.has('modelYearTo') ||
            queryParams.has('trReplyStatus') ||
            queryParams.has('trReplySharedStatus') ||
            queryParams.has('finalResult') ||
            queryParams.has('sbrStatus')
        );
    }, [queryParams]);

    const toggleQuickFilterPanel = useCallback(() => {
        toggleOverlay('escalationQuickFilters');
    }, [toggleOverlay]);

    const handleClearQuickFilters = useCallback(() => {
        const newQueryParams = new URLSearchParams();

        if (sizeFromQuery) {
            newQueryParams.set('size', sizeFromQuery);
        }

        if (textSearchQuery) {
            newQueryParams.set('query', textSearchQuery);
        }

        navigate(`/stis?${newQueryParams.toString()}`);
    }, [navigate, sizeFromQuery, textSearchQuery]);

    const fecthPublications = useCallback(
        async (vin: VIN | undefined) => {
            if (!vin) {
                return null;
            }

            try {
                const response = await vinSessionService.getPublications({
                    vin: vin.vinRecord.vin,
                    ignoreCache: true,
                });

                if (response.success) {
                    setPublications(response.data);
                }
            } catch (error) {
                handleVinSessionError('Open VIN Session Error', error);
                return;
            }
        },
        [selectedVin]
    );

    useEffect(() => {
        fecthPublications(selectedVin);
    }, [selectedVin]);

    return (
        <>
            {toggledOverlays.escalationQuickFilters && (
                <StisPublicationsQuickFiltersPanel
                    hasActiveFilters={hasQuickFilters}
                    selectedPublications={selectedPublications}
                    setSelectedPublications={setSelectedPublications}
                    onClear={handleClearQuickFilters}
                    onClose={toggleQuickFilterPanel}
                />
            )}
            <div className="pt-3 pl-5 pr-7 d-flex">
                <div className="d-flex w-100 flex-column">
                    <div className="mb-4">
                        <Typography variant="h2">STIS Publications</Typography>
                    </div>

                    {isAlertBarOpen && !account && (
                        <Row className="mb-3">
                            <Col>
                                <div className={classes.banner}>
                                    <div style={{ display: 'flex', justifyContent: 'center', gap: 16 }}>
                                        <Icon name="info" color="white" size={20} />
                                        <Typography variant="labelRegular" color="white" style={{ marginTop: 1 }}>
                                            Some documents are only available within Subaru Techshare. To access them,
                                            please{' '}
                                            <a href="/signin" style={{ color: '#fff', textDecoration: 'underline' }}>
                                                sign in
                                            </a>{' '}
                                            or{' '}
                                            <a
                                                href="/create-account"
                                                style={{ color: '#fff', textDecoration: 'underline' }}
                                            >
                                                create an account.
                                            </a>
                                        </Typography>
                                    </div>
                                    <Icon name="x-close" color="white" onPress={() => setisAlertBarOpen(false)} />
                                </div>
                            </Col>
                        </Row>
                    )}

                    {account && (
                        <div className={`d-flex flex-row ${classes.flexGap} mb-4 flex-wrap`}>
                            <div
                                onClick={() =>
                                    !account ? navigate(`/stis`) : navigate(`/vin/${selectedVin?.vinRecord.vin}/stis`)
                                }
                                className={`${classes.cursorPointer} d-flex`}
                            >
                                <Typography
                                    style={stisPublicationFilter === undefined ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    All
                                </Typography>
                            </div>
                            <div
                                onClick={() =>
                                    !account
                                        ? navigate(`/stis?filter=RECENTLY_VIEWED`)
                                        : navigate(`/vin/${selectedVin?.vinRecord.vin}/stis?filter=RECENTLY_VIEWED`)
                                }
                                className={`${classes.cursorPointer} d-flex`}
                            >
                                <Typography
                                    style={stisPublicationFilter === 'RECENTLY_VIEWED' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    Recently Viewed
                                </Typography>
                            </div>
                            <div
                                onClick={() =>
                                    !account
                                        ? navigate(`/stis?filter=FAVORITES`)
                                        : navigate(`/vin/${selectedVin?.vinRecord.vin}/stis?filter=FAVORITES`)
                                }
                                className={`${classes.cursorPointer} d-flex`}
                            >
                                <Typography
                                    style={stisPublicationFilter === 'FAVORITES' ? selectedTabHeadStyles : {}}
                                    color="grayFive"
                                    variant="h6"
                                >
                                    Favorites
                                </Typography>
                            </div>
                        </div>
                    )}

                    <div className={classes.filterContainer}>
                        <div className="w-50">
                            <TextInputHelper
                                ref={searchInputRef}
                                rightIcon={
                                    <Icon
                                        name={textSearchQuery ? 'x-close' : 'magnifying-glass'}
                                        onPress={
                                            textSearchQuery
                                                ? () => {
                                                      searchByText('');
                                                  }
                                                : undefined
                                        }
                                    />
                                }
                                value={newTextSearch}
                                onChangeText={setNewTextSearch}
                                placeholder="Search Keywords / Tool # / DTC"
                                onKeyPress={(e) => {
                                    if (e.nativeEvent.key === 'Enter') {
                                        console.log('search!!', newTextSearch);
                                        searchByText(newTextSearch);
                                    }
                                }}
                            />
                        </div>

                        <Dropdown className={classes.dropdown} show={isDropdownOpen} ref={dropdownRef}>
                            <Dropdown.Toggle
                                as={CustomToggle}
                                id="action-dropdown"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                setIsDropdownOpen={setIsDropdownOpen}
                                setIsDropdownTextOpen={setIsDropdownTextOpen}
                                style={{
                                    backgroundColor: selectedPublications.length == 0 ? colors.white : colors.blueFour,
                                }}
                            >
                                <Typography
                                    variant="label"
                                    style={{ color: colors.blueOne, fontWeight: '800', textTransform: 'capitalize' }}
                                >
                                    Publication Type
                                </Typography>
                                <Icon
                                    name="arrow-small-down"
                                    style={{ color: colors.blueOne, marginTop: 1 }}
                                    size={22}
                                />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {publicationTypes.map((filter, index) => (
                                    <div key={index} className={classes.dropdownItem}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                value={filter.publicationType}
                                                checked={selectedPublications.includes(filter)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedPublications([...selectedPublications, filter]);
                                                    } else {
                                                        setSelectedPublications(
                                                            selectedPublications.filter(
                                                                (item: { publicationType: string }) =>
                                                                    item.publicationType !== filter.publicationType
                                                            )
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className={classes.publicationItem}>
                                                <PublicationType publicationType={filter.publicationType} size={18} />
                                                {filter.publicationType}
                                            </div>
                                        </label>
                                    </div>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>

                        <div className="ml-4 d-flex">
                            <Button
                                active={hasQuickFilters}
                                variant="ghost-blue"
                                iconLeft={<Icon color="blueOne" name="filter-list" />}
                                onPress={toggleQuickFilterPanel}
                            >
                                {hasQuickFilters
                                    ? t('views:escalations.actions.filterToggle.active', 'Filters applied')
                                    : t('views:escalations.actions.filterToggle.inactive', 'Filters')}
                            </Button>
                            {hasQuickFilters && (
                                <div className="ml-1">
                                    <Button variant="ghost-blue" onPress={handleClearQuickFilters}>
                                        <Icon color="blueOne" name="x-close" />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <StisTable publications={publications} />
            <DocsPagination />
        </>
    );
};
