import { useContext } from 'react';
import { PermissionsStateContext, PermissionsDispatchContext } from './permissions.context';

export const usePermissionsState = () => {
    const state = useContext(PermissionsStateContext);

    if (state === undefined) {
        throw new Error('usePermissionsState must be used within a PermissionsProvider');
    }

    return state;
};

export const usePermissionsDispatch = () => {
    const dispatch = useContext(PermissionsDispatchContext);

    if (dispatch === undefined) {
        throw new Error('usePermissionsDispatch must be used within a PermissionsProvider');
    }

    return dispatch;
};
