import React from 'react';
import { Col } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';

const useFooterStyles = createUseStyles({
    footerContainer: {
        backgroundColor: colors.grayTwo,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '40px 60px 10px 60px',
        flexWrap: 'wrap',
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 24,
    },
    link: {
        fontSize: 15,
        fontWeight: '400',
    },
});

export const Footer = () => {
    const classes = useFooterStyles();
    const links = {
        siteLinks: ['Privacy Policy', 'Terms & Conditions', 'FAQ', 'Contact Us'],
        otherSites: ['ASE', 'Special Service Tools', 'Kenwood', 'NASTF'],
        other: ['Dealer Equipment and Services', 'SubaruDT', 'ASE Education Foundation', 'subaru.com'],
    };

    return (
        <div className={classes.footerContainer}>
            <div className={classes.row}>
                <Col>
                    <h6>Site Links</h6>
                    <ul className="list-unstyled">
                        {links.siteLinks.map((link) => (
                            <li className={classes.link}>
                                <a href="#">{link}</a>
                            </li>
                        ))}
                    </ul>
                </Col>

                <Col>
                    <h6>Other Sites</h6>
                    <ul className="list-unstyled">
                        {links.otherSites.map((link) => (
                            <li className={classes.link}>
                                <a href="#">{link}</a>
                            </li>
                        ))}
                    </ul>
                </Col>

                <Col>
                    <ul className="list-unstyled mt-5">
                        {links.other.map((link) => (
                            <li className={classes.link}>
                                <a href="#">{link}</a>
                            </li>
                        ))}
                    </ul>
                </Col>
            </div>
        </div>
    );
};
