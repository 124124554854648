import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, TextBadge, Typography, LoadingCircle } from '@packages/ui/shared';
import { Table, TableBody, TableCell, TableHead, TableHeader, TablePagination, TableRow } from '@web/components/table';
import { analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { caseStatusMap as statusMap, getBadgeVariantTechline } from '@web/utils';
import { Empty } from '@web/components/empty/empty';

const useStyles = createUseStyles({
    loaderCircleOuter: {
        top: '50%',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
    },
    caseNumberLink: {
        color: '#007bff',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    noResultsOuter: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    loadingCircleContainer: {
        minHeight: '40vh',
    },
    tableOuter: ({ isLoading }: { isLoading: boolean }) => ({
        height: '100%',
        minHeight: 'calc(100vh - 550px)',
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? 'none' : 'auto',
    }),
});

export const TechlineCases: FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles({ isLoading });
    let content: JSX.Element | JSX.Element[];
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const navigateToCaseDetails = (item: any) => {
        if (item.caseNumber) {
            analyticsService.logEvent(ANALYTICS_EVENTS.USER_OPENS_EXISTING_CASE);
            window.open(`/techline/${item.caseNumber}`, '_blank');
        }
    };

    const displayCases = useMemo(() => {
        return [];
    }, []);

    const tableHeaderRowRenderer = () => {
        return (
            <TableRow>
                <TableHeader>
                    <Typography variant="h5">Case No</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">VIN</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">Status</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">Case type</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">Created by</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">RO</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">Last updated</Typography>
                </TableHeader>
                <TableHeader>
                    <Typography variant="h5">Created Date</Typography>
                </TableHeader>
            </TableRow>
        );
    };

    const renderLoader = () => (
        <TableRow>
            <TableCell className={classes.loadingCircleContainer}>
                <div className={classes.loaderCircleOuter}>
                    <LoadingCircle size={64} />
                </div>
            </TableCell>
        </TableRow>
    );

    const renderRows = () =>
        displayCases.map((item: any) => (
            <React.Fragment>
                <TableRow key={item.caseNumber}>
                    <TableCell width={150}>
                        <Typography>
                            <div className={`${classes.caseNumberLink}`} onClick={() => navigateToCaseDetails(item)}>
                                {item.caseNumber}
                            </div>
                        </Typography>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.vin}</Typography>
                    </TableCell>
                    <TableCell width={150} className="d-flex">
                        <TextBadge variant={getBadgeVariantTechline(item.techlineCaseStatus)}>
                            {item.techlineCaseStatus}
                        </TextBadge>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.caseType}</Typography>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.fullName}</Typography>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.repairOrderNumber}</Typography>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.modifiedOnDescription}</Typography>
                    </TableCell>
                    <TableCell width={150}>
                        <Typography>{item.createdOnDescription}</Typography>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        ));

    const renderEmptyState = () => (
        <TableRow>
            <TableCell>
                <div className={classes.noResultsOuter}>
                    <Empty
                        description={`There is no Techline case created for this car`}
                        iconFlag={true}
                        descriptionStyle={{ fontWeight: '400', fontSize: 13, lineHeight: 20 }}
                        className="mt-10"
                    />
                </div>
            </TableCell>
        </TableRow>
    );

    if (isLoading) {
        content = renderLoader();
    } else if (displayCases?.length) {
        content = renderRows();
    } else {
        content = renderEmptyState();
    }

    return (
        <div>
            <h3 className="mb-4">{`Techline Cases`}</h3>

            <Table className={classes.tableOuter}>
                <TableHead ref={tableHeadRef}>{tableHeaderRowRenderer()}</TableHead>
                <TableBody ref={tableBodyRef}>{content}</TableBody>
            </Table>
        </div>
    );
};
