import React, { FC } from 'react';
import { StyleSheet } from 'react-native';
import { Typography } from '@packages/ui/shared';
import { CardProps, DataCard } from '../admin-hub/data-card';

export interface AdminHubSectionProps {
    title?: string;
    cards?: Array<CardProps>;
}

const styles = StyleSheet.create({
    contentTitle: {
        fontSize: 21,
        fontWeight: '600',
        margin: 0,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export const AdminHubSection: FC<AdminHubSectionProps> = ({ title, cards }) => {
    return (
        <div className="mb-4 mt-3">
            <div className="row">
                <div className="col-12 mb-4">
                    <Typography variant="h2" style={styles.contentTitle}>
                        {title}
                    </Typography>
                </div>
                {cards?.map((card, index) => (
                    <div key={index} className="col-12 col-md-6 col-lg-4 mb-3">
                        <DataCard
                            title={card.title}
                            description={card.description}
                            buttonText={card.buttonText}
                            buttonLink={card.buttonLink}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
