import React, { FC, useCallback, useEffect, useState } from 'react';
import uuid from 'uuid';
import { cloneDeep } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { createUseStyles } from 'react-jss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FormElement, FormComponent, FormComponentEditModal, RoleDropdown } from '@web/administration/components';
import {
    DetailView,
    DetailViewAside,
    DetailViewBody,
    DetailViewContent,
    DetailHeader,
} from '@web/components/detail-view';
import { Button, StsIconName, TextInputHelper, Toggle, Typography } from '@packages/ui/shared';

import {
    FeedbackFormTypeId,
    DraggableFeedbackFormComponent,
    draggableFeedbackFormElements,
    EntityModel,
    AccountRole,
} from '@packages/models/api';
import { entitiesService, feedbackAdminService } from '@web/services/singletons';
import Select from '@web/components/select';
import AsyncPage from '@web/components/async-page';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

export const FeedbackFormBuilder: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { feedbackFormId } = useParams<{ feedbackFormId: string }>();
    const classes = useNewFeedbackFormStyles();
    const [displayNameValue, setDisplayNameValue] = useState('New Feedback Form');
    const [titleInputValue, setTitleInputValue] = useState('');
    const [typeSelectValue, setTypeSelectValue] = useState<FeedbackFormTypeId>(FeedbackFormTypeId.Entity);
    const [isActive, setIsActive] = useState(false);
    const [formComponents, setFormComponents] = useState<DraggableFeedbackFormComponent[]>([]);
    const [showFormComponentEditModal, setShowFormComponentEditModal] = useState(false);
    const [formComponentToEdit, setFormComponentToEdit] = useState<DraggableFeedbackFormComponent>();
    const isFeedbackFormIdPresent = !!feedbackFormId;

    const [accountRoles, setAccountRoles] = useState<AccountRole[]>([]);
    const [selectedJobIds, setSelectedJobIds] = useState<string[]>([]);

    const fetchData = useCallback(async () => {
        if (!feedbackFormId) {
            return;
        }

        const response = await feedbackAdminService.fetchFeedbackFormById({ feedbackFormId });

        if (!response.success && response.aborted) {
            return;
        } else if (!response.success) {
            throw response.data;
        }
        setDisplayNameValue(response.data.form.formTitle);
        setTitleInputValue(response.data.form.formTitle);
        setTypeSelectValue(response.data.form.formType);
        setEntityTypeaheadSelections(response.data.form.entityTargets || []);
        setSelectedJobIds((response.data.form.jobTitleTargets || []).map((t) => t.jobTitleId));
        setIsActive(response.data.form.active);
        setFormComponents(
            response.data.form.formElements.map((formElement) => {
                return {
                    ...formElement,
                    title: '', // TODO: figure out how to set these
                    description: '', // TODO: figure out how to set these
                    iconName: '', // TODO: figure out how to set these
                };
            })
        );
    }, [feedbackFormId]);

    useEffect(() => {
        if (typeSelectValue === FeedbackFormTypeId.Person) {
            feedbackAdminService
                .fetchAccountRoles()
                .then((response) => {
                    if (!response.success && response.aborted) {
                        return;
                    } else if (!response.success) {
                        throw response.data;
                    }

                    setAccountRoles(response.data.rolesAndTitles.roles);
                })
                .catch((error) => {
                    window.alert(error.message);
                });
        }
    }, [typeSelectValue]);

    function handleCancelButtonPress() {
        navigate(-1);
    }

    async function handleSaveButtonPress() {
        try {
            const requestBody = {
                title: titleInputValue,
                feedbackFormTypeId: typeSelectValue,
                elements: formComponents,
                active: isActive,
                confirm: true, // TODO: dont hardcode this
                ...(typeSelectValue === FeedbackFormTypeId.Entity
                    ? {
                          feedbackTargetIds: entityTypeaheadSelections.map((entity) => entity.entityId),
                      }
                    : typeSelectValue === FeedbackFormTypeId.Person
                      ? { feedbackTargetIds: selectedJobIds }
                      : {}),
            };

            const response = feedbackFormId
                ? await feedbackAdminService.updateFeedbackForm(feedbackFormId, requestBody)
                : await feedbackAdminService.createFeedbackForm(requestBody);

            if (!response.success && response.aborted) {
                return;
            } else if (!response.success) {
                throw response.data;
            }

            window.alert(feedbackFormId ? 'Form was updated.' : 'Form was created.');
            navigate(-1);
        } catch (error) {
            window.alert(error.message);
        }
    }

    function addNewFormComponent(source: DraggableLocation, destination: DraggableLocation) {
        const formComponentsClone = cloneDeep(formComponents);
        const draggedFormElementClone = cloneDeep(Object.values(draggableFeedbackFormElements)[source.index]);
        const newFormComponent = Object.assign(draggedFormElementClone, { formElementId: uuid() });

        if (newFormComponent.options) {
            newFormComponent.options = newFormComponent.options.map((option, index) => {
                option.optionId = uuid();
                option.optionValue = String(index);
                return option;
            });
        } else {
            newFormComponent.options = [];
        }

        formComponentsClone.splice(destination.index, 0, newFormComponent);
        setFormComponents(formComponentsClone);
    }

    function reorderFormComponents(source: DraggableLocation, destination: DraggableLocation) {
        const formComponentsClone = cloneDeep(formComponents);
        const [draggedFormComponent] = formComponentsClone.splice(source.index, 1);

        formComponentsClone.splice(destination.index, 0, draggedFormComponent);
        setFormComponents(formComponentsClone);
    }

    function handleDragEnd(result: DropResult) {
        const { source, destination } = result;

        if (!destination) {
            return;
        }

        switch (source.droppableId) {
            case 'FORM_ELEMENTS':
                addNewFormComponent(source, destination);
                break;
            case destination.droppableId:
                reorderFormComponents(source, destination);
                break;
            default:
                return;
        }
    }

    function handleFormComponentEditClick(item: DraggableFeedbackFormComponent) {
        setFormComponentToEdit(item);
        setShowFormComponentEditModal(true);
    }

    function handleFormComponentEditModalHide() {
        setShowFormComponentEditModal(false);
        setFormComponentToEdit(undefined);
    }

    function handleFormComponentEditModalSave(item: DraggableFeedbackFormComponent) {
        const formComponentsClone = cloneDeep(formComponents);
        const formComponentIndex = formComponentsClone.findIndex(
            (formComponent) => formComponent.formElementId === item.formElementId
        );

        formComponentsClone.splice(formComponentIndex, 1, item);

        setFormComponents(formComponentsClone);
        setShowFormComponentEditModal(false);
        setFormComponentToEdit(undefined);
    }

    function handleFormComponentDeleteClick(item: DraggableFeedbackFormComponent) {
        if (window.confirm(`Are you sure you want to delete this form component (${item.description})?`)) {
            const formComponentsClone = cloneDeep(formComponents);
            const formComponentIndex = formComponentsClone.findIndex(
                (formComponent) => formComponent.formElementId === item.formElementId
            );

            formComponentsClone.splice(formComponentIndex, 1);
            setFormComponents(formComponentsClone);
        }
    }

    /* ------------------------------------------------- */
    /* Entites Typeahead */
    /* ------------------------------------------------- */
    const [entityTypeaheadIsLoading, setEntityTypeaheadIsLoading] = useState(false);
    const [entityTypeaheadOptions, setEntityTypeaheadOptions] = useState<EntityModel[]>([]);
    const [entityTypeaheadSelections, setEntityTypeaheadSelections] = useState<EntityModel[]>([]);

    const handleEntityTypeaheadSearch = useCallback(async (query: string) => {
        setEntityTypeaheadIsLoading(true);

        try {
            try {
                const response = await entitiesService.getEntities({ query });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                setEntityTypeaheadOptions(response.data.entities);
            } catch (error) {
                setEntityTypeaheadOptions([]);
                window.alert(error.message);
            }
        } catch (error) {
            window.alert(error.message);
        }

        setEntityTypeaheadIsLoading(false);
    }, []);

    const handleParentEntityTypeaheadChange = useCallback((selectedEntities: EntityModel[]) => {
        setEntityTypeaheadSelections(selectedEntities);
    }, []);

    const commonBreadcrumbs = [
        {
            to: '/administration',
            title: t('feedback:breadcrumbs.adminHub', 'Administration Hub'),
        },
        {
            to: '/administration/feedback',
            title: t('feedback:breadcrumbs.manageFeedback', 'Manage Feedback'),
        },
    ];

    const breadcrumbs = feedbackFormId
        ? [
              ...commonBreadcrumbs,
              {
                  to: `/administration/feedback/${feedbackFormId}`,
                  title: t('feedback:breadcrumbs.feedbackForm', 'Feedback'),
              },
              {
                  to: `/administration/feedback/${feedbackFormId}/edit`,
                  title: t('feedback:breadcrumbs.editFeedbackForm', 'Edit Feedback'),
              },
          ]
        : [
              ...commonBreadcrumbs,
              {
                  to: '/administration/feedback/new',
                  title: t('feedback:breadcrumbs.newFeedbackForm', 'New'),
              },
          ];

    const rightSideItems = [
        <Button variant="ghost-blue" onPress={handleCancelButtonPress} key="cancel">
            Cancel
        </Button>,
        <Button onPress={handleSaveButtonPress} key="save">
            Save Form
        </Button>,
    ];

    return (
        <AsyncPage fetchData={fetchData}>
            <FormComponentEditModal
                formComponent={formComponentToEdit}
                show={showFormComponentEditModal}
                onSave={handleFormComponentEditModalSave}
                onHide={handleFormComponentEditModalHide}
            />

            <DetailView>
                <DetailHeader
                    breadcrumbs={breadcrumbs}
                    hasBack={true}
                    subtitle={displayNameValue}
                    rightSideItems={rightSideItems}
                />

                <DetailViewContent topOffset={feedbackFormId ? 'breadcrumb' : 'header'}>
                    <DetailViewBody>
                        <DragDropContext onDragEnd={handleDragEnd}>
                            <div className={classes.formRow}>
                                <div className={classes.formElementsCol}>
                                    <Droppable droppableId="FORM_ELEMENTS" isDropDisabled={true}>
                                        {(provided) => (
                                            <div ref={provided.innerRef}>
                                                {Object.values(draggableFeedbackFormElements).map((item, index) => (
                                                    <Draggable
                                                        key={item.formElementTypeId}
                                                        draggableId={item.formElementTypeId}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <FormElement
                                                                provided={provided}
                                                                snapshot={snapshot}
                                                                title={item.title}
                                                                iconName={item.iconName as StsIconName}
                                                            />
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                                <div className={classes.formComponentsCol}>
                                    <Droppable droppableId="FORM_COMPONENTS">
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                className={classNames({
                                                    [classes.formContainer]: true,
                                                    [classes.formContainerIsDraggingOver]: snapshot.isDraggingOver,
                                                })}
                                            >
                                                {formComponents.length ? (
                                                    formComponents.map((item, index) => (
                                                        <Draggable
                                                            key={item.formElementId}
                                                            draggableId={item.formElementId}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <FormComponent
                                                                    provided={provided}
                                                                    snapshot={snapshot}
                                                                    item={item}
                                                                    onEditClick={() => {
                                                                        handleFormComponentEditClick(item);
                                                                    }}
                                                                    onDeleteClick={() => {
                                                                        handleFormComponentDeleteClick(item);
                                                                    }}
                                                                />
                                                            )}
                                                        </Draggable>
                                                    ))
                                                ) : (
                                                    <div className={classes.dropNotice}>
                                                        <p className="mb-0">
                                                            {t('feedback:adminFormBuilder.dropArea', 'Drop items here')}
                                                        </p>
                                                    </div>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </DragDropContext>
                    </DetailViewBody>
                    <DetailViewAside>
                        <Row className="mb-8">
                            <Col>
                                <Typography variant="h4" color="blueOne">
                                    {t('feedback:adminFormBuilder.title', 'Form Settings')}
                                </Typography>
                            </Col>
                        </Row>
                        <Row className="mb-6">
                            <Col>
                                <div className="mb-2">
                                    <Typography variant="h6">
                                        {t('feedback:adminFormBuilder.inputs.formTitle.label', 'Title')}
                                    </Typography>
                                </div>
                                <div className="mb-6">
                                    <TextInputHelper
                                        value={titleInputValue}
                                        onChangeText={setTitleInputValue}
                                        placeholder={t(
                                            'feedback:adminFormBuilder.inputs.formTitle.placeholder',
                                            'Form Title'
                                        )}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Typography variant="h6">
                                        {t('feedback:adminFormBuilder.inputs.formType.label', 'Type')}
                                    </Typography>
                                </div>
                                <div className="mb-6">
                                    <Select
                                        value={typeSelectValue}
                                        disabled={isFeedbackFormIdPresent}
                                        options={[
                                            {
                                                title: t(
                                                    'feedback:adminFormBuilder.inputs.formType.options.qmr',
                                                    'QMR'
                                                ),
                                                value: FeedbackFormTypeId.Qmr,
                                            },
                                            {
                                                title: t(
                                                    'feedback:adminFormBuilder.inputs.formType.options.entity',
                                                    'Entity'
                                                ),
                                                value: FeedbackFormTypeId.Entity,
                                            },
                                            {
                                                title: t(
                                                    'feedback:adminFormBuilder.inputs.formType.options.person',
                                                    'Peer to Peer'
                                                ),
                                                value: FeedbackFormTypeId.Person,
                                            },
                                            // {
                                            //     title: t(
                                            //         'feedback:adminFormBuilder.inputs.formType.options.feedbackRating',
                                            //         'Close the Loop'
                                            //     ),
                                            //     value: FeedbackFormTypeId.FeedbackRating,
                                            // },
                                        ]}
                                        onChange={(event) => {
                                            setTypeSelectValue(event.currentTarget.value as FeedbackFormTypeId);
                                        }}
                                    />
                                </div>

                                {typeSelectValue === 'ENTITY' && (
                                    <>
                                        <div className="mb-2">
                                            <Typography variant="h6">Target</Typography>
                                        </div>

                                        <div className="mb-6">
                                            <AsyncTypeahead
                                                multiple
                                                id="entity-async-typeahead"
                                                minLength={1}
                                                placeholder="Search Entities"
                                                labelKey="name"
                                                isLoading={entityTypeaheadIsLoading}
                                                onSearch={handleEntityTypeaheadSearch}
                                                options={entityTypeaheadOptions}
                                                onChange={handleParentEntityTypeaheadChange}
                                                selected={entityTypeaheadSelections}
                                                useCache={false}
                                            />
                                        </div>
                                    </>
                                )}

                                {typeSelectValue === FeedbackFormTypeId.Person && (
                                    <>
                                        <div className="mb-2">
                                            <Typography variant="h6">Target</Typography>
                                        </div>

                                        <div className="mb-6">
                                            <RoleDropdown
                                                id="role-dropdown"
                                                accountRoles={accountRoles}
                                                selectedJobIds={selectedJobIds}
                                                onSelect={(selectedJobId: string) => {
                                                    if (selectedJobIds.includes(selectedJobId)) {
                                                        setSelectedJobIds(
                                                            selectedJobIds.filter((id) => id !== selectedJobId)
                                                        );
                                                    } else {
                                                        setSelectedJobIds([...selectedJobIds, selectedJobId]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </>
                                )}

                                <div className="mb-2">
                                    <Typography variant="h6" style={{ marginBottom: 24 }}>
                                        {t('feedback:adminFormBuilder.inputs.active.label', 'Form is Active?')}
                                    </Typography>
                                </div>
                                <Toggle
                                    uncheckedLabel={t('text:inactive', 'Inactive')}
                                    checkedLabel={t('text:active', 'Active')}
                                    checked={isActive}
                                    onChange={setIsActive}
                                />
                            </Col>
                        </Row>
                    </DetailViewAside>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};

const useNewFeedbackFormStyles = createUseStyles({
    formRow: {
        display: 'flex',
    },
    formElementsCol: {
        width: 192,
        flexShrink: 0,
        marginRight: 30,
    },
    formComponentsCol: {
        flex: 1,
    },
    formContainer: {},
    formContainerIsDraggingOver: {},
    dropNotice: {
        padding: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
