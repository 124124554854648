import { Constants } from '@packages/core/config';
import { configService, analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

export const handleLogin = (showUnauthorizedPrompt: boolean, navigate: any) => {
    analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_TO_LOGIN);

    if (showUnauthorizedPrompt) {
        navigate('/');
        return;
    }

    let loginUrl = configService.get(Constants.Env.SamlLoginUrl) + '?clientDeviceTypeId=WEB_BROWSER';

    if (__DEV__) {
        loginUrl += '&redirectBaseUrl=temporary-hack';
    }

    window.open(loginUrl, '_self');
};
