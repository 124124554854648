import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, Typography } from '@packages/ui/shared';
import colors from '@packages/core/styles/colors';
import { StyleSheet } from 'react-native';

interface TagInputProps {
    placeholder?: string;
    tags?: string[];
    allowDuplicates?: boolean;
    handleAdd: (tag: string) => void;
    handleRemove: (index: number) => void;
}

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        width: '100%',
        position: 'relative',
    },
    inputWrapper: {
        border: '1px solid #ced4da',
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '8px',
        alignItems: 'center',
    },
    tag: {
        backgroundColor: colors.blueFour,
        borderRadius: '4px',
        padding: '2px 8px',
        display: 'flex',
        alignItems: 'center',
        color: colors.blueOne,
    },
    closeButton: {
        cursor: 'pointer',
        background: 'transparent',
        border: 'none',
        paddingRight: 0,
    },
    inputBox: {
        flex: '1',
        border: 'none',
        outline: 'none',
        minWidth: '100px',
    },
    dropdown: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        border: '1px solid #ced4da',
        borderRadius: '4px',
        background: '#fff',
        zIndex: 10,
    },
    dropdownItem: {
        cursor: 'pointer',
        padding: '12px 16px',
        '&:hover': {
            background: '#e9ecef',
        },
    },
    hidden: {
        display: 'none',
    },

    suffixIcon: {
        position: 'absolute',
        top: '50%',
        right: 10,
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
    },
});

const styles = StyleSheet.create({
    tag: {
        fontWeight: '700',
        lineHeight: 15.95,
        fontSize: 11,
    },
});

export const TagInput: React.FC<TagInputProps> = ({
    placeholder = 'Add a tag...',
    tags = [],
    handleAdd,
    handleRemove,
}) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const classes = useStyles();

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            handleAdd(inputValue);
            setInputValue('');
            setIsDropdownVisible(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        setIsDropdownVisible(event.target.value.trim() !== '');
    };

    const handleDropdownClick = () => {
        handleAdd(inputValue);
        setInputValue('');
        setIsDropdownVisible(false);
    };

    return (
        <div className={classes.container}>
            <div className={classes.inputWrapper}>
                {tags.map((tag, index) => (
                    <div key={index} className={classes.tag}>
                        <Typography color="blueOne" style={styles.tag}>
                            {tag}
                        </Typography>
                        <button
                            className={classes.closeButton}
                            onClick={() => handleRemove(index)}
                            aria-label="Remove tag"
                        >
                            <Icon name="x-close" color="blueOne" size={16} />
                        </button>
                    </div>
                ))}
                <input
                    type="text"
                    className={classes.inputBox}
                    placeholder={tags.length === 0 ? placeholder : ''}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className={`${classes.dropdown} ${isDropdownVisible ? '' : classes.hidden}`}>
                <div className={classes.dropdownItem} onClick={handleDropdownClick}>
                    <span>{inputValue}</span>
                    <span className={classes.suffixIcon}>
                        <Typography color="black" style={{ marginRight: 8 }}>
                            click or press enter to add
                        </Typography>
                        <Icon name="keyboard-return" color={'grayFive'} />
                    </span>
                </div>
            </div>
        </div>
    );
};
