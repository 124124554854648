import React, { FC } from 'react';
import { AccountsService } from '@packages/contexts/accounts';
import { DATE_FORMAT } from 'web/src/config/constants';
import { formatDate } from 'web/src/utils/date-format';
import { ScrollView, StyleSheet, View } from 'react-native';
import { Typography } from '../shared';
import { useBmisState } from '@packages/contexts/bmis';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
    section: {
        paddingHorizontal: 24,
        paddingVertical: 12,
    },
    labelWrapper: {
        alignItems: 'baseline',
        flexDirection: 'row',
    },
    title: {
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 24,
    },
    subTitle: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
        marginTop: 8,
        textTransform: 'uppercase',
    },
    content: {
        fontWeight: '400',
        lineHeight: 24,
    },
});

interface DetailsCardProps {
    content?: string;
    subTitle?: string;
    title: string;
}

const DetailsCard: FC<DetailsCardProps> = ({ title, subTitle, content }) => (
    <View style={styles.section}>
        {title && (
            <View style={styles.labelWrapper}>
                <Typography variant="h6" style={styles.title}>
                    {title}
                </Typography>
            </View>
        )}

        {subTitle && (
            <Typography style={styles.subTitle} color="blueOne">
                {subTitle}
            </Typography>
        )}
        {content && <Typography style={styles.content}>{content}</Typography>}
    </View>
);

interface BmisAsideDetailsProps {
    accountService: AccountsService;
}

const getRetailerLocation = (retailer?: { districtCode?: string; regionCode?: string; zoneCode?: string }) => {
    if (!retailer) return 'N/A';
    const { regionCode = '', zoneCode = '', districtCode = '' } = retailer;

    return `Region ${regionCode}, Zone ${zoneCode}, District ${districtCode}`;
};

export const BmisAsideDetails: FC<BmisAsideDetailsProps> = ({ accountService }) => {
    const { t } = useTranslation();
    const { selectedBmisRow } = useBmisState();

    if (!selectedBmisRow) {
        return null;
    }

    const { retailer, serviceDateandTime, techID } = selectedBmisRow;
    const formattedServiceDate = serviceDateandTime
        ? formatDate(serviceDateandTime, DATE_FORMAT.MON_DAY_YEAR_AT_H_MM)
        : 'N/A';

    const retailerLocation = getRetailerLocation(retailer);

    return (
        <ScrollView>
            <DetailsCard
                title={t('Retailer')}
                subTitle={retailer ? `${retailer.name} (#${retailer.code})` : 'N/A'}
                content={retailerLocation}
            />
            <DetailsCard title={t('Tested By')} subTitle={techID || 'N/A'} content={formattedServiceDate} />
        </ScrollView>
    );
};
