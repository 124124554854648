import { HttpClient } from '@packages/core/http';
import {
    Synonym,
    SynonymCreatePayload,
    FetchSynonymsOptions,
    GetSynonymsSearchTermsResponse,
} from '@packages/models/api/synonyms-search-term';

const TEMP_DATA = [
    {
        searchTermId: '1',
        searchTerm: 'Overheating',
        synonyms: ['Excessive heat', 'Temperature spike', 'Heat up', 'Temperature rise'],
    },
    {
        searchTermId: '2',
        searchTerm: 'Airbag',
        synonyms: ['Air cushion', 'Safety bag', 'Inflatable restraint', 'Crash bag', 'Safety device'],
    },
    {
        searchTermId: '3',
        searchTerm: 'Firing',
        synonyms: ['Launch', ' Shoot', ' Discharge', ' Eject', ' Throw', ' Send flying', ' Let off', ' Blast'],
    },
    {
        searchTermId: '4',
        searchTerm: 'Clicking',
        synonyms: ['Clink', ' Clank', ' Chink', ' Snick', ' Tick', ' Snap', ' Pop'],
    },
    {
        searchTermId: '5',
        searchTerm: 'Hose',
        synonyms: ['Pipe', ' Piping', ' Tube', ' Tubing', ' Conduit', ' Channel', ' Line', ' Duct'],
    },
    {
        searchTermId: '6',
        searchTerm: 'Clunking',
        synonyms: ['Jangle', ' Rattle', ' Clink', ' Clank', ' Jingle', ' Clunk', ' Clatter'],
    },
    {
        searchTermId: '7',
        searchTerm: 'Odor',
        synonyms: ['Smell', ' Scent', ' Aroma', ' Stink', ' Malodour'],
    },
];

export class SynonymsService {
    constructor(private http: HttpClient) {}

    async fetchSynonyms(options: FetchSynonymsOptions): Promise<GetSynonymsSearchTermsResponse> {
        const { text, orderBy = 'updatedAt', order = 'desc', page = 1, size = 10, abortSignal } = options;

        const queryParams = new URLSearchParams({
            text: text || '',
            orderBy,
            order,
            page: page.toString(),
            size: size.toString(),
        });

        // const response = await this.http.orchestrateRequest<GetSynonymsSearchTermsResponse>({
        //     method: 'GET',
        //     url: `/synonyms-search-terms?${queryParams.toString()}`,
        //     signal: abortSignal,
        //     ignoreCache: true,
        // });

        // if (!response.success) {
        //     throw new Error('Failed to fetch synonyms');
        // }

        return {
            data: TEMP_DATA,
            pagination: {
                page: 1,
                size: 100,
                total: 1000,
            },
        };
    }

    async createSynonym(payload: SynonymCreatePayload): Promise<Synonym> {
        const response = await this.http.orchestrateRequest<Synonym>({
            method: 'POST',
            url: '/synonyms-search-terms',
            body: payload,
        });

        if (!response.success) {
            throw new Error('Failed to create synonym');
        }

        return response.data;
    }

    async updateSynonym(id: string, payload: Partial<SynonymCreatePayload>): Promise<Synonym> {
        const response = await this.http.orchestrateRequest<Synonym>({
            method: 'PATCH',
            url: `/synonyms-search-terms/${id}`,
            body: payload,
        });

        if (!response.success) {
            throw new Error('Failed to update synonym');
        }

        return response.data;
    }

    async deleteSynonym(id: string): Promise<void> {
        const response = await this.http.orchestrateRequest<void>({
            method: 'DELETE',
            url: `/synonyms-search-terms/${id}`,
        });

        if (!response.success) {
            throw new Error('Failed to delete synonym');
        }
    }
}
