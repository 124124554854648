import React, { FC, useCallback, useEffect, useState } from 'react';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';
import { Col, Row, Spinner } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { DetailView, DetailViewBody, DetailViewContent } from '@web/components/detail-view';
import { fetchDashboard, fetchDashboardUpdates } from '@packages/contexts/dashboard/dashboard.context';
import { handleDocsError } from '@web/vin-sessions/utils/vinSessionUtils';
import { IDashboardUpdates } from '@packages/contexts/dashboard/dashboard.state';
import { isDevEnvironment, isLocal } from '@web/utils/is-dev-environment';
import { MenuTable } from '@web/components/menu-table';
import { Qmr, SavedSearch } from '@packages/models/api';
import { qmrsService, dashboardService, analyticsService, docsService } from '@web/services/singletons';
import { QmrTableHead, QmrTableRow } from '@web/qmr/components/qmr-table';
import { SORT_DIRECTION } from '@web/components/table';
import { TableRenderer } from '@web/components/table';
import { useAbortController } from '@packages/core/http';
import { useAuthState } from '@packages/contexts/auth';
import { useDashboardDispatch, useDashboardState } from '@packages/contexts/dashboard/dashboard.hooks';
import { useNavigate } from 'react-router-dom';
import { useQmrTableActions } from '@packages/contexts/qmrs';
import { useTranslation } from 'react-i18next';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import {
    AlertBar,
    Button,
    Icon,
    isValidIconType,
    StsIconName,
    TextBadge,
    Typography,
    useAlert,
} from '@packages/ui/shared';
import AsyncPage from '@web/components/async-page';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    banner: {
        alignItems: 'center',
        backgroundColor: colors.blueOne,
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        padding: 20,
    },
    card: {
        borderRadius: 8,
        boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.16)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        padding: 20,
    },
    menuCardContainer: {
        display: 'flex',
        gap: 10,
        justifyContent: 'space-between',
        maxWidth: '100%',
        overflow: 'hidden',
    },
});

interface QmrTableRowModel extends Qmr {
    rowId: string;
    checked: boolean;
}

export interface MenuProps {
    name: string;
    icon: string;
}

const Overview: FC = () => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { t } = useTranslation();
    const { account } = useAuthState();

    const [savedSearch, setSavedSearch] = useState<Partial<SavedSearch>>();
    const [qmrTableRows, setQmrTableRows] = useState<QmrTableRowModel[]>([]);
    const [startCount, setStartCount] = useState('0');
    const [endCount, setEndCount] = useState('0');
    const [qmrTotal, setQmrTotal] = useState('0');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedQmrs, setSelectedQmrs] = useState<QmrTableRowModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const { isVinLoading } = useVinSessionState();

    const dashboardState = useDashboardState();
    const dashboardDispatch = useDashboardDispatch();
    const { abortSignalRef } = useAbortController();

    const alert = useAlert();

    const qmrTableActions = useQmrTableActions({
        qmrs: selectedQmrs.length ? selectedQmrs : null,
        alert,
        qmrsService,
    });

    const handleSortChange = (sortBy: string, sortDirection: SORT_DIRECTION) => {
        setSortBy(sortBy);
        setSortDirection(sortDirection);
        fetchQmrList(sortBy, sortDirection);
    };

    const fetchQmrList = async (sortBy?: string, sortDirection?: SORT_DIRECTION) => {
        setIsLoading(true);
        try {
            let queryParams: any = { size: '10', qmrStatusId: 'DRAFT' };
            if (sortBy && sortDirection) {
                queryParams = { ...queryParams, sortOrder: sortBy, sortDir: sortDirection };
            }
            const queryString = new URLSearchParams(queryParams).toString();
            const response = await qmrsService.fetchQmrsList({ queryString });
            setIsLoading(false);
            if (response.success) {
                setQmrTableRows(
                    response.data.qmrs.map((qmr) => {
                        const qmrAsTableRow = { ...qmr, rowId: qmr.qmrId, checked: false };
                        return qmrAsTableRow;
                    })
                );
                setStartCount(response.data.displayStartCountDescription);
                setEndCount(response.data.displayEndCountDescription);
                setQmrTotal(response.data.totalCountDescription);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(typeof error.message === 'string' ? error.message : 'Something went wrong.');
        }
    };

    const fetchData = useCallback(async () => {
        await fetchDashboard({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });

        fetchDashboardUpdates({
            dashboardDispatch,
            dashboardService,
            signal: abortSignalRef.current,
        });

        if (!account) {
            return;
        }

        if (account.defaultSavedSearchId) {
            try {
                const dashboardQmrParams = new URLSearchParams({ size: '10' }).toString();
                const response = await qmrsService.getAdvancedSearchResultsForSearchId(
                    account.defaultSavedSearchId,
                    dashboardQmrParams
                );
                if (response.success) {
                    setSavedSearch(response.data.savedSearch);
                    setQmrTableRows(
                        response.data.results.qmrs.map((qmr) => {
                            const qmrAsTableRow = { ...qmr, rowId: qmr.qmrId, checked: false };
                            return qmrAsTableRow;
                        })
                    );
                    setStartCount(response.data.results.displayStartCountDescription);
                    setEndCount(response.data.results.displayEndCountDescription);
                    setQmrTotal(response.data.results.totalCountDescription);
                } else {
                    throw response.data;
                }
            } catch (error) {
                window.alert(typeof error.message === 'string' ? error.message : 'Something went wrong.');
            }
        } else {
            // set saved search to default state if no saved search is found (in the case of user had a saved search and then it was removed)
            setSavedSearch(undefined);
            fetchQmrList();
        }
    }, [abortSignalRef, account, dashboardDispatch]);

    const fetchFavoritesTableData = async () => {
        try {
            const response = await docsService.getAccountFavorites({
                fetchAccountParams: {
                    accountId: account?.accountId,
                },
                ignoreCache: true,
            });
            if (response.success) {
                console.log(response);
            }
        } catch (error) {
            handleDocsError(`Favorites Docs Fetching Error: No Record Found; an unknown error occurred`, error);
        }
    };

    function handleStartQmrButtonClick() {
        analyticsService.logEvent(ANALYTICS_EVENTS.USER_CLICKS_CREATE_A_QMR);
        navigate('/qmrs', { state: { startNew: true } });
    }

    useEffect(() => {
        fetchFavoritesTableData();
    }, [account]);

    if (isVinLoading) {
        return (
            <div style={{ height: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spinner animation={'border'} style={{ height: 72, width: 72, color: '#1F518F' }} />
            </div>
        );
    }

    return (
        <AsyncPage fetchData={fetchData}>
            <DetailView>
                <AlertBar
                    show={alert.isOpen}
                    success={alert.type === 'success'}
                    error={alert.type === 'error'}
                    onClose={alert.close}
                >
                    <Typography>{alert.content}</Typography>
                </AlertBar>
                <DetailViewContent topOffset="none">
                    <DetailViewBody>
                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h1">
                                    {t('views:dashboard.header.hello', `Hi ${account?.firstName}`)}
                                </Typography>
                                <Typography variant="h2" color="graySix" style={{ marginLeft: 12 }}>
                                    {t('views:dashboard.header.message', 'Welcome back!')}
                                </Typography>
                            </Col>
                        </Row>

                        {dashboardState.dashboard &&
                            (dashboardState.dashboard.bannerHeader || dashboardState.dashboard.bannerMessage) && (
                                <Row className="mb-8">
                                    <Col>
                                        <div className={classes.banner}>
                                            <div>
                                                <div className="mb-1">
                                                    {dashboardState.dashboard.bannerHeader && (
                                                        <Typography variant="h3" color="white">
                                                            {dashboardState.dashboard.bannerHeader}
                                                        </Typography>
                                                    )}
                                                </div>
                                                {dashboardState.dashboard.bannerMessage && (
                                                    <div style={{ paddingRight: '1em' }}>
                                                        <Typography color="white">
                                                            {dashboardState.dashboard.bannerMessage}
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                            {dashboardState.dashboard.bannerLink && (
                                                <div className="d-flex align-items-center justify-content-center p-5">
                                                    <Button
                                                        variant="info"
                                                        onPress={() => {
                                                            window.open(dashboardState.dashboard.bannerLink, '_blank');
                                                        }}
                                                        iconRight={
                                                            <Icon size={16} name="chevron-right" color="blueOne" />
                                                        }
                                                    >
                                                        {t('buttons:readMore', 'Read More')}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            )}

                        {isLocal() && (
                            <div className={`mb-2 ${classes.menuCardContainer}`}>
                                <MenuTable
                                    tableData={[
                                        {
                                            icon: 'Service Manual Correction',
                                            name: `Innovation Chronicles: Subaru's Insight`,
                                        },
                                        { icon: 'Owner Manual', name: `23' Solterra Owners Manual` },
                                        { icon: 'Video', name: `Subaru Service Insights: Diagnostics` },
                                        { icon: 'Warranty Bulletin', name: `22’ Impreza Service Manual ` },
                                    ]}
                                    header="Favorites"
                                    noDataText="No favorites have been added"
                                    noDataSubText="Mark STIS Publications as favorites and get an easy access to them"
                                />

                                <MenuTable
                                    tableData={[
                                        {
                                            icon: 'Service Manual Correction',
                                            name: `Innovation Chronicles: Subaru's Insight`,
                                        },
                                        { icon: 'Owner Manual', name: `23' Solterra Owners Manual` },
                                        {
                                            icon: 'On-Board Diag II Information',
                                            name: `Subaru Service Insights: Diagnostics`,
                                        },
                                        { icon: 'Warranty Bulletin', name: `22’ Impreza Service Manual ` },
                                    ]}
                                    header={`What's New`}
                                    noDataText="No new publications have been uploaded"
                                    noDataSubText={`SOA hasn't uploaded any new publications for this specific car recently`}
                                />

                                <MenuTable
                                    tableData={[
                                        {
                                            icon: 'Service Manual Correction',
                                            name: `Innovation Chronicles: Subaru's Insight`,
                                        },
                                        { icon: 'Owner Manual', name: `23' Solterra Owners Manual` },
                                        { icon: 'Video', name: `Subaru Service Insights: Diagnostics` },
                                        { icon: 'Warranty Bulletin', name: `22’ Impreza Service Manual ` },
                                    ]}
                                    header="Recently Viewed"
                                    noDataText="No recently Viewed documents"
                                    noDataSubText="Your last visited documents will automatically appear here"
                                />
                            </div>
                        )}

                        {/* Future Work TS-2048*/}
                        {/* <DocumentSearch/> */}

                        <Row className="mb-6">
                            <Col>
                                <Typography variant="h3">
                                    {t('views:dashboard.latestUpdates.title', 'Latest Updates')}
                                </Typography>
                            </Col>
                        </Row>

                        <Row className="mb-8">
                            {dashboardState.dashboard && dashboardState?.dashboardUpdates?.length > 0 ? (
                                <>
                                    {dashboardState.dashboardUpdates.map((update: IDashboardUpdates) => {
                                        return (
                                            <Col xs={4}>
                                                <div className={classes.card}>
                                                    <div>
                                                        <div className="mb-4">
                                                            <div className="d-flex">
                                                                <TextBadge
                                                                    variant="blue"
                                                                    icon={
                                                                        isValidIconType(update.type as StsIconName) ? (
                                                                            <Icon
                                                                                name={update.type as StsIconName}
                                                                                size={14}
                                                                                color="blueOne"
                                                                                style={{ display: 'flex' }}
                                                                            />
                                                                        ) : null
                                                                    }
                                                                >
                                                                    {update.type}
                                                                </TextBadge>
                                                            </div>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Typography variant="h5">{update.heading}</Typography>
                                                        </div>
                                                        <div className={update.link ? 'mb-6' : 'mb-0'}>
                                                            <Typography variant="labelRegular" color="graySix">
                                                                {update.description}
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                    {update.link && (
                                                        <div className="d-flex">
                                                            <Button
                                                                variant="tertiary"
                                                                iconRight={<Icon size={16} name="chevron-right" />}
                                                                onPress={() => {
                                                                    window.open(update.link, '_blank');
                                                                }}
                                                            >
                                                                {t('buttons:readMore', 'Read More')}
                                                            </Button>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </>
                            ) : (
                                <Col>
                                    <div className={classes.card}>
                                        <div className="mb-2">
                                            <Typography variant="h5">
                                                {t(
                                                    'views:dashboard.latestUpdates.noDataTitle',
                                                    'No updates available at this time.'
                                                )}
                                            </Typography>
                                        </div>
                                        <div className="mb-0">
                                            <Typography variant="labelRegular" color="graySix">
                                                {t(
                                                    'views:dashboard.latestUpdates.noDataDescription',
                                                    'Check back later.'
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                </Col>
                            )}
                        </Row>

                        <Row className="mb-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Typography variant="h3">
                                            {savedSearch
                                                ? savedSearch.name
                                                : t('views:dashboard.customDisplay.title', 'My Drafts')}
                                        </Typography>
                                        {/* <Button
                                            variant="link"
                                            style={{ marginLeft: 32 }}
                                            onPress={() => {
                                                return;
                                            }}
                                        >
                                            {t('buttons:changeView', 'Change view')}
                                        </Button> */}
                                    </div>
                                    {(account?.systemCapabilities.createDraftQmr ||
                                        account?.systemCapabilities.createDraftQmrForAnyRetailer) && (
                                        <Button
                                            iconRight={
                                                <Icon
                                                    size={16}
                                                    raised
                                                    name="plus"
                                                    raisedContainerStyle={{ marginLeft: 16, width: 22, height: 22 }}
                                                />
                                            }
                                            onPress={handleStartQmrButtonClick}
                                        >
                                            {t('buttons:startQmr')}
                                        </Button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col style={{ minHeight: 200 }}>
                                <TableRenderer<QmrTableRowModel>
                                    isLoading={isLoading}
                                    selectAll={selectAll}
                                    selectAllSetter={setSelectAll}
                                    tableRowsData={qmrTableRows}
                                    tableRowsDataSetter={setQmrTableRows}
                                    onSelectionChange={setSelectedQmrs}
                                    tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                                        return (
                                            <QmrTableHead
                                                selectedCount={selectedQmrs.length}
                                                selectAll={selectAllValue}
                                                onSelectAllChange={selectAllChangeHandler}
                                                sortOrder={sortBy}
                                                sortDirection={sortDirection ? (sortDirection as SORT_DIRECTION) : null}
                                                onSort={handleSortChange}
                                                onActionPress={qmrTableActions.handleActionPress}
                                            />
                                        );
                                    }}
                                    tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                                        return (
                                            <QmrTableRow
                                                key={rowData.qmrId}
                                                qmr={rowData}
                                                checked={rowData.checked}
                                                onCheckboxChange={rowSelectHandler}
                                            />
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="pb-4">
                            <Col>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Typography color="blueOne">
                                        {startCount} - {endCount} of {qmrTotal}
                                    </Typography>
                                    <Button
                                        variant="tertiary"
                                        iconRight={<Icon size={16} name="chevron-right" />}
                                        onPress={() => {
                                            navigate('/qmrs');
                                        }}
                                    >
                                        View all QMRs
                                    </Button>
                                    <div></div>
                                </div>
                            </Col>
                        </Row>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </AsyncPage>
    );
};

export default Overview;
