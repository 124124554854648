import React, { FC, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon, Typography } from '@packages/ui/shared';
import { Link, useNavigate } from 'react-router-dom';
import colors from '@packages/core/styles/colors';
import { GestureResponderEvent, StyleSheet, TouchableOpacity } from 'react-native';

interface BreadcrumbModel {
    title: string;
    to: string;
}

export interface DetailViewHeaderProps {
    breadcrumbs?: BreadcrumbModel[];
    children?: React.ReactNode;
    rightSideItems?: React.ReactNode[];
    hasBack?: boolean;
    subtitle?: string | React.ReactNode;
}

const useDetailViewHeaderStyles = createUseStyles({
    header: ({ hasBreadcrumbs }: { hasBreadcrumbs: boolean }) => ({
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.grayThree}`,
        display: 'flex',
        alignItems: 'center',
        padding: '0 30px',
        // flexDirection: 'column',
        // justifyContent: 'center',
        gap: '30px',
        height: hasBreadcrumbs ? 100 : 80,
        justifyContent: 'center',
    }),
});

export const DetailViewHeader: FC<DetailViewHeaderProps> = ({ breadcrumbs, hasBack, children }) => {
    const navigate = useNavigate();
    const classes = useDetailViewHeaderStyles({
        hasBreadcrumbs: !!(breadcrumbs && breadcrumbs.length > 0),
    });

    const onBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    return (
        <div className={classes.header}>
            {hasBack && (
                <div>
                    <Icon name="arrow-back" onPress={onBack} />
                </div>
            )}
            <div style={{ flex: 1 }}>
                {breadcrumbs && (
                    <div className="mb-1 w-100">
                        {breadcrumbs.map((breadcrumb, index) => {
                            const isLast = index === breadcrumbs.length - 1;

                            if (isLast) {
                                return (
                                    <Typography key={index} variant="label">
                                        {breadcrumb.title}
                                    </Typography>
                                );
                            } else {
                                return (
                                    <span key={index}>
                                        <Typography variant="labelRegular">
                                            <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
                                        </Typography>
                                        <Typography variant="labelRegular" color="grayFive">
                                            &nbsp;&nbsp;/&nbsp;&nbsp;
                                        </Typography>
                                    </span>
                                );
                            }
                        })}
                    </div>
                )}
                <div className="w-100">{children}</div>
            </div>
        </div>
    );
};

const useDetailHeaderStyles = createUseStyles({
    header: () => ({
        alignItems: 'center',
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.grayThree}`,
        display: 'flex',
        gap: '20px',
        height: 88,
        justifyContent: 'space-between',
        padding: '16px 24px',
    }),
    leftSection: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        gap: '20px',
    },
    breadcrumbs: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '4px',
    },
    rightSideItems: {
        alignItems: 'center',
        display: 'flex',
        gap: '15px',
    },
});

const styles = StyleSheet.create({
    backSlash: {
        color: colors.textDarkSecondary,
        fontWeight: '400',
        paddingLeft: 7,
        paddingRight: 7,
    },
    breadcrumb: {
        fontWeight: '400',
    },
    subTitle: {
        fontWeight: '600',
    },
    iconSpacing: {
        marginRight: 16,
    },
});

export const DetailHeader: FC<DetailViewHeaderProps> = ({
    breadcrumbs = [],
    subtitle,
    rightSideItems = [],
    children,
    hasBack,
}) => {
    const navigate = useNavigate();
    const classes = useDetailHeaderStyles({
        hasBreadcrumbs: breadcrumbs.length > 0,
    });

    const handleNavigation = useCallback(
        (event: GestureResponderEvent) => {
            event.preventDefault();
            if (breadcrumbs.length > 1) {
                const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2];

                if (previousBreadcrumb?.to) {
                    navigate(previousBreadcrumb.to);
                    return;
                }
            }
            navigate(-1);
        },
        [breadcrumbs, navigate]
    );

    return (
        <div className={classes.header}>
            <div className={classes.leftSection}>
                {hasBack && (
                    <TouchableOpacity
                        onPress={handleNavigation}
                        style={styles.iconSpacing}
                        accessibilityRole="button"
                        accessibilityLabel="arrow-back"
                        testID="back-icon"
                    >
                        <Icon name="arrow-back" color="textDarkSecondary" />
                    </TouchableOpacity>
                )}
                <div>
                    {breadcrumbs.length > 0 && (
                        <div className={classes.breadcrumbs}>
                            {breadcrumbs.map((breadcrumb, index) => {
                                const isLast = index === breadcrumbs.length - 1;

                                return (
                                    <span key={index}>
                                        {isLast ? (
                                            <Typography variant="label" style={styles.breadcrumb}>
                                                {breadcrumb.title}
                                            </Typography>
                                        ) : (
                                            <>
                                                <Typography variant="labelRegular" style={styles.breadcrumb}>
                                                    <Link to={breadcrumb.to}>{breadcrumb.title}</Link>
                                                </Typography>
                                                <Typography
                                                    style={styles.backSlash}
                                                    variant="labelRegular"
                                                    color="textDarkSecondary"
                                                >
                                                    &nbsp;/&nbsp;
                                                </Typography>
                                            </>
                                        )}
                                    </span>
                                );
                            })}
                        </div>
                    )}
                    {subtitle && (
                        <Typography variant="h3" style={styles.subTitle}>
                            {subtitle}
                        </Typography>
                    )}
                </div>
            </div>
            {children && <div className="w-100">{children}</div>}
            {rightSideItems.length ? (
                <div className={classes.rightSideItems}>
                    {rightSideItems.map((item, index) => (
                        <React.Fragment key={index}>{item}</React.Fragment>
                    ))}
                </div>
            ) : null}
        </div>
    );
};
