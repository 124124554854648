import { Overlay, Popover } from 'react-bootstrap';
import { Icon, RobotoFontFamily, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { Layers } from '@web/vin-sessions/assets/Icons';
import React, { useState } from 'react';
import colors from '@packages/core/styles/colors';
import { useAuthState } from '@packages/contexts/auth';

export const HoverModal = ({
    title,
    description,
    creationDate,
    version,
    toolNumber,
    applicability,
    index,
}: {
    title: string;
    description: string;
    creationDate: string;
    version: string;
    toolNumber: string;
    applicability: string;
    index: number;
}) => {
    const useStyles = createUseStyles({
        title: {
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
        },
        popOver: {
            minWidth: 400,
            color: '#333',
            border: '1px solid #ddd',
            borderRadius: 8,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            padding: 15,
        },
        description: {
            border: '1px solid #e0e0e0',
            padding: 10,
            borderRadius: 5,
            backgroundColor: '#f7f7f7',
            fontFamily: RobotoFontFamily.Regular,
            fontWeight: '400',
            fontSize: 15,
            maxHeight: 224,
            overflowY: 'auto !important',
            '&::-webkit-scrollbar': {
                width: 10,
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: '#f7f7f7',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors.grayThree,
                borderRadius: 10,
                border: '2px solid #f7f7f7',
            },
        },
        layers: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: '400',
            fontSize: 15,
            gap: 5,
        },
        titleContainer: {
            maxWidth: 200,
            fontSize: 15,
            color: '#007BFF',
            cursor: 'pointer',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    });

    const classes = useStyles();
    const { account } = useAuthState();
    console.log(account);
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState<HTMLElement | null>(null);

    const handleMouseEnter = (event: React.MouseEvent<HTMLSpanElement>) => {
        setTarget(event.currentTarget as HTMLElement);
        setShow(true);
    };

    const handleMouseLeave = () => {
        setShow(false);
    };

    const renderPopover = (
        <Popover
            id="hover-popover"
            className={classes.popOver}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={handleMouseLeave}
        >
            <Popover.Content style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <p>
                    <strong style={{ fontSize: 15 }}>{title}</strong>
                </p>
                <p>
                    <strong>Creation Date</strong> {creationDate}
                </p>
                <p className={classes.description}>{description}</p>
                <div className={classes.layers}>
                    <Layers />
                    <Typography variant="default">Version 03.01</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 0, justifyContent: 'center' }}>
                    <p>
                        <strong>Tool Numbers</strong>
                    </p>
                    <Typography variant="default">{toolNumber}</Typography>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 0, justifyContent: 'center' }}>
                    <p>
                        <strong>APPLICABILITY:</strong>
                    </p>
                    <Typography variant="default">{applicability}</Typography>
                </div>
            </Popover.Content>
        </Popover>
    );

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8 }}>
                <div
                    className={classes.titleContainer}
                    style={{ color: !account && index > 2 ? '#8FA3BC' : '#007BFF' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {title}
                </div>
                {!account && index > 2 && <Icon name="lock-closed" color="grayFive" style={{ marginBottom: 2 }} />}
            </div>

            {(account != null || (index != undefined && index <= 2)) && (
                <Overlay show={show} target={target} placement="right" onHide={() => setShow(false)}>
                    {renderPopover}
                </Overlay>
            )}
        </div>
    );
};
