import React, { FC, useCallback, useEffect, useState } from 'react';
import { AdminHubHeader } from '../components/admin-hub/admin-hub-header';
import { createUseStyles } from 'react-jss';
import { feedbackAdminService } from '@web/services/singletons';
import { FeedbackForm } from '@packages/models/api';
import { Link, useNavigate } from 'react-router-dom';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import { Typography, Button, TextBadge, Icon } from '@packages/ui/shared';
import { useAbortController } from '@packages/core/http';
import { useTranslation } from 'react-i18next';

interface FeedbackFormTableModel extends FeedbackForm {
    rowId: string;
}

const useStyles = createUseStyles({
    container: {
        minHeight: '82vh',
    },
    actionIcons: {
        display: 'flex',
        gap: '12px',
        justifyContent: 'center',
        padding: '10px',
    },
    pageFooter: {
        alignItems: 'center',
        borderTop: '1px solid #ddd',
        display: 'flex',
        height: '60px',
        justifyContent: 'center',
    },
});

const feedbackFromHeaders = [
    {
        key: 'name',
        label: 'Name',
        sortable: true,
        width: '35%',
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        width: '15%',
    },
    {
        key: 'createdAt',
        label: 'Created At',
        sortable: true,
        width: '25%',
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true,
        width: '15%',
    },
    {
        key: 'actions',
        label: 'Actions',
        sortable: false,
        width: '10%',
    },
];

export const ManageFeedback: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();

    const { abortSignalRef } = useAbortController();
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackForms, setFeedbackForms] = useState<FeedbackFormTableModel[]>([]);
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState({ page: 0, size: 50 });

    const fetchFeedbackForms = useCallback(async () => {
        setIsLoading(true);
        const response = await feedbackAdminService.fetchFeedbackForms({
            page: pagination.page,
            size: pagination.size,
            ignoreCache: true,
            signal: abortSignalRef.current,
        });
        setIsLoading(false);

        if (!response.success) {
            if (!response.aborted) {
                alert(response.data.message);
            }
            return;
        }

        setFeedbackForms(response.data.forms.map((form) => ({ ...form, rowId: form.feedbackFormId })));
        setTotal(response.data.totalCount);
    }, [pagination, abortSignalRef]);

    useEffect(() => {
        fetchFeedbackForms();
    }, [fetchFeedbackForms]);

    const handleNewForm = () => navigate('/administration/feedback/new');

    const renderTableHeader = () => (
        <TableRow>
            {feedbackFromHeaders.map((header, index) => (
                <TableHeader key={index} width={header.width} sortable={header.sortable}>
                    <Typography variant="label" numberOfLines={1}>
                        {t(
                            `feedback:adminFormList.headers.${header.key}`,
                            header.key.charAt(0).toUpperCase() + header.key.slice(1)
                        )}
                    </Typography>
                </TableHeader>
            ))}
        </TableRow>
    );

    const handleNavigateEdit = (id: string) => {
        navigate(`/administration/feedback/${id}/edit`);
    };

    const renderTableRow = (rowData: FeedbackFormTableModel) => (
        <TableRow key={rowData.rowId}>
            <TableCell>
                <Typography numberOfLines={1}>
                    <Link to={`/administration/feedback/${rowData.feedbackFormId}`}>{rowData.formTitle}</Link>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>{rowData.formType}</Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>{rowData.createdTimestampForDisplay}</Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>
                    {rowData.active ? (
                        <TextBadge variant="green">{t('active')}</TextBadge>
                    ) : (
                        <TextBadge variant="red">{t('inactive')}</TextBadge>
                    )}
                </Typography>
            </TableCell>
            <TableCell className={classes.actionIcons}>
                <Button variant="ghost-blue" onPress={() => handleNavigateEdit(rowData.feedbackFormId)}>
                    <Icon name="pencil" color="blueOne" />
                </Button>
            </TableCell>
        </TableRow>
    );

    return (
        <>
            <div className={`d-flex flex-column ${classes.container}`}>
                <AdminHubHeader
                    breadcrumbs={[
                        { title: t('feedback:breadcrumbs.adminHub', 'Administration Hub'), to: '/administration' },
                        { title: t('feedback:breadcrumbs.manageFeedback', 'Manage Feedback') },
                    ]}
                    externalButtons={[
                        <Button onPress={handleNewForm}>
                            {t('feedback:adminFormList.actions.create', 'New Feedback Form')}
                        </Button>,
                    ]}
                />
                <div className="container-fluid mt-4">
                    <div className="row mb-2">
                        <div className="col-12">
                            <TableRenderer<FeedbackForm>
                                isLoading={isLoading}
                                noResultsIconName="administration"
                                tableBodyRowRenderer={renderTableRow}
                                tableHeaderRowRenderer={renderTableHeader}
                                tableRowsData={feedbackForms}
                                tableRowsDataSetter={setFeedbackForms}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <footer className={`row d-flex justify-content-center align-items-center ${classes.pageFooter}`}>
                <div className="col-12">
                    <TablePagination
                        onClick={(page) => setPagination((prev) => ({ ...prev, page }))}
                        onSizeChange={(size) => setPagination((prev) => ({ ...prev, size }))}
                        page={pagination.page}
                        size={pagination.size}
                        total={total}
                    />
                </div>
            </footer>
        </>
    );
};
