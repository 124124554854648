import { useAbortController } from '@packages/core/http';
import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import useQuery from '@web/core/hooks/use-query';
import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { publicationTypes } from '@web/vin-sessions/utils/vinSessionUtils';
import { PublicationType } from './publication-type-icons';

const useStyles = createUseStyles({
    panel: {
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 5,
        width: '100%',
        maxWidth: 390,
        display: 'flex',
        position: 'absolute',
        flexDirection: 'column',
        backgroundColor: colors.white,
    },
    panelHeader: {
        display: 'flex',
        padding: '14px 24px',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: colors.grayOne,
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    panelScrollContainer: {
        flex: 1,
        overflowY: 'auto',
    },
    closeButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterHeader: {
        textTransform: 'uppercase',
        margin: '12px 20px',
    },
    filterContent: {
        margin: '0 20px 12px',
    },
    hr: {
        margin: '0 24px',
        marginTop: 20,
        marginBottom: 10,
    },
    dropdown: {
        '& .dropdown-menu': {
            width: 280,
            maxHeight: 300,
            padding: '8px 0 !important',
            borderRadius: `3px !important`,
            border: `1px solid ${colors.grayThree} !important`,
            boxShadow: `0px 16px 12px -12px rgba(0, 0, 0, 0.16) !important`,
            overflowY: 'auto !important',
        },
        '& .dropdown-menu::-webkit-scrollbar': {
            width: 10,
        },
        '& .dropdown-menu::-webkit-scrollbar-track': {
            backgroundColor: '#ffffff',
        },
        '& .dropdown-menu::-webkit-scrollbar-thumb': {
            backgroundColor: colors.blueFour,
            borderRadius: 10,
            border: '2px solid #ffffff',
        },
    },
    dropdownItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
        marginLeft: 5,
        padding: '8px',
    },
    filterStyling: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        width: '100%',
    },
    dropdownItemContainer: {
        '& .rbt-menu.dropdown-menu.show .dropdown-item:hover': {
            backgroundColor: '#dee6ef !important',
            color: '#4C525D !important',
        },
    },
});

type Props = {
    hasActiveFilters: boolean;
    onClose: () => void;
    onClear: () => void;
    selectedPublications: any;
    setSelectedPublications: any;
};

const StisPublicationsQuickFiltersPanel = (props: Props) => {
    const { selectedPublications, setSelectedPublications } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const currentQueryParams = useQuery();
    const navigate = useNavigate();
    const { abortSignalRef } = useAbortController();
    const { selectedVin } = useVinSessionState();

    const [selectedKeyword, setSelectedKeyword] = useState<string>('');

    useEffect(() => {}, [abortSignalRef, currentQueryParams, t]);

    const rightPanelDiv = document.getElementById('right-side-panel');

    if (!rightPanelDiv) {
        return null;
    }

    const divider = <hr className={classes.hr} />;

    return ReactDOM.createPortal(
        <div className={classes.panel}>
            <div className={classes.panelHeader}>
                <Typography>{t('publications:quickFilters.title', 'Filters')}</Typography>

                <div className={classes.closeButton} onClick={props.onClose}>
                    <Icon name="x-close" />
                </div>
            </div>
            <div className={classes.panelScrollContainer}>
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">{t('publications:quickFilters.keyword', 'Keyword')}</Typography>
                </div>

                <div className={classes.filterContent}>
                    <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Search Keywords / Tool # / DTC"
                        value={selectedKeyword}
                        onChange={(e) => {
                            setSelectedKeyword(e.target.value);
                        }}
                    />
                </div>
                <div className={classes.filterHeader}>
                    <Typography variant="caption2">
                        {t('publications:quickFilters.publicationType', 'Publication Type')}
                    </Typography>
                </div>

                <div className={`${classes.filterContent} ${classes.dropdownItemContainer}`}>
                    <Typeahead
                        id="model-async-typeahead"
                        placeholder="Select Publication Type"
                        multiple
                        selected={selectedPublications}
                        onChange={(selectedOptions) => {
                            setSelectedPublications(selectedOptions);
                        }}
                        className={classes.dropdown}
                        options={publicationTypes}
                        labelKey="publicationType"
                        renderMenuItemChildren={(option, props, index) => (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <input
                                    type="checkbox"
                                    checked={selectedPublications.some(
                                        (item: { publicationType: string }) =>
                                            item.publicationType == option.publicationType
                                    )}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedPublications([...selectedPublications, option]);
                                        } else {
                                            setSelectedPublications(
                                                selectedPublications.filter(
                                                    (item: { publicationType: string }) =>
                                                        item.publicationType !== option.publicationType
                                                )
                                            );
                                        }
                                    }}
                                />
                                <div className={classes.dropdownItem}>
                                    <PublicationType publicationType={option.publicationType} size={24} />
                                    {option.publicationType}
                                </div>
                            </div>
                        )}
                    />
                </div>

                {divider}

                <div className={classes.filterContent} style={{ display: 'flex', rowGap: 10, flexDirection: 'column' }}>
                    <div className={classes.filterStyling}>
                        <Typography variant="caption2">{t('publications:quickFilters.carLine', 'Carline')}</Typography>
                        <Typeahead selected={selectedVin ? [selectedVin.vinRecord.model] : []} options={[]} disabled />
                    </div>

                    <div className={classes.filterStyling}>
                        <Typography variant="caption2">
                            {t('publications:quickFilters.modelYear', 'Model Year')}
                        </Typography>
                        <Typeahead
                            selected={selectedVin ? [`${selectedVin.vinRecord.modelYear}`] : []}
                            options={[]}
                            disabled
                        />
                    </div>

                    <div className={classes.filterStyling} style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                        <div>
                            <Typography variant="caption2">{t('publications:quickFilters.trim', 'Trim')}</Typography>
                            <Typeahead selected={selectedVin ? ['Touring, M/R,...'] : []} options={[]} disabled />
                        </div>

                        <div>
                            <Typography variant="caption2">
                                {t('publications:quickFilters.functionsGroup', 'Functions Group')}
                            </Typography>
                            <Typeahead placeholder="Select" selected={[]} options={[]} />
                        </div>
                    </div>

                    <div className={classes.filterStyling} style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                        <div>
                            <Typography variant="caption2">
                                {t('publications:quickFilters.emissions', 'Emissions')}
                            </Typography>
                            <Typeahead placeholder="Select" selected={[]} options={[]} />
                        </div>

                        <div>
                            <Typography variant="caption2">{t('publications:quickFilters.body', 'Body')}</Typography>
                            <Typeahead placeholder="Select" selected={[]} options={[]} />
                        </div>
                    </div>

                    <div className={classes.filterStyling} style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
                        <div>
                            <Typography variant="caption2">{t('publications:quickFilters.dtc', 'DTC')}</Typography>
                            <Typeahead placeholder="Enter DTC" selected={[]} options={[]} />
                        </div>

                        <div>
                            <Typography variant="caption2">{t('publications:quickFilters.tool', 'Tool No')}</Typography>
                            <Typeahead placeholder="Enter Tool No" selected={[]} options={[]} />
                        </div>
                    </div>
                </div>

                {divider}

                <div className={classes.filterContent}>
                    <div className="mt-4 d-flex justify-content-between">
                        <Button
                            onPress={() => {
                                const newQueryParams = new URLSearchParams();
                                const currentSize = currentQueryParams.get('size');
                                const currentQuery = currentQueryParams.get('query');

                                navigate(``);
                                props.onClose();
                            }}
                        >
                            {t('publications:quickFilters.actions.apply', 'Apply')}
                        </Button>

                        <Button
                            variant="ghost-blue"
                            onPress={() => {
                                props.onClear();
                                props.onClose();
                            }}
                        >
                            {t('escalation:quickFilters.actions.clear', 'Clear All')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>,
        rightPanelDiv
    );
};

export default StisPublicationsQuickFiltersPanel;
