import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Typography, Button, Icon } from '@packages/ui/shared';
import { GestureResponderEvent, StyleSheet } from 'react-native';
import { useNavigate } from 'react-router-dom';

export interface CardProps {
    title: string;
    description: string;
    buttonText: string;
    buttonLink: string;
}

const useStyles = createUseStyles({
    card: {
        border: '1px solid #E3E3E3',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '160px',
        padding: '24px',
    },
});

const styles = StyleSheet.create({
    title: {
        fontSize: 15,
        fontWeight: '600',
        lineHeight: 24,
        marginBottom: 8,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    description: {
        fontSize: 15,
        fontWeight: '400',
        lineHeight: 24,
        marginTop: 'auto',
        marginBottom: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    button: {
        borderColor: '#1F518F',
        borderWidth: 1,
        paddingVertical: 0,
        maxWidth: '100%',
    },
    titleStyle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
});

export const DataCard: React.FC<CardProps> = ({ title, description, buttonText, buttonLink }) => {
    const classes = useStyles();
    const [isHovered, setIsHovered] = useState(false);
    const navigate = useNavigate();

    const handleNavigation = (event: GestureResponderEvent) => {
        event.preventDefault();
        navigate(buttonLink);
    };
    return (
        <div className={classes.card}>
            <Typography variant="statusBadge" style={styles.title} color="black">
                {title}
            </Typography>
            <Typography style={styles.description} color="textDarkSecondary">
                {description}
            </Typography>
            <div
                className={`d-flex justify-content-start mt-auto text-decoration-none w-auto`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <Button
                    style={styles.button}
                    iconRight={<Icon name="chevron-right" color={isHovered ? 'white' : 'blueOne'} />}
                    title={buttonText}
                    variant="outlinePrimary"
                    onPress={handleNavigation}
                    titleStyle={styles.titleStyle}
                />
            </div>
        </div>
    );
};
