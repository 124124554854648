import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { GestureResponderEvent, StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, Typography } from '@packages/ui/shared';
import { useNavigate } from 'react-router-dom';

export interface BreadcrumbModel {
    title: string;
    to?: string;
}

const useStyles = createUseStyles({
    header: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderBottom: '1px solid #e0e0e0',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '16px 24px',
    },
    titleContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: 8,
        maxWidth: '100%',
    },
    buttonsContainer: {
        alignItems: 'center',
        display: 'flex',
        gap: 16,
    },
});

const styles = StyleSheet.create({
    headerTitle: {
        fontSize: 21,
        fontWeight: '600',
        lineHeight: 32,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    iconSpacing: {
        marginRight: 16,
    },
});

export interface AdminHubHeaderProps {
    externalButtons?: React.ReactNode[];
    breadcrumbs: BreadcrumbModel[];
}

export const AdminHubHeader: React.FC<AdminHubHeaderProps> = ({ breadcrumbs, externalButtons = [] }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const handleNavigation = useCallback(
        (event: GestureResponderEvent) => {
            event.preventDefault();
            if (breadcrumbs.length > 1) {
                const previousBreadcrumb = breadcrumbs[breadcrumbs.length - 2];

                if (previousBreadcrumb?.to) {
                    navigate(previousBreadcrumb.to);
                    return;
                }
            }
            navigate(-1);
        },
        [breadcrumbs, navigate]
    );

    return (
        <div className={classes.header} data-testid="admin-hub-header">
            <div className={classes.titleContainer}>
                {breadcrumbs.length > 1 && (
                    <TouchableOpacity
                        accessibilityLabel="arrow-back"
                        accessibilityRole="button"
                        onPress={handleNavigation}
                        style={styles.iconSpacing}
                    >
                        <Icon name="arrow-back" color="textDarkSecondary" />
                    </TouchableOpacity>
                )}
                {breadcrumbs.map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                        <Typography
                            color={index === breadcrumbs.length - 1 ? 'black' : 'textDarkSecondary'}
                            style={styles.headerTitle}
                            variant="h2"
                        >
                            {breadcrumb.title}
                        </Typography>
                        {index < breadcrumbs.length - 1 && <Icon name="chevron-right" color="textDarkSecondary" />}
                    </React.Fragment>
                ))}
            </div>
            {externalButtons.length > 0 && (
                <div className={classes.buttonsContainer}>
                    {externalButtons.map((button, index) => (
                        <React.Fragment key={index}>{button}</React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};
