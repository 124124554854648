import React, { FC, useCallback, useEffect, useState } from 'react';
import { AdminHubHeader } from '../components/admin-hub/admin-hub-header';
import { Button, Icon, TextBadge, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { entitiesService } from '@web/services/singletons';
import { EntityModel } from '@packages/models/api';
import { Link, useNavigate } from 'react-router-dom';
import { StyleSheet } from 'react-native';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import { useTranslation } from 'react-i18next';
import colors from '@packages/core/styles/colors';

type EntityTableRowModal = {
    rowId: string;
    checked: boolean;
} & EntityModel;

const useStyles = createUseStyles({
    container: {
        minHeight: '82vh',
    },
    actionIcons: {
        display: 'flex',
        gap: '12px',
        justifyContent: 'center',
        padding: '10px',
    },
    pageFooter: {
        alignItems: 'center',
        borderTop: '1px solid #ddd',
        display: 'flex',
        height: '60px',
        justifyContent: 'center',
    },
});

const styles = StyleSheet.create({
    addingEntityButton: {
        borderRadius: 4,
        padding: 8,
        paddingLeft: 16,
    },
    addingEntityIcon: {
        backgroundColor: colors.white,
        borderRadius: 50,
    },
});

const entitiesTableHeaders = [
    {
        key: 'name',
        label: 'Name',
        sortable: true,
    },
    {
        key: 'description',
        label: 'Description',
        sortable: true,
    },
    {
        key: 'visibility',
        label: 'Visibility',
        sortable: true,
    },
    {
        key: 'enabled',
        label: 'Enabled',
        sortable: true,
    },
];

export const ManageEntities: FC = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const navigate = useNavigate();

    const [entities, setEntities] = useState<EntityTableRowModal[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalEntities, setTotalEntities] = useState(0);

    const renderTableHeader = () => (
        <TableRow>
            {entitiesTableHeaders.map((header, index) => (
                <TableHeader key={index} sortable={header.sortable}>
                    <Typography variant="label" numberOfLines={1}>
                        {t(
                            `feedback:adminFormList.headers.${header.key}`,
                            header.key.charAt(0).toUpperCase() + header.key.slice(1)
                        )}
                    </Typography>
                </TableHeader>
            ))}
        </TableRow>
    );

    const renderTableRow = (rowData: EntityTableRowModal) => (
        <TableRow key={rowData.rowId}>
            <TableCell>
                <Typography numberOfLines={1}>
                    <Link to={`/administration/entities/${rowData.entityId}`}>{rowData.name}</Link>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography>{rowData.description}</Typography>
            </TableCell>
            <TableCell>
                <div className="d-inline-flex">
                    {rowData.visible ? (
                        <TextBadge variant="green">Visible</TextBadge>
                    ) : (
                        <TextBadge variant="red">Hidden</TextBadge>
                    )}
                </div>
            </TableCell>
            <TableCell>
                <div className="d-inline-flex">
                    {rowData.active ? (
                        <TextBadge variant="green">Enabled</TextBadge>
                    ) : (
                        <TextBadge variant="default">Disabled</TextBadge>
                    )}
                </div>
            </TableCell>
        </TableRow>
    );

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);

            try {
                const response = await entitiesService.getEntities({
                    page: pageIndex,
                    size: pageSize,
                });

                if (!response.success && response.aborted) {
                    return;
                } else if (!response.success) {
                    throw response.data;
                }

                const entityTableRows = response.data.entities.map((entity) => {
                    return Object.assign(
                        {
                            rowId: entity.entityId,
                            checked: false,
                        },
                        entity
                    );
                });

                setEntities(entityTableRows);
                setTotalEntities(response.data.totalCount);
            } catch (error) {
                window.alert(error.message);
            }

            setIsLoading(false);
        }

        fetchData();
    }, [pageIndex, pageSize]);

    function handleNewEntityButtonClick() {
        navigate('/administration/entities/new');
    }

    const handlePaginationClick = useCallback((index: number) => {
        setPageIndex(index);
    }, []);

    const handlePaginationSizeChange = useCallback((size: number) => {
        setPageSize(size);
    }, []);

    return (
        <>
            <div className={`d-flex flex-column ${classes.container}`}>
                <AdminHubHeader
                    breadcrumbs={[
                        {
                            title: t('entities:breadcrumbs.adminHub', 'Administration Hub'),
                            to: '/administration',
                        },
                        {
                            title: t('entities:breadcrumbs.entities', 'Entities'),
                        },
                    ]}
                    externalButtons={[
                        <Button
                            iconRight={<Icon name="plus" color="blueOne" size={19.5} style={styles.addingEntityIcon} />}
                            onPress={handleNewEntityButtonClick}
                            style={styles.addingEntityButton}
                        >
                            New Entity
                        </Button>,
                    ]}
                />
                <div className="container-fluid mt-4">
                    <div className="row mb-2">
                        <div className="col-12">
                            <TableRenderer<EntityTableRowModal>
                                tableRowsData={entities}
                                tableRowsDataSetter={setEntities}
                                noResultsIconName="administration"
                                tableHeaderRowRenderer={renderTableHeader}
                                tableBodyRowRenderer={renderTableRow}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <footer className={`row d-flex justify-content-center align-items-center ${classes.pageFooter}`}>
                <div className="col-12">
                    <TablePagination
                        page={pageIndex}
                        size={pageSize}
                        total={totalEntities}
                        onClick={handlePaginationClick}
                        onSizeChange={handlePaginationSizeChange}
                    />
                </div>
            </footer>
        </>
    );
};
