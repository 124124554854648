import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Col, Row, Dropdown, DropdownButton } from 'react-bootstrap';

import { Button, Typography, LoadingCircle, Icon, AlertBar, useAlert } from '@packages/ui/shared';
import { Qmr } from '@packages/models/api';
import { useQmrCsvExport, useQmrTableActions } from '@packages/contexts/qmrs';

import useQuery from '@web/core/hooks/use-query';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { qmrsService } from '@web/services/singletons';

import { DetailView, DetailViewBody, DetailViewContent, DetailViewHeader } from '@web/components/detail-view';
import { SORT_DIRECTION, TablePagination, TableRenderer } from '@web/components/table';
import AdvancedSearchPanel from '@web/qmr/components/advanced-search-panel';
import { QmrTableHead, QmrTableRow } from '@web/qmr/components/qmr-table';
import { ConditionModel, GroupConditionModel } from '../components/query-builder';
import { useAuthState } from '@packages/contexts/auth';

interface QmrTableRowModel extends Qmr {
    rowId: string;
    checked: boolean;
}

const AdvancedSearchResults: FC = () => {
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const query = useQuery();

    const { searchId } = useParams<{ searchId: string }>();
    const pageFromQuery = query.get('page');
    const sizeFromQuery = query.get('size');

    const [isLoading, setIsLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [qmrTableRows, setQmrTableRows] = useState<QmrTableRowModel[]>([]);
    const [selectedQmrs, setSelectedQmrs] = useState<QmrTableRowModel[]>([]);

    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;
    const parsedSortOrder = query.get('sortOrder');
    const parsedSortDirection = query.get('sortDir');

    const [searchName, setSearchName] = useState('');
    const [searchQuery, setSearchQuery] = useState<GroupConditionModel>();

    const { toggledOverlays, toggleOverlay, dismissAllOverlays } = useContentOverlayState();

    const qmrCsvExport = useQmrCsvExport({ qmrsService });
    const alert = useAlert();

    const auth = useAuthState();

    const qmrTableActions = useQmrTableActions({
        qmrs: selectedQmrs.length ? selectedQmrs : [],
        alert,
        qmrsService,
    });

    const isNQRSearch = useMemo(() => {
        const currentSearchQuery = searchQuery?.rules as ConditionModel[];
        if (
            currentSearchQuery?.length &&
            currentSearchQuery.find((q) => q.fieldId && q.fieldId === 'NON_QUALITY_REPORT')?.operatorId === 'YES'
        ) {
            return true;
        }
        return false;
    }, [searchQuery]);

    const fetchData = useCallback(async () => {
        setIsLoading(true);

        const params = new URLSearchParams(search);

        params.set('page', '' + parsedPage);
        params.set('size', '' + parsedSize);

        try {
            if (!searchId) {
                throw new Error('No searchId found.');
            }

            const response = await qmrsService.getAdvancedSearchResultsForSearchId(searchId, params.toString());

            if (response.success) {
                const { results, savedSearch } = response.data;
                const qmrsWithCheckedProperty = results.qmrs.map((qmr) => {
                    const qmrWithCheckedProperty = Object.assign(qmr, { rowId: qmr.qmrId, checked: false });
                    return qmrWithCheckedProperty;
                });

                setSearchName(savedSearch.name);
                setSearchQuery(savedSearch.query);
                setPageStartCount(results.displayStartCountDescription);
                setPageEndCount(results.displayEndCountDescription);
                setPageTotalCount(results.totalCountDescription);
                setTotalEntires(results.totalCount);
                setQmrTableRows(qmrsWithCheckedProperty);
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error);
        }

        setIsLoading(false);
    }, [parsedPage, parsedSize, search, searchId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    function handleEditSearchClick() {
        toggleOverlay('advancedSearch');
    }

    async function handleSearchPanelApply(newQuery: GroupConditionModel) {
        try {
            const response = await qmrsService.updateAdvancedSearchQuery(searchId as string, newQuery);
            if (response.success) {
                dismissAllOverlays();

                const params = new URLSearchParams(search);
                params.set('page', String(0));
                navigate(`${pathname}?${params.toString()}`);

                fetchData();
            } else {
                throw response.data;
            }
        } catch (error) {
            window.alert(error.message);
        }
    }

    const handleSortChange = useCallback(
        (sortBy: string, sortDirection: SORT_DIRECTION) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('sortOrder', sortBy);
            params.set('sortDir', sortDirection);

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));
            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const renderTableActions = () => {
        if (!auth.account?.systemCapabilities.canExportQMRsAsCsv) return;

        return (
            <div className="d-flex justify-content-end">
                {qmrCsvExport.isLoading ? (
                    <div style={{ width: 200 }}>
                        <LoadingCircle />
                    </div>
                ) : (
                    <DropdownButton title="Table Actions" size="lg" variant="secondary" style={{ width: 200 }}>
                        <Dropdown.Item
                            as="button"
                            onClick={async () => {
                                qmrCsvExport.initiateCsvExportMutation.mutateAsync({
                                    qmrs: null,
                                    alert,
                                    searchId,
                                    isNQRSearch,
                                });
                            }}
                            className="d-flex align-items-center p-3"
                        >
                            <Icon name="download" color="blueOne" size={20} style={{ marginRight: 6 }} />
                            <Typography variant="default">Export All Reports as CSV</Typography>
                        </Dropdown.Item>
                    </DropdownButton>
                )}
            </div>
        );
    };

    return (
        <>
            {toggledOverlays.advancedSearch && searchQuery && (
                <AdvancedSearchPanel query={searchQuery} onApply={handleSearchPanelApply} />
            )}

            <AlertBar
                show={alert.isOpen}
                success={alert.type === 'success'}
                error={alert.type === 'error'}
                onClose={alert.close}
            >
                {alert.content}
            </AlertBar>

            <DetailView>
                <DetailViewHeader
                    breadcrumbs={[
                        {
                            to: isNQRSearch ? '/nqrs' : '/qmrs',
                            title: isNQRSearch ? 'NQRs' : 'QMRs',
                        },
                        {
                            to: '/qmrs/advanced-search/search',
                            title: 'Advanced Search',
                        },
                        {
                            to: '/qmrs/advanced-search/results',
                            title: 'Results',
                        },
                    ]}
                >
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <Typography variant="h2">{searchName}</Typography>
                            <Button variant="ghost-blue" onPress={handleEditSearchClick}>
                                Edit Search
                            </Button>
                        </div>

                        {renderTableActions()}
                    </div>
                </DetailViewHeader>
                <DetailViewContent topOffset="breadcrumb">
                    <DetailViewBody>
                        <Row>
                            <Col>
                                <TableRenderer<QmrTableRowModel>
                                    isLoading={isLoading}
                                    selectAll={selectAll}
                                    selectAllSetter={setSelectAll}
                                    tableRowsData={qmrTableRows}
                                    tableRowsDataSetter={setQmrTableRows}
                                    onSelectionChange={setSelectedQmrs}
                                    tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                                        return (
                                            <QmrTableHead
                                                sortOrder={parsedSortOrder}
                                                sortDirection={
                                                    parsedSortDirection ? (parsedSortDirection as SORT_DIRECTION) : null
                                                }
                                                selectedCount={selectedQmrs.length}
                                                selectAll={selectAllValue}
                                                onSelectAllChange={selectAllChangeHandler}
                                                onSort={handleSortChange}
                                                onActionPress={qmrTableActions.handleActionPress}
                                                showNQQmrs={isNQRSearch}
                                            />
                                        );
                                    }}
                                    tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                                        return (
                                            <QmrTableRow
                                                key={rowData.qmrId}
                                                qmr={rowData}
                                                checked={rowData.checked}
                                                onCheckboxChange={rowSelectHandler}
                                                listQueryParams={query.toString()}
                                                showNQQmrs={isNQRSearch}
                                            />
                                        );
                                    }}
                                />
                                <TablePagination
                                    page={parsedPage}
                                    size={parsedSize}
                                    total={totalEntries}
                                    disabled={isLoading}
                                    onClick={handlePaginationClick}
                                    onSizeChange={handlePaginationSizeChange}
                                    displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                                />
                            </Col>
                        </Row>
                    </DetailViewBody>
                </DetailViewContent>
            </DetailView>
        </>
    );
};

export default AdvancedSearchResults;
