import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AlertBar, Button, Icon, LoadingCircle, Typography, useAlert } from '@packages/ui/shared';
import { failCodesService } from '@web/services/singletons';
import { FailCode, FailCodes } from '@packages/models/api/fail-codes';
import { TableCell, TableHeader, TablePagination, TableRenderer, TableRow } from '@web/components/table';
import useQuery from '@web/core/hooks/use-query';
import colors from '@packages/core/styles/colors';
import { Form, Modal } from 'react-bootstrap';
import { useContentOverlayState } from '@web/core/hooks/use-content-overlay';
import { AddFailCodePanel } from '../components/add-fail-code-panel';
import { SuccessToaster } from './failCodeTeamsToaster/successToast';
import { FailCodeTeamsSearchBar } from '../components/fail-code-search-bar';
import { useTranslation } from 'react-i18next';
import { FailCodeTableRow } from '../components/fail-codes-teams-row';
import { FailCodeTableHead } from '../components/fail-codes-teams-head';
import { useFailCodesTableActions } from '../components/failCodeTeams.hooks';
import { FailCodeTableRenderer } from '@web/components/table/fail-code-table-renderer';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminHubHeader } from '../components/admin-hub/admin-hub-header';

export interface ProcessedFailCodes {
    team: string;
    failcodes: FailCode[];
    rowId: string;
    initialFailCodes: FailCode[];
}

export interface TeamFailCode {
    team: string;
    failcodes: FailCode[];
    rowId: string;
    initialFailCodes: FailCode[];
}

export const FailCodeTeams: FC = () => {
    const queryParams = useQuery();
    const elementRef = useRef<any>(null);
    const alert = useAlert();
    const { t } = useTranslation();
    const { toggledOverlays, toggleOverlay } = useContentOverlayState();
    const [quickSearchFailCodeQuery, setQuickSearchFailCodeQuery] = useState<string>(queryParams.get('failCode') || '');
    const [isLoading, setIsLoading] = useState(false);
    const [processedFailCodes, setProcessedFailCodes] = useState<ProcessedFailCodes[]>([]);
    const [missingProcessedFailCodes, setMissingProcessedFailCodes] = useState<ProcessedFailCodes[]>([]);
    const [searchProcessedFailCodes, setSearchProcessedFailCodes] = useState<ProcessedFailCodes[]>([]);
    const [searchProcessedMissingFailCode, setSearchProcessedMissingFailCode] = useState<ProcessedFailCodes[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedFailCodes, setSelectedFailCodes] = useState<FailCodes[]>([]);
    const [selectedTeamName, setSelectedTeamName] = useState<any>('');
    const [searchFailCode, setSearchFailCode] = useState<FailCodes[]>([]);
    const [missingFailCodes, setMissingFailCodes] = useState<FailCode[]>([]);
    const [failCode, setFailCode] = useState<string>('');
    const [deleteTeamFlag, setDeleteTeamFlag] = useState<boolean>(false);
    const [dataChange, setDataChange] = useState<boolean>(false);
    const [showMissingFailCodes, setShowMissingFailCodes] = useState<boolean>(false);
    const [selectedFailCode, setSelectedFailCode] = useState<ProcessedFailCodes>({
        team: '',
        failcodes: [],
        rowId: '',
        initialFailCodes: [],
    });
    const [showToast, setShowToast] = useState<{ show: boolean; message: string }>({ show: false, message: '' });
    const [selectedOption, setSelectedOption] = useState<FailCode>();
    const [selectedFailCodeSearch, setSelectedFailCodeSearch] = useState<FailCode>();
    const [searchFailCodeFlag, setSearchFailCodeFlag] = useState<boolean>(false);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);
    const [failCodes, setFailCodes] = useState<FailCode[]>([]);
    const [exportFailCodesWithTeams, setExportFailCodesWithTeams] = useState<FailCodes[]>([]);
    const [searchExportFailCode, setSearchExportFailCode] = useState<FailCodes[]>([]);
    const [failCodeDisplayCount, setFailCodeDisplayCount] = useState<number>(50);
    const [loaderLoading, setLoaderLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { pathname, search } = useLocation();

    const pageFromQuery = queryParams.get('page');
    const sizeFromQuery = queryParams.get('size');
    const parsedPage = pageFromQuery ? parseInt(pageFromQuery, 10) : 0;
    const parsedSize = sizeFromQuery ? parseInt(sizeFromQuery, 10) : 10;

    const [totalEntries, setTotalEntires] = useState(0);
    const [pageStartCount, setPageStartCount] = useState('0');
    const [pageEndCount, setPageEndCount] = useState('0');
    const [pageTotalCount, setPageTotalCount] = useState('0');

    const failCodesTableActions = useFailCodesTableActions({
        failCodes: selectedFailCodes.length ? selectedFailCodes : null,
        alert,
        failCodesService,
    });

    const processFailCodes = (failCodes: FailCode[], flag: boolean) => {
        const teamsMap: { [team: string]: FailCode[] } = {};
        const missing: FailCode[] = [];

        failCodes.forEach((failCode) => {
            if (failCode.grouping) {
                if (!teamsMap[failCode.grouping]) {
                    teamsMap[failCode.grouping] = [];
                }
                teamsMap[failCode.grouping].push({ failCode: failCode.failCode, description: failCode.description });
            } else {
                missing.push({ failCode: failCode.failCode, description: failCode.description });
            }
        });

        const teams: TeamFailCode[] = Object.entries(teamsMap).map(([team, failcodes]) => ({
            team,
            failcodes,
            rowId: team,
            initialFailCodes: failcodes.length > 5 ? failcodes.slice(0, 5) : failcodes,
        }));

        const missingTeams: TeamFailCode[] = missing.map((failCode, index) => ({
            team: 'missing Fail Codes',
            failcodes: [failCode],
            rowId: '1',
            initialFailCodes: [failCode],
        }));

        if (flag) {
            missing.length > 0 ? setSearchProcessedMissingFailCode(missingTeams) : setSearchProcessedFailCodes(teams);
            return;
        }

        setMissingFailCodes(missing);
        setMissingProcessedFailCodes([
            {
                team: 'missing Fail Codes',
                failcodes: missing,
                rowId: 'missing',
                initialFailCodes: missing.length > 5 ? missing.slice(0, 5) : missing,
            },
        ]);
    };

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await failCodesService.getFailCodeTeams({
                    ignoreCache: true,
                    queryParameters: {
                        page: parsedPage,
                        size: parsedSize,
                    },
                });

                if (response.success) {
                    const teams: TeamFailCode[] = response?.data?.data?.map((failCodeTeam) => ({
                        team: failCodeTeam.name,
                        failcodes: failCodeTeam.failCodes,
                        rowId: failCodeTeam.name,
                        initialFailCodes:
                            failCodeTeam?.failCodes?.length > 5
                                ? failCodeTeam?.failCodes?.slice(0, 5)
                                : failCodeTeam.failCodes,
                    }));

                    setProcessedFailCodes(teams);
                    setPageStartCount(response.data.displayStartCountDescription);
                    setPageEndCount(response.data.displayEndCountDescription);
                    setPageTotalCount(response.data.totalCountDescription);
                    setTotalEntires(response.data.totalCount);
                }
            } catch (error) {
                window.alert(`Fail Code Team Error: ${error.message}`);
            }
            setIsLoading(false);
            setDataChange(false);
        }
        fetchData();
        setSelectedOption(undefined);
        setSearchExportFailCode([]);
    }, [dataChange, parsedPage, parsedSize]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await failCodesService.getFailCode({
                    searchParams: {
                        failCode: quickSearchFailCodeQuery,
                    },
                    ignoreCache: true,
                });
                if (response.success) {
                    processFailCodes(response.data.failCodes, false);
                    setFailCodes(response.data.failCodes);
                    if (
                        quickSearchFailCodeQuery !== '' &&
                        response.data.failCodes.length > 0 &&
                        response.data.failCodes[0].grouping === selectedTeamName
                    ) {
                        setSearchFailCode([
                            {
                                failCode: quickSearchFailCodeQuery,
                                rowId: '1',
                                description: response.data.failCodes[0].description,
                            },
                        ]);
                        setSelectedTeamName(selectedTeamName);
                    }
                }
            } catch (error) {
                window.alert(`Fail Code Team Error: ${error.message}`);
            }
            setIsLoading(false);
            setDataChange(false);
        }
        fetchData();
    }, [quickSearchFailCodeQuery, dataChange]);

    useMemo(() => {
        const failCodesWithTeams: FailCodes[] = [];

        failCodes.forEach((failCode, index) => {
            failCodesWithTeams.push({
                failCode: failCode.failCode,
                rowId: `${index}`,
                description: failCode.description,
                grouping: failCode?.grouping ? failCode.grouping : 'missing Fail Codes',
                checked: false,
            });
        });

        setExportFailCodesWithTeams(failCodesWithTeams);
    }, [exportModalOpen]);

    useMemo(() => {
        if (selectedFailCodeSearch) {
            const rowId = exportFailCodesWithTeams.find(
                (failCode) => failCode.failCode === selectedFailCodeSearch.failCode
            )?.rowId;
            if (rowId) {
                let numberIndex: number = parseInt(rowId, 10);
                setSearchExportFailCode([
                    {
                        failCode: selectedFailCodeSearch.failCode,
                        description: selectedFailCodeSearch.description,
                        grouping: selectedFailCodeSearch.grouping,
                        rowId: rowId,
                        checked: exportFailCodesWithTeams[numberIndex].checked,
                    },
                ]);
            }
        }
    }, [selectedFailCodeSearch]);

    const handleSelectionChange = useCallback(
        (newSelectedFailCodes: FailCodes[]) => {
            setSelectedFailCodes((prevSelectedFailCodes) => {
                if (searchExportFailCode && searchExportFailCode.length > 0) {
                    if (newSelectedFailCodes.length === 0) {
                        const returnValue = selectedFailCodes.filter((failCode) => failCode.checked === true);
                        return returnValue;
                    } else {
                        prevSelectedFailCodes.push(searchExportFailCode[0]);
                        return prevSelectedFailCodes;
                    }
                } else {
                    return newSelectedFailCodes;
                }
            });
        },
        [searchExportFailCode, selectedFailCodes]
    );

    useEffect(() => {
        if (selectedFailCodes.length === 0) {
            setSelectAll(false);
        }
    }, [selectedFailCodes]);

    const openModal = (team: string, failcodes: FailCode[], failCode: ProcessedFailCodes) => {
        setSelectedTeamName(team);
        setSelectedFailCodes(
            failcodes.map((failcode, index) => ({
                failCode: failcode.failCode,
                description: failcode.description,
                rowId: `${index}`,
            }))
        );
        setModalOpen(true);
        setSelectedFailCode(failCode);
    };

    const handleClose = () => {
        setModalOpen(false);
        setSearchFailCode([]);
        setSelectedFailCodes([]);
        const url = new URL(window.location.href);
        url.searchParams.delete('failCode');
        window.history.replaceState(null, '', url.toString());
        setQuickSearchFailCodeQuery('');
        setSelectedTeamName('');
        setSelectedOption(undefined);
    };

    const handleCancelTeamDeleteModal = () => {
        setDeleteTeamFlag(false);
    };

    const handleDeleteFailCodeTeam = (team: string) => {
        setSelectedTeamName(team);
        setDeleteTeamFlag(true);
    };

    const handleDeleteTeam = (team: string) => {
        async function deleteTeam(team: string) {
            setIsLoading(true);
            try {
                const response = await failCodesService.deleteTeam({
                    deleteTeamParams: {
                        action: 'Delete',
                        team: team,
                    },
                    ignoreCache: true,
                });

                if (response.success) {
                    setShowToast({ show: true, message: 'Fail Code Team deleted Successfully' });
                    setDataChange(true);
                }
            } catch (error) {
                window.alert(`Fail Code Team Error: ${error.message}`);
            }
            setModalOpen(false);
            setIsLoading(false);
            setDeleteTeamFlag(false);
            setDataChange(true);
        }
        deleteTeam(team);
    };

    useEffect(() => {
        if (selectedOption && selectedOption.failCode && !searchFailCodeFlag) {
            processFailCodes([selectedOption], true);
        } else if (selectedOption && selectedOption.failCode && searchFailCodeFlag) {
            if (selectedOption && (selectedOption.grouping === selectedTeamName || !selectedOption.grouping)) {
                setSearchFailCode([
                    {
                        failCode: selectedOption.failCode,
                        rowId: '1',
                        description: selectedOption.description,
                    },
                ]);
                setSelectedTeamName(selectedTeamName);
            }
        } else {
            setSearchFailCode([]);
            setSearchFailCodeFlag(false);
            setSearchProcessedFailCodes([]);
            setSearchProcessedMissingFailCode([]);
            setShowMissingFailCodes(false);
        }
    }, [selectedOption]);

    const handleModalOpen = () => {
        setExportModalOpen(true);
    };

    const handleModalClose = () => {
        setSelectedFailCodes([]);
        setExportModalOpen(false);
        setSearchExportFailCode([]);
        setSelectedFailCodeSearch(undefined);
        setFailCodeDisplayCount(50);
    };

    const handleNextFailCodes = () => {
        setLoaderLoading(true);
        setFailCodeDisplayCount(failCodeDisplayCount + 50);
        setTimeout(() => {
            setLoaderLoading(false);
        }, 500);
    };

    const handlePaginationClick = useCallback(
        (index: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(index));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    const handlePaginationSizeChange = useCallback(
        (size: number) => {
            const params = new URLSearchParams(search);

            params.set('page', String(0));
            params.set('size', String(size));

            navigate(`${pathname}?${params.toString()}`);
        },
        [navigate, pathname, search]
    );

    function handleMissingFailCodesChange(event: React.ChangeEvent<HTMLInputElement>) {
        setShowMissingFailCodes(event.currentTarget.checked);
    }

    // This is the data that is being passed to the TableRenderer component
    let dataTable;
    if (searchProcessedMissingFailCode.length > 0) {
        dataTable = searchProcessedMissingFailCode;
    } else if (showMissingFailCodes) {
        dataTable = missingProcessedFailCodes;
    } else if (searchProcessedFailCodes.length > 0) {
        dataTable = searchProcessedFailCodes;
    } else {
        dataTable = processedFailCodes;
    }

    return (
        <>
            <AdminHubHeader
                breadcrumbs={[
                    {
                        title: t('fail-code:breadcrumbs.adminHub', 'Administration Hub'),
                        to: '/administration',
                    },
                    {
                        title: t('fail-code:breadcrumbs.failCodeTeams', 'Fail Code Teams'),
                    },
                ]}
            />

            <SuccessToaster
                show={showToast.show}
                onClose={() => setShowToast({ show: false, message: '' })}
                body={showToast.message}
                styles={{ backgroundColor: 'green' }}
                textColor="white"
            />

            {!isLoading && (
                <AlertBar
                    show={alert.isOpen}
                    success={alert.type === 'success'}
                    error={alert.type === 'error'}
                    onClose={alert.close}
                >
                    <Typography>{alert.content}</Typography>
                </AlertBar>
            )}

            {(toggledOverlays.addFailCode ||
                toggledOverlays.createFailCodeTeam ||
                toggledOverlays.editFailCodeTeam ||
                toggledOverlays.addFailCodeToTeam ||
                toggledOverlays.addMissingFailCode ||
                toggledOverlays.transferFailCode) && (
                <AddFailCodePanel
                    processedFailCodes={processedFailCodes}
                    missingFailCodes={missingFailCodes}
                    setShowToast={setShowToast}
                    selectedTeamName={selectedTeamName}
                    onDataChanged={() => setDataChange(true)}
                    setSelectedTeamName={setSelectedTeamName}
                    selectedFailCode={selectedFailCode}
                    setSelectedFailCode={setSelectedFailCode}
                    failcode={failCode}
                />
            )}
            <div className={`pt-7 pl-7 pr-7 d-flex`}>
                <div className="d-flex w-100 flex-column flex-wrap">
                    <div style={{ marginBottom: 8, display: 'flex', justifyContent: 'space-between', flex: 'wrap' }}>
                        <div className="w-50">
                            <FailCodeTeamsSearchBar
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                            />
                        </div>
                        <div style={{ display: 'flex', gap: '2vw', flex: 'wrap' }} data-testid="btn-add-fail-code">
                            <Button
                                iconLeft={<Icon name="plus" color="white" />}
                                onPress={() => {
                                    toggleOverlay('createFailCodeTeam');
                                }}
                            >
                                Add Fail Code Team
                            </Button>
                            <Button
                                iconLeft={<Icon name="plus" color="white" />}
                                onPress={() => {
                                    toggleOverlay('addFailCodeToTeam');
                                }}
                                data-testid="btn-add-fail-code"
                            >
                                Add Fail Code
                            </Button>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flex: 'wrap', marginTop: '10px', marginBottom: '5px', gap: '2vw' }}>
                        {searchProcessedFailCodes.length === 0 && searchProcessedMissingFailCode.length === 0 && (
                            <div className="mt-1">
                                <Form.Check
                                    label="Missing Fail Codes"
                                    type="checkbox"
                                    id="viewMatchingFailCodes"
                                    name="failCodeSearch"
                                    checked={showMissingFailCodes}
                                    onChange={handleMissingFailCodesChange}
                                />
                            </div>
                        )}

                        <div style={{ marginTop: '-2px' }}>
                            <Button
                                variant="ghost-blue"
                                iconLeft={<Icon name="download" color="blueOne" size={20} />}
                                onPress={handleModalOpen}
                            >
                                Export Fail Codes to CSV
                            </Button>
                        </div>
                    </div>

                    <TableRenderer<ProcessedFailCodes>
                        isLoading={isLoading}
                        tableRowsData={dataTable}
                        tableRowsDataSetter={setProcessedFailCodes}
                        selectAll={selectAll}
                        selectAllSetter={setSelectAll}
                        noResultsIconName="administration"
                        tableHeaderRowRenderer={() => {
                            return (
                                <TableRow>
                                    <TableHeader fixed hideBorder>
                                        <Typography variant="label">Fail Code Team</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">Fail Codes</Typography>
                                    </TableHeader>
                                    <TableHeader>
                                        <Typography variant="label">Actions</Typography>
                                    </TableHeader>
                                </TableRow>
                            );
                        }}
                        tableBodyRowRenderer={(rowData) => {
                            return (
                                <TableRow key={rowData.rowId} checked={rowData.checked}>
                                    <TableCell>
                                        <Typography>{rowData.team}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>
                                            {rowData.initialFailCodes.map((fc) => fc.failCode).join(', ')}
                                            {rowData.failcodes.length > 5 && (
                                                <span
                                                    style={{ color: colors.blueOne, cursor: 'pointer' }}
                                                    onClick={() => openModal(rowData.team, rowData.failcodes, rowData)}
                                                >
                                                    {` +${rowData.failcodes.length - 5} More`}
                                                </span>
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                                            <Button onPress={() => openModal(rowData.team, rowData.failcodes, rowData)}>
                                                Edit
                                            </Button>
                                            {!showMissingFailCodes && (
                                                <Button
                                                    variant="danger"
                                                    onPress={() => handleDeleteFailCodeTeam(rowData.team)}
                                                >
                                                    Delete
                                                </Button>
                                            )}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                    />
                    {!showMissingFailCodes && (
                        <TablePagination
                            page={parsedPage}
                            size={parsedSize}
                            total={totalEntries}
                            onClick={handlePaginationClick}
                            onSizeChange={handlePaginationSizeChange}
                            displayCount={`${pageStartCount}-${pageEndCount} of ${pageTotalCount}`}
                        />
                    )}
                </div>
            </div>

            <Modal ref={elementRef} show={exportModalOpen} onHide={handleModalClose} size="lg" centered style>
                <Modal.Header placeholder={'exportFailCodes'} closeButton>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Modal.Title id="contained-modal-title-vcenter">Export Fail Codes to CSV</Modal.Title>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <>
                        <div style={{ marginBottom: '8px' }}>
                            <FailCodeTeamsSearchBar
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedOption={selectedFailCodeSearch}
                                setSelectedOption={setSelectedFailCodeSearch}
                                searchExportFailCode={searchExportFailCode}
                                setSearchExportFailCode={setSearchExportFailCode}
                            />
                        </div>

                        <FailCodeTableRenderer<FailCodes>
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            selectAll={selectAll}
                            selectAllSetter={setSelectAll}
                            exportFailCodesWithTeams={exportFailCodesWithTeams}
                            exportFailCodeWithTeamsSetter={setExportFailCodesWithTeams}
                            selectedFailCodeSearch={selectedFailCodeSearch}
                            tableRowsData={
                                selectedFailCodeSearch
                                    ? searchExportFailCode
                                    : exportFailCodesWithTeams.slice(0, failCodeDisplayCount)
                            }
                            tableRowsDataSetter={
                                selectedFailCodeSearch ? setSearchExportFailCode : setExportFailCodesWithTeams
                            }
                            noResultsIconName="administration"
                            onSelectionChange={handleSelectionChange}
                            selectedFailCodes={selectedFailCodes}
                            setSelectedFailCodes={setSelectedFailCodes}
                            setSearchExportFailCode={setSearchExportFailCode}
                            tableHeaderRowRenderer={(selectAllValue, selectAllChangeHandler) => {
                                return (
                                    <FailCodeTableHead
                                        setIsLoading={setIsLoading}
                                        handleModalClose={handleModalClose}
                                        selectedCount={selectedFailCodes.length}
                                        selectedItems={selectedFailCodes}
                                        selectAll={selectAllValue}
                                        onSelectAllChange={selectAllChangeHandler}
                                        onActionPress={failCodesTableActions.handleActionPress}
                                        selectedFailCodeSearch={selectedFailCodeSearch}
                                    />
                                );
                            }}
                            tableBodyRowRenderer={(rowData, rowSelectHandler) => {
                                return (
                                    <FailCodeTableRow
                                        key={rowData.rowId}
                                        rowId={rowData.rowId}
                                        failCode={rowData}
                                        checked={rowData?.checked as boolean}
                                        onCheckboxChange={rowSelectHandler}
                                        listQueryParams={queryParams.toString()}
                                    />
                                );
                            }}
                        />

                        <div style={{ marginTop: '10px', maxWidth: '16%' }}>
                            <Button style={{ marginRight: 10 }} onPress={handleNextFailCodes}>
                                {loaderLoading ? <LoadingCircle /> : 'Load More'}
                            </Button>
                        </div>
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <Button onPress={handleModalClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalOpen} onHide={handleClose} size="lg" centered style>
                <Modal.Header placeholder={'allFailCodes'} closeButton>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                        }}
                    >
                        <Modal.Title id="contained-modal-title-vcenter">{`All Fail Codes in ${selectedTeamName}`}</Modal.Title>
                        <div style={{ marginRight: '3vw', display: 'flex', gap: '2vw' }}>
                            <Button
                                iconLeft={<Icon name="plus" color="white" />}
                                onPress={() => {
                                    showMissingFailCodes
                                        ? toggleOverlay('addMissingFailCode')
                                        : toggleOverlay('addFailCode');
                                    setModalOpen(false);
                                }}
                            >
                                Add Fail Code
                            </Button>
                            {!showMissingFailCodes && (
                                <Button
                                    iconLeft={<Icon name="pencil" color="white" />}
                                    onPress={() => {
                                        toggleOverlay('editFailCodeTeam');
                                        setModalOpen(false);
                                    }}
                                >
                                    Edit Name
                                </Button>
                            )}
                        </div>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <>
                        <div style={{ marginBottom: '8px' }}>
                            <FailCodeTeamsSearchBar
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                selectedOption={selectedOption}
                                setSelectedOption={setSelectedOption}
                                selectedTeamName={selectedTeamName}
                                setSearchFailCodeFlag={setSearchFailCodeFlag}
                            />
                        </div>

                        <TableRenderer<FailCodes>
                            tableRowsData={
                                selectedFailCodes && searchFailCode.length === 0 ? selectedFailCodes : searchFailCode
                            }
                            tableRowsDataSetter={setSelectedFailCodes}
                            noResultsIconName="administration"
                            tableHeaderRowRenderer={() => {
                                return (
                                    <TableRow>
                                        <TableHeader fixed hideBorder width={'calc(30vh)'}>
                                            <Typography variant="label">Fail Code</Typography>
                                        </TableHeader>
                                        <TableHeader>
                                            <Typography variant="label">Description</Typography>
                                        </TableHeader>
                                        <TableHeader>
                                            <Typography variant="label">Action</Typography>
                                        </TableHeader>
                                    </TableRow>
                                );
                            }}
                            tableBodyRowRenderer={(failCode) => {
                                return (
                                    <TableRow key={failCode.rowId}>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{failCode.failCode}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography numberOfLines={1}>{failCode.description}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="transfer"
                                                onPress={() => {
                                                    toggleOverlay('transferFailCode');
                                                    setModalOpen(false);
                                                    setFailCode(failCode.failCode);
                                                }}
                                            >
                                                {t('Move')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            }}
                        />
                    </>
                </Modal.Body>

                <Modal.Footer>
                    <Button onPress={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={deleteTeamFlag} onHide={handleCancelTeamDeleteModal} size="sm">
                <Modal.Header placeholder={'confirmDelete'} style={{ background: colors.grayThree }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Modal.Title>{`Confirm Delete`}</Modal.Title>
                    </div>
                </Modal.Header>
                <div style={{ padding: '10px' }}>
                    <Modal.Body>
                        <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {deleteTeamFlag
                                ? `Are you sure you want to delete the Fail Code Team "${selectedTeamName}"`
                                : `Are you sure you want to delete ${failCode} from ${selectedTeamName}`}
                        </Typography>
                    </Modal.Body>
                    <div className="d-flex justify-content-end">
                        <Button variant="ghost-blue" onPress={handleCancelTeamDeleteModal}>
                            Cancel
                        </Button>
                        <Button
                            variant="danger"
                            style={{ marginLeft: 8 }}
                            onPress={() => {
                                handleDeleteTeam(selectedTeamName);
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
