import React, { useState } from 'react';
import colors from '@packages/core/styles/colors';
import { pendingReviewQmrsService } from '@web/services/singletons';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { PendingReviewQmrsResponse } from '@packages/contexts/qmrsdashboard';
import { useNavigate } from 'react-router-dom';
import { DimensionValue } from 'react-native';
import { analyticsService } from '@web/services/singletons';
import { ANALYTICS_EVENTS } from '@packages/core/analytics';

const styles = StyleSheet.create({
    section: {
        paddingVertical: 12,
        //paddingRight: 20,
    },
    buttonOuter: {
        alignItems: 'flex-start',
    },
    buttonRowText: {
        width: 'calc(100% - 40px)' as DimensionValue,
        gap: 4,
    },
    button: {
        textDecorationLine: 'none',
        height: 40,
        width: 40,
        borderRadius: 4,
        padding: 8,
    },
    viewall: {
        textDecorationLine: 'none',
        height: 40,
        borderRadius: 4,
        padding: 8,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        paddingBottom: 8,
        gap: 4,
    },
    rowheader: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 8,
        paddingBottom: 8,
        gap: 4,
        justifyContent: 'space-between',
    },
    divider: {
        borderBottomWidth: 1,
        borderBottomColor: colors.grayFour,
    },
    countBadge: {
        height: 25,
        width: 25,
        backgroundColor: colors.redOne,
        borderRadius: '50%' as unknown as number,
        fontSize: 12,
        padding: 1,
        textAlign: 'center',
        color: colors.white,
        fontWeight: '600',
        translateX: -6,
    },
});

const useLoadingBarStyle = createUseStyles({
    '@keyframes barWidth': {
        '0%': { width: '0%' },
        '10%': { width: '10%' },
        '25%': { width: '25%' },
        '50%': { width: '100%' },
        '75%': { width: '40%' },
        '100%': { width: '0%' },
    },

    horizontalbarwrap: {
        height: 20,
        width: '100%',
    },
    bar: {
        position: 'relative',
        width: '0%',
        height: 20,
        animationName: '$barWidth',
        'animation-duration': '3s',
        'animation-iteration-count': 'infinite',
        'animation-timing-function': 'linear',
        '&.bar1': {
            'animation-delay': '0s',
            background: colors.grayOne,
            top: 0,
        },
    },
});

const LoadingBar = () => {
    const classes = useLoadingBarStyle();

    return (
        <div className={classes.horizontalbarwrap}>
            <div className={`${classes.bar} bar1`}></div>
        </div>
    );
};
const QmrsDashboardPendingReviewList: React.FC<{
    title?: string;
    viewAllLink?: string;
    districtId?: string;
    isNQR?: boolean;
}> = ({ title = 'Pending Review QMRs', viewAllLink, districtId, isNQR }) => {
    const [pendingReviewQmrsList, setPendingReviewQmrsList] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
    const navigate = useNavigate();
    const asyncPendingReviewFetch = async () => {
        try {
            const pendingReviewListRresponse = await pendingReviewQmrsService.fetchPendingReviewQmrs({ isNQR });
            if (pendingReviewListRresponse.success && pendingReviewListRresponse.data) {
                const successResponse: PendingReviewQmrsResponse = pendingReviewListRresponse.data;
                const districtSpecificPendingReviews = (
                    successResponse.pendingReviewQmrs ||
                    successResponse.pendingReviewNqrs ||
                    []
                ).filter((qmr) => qmr.districtId == districtId);
                setPendingReviewQmrsList(districtSpecificPendingReviews);
                setLoading(false);
            } else throw pendingReviewListRresponse.data;
        } catch (err) {
            if ('AUTHORIZATION_REQUIRED' !== err.code) {
                alert(`${err.code}, ${err.message}`);
            } else {
                setLoading(false);
                setErrorMessage(err.code);
            }
        }
    };
    //load when loading
    React.useEffect(() => {
        setLoading(true);
        asyncPendingReviewFetch();
    }, [districtId]);
    const { t } = useTranslation();
    return (
        <>
            {errorMessage === undefined && (
                <View>
                    <View style={styles.section}>
                        <View style={styles.rowheader}>
                            <Typography variant="caption3">
                                {isNQR ? 'Pending Review NQRs' : t(title || 'Pending Review QMRs')}
                            </Typography>
                            <span style={styles.countBadge}>{pendingReviewQmrsList?.length}</span>
                        </View>
                        {loading === true &&
                            Array.from(Array(5).keys()).map((key) => (
                                <>
                                    <View style={styles.row}>
                                        <View style={styles.buttonRowText}>
                                            <LoadingBar />
                                            <LoadingBar />
                                            <LoadingBar />
                                        </View>
                                    </View>
                                </>
                            ))}
                        {loading === false &&
                            pendingReviewQmrsList?.slice(0, 5).map((qmrs) => (
                                <>
                                    <TouchableOpacity
                                        onPress={() => {
                                            analyticsService.logEvent(
                                                ANALYTICS_EVENTS.QMRDASH_USER_CLICKED_TO_REVIEW_QMR,
                                                {
                                                    QMR_ID: qmrs.stsIdentifier,
                                                }
                                            );
                                            window.open(qmrs.qmrLink, '_blank');
                                        }}
                                    >
                                        <View style={styles.row}>
                                            <View style={styles.buttonRowText}>
                                                <Typography variant="caption3">{qmrs.stsIdentifier}</Typography>
                                                <Typography variant="caption">{qmrs.vin}</Typography>
                                                <Typography variant="caption">{qmrs.name}</Typography>
                                            </View>
                                            <View style={styles.buttonOuter}>
                                                <Button
                                                    variant="link"
                                                    onPress={() => {
                                                        window.open(qmrs.qmrLink, '_blank');
                                                    }}
                                                    style={styles.button}
                                                >
                                                    <Icon size={16} name="chevron-right" />
                                                </Button>
                                            </View>
                                        </View>
                                    </TouchableOpacity>
                                </>
                            ))}
                        {loading === false && pendingReviewQmrsList && pendingReviewQmrsList.length > 5 && (
                            <View style={styles.row}>
                                <View style={styles.buttonOuter}>
                                    <Button
                                        variant="link"
                                        onPress={() => {
                                            if (isNQR) {
                                                window.open(
                                                    `${window.location.origin}/nqrs?retailer=RETAILER_ONLY&retailer=MY_DISTRICT&selectedDistrict=${districtId}`,
                                                    '_blank'
                                                );
                                            } else {
                                                window.open(
                                                    `${window.location.origin}/qmrs?retailer=RETAILER_ONLY&retailer=MY_DISTRICT&dsqmStatusId=PENDING_REVIEW&selectedDistrict=${districtId}`,
                                                    '_blank'
                                                );
                                            }
                                        }}
                                        style={styles.viewall}
                                    >
                                        <Typography variant="label">{'Review All Pending'}</Typography>
                                    </Button>
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            )}
        </>
    );
};

export default QmrsDashboardPendingReviewList;
