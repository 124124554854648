import React, { FC } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import subaruLogo from '@packages/ui/assets/images/subaru-logo.jpg';
import { Button } from '@packages/ui/shared';
import { handleLogin } from '../utils';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from '@packages/contexts/auth';

const useHeaderTechShareLandingPageStyles = createUseStyles({
    navBarContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${colors.grayThree}`,
    },
    logo: {
        maxHeight: 48,
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        marginBottom: 5,
        marginRight: 44,
        flexDirection: 'row',
    },
});

interface HeaderProps {}

export const Header: FC<HeaderProps> = (props) => {
    const classes = useHeaderTechShareLandingPageStyles();
    const navigate = useNavigate();
    const { showUnauthorizedPrompt } = useAuthState();

    return (
        <Navbar expand="lg" className={classes.navBarContainer}>
            <Navbar.Brand href="#" className="ml-8">
                <img src={subaruLogo} alt="Subaru Logo" className={classes.logo} />
            </Navbar.Brand>

            <Nav className={classes.buttonContainer}>
                <div style={{ border: `1px solid ${colors.blueOne}`, borderRadius: 4 }}>
                    <Button title={'Create account'} variant="ghost-blue" />
                </div>
                <Button title={'Sign in'} onPress={() => handleLogin(showUnauthorizedPrompt, navigate)} />
            </Nav>
        </Navbar>
    );
};
