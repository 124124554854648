import React, { FC, useCallback, useMemo } from 'react';
import { BmisBatteryBadge } from '@web/bmis/components/bmis-battery-badge';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { bmisActions, useBmisDispatch, useBmisState } from '@packages/contexts/bmis';
import { getDisplayDate } from '@web/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { DATE_FORMAT } from '@web/config/constants';
import { StyleSheet } from 'react-native';
import { isLocal } from '@web/utils/is-dev-environment';

const styles = StyleSheet.create({
    typographyStyles: {
        fontWeight: '600',
        fontSize: 21,
        lineHeight: 32,
    },
});

export const BmisHeader: FC = () => {
    const bmisDispatch = useBmisDispatch();
    const navigate = useNavigate();
    const { vin: vinString } = useParams<{ vin: string }>();
    const { selectedBmisRow } = useBmisState();

    const formattedServiceDate = useMemo(
        () => getDisplayDate(selectedBmisRow?.serviceDateandTime, DATE_FORMAT.MON_DAY_YEAR),
        [selectedBmisRow?.serviceDateandTime]
    );

    const handleButtonPress = useCallback(() => {
        if (window.history.state && window.history.length > 1) {
            navigate(-1);
        } else {
            navigate(`/vin/${vinString}`);
        }
        bmisDispatch(bmisActions.setRowGuid({ rowGuid: null }));
    }, [bmisDispatch, navigate, vinString]);

    return (
        <>
            <div className="d-flex align-items-center">
                <div className="mr-4">
                    <Button variant="link" onPress={handleButtonPress}>
                        <Icon name="arrow-back" />
                    </Button>
                </div>
                <div className="mb-0 mr-4">
                    <Typography variant="h3" style={styles.typographyStyles}>
                        {formattedServiceDate}
                    </Typography>
                </div>
                <BmisBatteryBadge label={selectedBmisRow?.finalBatteryTestDecision} />
            </div>
            {isLocal() && (
                <div className="d-flex align-items-center">
                    <div className="ml-6">
                        <Button variant="link-header-button">
                            <Icon name="sync-down" color="blueOne" />
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
