import React, { FC, useCallback, useMemo } from 'react';
import { AdminHubHeader } from '../components/admin-hub/admin-hub-header';
import { AdminHubSection } from '../components/admin-hub/admin-hub-section';
import { CardProps } from '../components/admin-hub/data-card';
import { LoadingCircle } from '@packages/ui/shared';
import { useAuthState } from '@packages/contexts/auth';
import { usePermissionsState } from '@packages/contexts/permissions';
import { useTranslation } from 'react-i18next';
import { isDevEnvironment } from '@web/utils/is-dev-environment';

type Section = {
    title?: string;
    cards: Array<CardProps & { show?: boolean }>;
};

export const AdminHub: FC = () => {
    const { t } = useTranslation();
    const { account } = useAuthState();
    const { permissions, loading } = usePermissionsState();

    const hasPermission = useCallback(
        (ids: string[]) => permissions?.some((p: any) => ids.includes(p.permissionId)),
        [permissions]
    );
    const showFailCodeTeamDashboard = useMemo(() => hasPermission(['ADM003', 'ADM012']), [hasPermission]);
    const showContentSetting = useMemo(() => hasPermission(['DASHBOARD002']), [hasPermission]);

    const rawSections: Section[] = useMemo(
        () => [
            {
                title: t('administration:sections.contentManagement.title', 'Content Management'),
                cards: [
                    {
                        title: t(
                            'administration:sections.contentManagement.cards.techlineWorksheets.title',
                            'Techline Worksheets'
                        ),
                        description: t(
                            'administration:sections.contentManagement.cards.techlineWorksheets.description',
                            'Access and edit case type worksheets.'
                        ),
                        buttonText: t(
                            'administration:sections.contentManagement.cards.techlineWorksheets.buttonText',
                            'Manage Worksheets'
                        ),
                        buttonLink: '/administration/worksheets',
                        show: account?.techlineCapabilities.canCreateWorkSheetsTechlineCase,
                    },
                    {
                        title: t('administration:sections.contentManagement.cards.feedback.title', 'Feedback'),
                        description: t(
                            'administration:sections.contentManagement.cards.feedback.description',
                            'Create feedbacks for Entities, QMRs, Users.'
                        ),
                        buttonText: t(
                            'administration:sections.contentManagement.cards.feedback.buttonText',
                            'Manage Feedback'
                        ),
                        buttonLink: '/administration/feedback',
                        show: account?.systemCapabilities.manageFeedbackForms,
                    },
                    {
                        title: t(
                            'administration:sections.contentManagement.cards.contentSetting.title',
                            'Content Settings'
                        ),
                        description: t(
                            'administration:sections.contentManagement.cards.contentSetting.description',
                            'Update and manage page contents.'
                        ),
                        buttonText: t(
                            'administration:sections.contentManagement.cards.contentSetting.buttonText',
                            'Manage content'
                        ),
                        buttonLink: '/administration/content-settings',
                        show: showContentSetting,
                    },
                ],
            },
            {
                title: t('administration:sections.dataResourceManagement.title', 'Data & Resources Management'),
                cards: [
                    {
                        title: t('administration:sections.other.cards.failCode.title', 'Fail Codes Teams'),
                        description: t(
                            'administration:sections.other.cards.failCode.description',
                            'Add and group fail codes into teams.'
                        ),
                        buttonText: t('administration:sections.other.cards.failCode.buttonText', 'Manage Fail Codes'),
                        buttonLink: '/administration/fail-code-teams',
                        show: showFailCodeTeamDashboard,
                    },
                    {
                        title: t(
                            'administration:sections.other.cards.toughbookAttachments.title',
                            'Toughbook Attachments'
                        ),
                        description: t(
                            'administration:sections.other.cards.toughbookAttachments.description',
                            'Access all attachments and their reports.'
                        ),
                        buttonText: t(
                            'administration:sections.other.cards.toughbookAttachments.buttonText',
                            'View Toughbook Attachments'
                        ),
                        buttonLink: '/administration/toughbook-attachments',
                        show: account?.systemCapabilities.viewToughbookAttachments,
                    },
                ],
            },
            {
                title: t('administration:sections.accessManagement.title', 'Access Management'),
                cards: [
                    {
                        title: t('administration:sections.accessManagement.cards.retailers.title', 'Retailers'),
                        description: t(
                            'administration:sections.accessManagement.cards.retailers.description',
                            'Retailer Fail Code Settings Management.'
                        ),
                        buttonText: t(
                            'administration:sections.accessManagement.cards.retailers.buttonText',
                            'Manage Retailers'
                        ),
                        buttonLink: '/administration/retailers',
                        show: account?.systemCapabilities.canViewFailCodeApprover,
                    },
                    {
                        title: t('administration:sections.accessManagement.cards.entities.title', 'Entities'),
                        description: t(
                            'administration:sections.accessManagement.cards.entities.description',
                            'Add entities, manage their details and statuses.'
                        ),
                        buttonText: t(
                            'administration:sections.accessManagement.cards.entities.buttonText',
                            'Manage Entities'
                        ),
                        buttonLink: '/administration/entities',
                        show: account?.systemCapabilities.manageFeedbackEntities,
                    },
                    {
                        title: t('administration:sections.accessManagement.cards.users.title', 'Users'),
                        description: t(
                            'administration:sections.accessManagement.cards.users.description',
                            'Add users, assign roles and manage access.'
                        ),
                        buttonText: t(
                            'administration:sections.accessManagement.cards.users.buttonText',
                            'Manage Users'
                        ),
                        buttonLink: '/administration/users',
                        show: account?.systemCapabilities.canEditUserPermissions,
                    },
                ],
            },
            {
                title: t('administration:sections.other.title', 'Other'),
                cards: [
                    {
                        title: t('administration:sections.other.cards.synonyms.title', 'Search Synonyms'),
                        description: t(
                            'administration:sections.other.cards.synonyms.description',
                            'Create synonyms to enhance the search engine.'
                        ),
                        buttonText: t('administration:sections.other.cards.synonyms.buttonText', 'Manage content'),
                        buttonLink: '/administration/synonyms',
                        show: isDevEnvironment(),
                    },
                ],
            },
        ],
        [t, showContentSetting, showFailCodeTeamDashboard, account]
    );

    const filteredSections = useMemo(
        () =>
            rawSections
                .map((section) => ({
                    ...section,
                    cards: section.cards.filter((card) => card.show),
                }))
                .filter((section) => section.cards.length > 0),
        [rawSections]
    );

    return (
        <>
            <AdminHubHeader breadcrumbs={[{ title: t('administration:breadcrumbs.adminHub', 'Administration Hub') }]} />
            <div className="container-fluid pl-6 pr-6">
                {!loading ? (
                    filteredSections.map((section, index) => (
                        <AdminHubSection key={index} title={section.title} cards={section.cards} />
                    ))
                ) : (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <LoadingCircle size={64} />
                    </div>
                )}
            </div>
        </>
    );
};
