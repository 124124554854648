import React, { FC, ReactNode, useMemo, useCallback, useState } from 'react';
import { Icon, StsIconName, Typography } from '@packages/ui/shared';
import { createUseStyles } from 'react-jss';
import { StyleSheet } from 'react-native';
import colors from '@packages/core/styles/colors';

const useStyles = createUseStyles({
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '16px',
    },
    itemsPerPageWrapper: {
        position: 'relative',
        width: '136px',
    },
    itemsPerPage: {
        appearance: 'none',
        width: '100%',
        padding: '8px',
        border: '1px solid',
        borderColor: colors.blueOne,
        borderRadius: '4px',
        cursor: 'pointer',
        color: '#1f518f',
        fontSize: '15px',
        fontWeight: '500',
        backgroundColor: 'white',
        position: 'relative',
        zIndex: 2,
    },
    customIcon: {
        position: 'absolute',
        top: '50%',
        right: '4px',
        transform: 'translateY(-40%)',
        pointerEvents: 'none',
        zIndex: 99,
    },
    pageDropdown: {
        position: 'absolute',
        bottom: '100%',
        left: 0,
        width: '80%',
        maxHeight: '200px',
        overflowY: 'auto',
        border: '1px solid',
        borderColor: colors.blueOne,
        borderRadius: '4px',
        backgroundColor: 'white',
        zIndex: 10,

        '&::-webkit-scrollbar': {
            display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
    },

    dropdownItem: {
        padding: '8px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
    },
});

const styles = StyleSheet.create({
    currentPage: {
        paddingHorizontal: 16,
        paddingVertical: 12,
        borderRadius: 4,
        marginRight: 12,
        backgroundColor: colors.blueOne,
        cursor: 'pointer',
    },
});

interface PaginationProps {
    currentPage: number;
    pages: number;
    itemsPerPage: number;
    itemsPerPageOptions: number[];
    onPageChange?: (page: number) => void;
    onItemsPerPageChange: (itemsPerPage: number) => void;
    className?: string;
    style?: React.CSSProperties;
    customRenderPage?: (currentPage: number, onPageChange: (page: number) => void) => ReactNode;
    customRenderItemsPerPage?: (
        itemsPerPage: number,
        options: number[],
        onChange: (itemsPerPage: number) => void
    ) => ReactNode;
    selectIcon?: StsIconName;
}

export const Pagination: FC<PaginationProps> = React.memo(
    ({
        currentPage,
        pages,
        itemsPerPage,
        itemsPerPageOptions,
        onPageChange,
        onItemsPerPageChange,
        className,
        style,
        customRenderPage,
        customRenderItemsPerPage,
        selectIcon = 'chevron-down',
    }) => {
        const classes = useStyles();
        const [showPageDropdown, setShowPageDropdown] = useState(false);

        const options = useMemo(
            () =>
                itemsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                        {option} per page
                    </option>
                )),
            [itemsPerPageOptions]
        );

        const pageOptions = useMemo(
            () =>
                Array.from({ length: pages }, (_, i) => i + 1).map((page) => (
                    <div
                        key={page}
                        className={classes.dropdownItem}
                        onClick={() => {
                            setShowPageDropdown(false);
                            onPageChange?.(page);
                        }}
                    >
                        {page}
                    </div>
                )),
            [pages, onPageChange, classes.dropdownItem]
        );

        const handleItemsPerPageChange = useCallback(
            (e: React.ChangeEvent<HTMLSelectElement>) => {
                onItemsPerPageChange(Number(e.target.value));
            },
            [onItemsPerPageChange]
        );

        return (
            <div className={`${classes.pagination} ${className || ''}`} style={style}>
                {customRenderPage ? (
                    customRenderPage(currentPage, (page) => onPageChange?.(page))
                ) : (
                    <div style={{ position: 'relative' }}>
                        <Typography
                            style={styles.currentPage}
                            color="white"
                            onPress={() => setShowPageDropdown((prev) => !prev)}
                        >
                            {currentPage}
                        </Typography>
                        {showPageDropdown && <div className={classes.pageDropdown}>{pageOptions}</div>}
                    </div>
                )}

                {customRenderItemsPerPage ? (
                    customRenderItemsPerPage(itemsPerPage, itemsPerPageOptions, onItemsPerPageChange)
                ) : (
                    <div className={classes.itemsPerPageWrapper}>
                        <select
                            className={classes.itemsPerPage}
                            value={itemsPerPage}
                            onChange={handleItemsPerPageChange}
                        >
                            {options}
                        </select>
                        <div className={classes.customIcon}>{<Icon name={selectIcon} color="blueOne" />}</div>
                    </div>
                )}
            </div>
        );
    }
);

Pagination.displayName = 'Pagination';
