import React, { FC, useEffect, useState, useCallback } from 'react';
import { Bmis } from '@packages/models/api';
import { BmisBatteryBadge } from '@web/bmis/components/bmis-battery-badge';
import { BmisLayout } from '@web/bmis/components/bmis-layout';
import { Icon, Typography } from '@packages/ui/shared';
import { DATE_FORMAT } from '@web/config/constants';
import { Link, useParams } from 'react-router-dom';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@web/components/table';
import { bmisService } from '@web/services/singletons';
import { setVin, useBmisDispatch, useBmisState } from '@packages/contexts/bmis';
import { useTranslation } from 'react-i18next';
import { formatToTitleCase, getDisplayDate, getDisplayValue } from '@web/utils';
import { createUseStyles } from 'react-jss';
import { isLocal } from '@web/utils/is-dev-environment';

interface BmisTableRowModel extends Bmis {
    rowId: string;
    checked: boolean;
}

const BMIS_TITLE = 'BMIS - Battery tests';
const EMPTY_STATE_DESC = 'No BMIS test available for this vehicle yet.';

const useBmisListStyles = createUseStyles({
    tableContainer: {
        minHeight: '50vh',
    },
});

const BmisTableHeader: FC = () => {
    const { t } = useTranslation();

    return (
        <TableRow>
            {['Latest Battery Test Decision', 'Battery Service Date', 'Test Type', 'Voltage', 'Tool Type'].map(
                (header) => (
                    <TableHeader key={header}>
                        <Typography variant="label">{t(header)}</Typography>
                    </TableHeader>
                )
            )}
        </TableRow>
    );
};

const BmisTableRow: FC<{ rowData: BmisTableRowModel }> = ({ rowData }) => {
    const { rowId, finalBatteryTestDecision, serviceDateandTime, testType, voltage, toolType } = rowData;

    return (
        <TableRow key={rowId}>
            <TableCell>
                <Typography numberOfLines={1}>
                    <BmisBatteryBadge label={getDisplayValue(finalBatteryTestDecision)} />
                </Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>
                    <Link to={`./${rowId}`}>{getDisplayDate(serviceDateandTime, DATE_FORMAT.MON_DAY_YEAR)}</Link>
                </Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>{getDisplayValue(formatToTitleCase(testType))}</Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>{getDisplayValue(voltage?.toFixed(1), 'v')}</Typography>
            </TableCell>
            <TableCell>
                <Typography numberOfLines={1}>{getDisplayValue(toolType)}</Typography>
            </TableCell>
        </TableRow>
    );
};

export const BmisList: FC = () => {
    const [bmisTableRows, setBmisTableRows] = useState<BmisTableRowModel[]>([]);
    const classes = useBmisListStyles();

    const bmisDispatch = useBmisDispatch();
    const { bmisData, loading: isLoading } = useBmisState();
    const { t } = useTranslation();
    const { vin: vinString } = useParams<{ vin: string }>();

    useEffect(() => {
        setVin(bmisDispatch, bmisService, { vin: vinString });
    }, [bmisDispatch, vinString]);

    useEffect(() => {
        if (bmisData) {
            setBmisTableRows(
                bmisData.map((item) => ({
                    ...item,
                    rowId: item.rowGuid,
                    checked: false,
                }))
            );
        }
    }, [bmisData]);

    const renderHeader = useCallback(() => <BmisTableHeader />, []);
    const renderRow = useCallback((rowData: BmisTableRowModel) => <BmisTableRow rowData={rowData} />, []);

    return (
        <BmisLayout>
            <div className={`pt-7 pl-7 pr-7 d-flex ${classes.tableContainer}`}>
                <div className="d-flex w-100 flex-column">
                    <header className="mb-6 d-flex align-items-center">
                        <Typography variant="h2">{t(BMIS_TITLE)}</Typography>
                        {isLocal() && <Icon name="info" style={{ marginLeft: 15 }} />}
                    </header>

                    <TableRenderer<BmisTableRowModel>
                        isLoading={isLoading}
                        tableRowsData={bmisTableRows}
                        tableRowsDataSetter={setBmisTableRows}
                        tableHeaderRowRenderer={renderHeader}
                        emptyStateProps={{ description: t(EMPTY_STATE_DESC) }}
                        tableBodyRowRenderer={renderRow}
                    />
                </div>
            </div>
        </BmisLayout>
    );
};
