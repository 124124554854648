import React, { FC } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { useVinSessionDispatch, useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { vinSessionActions } from '@web/vin-sessions/context/vin-sessions.state';
import { createUseStyles } from 'react-jss';
import { Spinner } from 'react-bootstrap';
import { VinSessionCarIcon } from '@web/vin-sessions/assets/Icons';
import { useAuthState } from '@packages/contexts/auth/auth.hooks';
import { closeVinSession } from '../utils/vinSessionUtils';

type VinButtonProps = {
    setVinSessionModal: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = createUseStyles({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvent: 'none',
    },
    vinSessionActive: {
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 600,
        fontFamily: 'Roboto',
        marginTop: '4px',
    },
    vinSessionInActive: {
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 600,
        fontFamily: 'Roboto',
        marginTop: '2px',
    },
    vinActiveButton: {
        width: 40,
        height: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeftWidth: 1,
        borderLeftStyle: 'solid',
        borderLeftColor: '#DEE6EF',
    },
    vinLoaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    vinLoader: {
        color: '#FFFFFF',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 600,
        fontFamily: 'Roboto',
        marginTop: '2px',
    },
});

export const VinSessionButton: FC<VinButtonProps> = (props) => {
    const { account } = useAuthState();
    const { setVinSessionModal, isLoading, setIsLoading } = props;
    const isVinSessionActive = useMatch('/vin/*');
    const { isVinLoading, selectedVin, title } = useVinSessionState();
    const dispatch = useVinSessionDispatch();
    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <>
            <Button
                style={{
                    width: isVinLoading ? 198 : isVinSessionActive ? 303 : 177,
                    marginRight: 12,
                    backgroundColor: '#001957',
                    padding: 8,
                }}
                onPress={() => setVinSessionModal(true)}
            >
                {isVinLoading ? (
                    <div className={classes.vinLoaderContainer}>
                        <div className={classes.spinnerContainer}>
                            <Spinner animation="border" style={{ color: '#FFFFFF' }} />
                        </div>
                        <div className={classes.vinLoader}>Opening VIN Session</div>
                    </div>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: isVinSessionActive ? 8 : 4,
                        }}
                    >
                        <div>
                            <VinSessionCarIcon />
                        </div>
                        {isVinSessionActive && <div className={classes.vinSessionActive}>VIN Session: {title}</div>}
                        {!isVinSessionActive && <div className={classes.vinSessionInActive}>Open VIN Session</div>}
                    </div>
                )}
            </Button>
            {isVinSessionActive && (
                <Button
                    style={{ width: 40, height: 40, backgroundColor: '#001957', padding: 8, marginLeft: -15 }}
                    onPress={() => {
                        closeVinSession(selectedVin?.vinRecord.vin, account?.accountId, setIsLoading, dispatch);
                        dispatch(vinSessionActions.setSelectedVin({ selectedVin: undefined }));
                        navigate('/');
                    }}
                >
                    <div className={classes.vinActiveButton}>
                        <Icon name="x-close" color="white" style={{ marginLeft: 5 }} />
                    </div>
                </Button>
            )}
        </>
    );
};
