import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import colors from '@packages/core/styles/colors';
import { Button, Icon, Typography } from '@packages/ui/shared';
import { Dropdown } from 'react-bootstrap';
import { dateRange, filterList, handleSelectedFilters } from '@web/vin-sessions/utils/vinSessionUtils';
import { useVinSessionDispatch, useVinSessionState } from '@web/vin-sessions/context/vin-sessions.hooks';
import { vinSessionActions } from '@web/vin-sessions/context/vin-sessions.state';
import { Favourite, Recent, TextIcon } from '@web/vin-sessions/assets/Icons';
import { format } from 'date-fns';
import { Value } from 'react-calendar/dist/cjs/shared/types';
import { DateTime } from 'luxon';
import { DateFormat } from '@web/administration/components/worksheet/inputPickers/util';
import Calendar from 'react-calendar';

type DocsFilterPanelProps = {};

interface CustomToggleProps {
    onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsDropdownTextOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = createUseStyles({
    filterPanelContainer: {
        height: 64,
        borderTop: `1px solid ${colors.grayThree}`,
        borderBottom: `1px solid ${colors.grayThree}`,
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    filterContainer: {
        display: 'flex',
        gap: 24,
    },
    customToggle: {
        borderRadius: 3,
        height: 40,
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        padding: '2px 4px 2px 8px',
        justifyContent: 'space-between',
    },
    dropdown: {
        '& .dropdown-menu': {
            width: 280,
            maxHeight: 300,
            padding: '8px 0 !important',
            borderRadius: `3px !important`,
            border: `1px solid ${colors.grayThree} !important`,
            boxShadow: `0px 16px 12px -12px rgba(0, 0, 0, 0.16) !important`,
            overflowY: 'auto !important',
        },
        '& .dropdown-menu::-webkit-scrollbar': {
            width: 10,
        },
        '& .dropdown-menu::-webkit-scrollbar-track': {
            backgroundColor: '#ffffff',
        },
        '& .dropdown-menu::-webkit-scrollbar-thumb': {
            backgroundColor: colors.blueFour,
            borderRadius: 10,
            border: '2px solid #ffffff',
        },
    },
    dropdownItem: {
        padding: '5px 10px',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.blueFour,
        },
        '& label': {
            display: 'flex',
            alignItems: 'center',
        },
        '& input[type="checkbox"]': {
            marginRight: 10,
        },
    },
    clearAllButton: {
        border: `1px solid ${colors.blueOne}`,
        borderRadius: 3,
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    calendarContainer: {
        position: 'absolute',
        zIndex: 1002,
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
});

export const CustomToggle: FC<CustomToggleProps> = React.forwardRef<HTMLDivElement, CustomToggleProps>(
    ({ children, onClick, style, setIsDropdownTextOpen }, ref) => {
        const classes = useStyles();

        function handleButtonClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
            event.preventDefault();

            if (!onClick) {
                return;
            }
            onClick(event);
            setIsDropdownTextOpen((prev) => !prev);
        }

        return (
            <div
                tabIndex={0}
                ref={ref as any}
                className={classes.customToggle}
                style={style}
                onClick={handleButtonClick}
            >
                {children}
            </div>
        );
    }
);

const DocsFilterPanel: FC<DocsFilterPanelProps> = (props) => {
    const classes = useStyles();
    const { selectedFilters } = useVinSessionState();
    const dispatch = useVinSessionDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDateRangeDropDown, setIsDateRangeDropdownOpen] = useState(false);
    const [isDropdownTextOpen, setIsDropdownTextOpen] = useState(false);
    const [hasQuickFilters, setHasQuickFilters] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownDateRangeRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<HTMLDivElement>(null);
    const [datePicker, setDatePicker] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const {} = props;

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
            if (dropdownDateRangeRef.current && !dropdownDateRangeRef.current?.contains(event.target as Node)) {
                setIsDateRangeDropdownOpen(false);
                if (calendarRef.current && !calendarRef.current?.contains(event.target as Node)) {
                    setDatePicker(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (dropdownRef.current && dropdownRef.current.classList.contains('show')) {
            setIsDropdownOpen(true);
        } else if (dropdownDateRangeRef.current && dropdownDateRangeRef.current.classList.contains('show')) {
            setIsDateRangeDropdownOpen(true);
        }
    }, [isDropdownTextOpen, dropdownRef, dropdownDateRangeRef]);

    const handleSetDate = useCallback((dateValue: any) => {
        const formattedDate = DateTime.fromFormat(dateValue, DateFormat).startOf('day').toFormat('MM/dd/yyyy');

        setSelectedDate(formattedDate);
        setDatePicker(false);
    }, []);

    const handleOnChange = useCallback(
        (data: Value) => {
            if (data) {
                const parsedDate = DateTime.fromISO(new Date(data.toString()).toISOString()).startOf('day');
                const formattedDate = parsedDate.toFormat(DateFormat);
                handleSetDate(formattedDate);
            }
        },
        [format]
    );

    const toggleCalendar = () => {
        if (dropdownDateRangeRef.current) {
            const { top, right } = dropdownDateRangeRef.current.getBoundingClientRect();
            setCalendarPosition({ top: top + 150, left: right + 4 });
            setDatePicker(true);
        }
    };

    const handleClearAllClick = () => {
        dispatch(
            vinSessionActions.setSelectedFilters({
                selectedFilters: [],
            })
        );
        setSelectedDate('');
        setDatePicker(false);
    };

    return (
        <div className={classes.filterPanelContainer}>
            <div className={classes.filterContainer}>
                {/* Filters */}
                <Dropdown className={classes.dropdown}>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="action-dropdown"
                        setIsDropdownOpen={setIsDropdownOpen}
                        setIsDropdownTextOpen={setIsDropdownTextOpen}
                        style={{
                            backgroundColor: selectedFilters.length == 0 ? colors.white : colors.blueFour,
                            width: isDropdownOpen ? 280 : 'auto',
                        }}
                    >
                        {selectedFilters.length > 0 ? (
                            <Typography
                                variant="label"
                                style={{ color: colors.blueOne, fontWeight: '800', textTransform: 'capitalize' }}
                            >
                                {selectedFilters[0]}
                            </Typography>
                        ) : (
                            <Typography
                                variant="label"
                                style={{ color: colors.blueOne, fontWeight: '800', textTransform: 'capitalize' }}
                            >
                                Select Filters
                            </Typography>
                        )}
                        <Icon name="arrow-small-down" style={{ color: colors.blueOne, marginTop: 1 }} size={22} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu ref={dropdownRef}>
                        {filterList.map((filter, index) => (
                            <div key={index} className={classes.dropdownItem}>
                                <label>
                                    <input
                                        type="checkbox"
                                        value={filter}
                                        checked={selectedFilters.includes(filter)}
                                        onChange={() =>
                                            handleSelectedFilters(filter, selectedFilters, vinSessionActions, dispatch)
                                        }
                                    />
                                    <Typography variant="dropdown">{filter}</Typography>
                                </label>
                            </div>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                {/* Dates */}
                <Dropdown show={isDateRangeDropDown} className={classes.dropdown} ref={dropdownDateRangeRef}>
                    <Dropdown.Toggle
                        as={CustomToggle}
                        id="action-dropdown"
                        onClick={() => setIsDateRangeDropdownOpen(!isDateRangeDropDown)}
                        setIsDropdownOpen={setIsDateRangeDropdownOpen}
                        setIsDropdownTextOpen={setIsDropdownTextOpen}
                        style={{
                            backgroundColor: selectedDate.length == 0 ? colors.white : colors.blueFour,
                            width: isDateRangeDropDown || datePicker ? 200 : 'auto',
                        }}
                    >
                        {selectedDate.length > 0 ? (
                            <Typography
                                variant="label"
                                style={{ color: colors.blueOne, fontWeight: '800', textTransform: 'capitalize' }}
                            >
                                {selectedDate}
                            </Typography>
                        ) : (
                            <Typography
                                variant="label"
                                style={{ color: colors.blueOne, fontWeight: '800', textTransform: 'capitalize' }}
                            >
                                Date
                            </Typography>
                        )}
                        <Icon name="arrow-small-down" style={{ color: colors.blueOne, marginTop: 1 }} size={22} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                        style={{
                            width: isDateRangeDropDown || datePicker ? 200 : 'auto',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        {dateRange.map((range, index) => (
                            <div
                                key={index}
                                className={`${classes.dropdownItem} ${classes.dateContainer}`}
                                onClick={() => {
                                    if (range == 'Custom date range') {
                                        toggleCalendar();
                                    } else {
                                        setSelectedDate(range);
                                        setIsDateRangeDropdownOpen(false);
                                        setDatePicker(false);
                                    }
                                }}
                            >
                                <Typography variant="dropdown">{range}</Typography>
                                {range == 'Custom date range' && (
                                    <Icon name="chevron-right" style={{ color: colors.grayFive }} size={16} />
                                )}
                            </div>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                {datePicker && (
                    <div
                        ref={calendarRef}
                        className={classes.calendarContainer}
                        style={{
                            top: `${calendarPosition.top}px`,
                            left: `${calendarPosition.left}px`,
                            padding: '10px',
                            backgroundColor: '#fff',
                        }}
                        onClick={(e) => {
                            if ((e.target as HTMLElement).classList.contains('react-calendar__month-view__days__day')) {
                                return;
                            }
                            e.stopPropagation();
                        }}
                    >
                        <Calendar
                            onChange={handleOnChange}
                            value={dateRange.includes(selectedDate) ? '' : selectedDate}
                        />
                    </div>
                )}

                {/* Icons */}
                <TextIcon />
                <Recent />
                <Favourite />
                <div style={{ display: 'flex' }}>
                    <Button
                        active={hasQuickFilters}
                        variant="ghost-blue"
                        iconLeft={<Icon color="blueOne" name="filter-list" />}
                        onPress={() => setHasQuickFilters(!hasQuickFilters)}
                    >
                        {hasQuickFilters ? 'Filters Applied' : 'More Filters'}
                    </Button>
                    {hasQuickFilters && (
                        <div className="ml-1">
                            <Button variant="ghost-blue" onPress={() => setHasQuickFilters(false)}>
                                <Icon color="blueOne" name="x-close" />
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className={classes.clearAllButton}>
                <Button variant="ghost-blue" onPress={handleClearAllClick}>
                    Clear all
                </Button>
            </div>
        </div>
    );
};

export default DocsFilterPanel;
