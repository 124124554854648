import React, { createContext, FC, useReducer, useEffect } from 'react';
import { permissionsReducer, PermissionsState, PermissionsAction, initialState } from './permissions.state';
import { PermissionsService } from './permissions.service';

type PermissionsActionDispatcher = React.Dispatch<PermissionsAction>;

interface PermissionsProviderProps {
    children: React.ReactNode;
    permissionsService: PermissionsService;
    accountId: string;
}

export const PermissionsStateContext = createContext<PermissionsState | undefined>(undefined);
export const PermissionsDispatchContext = createContext<PermissionsActionDispatcher | undefined>(undefined);

export const PermissionsProvider: FC<PermissionsProviderProps> = ({ children, permissionsService, accountId }) => {
    const [state, dispatch] = useReducer(permissionsReducer, initialState);

    useEffect(() => {
        const abortController = new AbortController();

        if (accountId) {
            const fetchPermissions = async () => {
                dispatch({ type: 'SET_LOADING', payload: { loading: true } });
                try {
                    const permissions = await permissionsService.fetchPermissions({
                        accountId,
                        abortSignal: abortController.signal,
                    });

                    dispatch({ type: 'SET_PERMISSIONS', payload: { permissions } });
                } catch (error: any) {
                    dispatch({ type: 'SET_ERROR', payload: { error: error.message } });
                } finally {
                    dispatch({ type: 'SET_LOADING', payload: { loading: false } });
                }
            };

            fetchPermissions();
        }

        return () => {
            abortController.abort();
        };
    }, [permissionsService, accountId]);

    return (
        <PermissionsStateContext.Provider value={state}>
            <PermissionsDispatchContext.Provider value={dispatch}>{children}</PermissionsDispatchContext.Provider>
        </PermissionsStateContext.Provider>
    );
};
