import { get, pick } from 'lodash';
import i18n from 'i18next';
import { Platform } from 'react-native';
import { isAfter, parseISO } from 'date-fns';

import {
    Qmr,
    Asset,
    QmrEwdState,
    AssetMediaType,
    QmrStatusTypeId,
    DsqmReviewStatusTypeId,
    TrReplyStatusType,
} from '@packages/models/api';
import { AssetMediaUrlsByType, AssetMediaViewModel, getAssetMediaUrlsByType } from '@packages/core/utils';
import colors from '@packages/core/styles/colors';

export enum QmrReportSectionStatus {
    Empty,
    Optional,
    Partial,
    Warning,
    Completed,
    Loading,
    Error,
}

const qmrSectionStatusIconMap = {
    [QmrReportSectionStatus.Empty]: 'check-circle-hollow',
    [QmrReportSectionStatus.Optional]: 'x-circle',
    [QmrReportSectionStatus.Partial]: 'warning',
    [QmrReportSectionStatus.Warning]: 'warning',
    [QmrReportSectionStatus.Completed]: 'check-circle-hollow',
    [QmrReportSectionStatus.Loading]: 'upload-in-progress',
    [QmrReportSectionStatus.Error]: 'error',
} as const;

const qmrSectionStatusColorMap = {
    [QmrReportSectionStatus.Empty]: 'grayThree',
    [QmrReportSectionStatus.Optional]: 'grayFive',
    [QmrReportSectionStatus.Partial]: 'yellowOne',
    [QmrReportSectionStatus.Warning]: 'yellowOne',
    [QmrReportSectionStatus.Completed]: 'blueOne',
    [QmrReportSectionStatus.Loading]: 'blueOne',
    [QmrReportSectionStatus.Error]: 'redOne',
} as const;

const qmrQaReviewIconMap = {
    [QmrStatusTypeId.PendingReview]: 'circle-indeterminate',
    [QmrStatusTypeId.UnderReview]: 'warning',
    [QmrStatusTypeId.Completed]: 'check-circle-hollow',
    [QmrStatusTypeId.MoreInfoRequested]: 'error',
} as const;

const qmrQaReviewColorMap = {
    [QmrStatusTypeId.PendingReview]: {
        iconColor: 'brandSecondary',
        badgeVariant: 'default',
    },
    [QmrStatusTypeId.UnderReview]: {
        iconColor: 'yellowOne',
        badgeVariant: 'yellow',
    },
    [QmrStatusTypeId.Completed]: {
        iconColor: 'greenOne',
        badgeVariant: 'green',
    },
    [QmrStatusTypeId.MoreInfoRequested]: {
        iconColor: 'redOne',
        badgeVariant: 'red',
    },
} as const;

const qmrDsqmReviewIconMap = {
    [DsqmReviewStatusTypeId.PendingReview]: 'circle-indeterminate',
    [DsqmReviewStatusTypeId.UnderReview]: 'warning',
    [DsqmReviewStatusTypeId.Completed]: 'check-circle-hollow',
    [DsqmReviewStatusTypeId.MoreInfoRequested]: 'error',
} as const;

const dsqmReviewStatusIconMap = {
    [DsqmReviewStatusTypeId.NoActionNeeded]: 'check-circle-hollow',
    [DsqmReviewStatusTypeId.PendingReview]: 'warning',
    [DsqmReviewStatusTypeId.UnderReview]: 'warning',
};

const qmrDsqmReviewColorMap = {
    [DsqmReviewStatusTypeId.PendingReview]: {
        iconColor: 'brandSecondary',
        badgeVariant: 'default',
    },
    [DsqmReviewStatusTypeId.UnderReview]: {
        iconColor: 'yellowOne',
        badgeVariant: 'yellow',
    },
    [DsqmReviewStatusTypeId.Completed]: {
        iconColor: 'greenOne',
        badgeVariant: 'green',
    },
    [DsqmReviewStatusTypeId.MoreInfoRequested]: {
        iconColor: 'redOne',
        badgeVariant: 'red',
    },
} as const;

const dsqmReviewStatusColorMap = {
    [DsqmReviewStatusTypeId.PendingReview]: {
        iconColor: 'yellowOne',
        badgeVariant: 'yellow',
    },
    [DsqmReviewStatusTypeId.UnderReview]: {
        iconColor: 'yellowOne',
        badgeVariant: 'yellow',
    },
    [DsqmReviewStatusTypeId.Completed]: {
        iconColor: 'greenOne',
        badgeVariant: 'green',
    },
};

const qmrTrReplyColorMap = {
    [TrReplyStatusType.Draft]: {
        badgeVariant: 'yellow',
    },
    [TrReplyStatusType.Issued]: {
        badgeVariant: 'yellow',
    },
    [TrReplyStatusType.TempReplyReceived]: {
        badgeVariant: 'blue',
    },
    [TrReplyStatusType.FinalReplyReceived]: {
        badgeVariant: 'blue',
    },
    [TrReplyStatusType.FinalReplyApproved]: {
        badgeVariant: 'green',
    },
    [TrReplyStatusType.FinalReplyRefused]: {
        badgeVariant: 'red',
    },
    [TrReplyStatusType.NotEscalated]: {
        badgeVariant: 'yellow',
    },
} as const;

export interface BaseQmrReportSection {
    status: QmrReportSectionStatus;
    required: boolean;
    iconName: string;
    iconColor: keyof typeof colors;
    description?: string;
}

export const getQmrRetailerSection = ({ qmr }: { qmr: Qmr | null }): BaseQmrReportSection => {
    const status = qmr ? QmrReportSectionStatus.Completed : QmrReportSectionStatus.Empty;

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description:
            Platform.OS === 'web'
                ? `#${qmr?.retailer.code}`
                : qmr
                  ? `${qmr.retailer.name} (#${qmr.retailer.code})`
                  : 'N/A',
    };
};

export const getQmrVehicleDetailsSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasRequiredVehicleDetailFields = !!qmr && qmr.modelYear && qmr.carlineId;
    const hasPartialVehicleDetailFields =
        !!qmr && (qmr.vin || qmr.modelYear || qmr.carlineId || qmr.transmissionNumber || qmr.engineNumber);

    const status = hasRequiredVehicleDetailFields
        ? QmrReportSectionStatus.Completed
        : submitAttempted && !hasRequiredVehicleDetailFields
          ? QmrReportSectionStatus.Error
          : hasPartialVehicleDetailFields
            ? QmrReportSectionStatus.Partial
            : QmrReportSectionStatus.Empty;

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description: qmr ? `${qmr.modelYear} ${qmr.carlineName}` : 'N/A',
    };
};

export const getQmrMediaSection = ({
    qmr,
    numPending = 0,
    numUploading = 0,
    numUploadErrors = 0,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    numPending?: number;
    numUploading?: number;
    numUploadErrors?: number;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const qmrAssetCount = (qmr && qmr.assets && qmr.assets.length) || 0;

    const status =
        numUploadErrors > 0
            ? QmrReportSectionStatus.Error
            : numUploading > 0
              ? QmrReportSectionStatus.Loading
              : numPending > 0
                ? QmrReportSectionStatus.Warning
                : qmrAssetCount > 0
                  ? QmrReportSectionStatus.Completed
                  : QmrReportSectionStatus.Optional;

    return {
        status,
        required: false,
        description:
            status === QmrReportSectionStatus.Error
                ? i18n.t('qmr:sections.media.secondaryError', {
                      count: numUploadErrors,
                  })
                : status === QmrReportSectionStatus.Loading
                  ? i18n.t('qmr:sections.media.secondaryUploading', {
                        count: numUploading,
                    })
                  : status === QmrReportSectionStatus.Warning
                    ? i18n.t('qmr:sections.media.secondaryPending', {
                          count: numPending,
                      })
                    : i18n.t('qmr:sections.media.secondary', {
                          count: qmrAssetCount,
                      }),
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
    };
};

export const getQmrConcernSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasRequiredConcernFields =
        !!qmr &&
        qmr.complaint?.trim().length &&
        typeof qmr.complaintDuplicated === 'boolean' &&
        qmr.mileage &&
        qmr.failureDate &&
        typeof qmr.ewdSignsExist === 'boolean' &&
        (qmr.ewdSignsExist === true
            ? qmr.ewdRollover ||
              qmr.ewdFire ||
              qmr.ewdAccident ||
              qmr.ewdInjury ||
              qmr.ewdDeath ||
              qmr.ewdPropertyDamage
            : true);

    const hasPartialConcernFields = qmr
        ? qmr.complaint?.trim().length ||
          typeof qmr.complaintDuplicated === 'boolean' ||
          qmr.repairOrderNumber ||
          qmr.mileage ||
          qmr.failureDate ||
          typeof qmr.ewdSignsExist === 'boolean'
        : false;

    const status = hasRequiredConcernFields
        ? QmrReportSectionStatus.Completed
        : submitAttempted && !hasRequiredConcernFields
          ? QmrReportSectionStatus.Error
          : hasPartialConcernFields
            ? QmrReportSectionStatus.Partial
            : QmrReportSectionStatus.Empty;

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description:
            Platform.OS === 'web'
                ? status === QmrReportSectionStatus.Completed
                    ? i18n.t('qmr:sections.status.complete', 'Complete')
                    : ''
                : (qmr && qmr.complaint) || '',
    };
};

export const getQmrCauseSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasRequiredCauseFields = !!qmr && qmr.cause;
    const hasPartialCauseFields = qmr ? qmr.cause || qmr.partNumber : false;

    const status = hasRequiredCauseFields
        ? QmrReportSectionStatus.Completed
        : submitAttempted && !hasRequiredCauseFields
          ? QmrReportSectionStatus.Error
          : hasPartialCauseFields
            ? QmrReportSectionStatus.Partial
            : QmrReportSectionStatus.Empty;

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description:
            Platform.OS === 'web'
                ? status === QmrReportSectionStatus.Completed
                    ? i18n.t('qmr:sections.status.complete', 'Complete')
                    : ''
                : (qmr && qmr.cause) || '',
    };
};

export const getQmrCorrectionSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasRequiredCorrectionFields =
        !!qmr &&
        (qmr.importedFromSiebel
            ? !!qmr.correction
            : !!qmr.correction && typeof qmr.issueResolved === 'boolean' && !!qmr.repairDate);

    const hasPartialCorrectionFields = qmr
        ? qmr.importedFromSiebel
            ? !!qmr.correction
            : !!qmr.correction || typeof qmr.issueResolved === 'boolean' || !!qmr.repairDate
        : false;

    const status = hasRequiredCorrectionFields
        ? QmrReportSectionStatus.Completed
        : submitAttempted && !hasRequiredCorrectionFields
          ? QmrReportSectionStatus.Error
          : hasPartialCorrectionFields
            ? QmrReportSectionStatus.Partial
            : QmrReportSectionStatus.Empty;

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description:
            Platform.OS === 'web'
                ? status === QmrReportSectionStatus.Completed
                    ? i18n.t('qmr:sections.status.complete', 'Complete')
                    : ''
                : (qmr && qmr.correction) || '',
    };
};

export const getQmrDtcCodesSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasDtcCodes = !!qmr && qmr.dtcCodesSet && Array.isArray(qmr.dtcCodes);

    const status = hasDtcCodes ? QmrReportSectionStatus.Completed : QmrReportSectionStatus.Optional;

    return {
        status,
        required: false,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description:
            Platform.OS === 'web'
                ? i18n.t('qmr:sections.dtcCodes.secondary', '{{ count}} added', {
                      count: (qmr && qmr.dtcCodes && qmr.dtcCodes.length) || 0,
                  })
                : (qmr && qmr.dtcCodes && qmr.dtcCodes.map((c) => c.dtcCode).join(', ')) ||
                  i18n.t('qmr:sections.status.optional', 'Optional'),
    };
};

export const getQmrFailCodeSection = ({
    qmr,
    submitAttempted = false,
}: {
    qmr: Qmr | null;
    submitAttempted?: boolean;
}): BaseQmrReportSection => {
    const hasFailCode = qmr && qmr.failCode;
    const requiresApprover = qmr?.failCodeApproved === false;

    const status =
        submitAttempted && !hasFailCode
            ? QmrReportSectionStatus.Error
            : hasFailCode || !qmr?.capabilities.addFailCodeToQmr || requiresApprover
              ? QmrReportSectionStatus.Completed
              : QmrReportSectionStatus.Empty;

    const getDescription = () => {
        if (!qmr?.capabilities.addFailCodeToQmr || requiresApprover) {
            return 'Sending to Approver';
        }

        if (hasFailCode && qmr?.isValidFailCode === true) {
            return `${qmr?.failCode} - ${qmr?.failCodeDescription || 'No Description'}`;
        }

        if (hasFailCode && qmr?.isValidFailCode === false) {
            return `${qmr?.failCode} - Invalid Fail Code Selected`;
        }

        return '';
    };

    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description: getDescription(),
    };
};

export const getQmrObjectiveSection = ({ qmr }: { qmr: Qmr | null }): BaseQmrReportSection => {
    const status = QmrReportSectionStatus.Completed;
    return {
        status,
        required: true,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
        description: qmr?.qmrCategories?.join(', '),
    };
};

export const getQmrAdditionalInfoSection = ({ qmr }: { qmr: Qmr | null }): BaseQmrReportSection => {
    const hasAdditionalComments =
        qmr && Array.isArray(qmr.additionalInfoComments) && qmr.additionalInfoComments.length > 0;
    const status = hasAdditionalComments ? QmrReportSectionStatus.Completed : QmrReportSectionStatus.Empty;

    return {
        status,
        required: false,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
    };
};

export const getQmrOutcome = ({ qmr }: { qmr: Qmr | null }): BaseQmrReportSection => {
    const hasQmrOutcome = qmr && qmr.outcomeAvailable;
    const status = hasQmrOutcome ? QmrReportSectionStatus.Completed : QmrReportSectionStatus.Empty;

    return {
        status,
        required: false,
        iconName: qmrSectionStatusIconMap[status],
        iconColor: qmrSectionStatusColorMap[status],
    };
};

export const getQmrVehicleDetails = (qmr: Qmr | null) => {
    if (!qmr) {
        return { displayName: 'N/A', vin: 'N/A' };
    }

    const { modelYear, carlineName, vin } = qmr;

    return {
        displayName: `${modelYear} ${carlineName}`,
        vin: vin || 'N/A',
    };
};

export type QmrAssetDetails = {
    assetId: Asset['assetId'];
    assetTypeId: Asset['assetTypeId'];
    assetProcessingStateId: Asset['assetProcessingStateId'];
    createdTimestampDescription: Asset['createdTimestampDescription'];
    isAdditionalUpload: boolean;
    name: Asset['name'];
    note: Asset['note'];
    isRestricted: boolean;
    media: AssetMediaUrlsByType;
    thumbnailMedia: AssetMediaViewModel;
};

export const getQmrAssetDetails = (qmr: Qmr | null): QmrAssetDetails[] => {
    if (!qmr || !Array.isArray(qmr.assets)) {
        return [];
    }

    return qmr.assets
        .map((asset) => {
            // reduces media array to map of
            // AssetMediaType => { uri, size }
            const media = getAssetMediaUrlsByType(asset.media, asset.assetId, asset.name);
            const restrictedAssetIds = qmr.restrictedAssetIds || [];

            return {
                assetId: asset.assetId,
                assetTypeId: asset.assetTypeId,
                assetProcessingStateId: asset.assetProcessingStateId,
                createdTimestampDescription: asset.createdTimestampDescription,
                isAdditionalUpload:
                    !asset.importedFromSiebel &&
                    isAfter(parseISO(asset.createdTimestamp), parseISO(qmr.firstSubmittedTimestamp)),
                note: asset.note,
                name: asset.name,
                isRestricted: restrictedAssetIds.includes(asset.assetId),
                media,
                thumbnailMedia:
                    media[AssetMediaType.Thumbnail] || media[AssetMediaType.Original] || media[AssetMediaType.File],
            };
        })
        .filter((asset) => {
            return asset.isRestricted ? !!qmr.capabilities.viewRestrictedMediaOnQmr : true;
        });
};

export const getQmrRoleReview = (qmr: Qmr) => {
    const trReplyStatusType = get(
        qmr,
        'trReplyStatus.trReplyStatusId',
        TrReplyStatusType.NotEscalated
    ) as TrReplyStatusType;
    return {
        qa: {
            iconName: qmrQaReviewIconMap[qmr.qmrStatus.qmrStatusTypeId],
            ...qmrQaReviewColorMap[qmr.qmrStatus.qmrStatusTypeId],
        },
        dsqm: {
            iconName: dsqmReviewStatusIconMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
            ...dsqmReviewStatusColorMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
        },
        dqsm: {
            iconName: qmrDsqmReviewIconMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
            ...qmrDsqmReviewColorMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
        },
        trReply: {
            ...qmrTrReplyColorMap[trReplyStatusType],
        },
        dsqmStatus: {
            iconName: qmrQaReviewIconMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
            ...qmrQaReviewColorMap[qmr.dsqmReviewStatus.dsqmReviewStatusTypeId],
        },
    };
};

export const initialQmrEwdState: QmrEwdState = {
    ewdSignsExist: undefined,
    ewdAccident: false,
    ewdDeath: false,
    ewdDeathCount: 0,
    ewdFire: false,
    ewdInjury: false,
    ewdInjuryCount: 0,
    ewdPropertyDamage: false,
    ewdRollover: false,
};

export function getQmrEwdState(qmr: Qmr): QmrEwdState {
    return Object.assign(
        {},
        initialQmrEwdState,
        pick(qmr, [
            'ewdSignsExist',
            'ewdRollover',
            'ewdFire',
            'ewdAccident',
            'ewdPropertyDamage',
            'ewdInjury',
            'ewdInjuryCount',
            'ewdDeath',
            'ewdDeathCount',
        ])
    );
}
