import { DraggableFormElement, FormElement, FormElementOption, FormElementAction, FormElementFile } from './form';
import { TechlineStatusCode } from './techline';

export enum WorksheetGroupStatus {
    Pending = 'PENDING',
    Archived = 'ARCHIVED',
    Pilot = 'PILOT',
    Published = 'PUBLISHED',
}

export enum WorkSheetStatusEnum {
    All = 'ALL',
    Pending = 'PENDING',
    Pilot = 'PILOT',
    Published = 'PUBLISHED',
    Archived = 'ARCHIVED',
    AddVersion = 'ADD VERSION',
}

export enum WorksheetCaseTypeStatus {
    Active = 'ACTIVE',
}

export interface CaseType {
    caseTypeId: string;
    northstarId: string;
    caseType: string;
}

export interface CaseTypesResponse {
    casetypes: CaseType[];
}
export interface WorksheetForm {
    worksheetId?: string;
    worksheetGroupId?: string;
    caseTypeId: string;
    caseType?: string;
    northstarId: string;
    groupStatus: string;
    worksheetGroupName: string;
    elements: WorksheetFormElement[];
    description?: string;
    displayname: string;
    worksheetStatus: string;
    version: number;
    createdAccount?: WorksheetUsersAccount;
    lastUpdatedAccount?: WorksheetUsersAccount;
    createdTimestamp?: string;
    createdTimestampForDisplay?: string;
    lastUpdatedTimestamp?: string;
    lastUpdatedTimestampForDisplay?: string;
    worksheetSubmissionStatus?: WorksheetAnswerStatus;
    existingElements?: ExistingElements[];
    oldWorksheetElements?: WorksheetFormElement[];
}
export interface WorksheetFormElementOptions extends FormElementOption {
    trgtWorksheetElementIdList: string[];
    assets?: Asset[];
    optionDisplayOrder: number;
}

export interface selectedOption {
    answerText: string;
    optionId: string;
    optionLabel: string;
}

export enum WorksheetAssetImageType {
    ORIGINAL = 'ORIGINAL',
    THUMBNAIL = 'THUMBNAIL',
    THUMBNAIL_SMALL = 'THUMBNAIL_SMALL',
}

export enum WorksheetAssetType {
    IMAGE = 'IMAGE',
    FILE = 'FILE',
}
interface Media {
    assetImageId: string;
    assetImageTypeId: WorksheetAssetImageType;
    assetVideoId?: string;
    assetFileId?: string;
    assetVideoTypeId?: string;
    assetImageTypeIdDescription: string;
    url: string;
    contentType: string;
    sizeInBytes: number;
    sizeInBytesDescription: string;
    width: number;
    widthDescription: string;
    height: number;
    heightDescription: string;
    createdTimestamp: Date;
    createdTimestampDescription: string;
}

export enum AssetProcessingStateId {
    UPLOADING = 'UPLOADING',
    PROCESSING = 'PROCESSING',
    COMPLETE = 'COMPLETE',
    ERROR = 'ERROR',
}
export interface Asset {
    assetId: string;
    assetTypeId: WorksheetAssetType;
    assetTypeIdDescription: string;
    assetDispositionId: string;
    assetDispositionIdDescription: string;
    assetProcessingStateId: AssetProcessingStateId;
    assetProcessingStateIdDescription: string;
    percentUploaded: number;
    percentUploadedDescription: string;
    name: string;
    importedFromSiebel: boolean;
    createdTimestamp: Date;
    createdTimestampDescription: string;
    caption: string;
    media: Media[];
}

export interface WorksheetFormElement {
    worksheetElementId: string;
    worksheetElementTypeId: FormElement['formElementTypeId'];
    worksheetElementLabel: FormElement['formElementLabel'];
    worksheetElementTooltip: FormElement['formElementTooltip'];
    worksheetElementDescription: FormElement['formElementDescription'];
    worksheetElementOrder: number;
    worksheetElementDefaultValue: boolean;
    worksheetElementRequired: boolean;
    worksheetElementAllowMultiple: boolean;
    worksheetElementHasRule: boolean;
    selectedOptions?: selectedOption[];
    answerAssets?: Asset[];
    options: WorksheetFormElementOptions[];
    action?: FormElementAction;
    assets?: Asset[];
    formElementFile?: FormElementFile;
}

export interface WorksheetFormElementOption {
    optionId: string;
    optionValue: string;
    optionLabel: string;
    optionPlaceholder: string;
    optionImage?: string;
    isOther?: boolean;
    trgtElementIdList?: string[];
    optionDisplayOrder?: number;
}

export interface CreateAsset {
    assetDispositionId: 'WORKSHEET';
    worksheetElementId?: string;
    assetTypeId: WorksheetAssetType;
    hasOverlay: boolean;
    name: string;
    sizeInBytes?: number;
    caption?: string;
    note?: string;
}
export interface CreateOptionAsset extends Omit<CreateAsset, 'worksheetElementId'> {
    worksheetElementOptionId?: string;
}
export interface CreateOverlayAsset extends CreateAsset {
    replacesAssetId?: string;
    note?: string;
}

export type CreateAssetTypes = CreateOverlayAsset | CreateAsset | CreateOptionAsset;

export interface CreateAssets {
    assets: CreateAssetTypes[];
}

export interface CreateAssetsResonseHttpHeaders {
    'x-amz-meta-asset-id': string;
    'x-amz-meta-asset-disposition-id': string;
    'x-amz-meta-access-token': string;
    'x-amz-meta-asset-type-id': string;
    'x-amz-meta-backend-callback-base-url': string;
}

interface PresignedUpload {
    httpMethod: string;
    url: string;
    expirationTimestamp: string;
    expirationTimestampDescription: string;
    httpHeaders: CreateAssetsResonseHttpHeaders;
}

export interface AnswerPresignedUpload {
    httpMethod: string;
    url: string;
    expirationTimestamp: string;
    expirationTimestampDescription: string;
    httpHeaders: {
        'x-ms-blob-type': string;
    };
}

export interface AnswerAsset {
    assetId: string;
    assetTypeId: WorksheetAssetType;
    assetTypeIdDescription: string;
    assetDispositionId: 'TECHSHARE';
    assetDispositionIdDescription: string;
    assetProcessingStateId: AssetProcessingStateId;
    assetProcessingStateIdDescription: string;
    percentUploaded: number;
    percentUploadedDescription: string;
    name: string;
    importedFromSiebel: boolean;
    createdTimestamp: string;
    createdTimestampDescription: string;
}

export interface CreateAnswerAssetsResponse {
    results: {
        assets: { asset: AnswerAsset; presignedUpload: AnswerPresignedUpload }[];
        statusCode: number;
        status: string;
        message: string;
        totalRecords: string;
    };
}
export interface OverlayPresignedUpload extends Omit<PresignedUpload, 'httpHeaders'> {
    httpHeaders: {
        'x-amz-meta-asset-id': string;
    };
}
interface CreateAssetData {
    presignedUpload: PresignedUpload;
    asset: Asset;
    overlayPresignedUpload: OverlayPresignedUpload;
}
export interface CreateAssetsResponse {
    assets: CreateAssetData[];
}

interface DeleteOptionAssets {
    worksheetElementOptionId: string;
    assetId: string;
}

interface DeleteElementAssets {
    worksheetElementId: string;
    assetId: string;
}

export type DeleteAssetBody = DeleteOptionAssets | DeleteElementAssets;

export interface CreateWorksheetFormRequestBody {
    caseTypeId: string;
    northstarId: string;
    groupStatus: string;
    worksheetGroupName: string;
    elements: Omit<WorksheetFormElement, 'formElementFile'>[];
    description?: string;
    displayname: string;
    worksheetStatus: WorksheetGroupStatus;
    version: number;
}

export interface UpdateWorksheetFormRequestBody extends CreateWorksheetFormRequestBody {
    worksheetId: string;
    worksheetGroupId: string;
    caseType: string;
    existingElements?: ExistingElements[];
}

export interface AddVersionWorksheetFormRequestBody extends UpdateWorksheetFormRequestBody {}

export interface UpdateWorksheetFormStatusBody {
    worksheetId: string;
    worksheetStatus: WorkSheetStatusEnum;
}

export interface UpdateWorksheetFormStatusResponse extends WorksheetFormGetResponse {}

export interface WorksheetFormDto extends CreateWorksheetFormRequestBody {
    worksheetId: string;
    createdTimestamp: string;
    createdTimestampForDisplay: string;
}

export interface WorksheetUsersAccount {
    accountId: string;
    accountStatusId: WorksheetGroupStatus;
    name: string;
    firstName: string;
    lastName: string;
    timeZone: string;
    locale: string;
}

export interface ExistingElements {
    elementId: string;
    isActive: boolean;
    mappingId: string;
    statusCode: number;
    worksheetId: string;
}

export interface WorksheetFormGetResponse extends CreateWorksheetFormRequestBody {
    caseType: string;
    worksheetGroupId: string;
    worksheetId: string;
    createdAccount: WorksheetUsersAccount;
    lastUpdatedAccount: WorksheetUsersAccount;
    createdTimestamp: string;
    createdTimestampForDisplay: string;
    lastUpdatedTimestamp: string;
    lastUpdatedTimestampForDisplay: string;
    existingElements: ExistingElements[];
    worksheetSubmissionStatus?: WorksheetAnswerStatus;
}

export interface DraggableWorksheetFormElement extends WorksheetFormElement {
    title: string;
    description: string;
    iconName: string;
}

export interface DraggableWorksheetFormComponent extends DraggableFormElement {}

export interface Worksheets {
    worksheetId: string;
    displayName: string;
    version: number;
    displayNameWithVersion: string;
    status: WorksheetGroupStatus;
    lastUpdatedTimestamp: string;
    allowEdit: boolean;
}
export interface WorksheetsFetchObject {
    caseType: string;
    statusList: WorksheetGroupStatus[];
    lastUpdatedTimestampForDisplay: string;
    lastUpdatedTimestamp: string;
    totalCount: number;
    maxVersion: number;
    worksheets: Worksheets[];
}

export type WorksheetsFetchResponse = {
    summaryMap: Record<string, WorksheetsFetchObject>;
    totalCount: number;
};

export const enum WorksheetAnswerStatus {
    Submitted = 'SUBMITTED',
    Draft = 'DRAFT',
}
export interface SelectedOption {
    optionId: string;
    optionLabel: string;
    answerText: string;
}

export interface CreateSelectedOptionAssets {
    assetTypeId: WorksheetAssetType;
    assetDispositionId: 'TECHSHARE';
    caseNumber: string;
    fileName: string;
    techlinecasestatus: TechlineStatusCode;
    restricted: boolean;
    hasOverlay: boolean;
    note: string;
    worksheetQuestionSubmissionSourceId: string;
}

export interface WorksheetListByCaseTypeBody {
    caseTypes: { caseType: string; northstarId: string }[];
    isPilotUser: boolean;
}

export interface SaveCaseTypeRequestBody {
    casenumber: string;
    caseTypeIntersects: {
        casetypesourceid: string;
        isprimary: boolean;
        statuscode: number;
    }[];
}

export interface WorksheetListByCaseType {
    [x: string]: { worksheetId: string; displayName: string }[];
}

export interface WorksheetElementAnswers extends WorksheetAnswerElement {
    asset?: WorksheetAnswerAsset;
}

export interface WorksheetAnswerElement {
    elementId: string;
    selectedOptions: SelectedOption[];
    sourceId?: string;
    elementOrder?: string;
}

export interface WorksheetAnswerAsset {
    answerFile: FormElementFile;
}

export interface WorksheetAnswerAssetBody {
    assetTypeId: WorksheetAssetType;
    assetDispositionId: 'TECHSHARE';
    caseNumber: string;
    fileName: string;
    restricted: boolean;
    hasOverlay: boolean;
    note: string;
    worksheetQuestionSubmissionSourceId: string;
}

// For updating image (draw, write etc...)
export interface WorksheetUpdateAnswerAsset {
    assetTypeId: WorksheetAssetType.IMAGE;
    assetDispositionId: 'TECHSHARE';
    caseNumber: string;
    name: string;
    fileName: string;
    restricted: boolean;
    note: string;
    hasOverlay: boolean;
    replacesAssetId: string;
}

export interface CreateAnswerAssetsBody {
    assets: (WorksheetAnswerAssetBody | WorksheetUpdateAnswerAsset)[];
    techlinecasestatus: string;
}

export interface WorksheetAnswerBody {
    submissionId: string;
    caseNumber: string;
    userName: string;
    worksheetId: string;
    answers: WorksheetAnswerElement[];
    status: WorksheetAnswerStatus;
    // isPrimary: boolean;
}

export interface WorksheetSubmissionResponse {
    statusCode: number;
    message: string;
    status: string;
    results: {
        retailerId: string;
        lastUpdatedTimestamp: string;
        lastUpdatedTimestampForDisplay: string;
        submissionId: string;
        caseNumber: string;
        userName: string;
        worksheetId: string;
        answers: WorksheetAnswerElement[];
        status: WorksheetAnswerStatus;
        statusCode: number;
    };
}

export interface DeleteDraftWorksheetSubmissionResponse {
    statusCode: number;
    status: string;
    message: string;
    results: string;
}

export interface WorksheetSubmissions {
    lastUpdatedTimestamp: string;
    lastUpdatedTimestampForDisplay: string;
    retailerNumber: string;
    worksheetCaseType: string;
    worksheetCaseTypeSourceId: string;
    status: WorksheetAnswerStatus;
    statusCode: string;
    submissionId: string;
    userDisplayName: string;
    userName: string;
    worksheetId: string;
    worksheetTitle: string;
}
