import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { format, parse, isAfter, isValid } from 'date-fns';

import { TextInputHelper } from '@packages/ui/shared';
import { useQmrState, patchQmr, useQmrDispatch } from '@packages/contexts/qmrs';
import { qmrsService } from '@web/services/singletons';
import { useMatch } from 'react-router-dom';
import { PatchQmrDto } from '@packages/models/api';
import { useAbortController } from '@packages/core/http';

const RepairOrderDetailsDraft: FC<{ concrenInput: React.ReactElement }> = ({ concrenInput }) => {
    const { t } = useTranslation();
    const { abortSignalRef } = useAbortController();

    const qmrDispatch = useQmrDispatch();
    const { qmr } = useQmrState();
    const [didInit, setDidInit] = useState(false);
    const editRouteMatch = useMatch('/qmrs/:displayIdentifier/edit');

    const [repairOrderNumber, setRepairOrderNumber] = useState(qmr?.repairOrderNumber ?? '');
    const [mileage, setMileage] = useState<number | null>(qmr?.mileage ?? null);
    const [failureDate, setFailureDate] = useState(qmr?.failureDate ?? '');

    const maxFailureDate = format(new Date(), 'yyyy-MM-dd');
    const maxFailureDateDisplay = format(new Date(), 'MM/dd/yyyy');
    const [dateError, setDateError] = useState('');

    const saveChanges = useCallback(
        (qmrPatch: PatchQmrDto) => {
            if (!qmr) {
                return;
            }

            patchQmr({
                qmrId: qmr.qmrId,
                qmrsService,
                qmrDispatch,
                signal: abortSignalRef.current,
                qmrPatch,
                isLocalPatch: !!editRouteMatch,
            }).catch((e) => {
                alert(e.message);
            });
        },
        [abortSignalRef, editRouteMatch, qmr, qmrDispatch]
    );

    const timeoutRefs = useRef<{ [k: string]: NodeJS.Timeout }>({});
    useEffect(() => {
        if (timeoutRefs.current.repairOrderNumber) {
            clearTimeout(timeoutRefs.current.repairOrderNumber);
        }

        if ((!qmr?.repairOrderNumber && !repairOrderNumber) || qmr?.repairOrderNumber === repairOrderNumber) {
            return;
        }

        timeoutRefs.current.repairOrderNumber = global.setTimeout(() => {
            saveChanges({ repairOrderNumber });
        }, 60000);
    }, [saveChanges, repairOrderNumber, qmr?.repairOrderNumber]);

    useEffect(() => {
        if (timeoutRefs.current.mileage) {
            clearTimeout(timeoutRefs.current.mileage);
        }

        if ((!qmr?.mileage && !mileage) || qmr?.mileage === mileage) {
            return;
        }

        timeoutRefs.current.mileage = global.setTimeout(() => {
            saveChanges({ mileage });
        }, 60000);
    }, [saveChanges, mileage, qmr?.mileage]);

    useEffect(() => {
        if (timeoutRefs.current.failureDate) {
            clearTimeout(timeoutRefs.current.failureDate);
        }

        if ((!qmr?.failureDate && !failureDate) || qmr?.failureDate === failureDate) {
            return;
        }

        timeoutRefs.current.failureDate = global.setTimeout(() => {
            if (!isValid(parse(failureDate, 'yyyy-MM-dd', new Date()))) {
                saveChanges({ failureDate: null });
                return;
            }

            saveChanges({ failureDate });
        }, 60000);
    }, [saveChanges, failureDate, qmr?.failureDate]);

    useEffect(() => {
        if (!qmr || didInit) {
            return;
        }

        setDidInit(true);

        setRepairOrderNumber(qmr.repairOrderNumber || '');
        setMileage(qmr.mileage || null);
        setFailureDate(qmr.failureDate || '');
    }, [didInit, qmr]);

    useEffect(() => {
        const parsed = parse(failureDate, 'yyyy-MM-dd', new Date());
        const referenceDate = new Date(2014, 0, 1);
        if (isValid(parsed)) {
            if (isAfter(parsed, new Date())) {
                setDateError(`Must be on or before ${maxFailureDateDisplay}`);
                return;
            }
            if (!isAfter(parsed, referenceDate)) {
                setDateError('Must be on or after 01/01/2014');
                return;
            }

            setDateError('');
        }
        setDateError('');
    }, [failureDate, maxFailureDateDisplay]);

    return (
        <>
            <h4 className="mb-4">{t('qmr:sections.repairOrder.title')}</h4>

            <Row>
                <Col xs={12} className="mb-3">
                    <TextInputHelper
                        editable={!!editRouteMatch ? qmr?.capabilities.editRepairOrderOnQmr : true}
                        optional
                        controlId="qmr-repairOrderNumber"
                        label={t('qmr:inputs.repairOrderNumber.label')}
                        value={repairOrderNumber}
                        onChangeText={(v) => {
                            setRepairOrderNumber(v);
                        }}
                        placeholder={t('qmr:inputs.repairOrderNumber.placeholder', 'Enter RO Number')}
                        onBlur={() => {
                            if (!qmr) {
                                return;
                            }

                            if (qmr.repairOrderNumber !== repairOrderNumber) {
                                const qmrPatch = { repairOrderNumber };
                                patchQmr({
                                    qmrId: qmr.qmrId,
                                    qmrsService,
                                    qmrDispatch,
                                    isLocalPatch: true,
                                    qmrPatch,
                                });
                                saveChanges(qmrPatch);
                            }
                        }}
                    />
                </Col>

                <Col xs={6} className="mb-3">
                    <TextInputHelper
                        required
                        controlId="qmr-mileage"
                        maxLength={6}
                        label={t('qmr:inputs.mileage.label')}
                        value={mileage === null ? '' : '' + mileage}
                        onChangeText={(mileageText) => {
                            const parsed = parseInt(mileageText, 10);
                            const mileageUpdate = parsed >= 0 ? parsed : null;
                            setMileage(mileageUpdate || null);
                        }}
                        onBlur={() => {
                            if (!qmr) {
                                return;
                            }

                            if (qmr.mileage !== mileage) {
                                const qmrPatch = { mileage };
                                patchQmr({
                                    qmrId: qmr.qmrId,
                                    qmrsService,
                                    qmrDispatch,
                                    isLocalPatch: true,
                                    qmrPatch,
                                });
                                saveChanges(qmrPatch);
                            }
                        }}
                        placeholder={t('qmr:inputs.mileage.placeholder', 'Enter Mileage')}
                    />
                </Col>

                <Col xs={6} className="mb-3">
                    <TextInputHelper
                        required
                        type="date"
                        controlId="qmr-failureDate"
                        label={t('qmr:inputs.failureDate.label')}
                        value={failureDate}
                        maxDate={maxFailureDate}
                        onChangeText={(v) => {
                            setFailureDate(v);
                        }}
                        onBlur={() => {
                            if (!qmr) {
                                return;
                            }

                            if (qmr.failureDate === failureDate) {
                                return;
                            }

                            const parsed = parse(failureDate, 'yyyy-MM-dd', new Date());
                            const referenceDate = new Date(2014, 0, 1);
                            if (isValid(parsed)) {
                                const qmrPatch = { failureDate };
                                patchQmr({
                                    qmrId: qmr.qmrId,
                                    qmrsService,
                                    qmrDispatch,
                                    isLocalPatch: true,
                                    qmrPatch,
                                });
                                saveChanges(qmrPatch);
                            } else if (!isValid(parsed) || isAfter(parsed, referenceDate)) {
                                setDateError('');
                            }
                        }}
                        errorMessage={dateError}
                    />
                </Col>

                <Col xs={12}>{concrenInput}</Col>
            </Row>
        </>
    );
};

export default RepairOrderDetailsDraft;
