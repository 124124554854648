import React, { FC, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { RobotoFontFamily, Typography } from '@packages/ui/shared';
import { TableCell, TableHeader, TableRenderer, TableRow } from '@web/components/table';
import { Download, Eye, FavouriteOpen, Layers, NoFoundError, Share } from '@web/vin-sessions/assets/Icons';
import colors from '@packages/core/styles/colors';
import { HoverModal } from '../docs/hover-modal';
import { PublicationType } from './publication-type-icons';
import { StisDocument } from '@web/vin-sessions/types';
import { useAuthState } from '@packages/contexts/auth';

type StisTableProps = {
    rowId?: 1;
    publications: StisDocument[];
};

const useStyles = createUseStyles({
    iconsContainer: {
        height: 56,
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        maxWidth: 250,
    },
    popOver: {
        minWidth: 400,
        color: '#333',
        border: '1px solid #ddd',
        borderRadius: 8,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: 15,
    },
    description: {
        border: '1px solid #e0e0e0',
        padding: 10,
        borderRadius: 5,
        backgroundColor: '#f7f7f7',
        fontFamily: RobotoFontFamily.Regular,
        fontWeight: '400',
        fontSize: 15,
        maxHeight: 224,
        overflowY: 'auto !important',
        '&::-webkit-scrollbar': {
            width: 10,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#f7f7f7',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.grayThree,
            borderRadius: 10,
            border: '2px solid #f7f7f7',
        },
    },
    layers: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '400',
        fontSize: 15,
        gap: 5,
    },
    icons: {
        cursor: 'pointer',
    },
    documentType: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
});

const StisTable: FC<StisTableProps> = (props) => {
    const { publications } = props;
    const classes = useStyles();
    const { account } = useAuthState();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rowsData, setRowsData] = useState<any>([]);

    const formatDate = (isoDate: string) => {
        const date = new Date(isoDate);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    };

    return (
        <div style={{ padding: 20 }}>
            <TableRenderer<any>
                isLoading={isLoading}
                tableRowsData={publications.map((row, index) => ({ ...row, index }))}
                tableRowsDataSetter={setRowsData}
                noResultsTitle={'No results found'}
                emptyStateProps={{
                    image: <NoFoundError />,
                    description: "Try adjusting your search or filters to find what you're looking for",
                    descriptionStyle: { fontWeight: '400', fontSize: 12, lineHeight: 15 },
                }}
                tableHeaderRowRenderer={() => {
                    return (
                        <TableRow>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Document Type
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Title / ID / Case No
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Doc Code
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Created Date
                                </Typography>
                            </TableHeader>
                            <TableHeader>
                                <Typography variant="label" numberOfLines={1}>
                                    Actions
                                </Typography>
                            </TableHeader>
                        </TableRow>
                    );
                }}
                tableBodyRowRenderer={(rowData) => {
                    return (
                        <TableRow
                            key={rowData.rowId}
                            style={{ backgroundColor: !account && rowData.index > 2 ? '#F8F8F8' : '#fffff' }}
                        >
                            <TableCell>
                                <div className={classes.documentType}>
                                    <PublicationType publicationType={rowData.docType} size={20} />
                                    <Typography numberOfLines={1} style={{ marginTop: 2 }}>
                                        {rowData.docType}
                                    </Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className={classes.title}>
                                    <HoverModal
                                        title={rowData.title}
                                        description="This document provides a comprehensive guide to maintaining and troubleshooting the battery and charging system of your Subaru vehicle. It includes step-by-step procedures for regular maintenance, diagnostic techniques for identifying common charging issues, and recommendations for ensuring optimal battery performance. The guide also covers the use of specific tools and equipment for accurate diagnostics and repairs. This document provides a comprehensive guide to maintaining and troubleshooting the battery and charging system of your Subaru vehicle. It includes step-by-step procedures for regular maintenance, diagnostic techniques for identifying common charging issues, and recommendations for ensuring optimal battery performance. The guide also covers the use of specific tools and equipment for accurate diagnostics and repairs."
                                        creationDate="Feb 18, 2020"
                                        version="03.01"
                                        toolNumber="T-CHG-04567"
                                        applicability="All Subaru Models (20120-2024)"
                                        index={rowData.index}
                                    />
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{rowData.docCode}</Typography>
                                </div>
                            </TableCell>

                            <TableCell>
                                <div className="d-flex align-items-center">
                                    <Typography numberOfLines={1}>{formatDate(rowData.createdDate)}</Typography>
                                </div>
                            </TableCell>

                            <div className={classes.iconsContainer}>
                                {account && (
                                    <>
                                        <div className={classes.icons} onClick={() => {}}>
                                            <Eye />
                                        </div>
                                        <div className={classes.icons} onClick={() => {}}>
                                            <Share />
                                        </div>
                                        <div className={classes.icons} onClick={() => {}}>
                                            <FavouriteOpen />
                                        </div>
                                    </>
                                )}
                                <div className={classes.icons} onClick={() => {}}>
                                    <Download color={!account && rowData.index > 2 ? '#8FA3BC' : '#1F518F'} />
                                </div>
                            </div>
                        </TableRow>
                    );
                }}
                isPublicUser={account ? false : true}
            />
        </div>
    );
};

export default StisTable;
