import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Icon } from '@packages/ui/shared';
import { Retailer } from '@web/techline/views';
import { Typeahead } from 'react-bootstrap-typeahead';
import colors from '@packages/core/styles/colors';

export interface SearchRetailerProps {
    allRetailers: Retailer[];
    clearSelection: () => Promise<void>;
    fetchRetailers: (text: string) => void;
    labelKey?: (option: Retailer) => string;
    placeholder?: string;
    recentSearch: () => Promise<void>;
    selected: Retailer[];
    setSelected: (selected: Retailer[]) => void;
}

const useStyles = createUseStyles({
    clearIcon: {
        position: 'absolute',
        right: 8,
        top: 8,
        zIndex: 2,
        backgroundColor: colors.white,
        cursor: 'pointer',
    },
});

export const SearchRetailer: React.FC<SearchRetailerProps> = ({
    allRetailers,
    clearSelection,
    fetchRetailers,
    labelKey = (option) => `${option.retailerName} (#${option.retailerNumber})`,
    placeholder = 'Search Retailer',
    recentSearch,
    selected,
    setSelected,
}) => {
    const classes = useStyles();
    const typeaheadRef = useRef<any>(null);
    const [oldSelect, setOldSelect] = useState<string>('');

    const handleInputChange = async (text: string) => {
        fetchRetailers(text);
    };

    const handleFocus = async () => {
        if (typeaheadRef.current && typeaheadRef.current.inputNode) {
            typeaheadRef.current.inputNode.value = '';
        }
    };

    const handleClearSelection = async () => {
        setOldSelect('');
        await clearSelection();
        if (typeaheadRef.current) {
            typeaheadRef.current.clear();
            typeaheadRef.current.toggleMenu();
            typeaheadRef.current.inputNode.focus();
        }
    };

    const handleSelectionChange = (selected: Retailer[]) => {
        const isUserSelection = selected.length > 0;
        setSelected(selected);
        recentSearch();
        if (typeaheadRef.current && isUserSelection) {
            typeaheadRef.current.inputNode.blur();
            setOldSelect(labelKey(selected[0]));
        }
    };

    const handleToggleMenu = () => {
        if (typeaheadRef.current) {
            typeaheadRef.current.toggleMenu();
            typeaheadRef.current.inputNode.focus();
        }
    };

    const handleBlur = () => {
        recentSearch();
        if (typeaheadRef.current) {
            const inputValue = typeaheadRef.current.inputNode.value;

            if (inputValue === '') {
                return;
            }

            if (selected.length > 0) {
                const selectedLabel = labelKey(selected[0]);
                typeaheadRef.current.setState({ text: selectedLabel });
            } else {
                typeaheadRef.current.setState({ text: oldSelect });
            }
        }
    };

    return (
        <div className="w-25">
            <Typeahead
                filterBy={() => true}
                id="search-retailer"
                labelKey={labelKey}
                onBlur={handleBlur}
                onChange={handleSelectionChange}
                onFocus={handleFocus}
                onInputChange={handleInputChange}
                options={allRetailers}
                placeholder={placeholder}
                ref={typeaheadRef}
                selected={selected}
            >
                <div className={classes.clearIcon}>
                    {typeaheadRef.current?.inputNode?.value?.length ? (
                        <Icon name="x-close" onPress={handleClearSelection} />
                    ) : (
                        <Icon name="chevron-down" onPress={handleToggleMenu} />
                    )}
                </div>
            </Typeahead>
        </div>
    );
};
