import { HttpClient } from '@packages/core/http';

export interface QmrsReviewPendingDTO {
    qmrId: string;
    stsIdentifier: string;
    vin: string;
    name: string;
    qmrLink: string;
    districtId: string;
}

export interface PendingReviewQmrsResponse {
    pendingReviewQmrs: QmrsReviewPendingDTO[];
    pendingReviewNqrs: QmrsReviewPendingDTO[];
}

export class PendingReviewQmrsService {
    constructor(private http: HttpClient) {}

    fetchPendingReviewQmrs({
        ignoreCache = false,
        signal,
        isNQR,
    }: {
        ignoreCache?: boolean;
        signal?: AbortSignal;
        isNQR?: boolean;
    }) {
        const url = `/qmr/dashboard/${isNQR ? 'pendingreviewnqrs' : 'pendingreviewqmrs'}`;

        return this.http.orchestrateRequest<PendingReviewQmrsResponse>({
            method: 'GET',
            url,
            ignoreCache,
            signal,
        });
    }
}
