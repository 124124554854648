import React, { FC } from 'react';
import { Navigate } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';

import { Account, AccountStatusId } from '@packages/models/api';
import { WebStorageKeys } from '@packages/core/storage';
import { useAuthState, AuthStatus } from '@packages/contexts/auth';

import { storageService } from '@web/services/singletons';

interface PrivateRouteProps {
    path: string;
    routeGuard?: (account: Account | null) => boolean;
}
/**
 * Returning components for private routes are changes from v5 to v6. We can not use directly Routes which return from PrivateRoutes
 * so We have to set it in element property of Routes to private it. and if user successfully authenticated then return <Outlet/>
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({ routeGuard, path }) => {
    const { account, status: authStatus } = useAuthState();
    const location = useLocation();

    // https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
    if (path !== '/onboard' && account?.accountStatusId === AccountStatusId.New) {
        storageService.setItem(WebStorageKeys.AuthRedirectUrl, location);
        return <Navigate to="/onboard" />;
    }

    if (authStatus !== AuthStatus.Authenticated) {
        return <React.Fragment></React.Fragment>;
    }

    // pass account object to guard function
    // if it returns false, don't render route
    if (typeof routeGuard === 'function' && !routeGuard(account)) {
        return <React.Fragment></React.Fragment>;
    }

    return <Outlet />;
};

export default PrivateRoute;
